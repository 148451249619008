import {
  CreditCardCreateModel,
  CreditCardInterface,
  CreditCardProviderInterface,
  PaymentGatewayInterface,
  UpdateBlueMediaPaymentStatusModel
} from '@on-arte/core-types';
import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';

import { api } from '../api';

export const getUserCards: () => Promise<CreditCardInterface[]> = (): Promise<CreditCardInterface[]> => new Promise(
  (resolve: ApiResolve<CreditCardInterface[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/payments/credit-cards').then(
      (response: AxiosResponse<CreditCardInterface[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const addNewCardToUser: (data: CreditCardCreateModel) => Promise<void> = (data: CreditCardCreateModel): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/payments/credit-cards', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const checkCardType: (
  cardNumber: string
) => Promise<CreditCardProviderInterface> = (
  cardNumber: string
): Promise<CreditCardProviderInterface> => new Promise(
  (resolve: ApiResolve<CreditCardProviderInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/payments/credit-cards/check-type', { cardNumber }).then(
      (response: AxiosResponse<CreditCardProviderInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const revokeCardFromUser: (id: string) => Promise<void> = (id: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.delete(`/payments/credit-cards/${id}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getAvailableGateways: () => Promise<PaymentGatewayInterface[]> = (): Promise<PaymentGatewayInterface[]> => new Promise(
  (resolve: ApiResolve<PaymentGatewayInterface[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/payments/blue-media/available-gateways').then(
      (response: AxiosResponse<PaymentGatewayInterface[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updatePaymentStatus: (data: UpdateBlueMediaPaymentStatusModel) => Promise<void> = (
  data: UpdateBlueMediaPaymentStatusModel
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/payments/blue-media/update-status', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
