import { Color, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { ContainerProps } from './loader.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${Color.White};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  /* TODO: change to enum */
  z-index: 900;
  opacity: ${({ $isVisible }: ContainerProps): number => $isVisible ? 1 : 0};
`;

export const LoaderItem: StyledComponent<'div'> = styled.div`
  background: ${Color.Error500};
  animation: loaderAnimation 1s infinite ease-in-out;
  width: 10px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  
  &:before,
  &:after {
    background: ${Color.Error500};
    animation: loaderAnimation 1s infinite ease-in-out;
    width: 10px;
    height: 40px;
    position: absolute;
    top: 0;
    content: '';
  }

    color: ${Color.Error500};
    margin: 0 auto;
    font-size: 11px;
    animation-delay: -0.16s;

  &::before {
    left: -15px;
    animation-delay: -0.32s;
  }
  &::after {
    left: 15px;
  }

  @keyframes loaderAnimation {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
`;
