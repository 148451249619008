import { StyledComponent, TextBox, ZIndex } from '@on-arte/ui';
import styled from 'styled-components';

import { StyledTextBoxProps } from './cookiesPopup.types';

export const StyledTextBox: StyledComponent<typeof TextBox, StyledTextBoxProps> = styled(TextBox)<StyledTextBoxProps>`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: ${ZIndex.CookiesPopup};
  transition: 0.3s;

  ${({ $visible }: StyledTextBoxProps): string => $visible ? `
    opacity: 1;
    pointer-events: initial;
  ` : `
    opacity: 0;
    pointer-events: none;
  `}
`;
