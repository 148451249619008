import { CartCheckoutInterface, CartInterface, DeliveryOptionInterface } from '@on-arte/core-types';
import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { 
  CartUpdatePaymentSettingsModel, 
  UpdateAddressIdInOrderModel, 
  UpdateCommentToOrderModel, 
  UpdateDeliveryInCartAuctionModel 
} from '@onArte/shared/models';

import { api } from '../api';

export const getCart: (cartId?: string) => Promise<CartInterface> = (cartId: string = ''): Promise<CartInterface> => new Promise(
  (resolve: ApiResolve<CartInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(cartId ? `/carts/${cartId}` : '/carts').then(
      (response: AxiosResponse<CartInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getDeliveryOptionsForAuctions: (cartId: string) => Promise<Record<string, DeliveryOptionInterface[]>> = (
  cartId: string
): Promise<Record<string, DeliveryOptionInterface[]>> => new Promise(
  (resolve: ApiResolve<Record<string, DeliveryOptionInterface[]>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/carts/${cartId}/available-deliveries`).then(
      (response: AxiosResponse<Record<string, DeliveryOptionInterface[]>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updateDiscountCode: (
  cartId: string, code: string
) => Promise<void> = (cartId: string, code: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.patch(`/carts/${cartId}/discount`, { codeValue: code }).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updateDeliveryInCartAuction: (cartId: string, auctionId: string, data: UpdateDeliveryInCartAuctionModel) => Promise<void> = (
  cartId: string, auctionId: string, data: UpdateDeliveryInCartAuctionModel
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/carts/${cartId}/auctions/${auctionId}/delivery`, data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const deleteDiscountCode: (
  cartId: string, code: string
) => Promise<void> = (cartId: string, code: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.delete(`/carts/${cartId}/discount`, { data: { codeValue: code } }).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updateAddressIdInOrder: (cartId: string, data: UpdateAddressIdInOrderModel) => Promise<void> = (
  cartId: string, data: UpdateAddressIdInOrderModel
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.patch(`/carts/${cartId}/addresses`, data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const deleteCartItem: (
  cartId: string, itemId: string
) => Promise<void> = (cartId: string, itemId: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.delete(`/carts/${cartId}/auctions/${itemId}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updateCommentToOrder: (cartId: string, data: UpdateCommentToOrderModel) => Promise<void> = (
  cartId: string, data: UpdateCommentToOrderModel
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.patch(`/carts/${cartId}/comment`, data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const addCartItem: (
  cartId: string, auctionId: string
) => Promise<void> = (cartId: string, auctionId: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/carts/${cartId}/auctions/${auctionId}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getDeliveryOptionsForAuction: (cartId: string, auctionId: string) => Promise<DeliveryOptionInterface[]> = (
  cartId: string, auctionId: string
): Promise<DeliveryOptionInterface[]> => new Promise(
  (resolve: ApiResolve<DeliveryOptionInterface[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/carts/${cartId}/auctions/${auctionId}/available-deliveries`).then(
      (response: AxiosResponse<DeliveryOptionInterface[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updatePaymentSettings: (
  cartId: string, data: CartUpdatePaymentSettingsModel
  ) => Promise<void> = (
    cartId: string, data: CartUpdatePaymentSettingsModel
  ): Promise<void> => new Promise(
    (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
      api.patch(`/carts/${cartId}/payment`, data).then(
        (response: AxiosResponse<void>): void => { resolve(response?.data); }
      ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
    )
  );

export const finishCart: (cartId: string) => Promise<CartCheckoutInterface> = (
  cartId: string
): Promise<CartCheckoutInterface> => new Promise(
  (resolve: ApiResolve<CartCheckoutInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/carts/${cartId}/finish`).then(
      (response: AxiosResponse<CartCheckoutInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
