import { Breakpoint, Color, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { LetterButtonProps } from './baseViewWithAlphabet.props';

export const Container: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Gray200};
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
`;

export const Children: StyledComponent<'div'> = styled.div``;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  padding: 80px 40px 40px;

  @media ${Breakpoint.Tablet} {
    padding: 80px 32px 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 80px 20px 40px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 40px 20px;
  }
`;

export const LetterButton: StyledComponent<'button', LetterButtonProps> = styled.button<LetterButtonProps>`
  font-size: 16px;
  line-height: 26px;
  color: ${({ $active }: LetterButtonProps): string => $active ? Color.Black : Color.Gray500};
  text-transform: uppercase;
  margin-right: 12px;

  &:hover {
    color: ${Color.Black};
  }
`;
