import { SocialsProvider } from '@on-arte/ui';

import { AppConfig } from '@onArte/frontend/interfaces';

declare const window: {
  APP_VERSION: string;
  APP_INSTANCE: string;
  APP_GTM_ID: string;
};

export const appConfig: AppConfig = {
  api: process.env.REACT_APP_API_URL || '/api',
  websocket: process.env.REACT_APP_WEBSOCKET_URL || '/',
  applicationName: 'on*arte',
  gtmId: process.env.REACT_APP_GTM_ID
    ? process.env.REACT_APP_GTM_ID
    : (typeof window !== 'undefined')
      ? window.APP_GTM_ID || undefined
      : undefined,
  socials: [
    { type: SocialsProvider.Instagram, url: 'https://www.instagram.com/by_onarte/' },
    { type: SocialsProvider.Facebook, url: 'https://www.facebook.com/On-arte-112138707910731' },
    { type: SocialsProvider.Linkedin, url: 'https://www.linkedin.com/company/on-arte-sp-z-o-o/' },
  ],
  magazineUrl: 'https://magazyn.onarte.pl/',
  loggerSettings: {
    version: process.env.REACT_APP_VERSION
      ? process.env.REACT_APP_VERSION
      : (typeof window !== 'undefined')
        ? window.APP_VERSION || 'unknown'
        : 'unknown',
    env: process.env.REACT_APP_INSTANCE
      ? process.env.REACT_APP_INSTANCE
      : (typeof window !== 'undefined')
        ? window.APP_INSTANCE || 'unknown'
        : 'unknown',
    applicationId: process.env.REACT_APP_ID ?? 'on*arte website FE',
    service: process.env.REACT_APP_SERVICE ?? 'on*arte',
    clientToken: process.env.REACT_APP_FE_LOGGER_TOKEN
      ? process.env.REACT_APP_FE_LOGGER_TOKEN
      : '',
  },
  hiddenModules: {
    collectors: true
  }
};
