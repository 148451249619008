import { BoxesSlider, Breakpoint, Grid, Heading, SectionTitle, StyledComponent, MessageBox } from '@on-arte/ui';
import styled from 'styled-components';

import { StyledBoxesSliderProps } from './boxesSliderSection.types';

export const Container: StyledComponent<'div'> = styled.div``;

export const StyledBoxesSlider: StyledComponent<typeof BoxesSlider, StyledBoxesSliderProps> = styled(BoxesSlider)`
  max-width: calc(${Grid.FullHd} + ((100% - ${Grid.FullHd}) / 2) + 40px);
  margin: 0 0 ${({ $withoutBottomMargin }: StyledBoxesSliderProps): string => $withoutBottomMargin ? '0' : '80px'} auto;
`;

export const StyledSectionTitle: StyledComponent<typeof SectionTitle> = styled(SectionTitle)`
  max-width: calc(${Grid.FullHd} + 80px);
  margin: 0 auto 32px;
  padding: 0 40px;

  @media ${Breakpoint.Tablet} {
    padding: 0 32px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;

export const StyledHeading: StyledComponent<typeof Heading> = styled(Heading)`
  margin: 0 auto 32px;
  padding: 0 40px;

  @media ${Breakpoint.Tablet} {
    padding: 0 32px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;

export const StyledMessageBox: StyledComponent<typeof MessageBox> = styled(MessageBox)`
  max-width: 740px;
  margin: 0 10px 0 40px;

  @media ${Breakpoint.Tablet} {
    margin-left: 32px;
  }

  @media ${Breakpoint.Mobile} {
    margin-left: 20px;
  }
`;
