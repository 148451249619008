import { UserDeliverySettingsAction } from './userDeliverySettings.enum';
import { UserDeliverySettingsState } from './userDeliverySettings.interface';
import { UserDeliverySettingsActions } from './userDeliverySettings.type';

export const userDeliverySettingsReducer = (
  state: UserDeliverySettingsState, action: UserDeliverySettingsActions
): UserDeliverySettingsState => {
  switch (action.type) {
    case UserDeliverySettingsAction.SetAddresses:
      return { ...state, addresses: action.payload };
    case UserDeliverySettingsAction.SetAddressId:
      return { ...state, addressId: action.payload };
    case UserDeliverySettingsAction.SetCurrentAddressTypeInPopup:
      return { ...state, currentAddressTypeInPopup: action.payload };
    case UserDeliverySettingsAction.SetInitialBillingValues:
      return { ...state, initialBillingValues: action.payload };
    case UserDeliverySettingsAction.SetInitialShippingValues:
      return { ...state, initialShippingValues: action.payload };
    case UserDeliverySettingsAction.SetIsPopupVisible:
      return { ...state, isPopupVisible: action.payload };
    case UserDeliverySettingsAction.SetSmallDialogHeaderTranslation:
      return { ...state, smallDialogHeaderTranslation: action.payload };
    default:
      return state;
  }
};
