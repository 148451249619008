export enum RouteNameEnum {
  AboutUs = 'aboutUs',
  ArtistDetails = 'artistDetails',
  Artists = 'artists',
  ArtistsList = 'artistsList',
  ArtworkDetails = 'artworkDetails',
  ArtworksList = 'artworksList',
  CartBilling = 'cartBilling',
  CartDelivery = 'cartDelivery',
  CartMissingItem = 'cartMissingItem',
  CartPaymentError = 'cartPaymentError',
  CartSuccess = 'cartSuccess',
  ChoosePassword = 'choosePassword',
  CollectorDetails = 'collectorDetails',
  CollectorsList = 'collectorsList',
  Collection = 'collection',
  CollectionsList = 'collectionsList',
  EventDetails = 'eventDetails',
  EventsList = 'eventsList',
  HandbookBuyer = 'handbookBuyer',
  HandbookSeller = 'handbookSeller',
  Faq = 'faq',
  Home = 'home',
  Contact = 'contact',
  Logout = 'logout',
  NotFound = 'notFound',  
  Orders = 'orders',
  Payments = 'payments',
  PriceProposalAccepted = 'priceProposalAccepted',
  PriceProposalRejected = 'priceProposalRejected',
  PrivacyPolicy = 'privacyPolicy',
  Registration = 'registration',
  RegistrationConfirm = 'registrationConfirm',
  Regulations = 'regulations',
  RemindPassword = 'remindPassword',
  UserAccountSettings = 'userAccountSettings',
  UserArtworkAdd = 'userArtworkAdd',
  UserArtworkDetails = 'userArtworkDetails',
  UserArtworkEdit = 'userArtworkEdit',
  UserArtworkSalesSettings = 'userArtworkSalesSettings',
  UserBiddingsList = 'userBiddingsList',
  UserDataForPayouts = 'userDataForPayouts',
  UserDeliverySettings = 'userDeliverySettings',
  UserFavoriteArtworks = 'userFavoriteArtworks',
  UserMyShopping = 'userMyShopping',
  UserPublicProfileSettings = 'userPublicProfileSettings',
  UserSalesList = 'userSalesList',
  UserSoldArtworks = 'userSoldArtworks',
  Search = 'search',
  Shipping = 'shipping',
  SignIn = 'signIn',
  StaticSubscriptionConfirmation = 'staticSubscriptionConfirmation',
  StaticUnsubscriptionConfirmation = 'staticUnsubscriptionConfirmation',
  StaticSubscriptionError = 'staticSubscriptionError',
}
