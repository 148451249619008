import { BoxSlide, UseRedirect, useRedirect } from '@on-arte/ui';
import React, { useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { appConfig } from '@onArte/frontend/app.config';
import { useObjectsTransformations } from '@onArte/frontend/hooks';
import { UseObjectsTranformations } from '@onArte/frontend/interfaces';

import { Container, StyledBoxesSlider, StyledSectionTitle } from './blogBoxesSliderSection.styled';
import { BlogBoxesSliderSectionProps } from './blogBoxesSliderSection.types';

export const BlogBoxesSliderSection: React.FC<BlogBoxesSliderSectionProps> = (props: BlogBoxesSliderSectionProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { posts, withTopBar = false, className }: BlogBoxesSliderSectionProps = props;
  const { redirect }: UseRedirect = useRedirect();
  const { transformPostDtoToBoxSlideType }: UseObjectsTranformations = useObjectsTransformations();

  const blogPosts: BoxSlide[] = useMemo(
    (): BoxSlide[] => posts.map(transformPostDtoToBoxSlideType),
    [posts]
  );

  return (
    <Container className={className}>
      <StyledSectionTitle
        header={t('onarte.website.blogBoxesSliderSection.header')}
        buttonLabel={t('onarte.common.showMore')}
        buttonAction={(): void => redirect(appConfig.magazineUrl, { isExternal: true, inNewTab: true })}
        $withTopBar={withTopBar}
      />
      <StyledBoxesSlider slides={blogPosts} />
    </Container>
  );
};
