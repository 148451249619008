import { ItemSaleEditStatus } from '@onArte/shared/enums';

import { UserArtworkSalesSettingsState } from './userArtworkSalesSettingsState.interface';

export const userArtworkSalesSettingsInitialState: UserArtworkSalesSettingsState = {
  itemSalesType: ItemSaleEditStatus.Disabled,
  acceptablePrice: '',
  canParticipateEvent: false,
  activeAuctionUrl: '',
  activeAuctionDescription: '',
  activeAuction: null,
};
