import { ExternalUserProfileInterface, TranslationsEnum } from '@on-arte/core-types';
import { Breakpoint, useFormikForm, UseFormikForm, UseNotifications, useNotifications } from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import { updateProfile } from '@onArte/frontend/api/requests';
import { QueryKey } from '@onArte/frontend/enums';
import { useAuth } from '@onArte/frontend/hooks';
import { UpdateProfileFormInterface, UseAuth } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { FormikForm } from '@onArte/frontend/theme';

import { useUpdateProfileFormValidation } from './updateProfileForm.hook';
import { StyledButton, StyledInput } from './updateProfileForm.styled';
import { UpdateProfileFormProps } from './updateProfileForm.types';

export const UpdateProfileForm: React.FC<UpdateProfileFormProps> = (props: UpdateProfileFormProps): JSX.Element => {
  const { initialValues, profileData, updateProfileData }: UpdateProfileFormProps = props;
  const { t }: TransProps<never> = useTranslation();
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const { addToast }: UseNotifications = useNotifications();
  const { refreshProfileData }: UseAuth = useAuth();
  const updateProfileFormValidationSchema: Yup.SchemaOf<UpdateProfileFormInterface> = useUpdateProfileFormValidation();
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.SmallMobile });
  const queryClient: QueryClient = useQueryClient();

  const updateProfileAction: (data: UpdateProfileFormInterface) => void = (data: UpdateProfileFormInterface): void => {
    if (!profileData) {
      return;
    }

    updateProfile({ ...data, hash: profileData.hash })
      .then((response: ExternalUserProfileInterface): void => {
        const { agreements, ...user }: ExternalUserProfileInterface = response;
        refreshProfileData(user);
        updateProfileData(user);
        addToast({ content: t('onarte.website.userAccountSettingsView.updateProfileSuccess') });
      })
      .catch((error: FrontendApiError): void => {
        addToast({ content: t(error.message) });

        if (error.message === TranslationsEnum.WrongUserHash) {
          void queryClient.invalidateQueries(QueryKey.Profile);
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={updateProfileAction}
      validationSchema={updateProfileFormValidationSchema}
      validateOnChange={isFormSubmitted}
      validateOnBlur={isFormSubmitted}
      enableReinitialize
    >
      {({ handleSubmit, setFieldValue, errors, values }: FormikProps<UpdateProfileFormInterface>) => (
        <FormikForm onSubmit={handleSubmit}>
          <StyledInput
            label={t('onarte.common.firstName')}
            onChange={(value: string): void => setFieldValue('firstName', value)}
            validationMessage={errors.firstName ?? ''}
            value={values.firstName}
          />
          <StyledInput
            label={t('onarte.common.lastName')}
            onChange={(value: string): void => setFieldValue('lastName', value)}
            validationMessage={errors.lastName ?? ''}
            value={values.lastName}
          />
          <StyledInput
            label={t('onarte.common.phone')}
            onChange={(value: string): void => setFieldValue('phone', value)}
            validationMessage={errors.phone ?? ''}
            value={values.phone}
            withOnlyNumbersAllowed
          />
          <StyledInput
            label={t('onarte.common.email')}
            value={profileData?.email}
            disabled
          />
          <StyledButton
            label={t('onarte.common.saveChanges')}
            fullWidth={isSmallMobile}
            type='submit'
            onClick={setFormSubmitted}
          />
        </FormikForm>
      )}
    </Formik>
  );
};
