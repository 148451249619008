import { ArtistSummary, Button, FormField, FormSection, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const StyledArtistSummary: StyledComponent<typeof ArtistSummary> = styled(ArtistSummary)`
  max-width: 408px;
`;

export const StyledFormField: StyledComponent<typeof FormField> = styled(FormField)`
  ${StyledArtistSummary} + &,
  & + & {
    margin-top: 24px;
  }
`;

export const StyledFormSection: StyledComponent<typeof FormSection> = styled(FormSection)`
  & + & {
    margin-top: 64px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  ${StyledFormField} + & {
    margin-top: 24px;
  }
`;

export const SmallLocationFormWrapper: StyledComponent<'div'> = styled.div`
  max-width: 408px;
`;
