import { AttributeNameEnum, CategoryAttributeInterface, ItemAttributeInterface } from '@on-arte/core-types';

export function findAttributeValueByName(
  artworkAttributes: ItemAttributeInterface[], categoryAttributes: CategoryAttributeInterface[], attributeName: AttributeNameEnum
): string | undefined {
  const attributeId: number | undefined = categoryAttributes
    .find((categoryAttribute: CategoryAttributeInterface) => categoryAttribute.name === attributeName)?.id;

  return artworkAttributes
    .find((artworkAttribute: ItemAttributeInterface): boolean => artworkAttribute.attributeId === attributeId)?.value;
}
