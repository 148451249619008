import { SettingsInterface, SlidersArrayInterface, WebsiteUrlInterface } from '@on-arte/core-types';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { WebsiteUrlType } from '@onArte/shared/enums';
import { AgreementInterface } from '@onArte/shared/interfaces/common/users';

import { api } from '../api';

export const getAgreements: () => Promise<AgreementInterface[]> = (): Promise<AgreementInterface[]> => new Promise(
  (resolve: ApiResolve<AgreementInterface[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/static/agreements').then(
      (response: AxiosResponse<AgreementInterface[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getSettings: () => Promise<SettingsInterface> = (): Promise<SettingsInterface> => new Promise(
  (resolve: ApiResolve<SettingsInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/static/settings').then(
      (response: AxiosResponse<SettingsInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getSliders: () => Promise<SlidersArrayInterface[]> = (): Promise<SlidersArrayInterface[]> => new Promise(
  (resolve: ApiResolve<SlidersArrayInterface[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/static/sliders').then(
      (response: AxiosResponse<SlidersArrayInterface[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getRoutingDetails: (routingUrl: string, type: WebsiteUrlType) => Promise<WebsiteUrlInterface> = (
  routingUrl: string, type: WebsiteUrlType
): Promise<WebsiteUrlInterface> => new Promise(
  (resolve: ApiResolve<WebsiteUrlInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/static/website-routing?${stringify({ routingUrl, type }, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<WebsiteUrlInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
