import React, { Context } from 'react';

import { RoutingState } from '@onArte/frontend/interfaces';

export const RoutingContext: Context<RoutingState> = React.createContext<RoutingState>({
  routes: [],
  elementParamsFromRouting: {},
  currentWebsiteUrl: undefined,
  currentRouteObject: undefined,
});
