import { UserProfileInterface } from '@on-arte/core-types';
import { 
  useLogger, 
  UseLogger,
  UseState 
} from '@on-arte/ui';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getProfile } from '@onArte/frontend/api/requests';
import { BaseViewWithTabs } from '@onArte/frontend/components';
import { QueryKey } from '@onArte/frontend/enums';
import { UpdateProfileFormInterface } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { UserProfileWithToken } from '@onArte/shared/interfaces';

import { UpdatePasswordForm, UpdateProfileForm } from './components';
import { FormContainer, FormTitle, SettingsContainer } from './userAccountSettings.styled';

export const UserAccountSettingsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const [profileData, setProfileData]: UseState<UserProfileInterface | null> = useState<UserProfileInterface | null>(null);

  useQuery(
    [QueryKey.Profile],
    (): Promise<UserProfileWithToken> => getProfile(),
    {
      onSuccess: (data: UserProfileWithToken): void => setProfileData(data.user),
      onError: (error: FrontendApiError) => logger(QueryKey.Profile, error)
    }
  );

  const profileInitialValues: UpdateProfileFormInterface = useMemo(
    (): UpdateProfileFormInterface => {
      return {
        firstName: profileData?.firstName ?? '',
        lastName: profileData?.lastName ?? '',
        phone: profileData?.phone ?? ''
      };
    },
    [profileData]
  );

  const updateProfileData: (data: UserProfileInterface) => void = (data: UserProfileInterface): void => {
    setProfileData({ ...profileData, ...data });
  };
  
  return (
    <BaseViewWithTabs>
      <SettingsContainer>
        <FormContainer>
          <FormTitle>{t('onarte.website.userAccountSettingsView.formTitle.myData')}</FormTitle>
          <UpdateProfileForm
            initialValues={profileInitialValues}
            profileData={profileData}
            updateProfileData={updateProfileData}
          />
        </FormContainer>
        <FormContainer>
          <FormTitle>{t('onarte.common.password')}</FormTitle>
          <UpdatePasswordForm
            userHash={profileData?.hash ?? null}
            updateProfileData={updateProfileData}
          />
        </FormContainer>
      </SettingsContainer>
    </BaseViewWithTabs>
  );
};
