import { CollectionBoxItem } from '@on-arte/ui';

import { FileFromViews } from '@onArte/frontend/enums';

export const collectionBoxesItems: CollectionBoxItem[] = [
  { image: FileFromViews.CollectionImageFirst },
  { image: FileFromViews.CollectionImageSecond },
  { image: FileFromViews.CollectionImageThird },
  { image: FileFromViews.CollectionImageFourth }
];

export const youtubeHash: string = 'LsJKkI38zAw';
