import { AddPhotoTheme, FormFieldType, FormSectionData } from '@on-arte/ui';

export const artworkBasicInfoForm: FormSectionData[] = [
  {
    label: 'onarte.common.artworkBasicInfoForm.infoSection.label',
    children: [
      {
        type: FormFieldType.Autosuggest,
        label: 'onarte.common.itemsAttributes.author.label',
        description: {
          position: 'top',
          content: 'onarte.common.itemsAttributes.author.description',
        },
        name: 'manufacturer',
        alwaysAvailable: true,
        additionalFieldProps: {
          withAcceptNotSuggestedValue: true,
          onSelectNotSuggestedValuePromise: (): Promise<void> => new Promise((): void => undefined)
        },
        validationRules: [
          { type: 'required', value: true },
        ],
      },
      {
        type: FormFieldType.Input,
        label: 'onarte.common.itemsAttributes.name.label',
        name: 'name',
        alwaysAvailable: true,
        validationRules: [
          { type: 'required', value: true },
          { type: 'min', value: 3 },
          { type: 'max', value: 64 }
        ],
      },
    ]
  },
  {
    label: 'onarte.common.artworkBasicInfoForm.photosSection.label',
    children: [
      {
        type: FormFieldType.AddPhoto,
        name: 'artworkPhotos',
        alwaysAvailable: true,
        description: {
          position: 'top',
          content: 'onarte.common.itemsAttributes.artworkPhotos.description',
        },
        additionalFieldProps: {
          addPhotoTheme: AddPhotoTheme.WithBoxLoaderOnInit,
          requirements: [
            'onarte.common.itemsAttributes.artworkPhotos.requirements.1',
            'onarte.common.itemsAttributes.artworkPhotos.requirements.2',
            'onarte.common.itemsAttributes.artworkPhotos.requirements.3',
            'onarte.common.itemsAttributes.artworkPhotos.requirements.4',
          ],
          attachmentType: 'uploaded_itemPhoto',
          acceptedFilesTypes: 'image/png, image/jpeg',
          maxFileSizeInBytes: 5 * 1024 * 1024,
          maxNumberOfFiles: 8,
        },
        validationRules: [
          { type: 'required', value: true },
        ],
      },
    ]
  }
];
