import { MenuPositionData } from '@on-arte/ui';

import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const arrowMenuPositions: MenuPositionData[] = [
  // TODO: temporary hidden
  {
    label: 'onarte.website.meta.faq.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.Faq)?.url ?? '/',
  },
  // {
  //  label: 'onarte.website.meta.orders.title',
  //  internalPath: getRouteDetailsByName(RouteNameEnum.Orders)?.url ?? '/',
  // },
  // {
  //  label: 'onarte.website.meta.payments.title',
  //  internalPath: getRouteDetailsByName(RouteNameEnum.Payments)?.url ?? '/',
  // },
  // {
  //  label: 'onarte.website.meta.shipping.title',
  //  internalPath: getRouteDetailsByName(RouteNameEnum.Shipping)?.url ?? '/',
  // },
  {
    label: 'onarte.website.meta.privacyPolicy.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.PrivacyPolicy)?.url ?? '/',
  },
  {
    label: 'onarte.website.meta.regulations.title',
    internalPath: getRouteDetailsByName(RouteNameEnum.Regulations)?.url ?? '/',
  },
];
