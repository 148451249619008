import { Breadcrumbs, Breakpoint, LogoTheme, UseParsers, useParsers } from '@on-arte/ui';
import React from 'react';
import useCollapse from 'react-collapsed';
import { UseCollapseOutput } from 'react-collapsed/dist/types';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Params, useParams } from 'react-router-dom';

import { useBasket } from '@onArte/frontend/hooks';
import { UseBasket } from '@onArte/frontend/interfaces';

import { BasketColumn } from '../basketColumn/basketColumn.component';

import { 
  BreadcrumbsContainer,
  BasketColumnContainer, 
  ChildrenContainer, 
  Container, 
  InnerContainer, 
  LogoButton, 
  StyledLogo, 
  UserDetailsContainer, 
  MobileCartTitle,
  LogoContainer,
  Title,
  UnderlineButton,
  MobileCartContainer,
} from './cartBaseView.styled';
import { CartBaseViewProps } from './cartBaseView.types';

export const CartBaseView: React.FC<CartBaseViewProps> = (props: CartBaseViewProps): JSX.Element => {
  const { breadcrumbs, children }: CartBaseViewProps = props;
  const { t }: TransProps<never> = useTranslation();
  const { getCollapseProps, setExpanded, isExpanded }: UseCollapseOutput = useCollapse();
  const { id }: Readonly<Params<string>> = useParams();
  const { cartData }: UseBasket = useBasket(id);
  const { parsePrice }: UseParsers = useParsers();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const currency: string = 'zł';

  const onExpandedToggle: () => void = (): void => setExpanded(!isExpanded);

  return (
    <Container>
      <UserDetailsContainer>
        <InnerContainer>
          <LogoContainer>
            <LogoButton internalPath='/'>
              <StyledLogo logoTheme={LogoTheme.Classic} />
            </LogoButton>
          </LogoContainer>
          {isMobile && (
            <MobileCartContainer>
              <MobileCartTitle>
                <Title>
                  {t('onarte.website.cartBaseView.mobileCartContainer.title')} {parsePrice(cartData?.finalPrice ?? 0)} {currency}
                </Title>
                <UnderlineButton onClick={onExpandedToggle}>
                  {t(`onarte.website.cartBaseView.basketColumnContainer.${isExpanded ? 'collapse' : 'expand'}`)}
                </UnderlineButton>
              </MobileCartTitle>
              <BasketColumnContainer {...getCollapseProps()}>
                <BasketColumn />
              </BasketColumnContainer>
            </MobileCartContainer>
          )}
          <BreadcrumbsContainer>
            <Breadcrumbs breadcrumbs={breadcrumbs} mobileFullSize />
          </BreadcrumbsContainer>
          <ChildrenContainer>
            {children}
          </ChildrenContainer>
        </InnerContainer>
      </UserDetailsContainer>
      {!isMobile && (
        <BasketColumnContainer>
          <BasketColumn />
        </BasketColumnContainer>
      )}
    </Container>
  );
};
