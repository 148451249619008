import { Breakpoint, Color, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { PageTitle } from '@onArte/frontend/theme';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Color.Gray200};
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin-bottom: 0;
  padding-bottom: 40px;
`;

export const CollectionsContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: auto;
  padding: 40px 40px 120px;
  background-color: ${Color.White};

  @media ${Breakpoint.Tablet} {
    padding: 32px 32px 120px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 0 120px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding-bottom: 80px;
  }
`;
