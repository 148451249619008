import {
  AuctionDetailsInterface,
  AuctionTypeEnum,
  ItemStatusEnum,
  ItemWithAuctionsInterface,
  LocationTypeEnum,
  ThumbnailAttachmentTypeEnum
} from '@on-arte/core-types';
import {
  BooleanValue,
  ButtonTheme,
  DropdownOption,
  FormFieldType,
  FormFieldValue,
  getPathWithParams,
  Language,
  SmallLocationForm,
  AddressFormData,
  UseFormatDate,
  useFormatDate,
  UseFormikForm,
  useFormikForm,
  UseNotifications,
  useNotifications,
  UseState,
  useRedirect,
  UseRedirect
} from '@on-arte/ui';
import { FormikProps } from 'formik';
import React, { Dispatch, useReducer, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { changeSalesSettings, getItemDetails } from '@onArte/frontend/api/requests';
import { BaseViewWithBreadcrumbs } from '@onArte/frontend/components';
import { QueryKey } from '@onArte/frontend/enums';
import { FrontendApiError } from '@onArte/frontend/models';
import { getImageThumbnail, getItemSaleEditStatusByAuctionType } from '@onArte/frontend/utils';
import { ItemSaleEditStatus, RouteNameEnum } from '@onArte/shared/enums';
import { getItemActiveAuctionPerTypes, getRouteDetailsByName } from '@onArte/shared/utils';

import {
  UserArtworkSalesSettingsAction,
  UserArtworkSalesSettingsActions,
  userArtworkSalesSettingsInitialState,
  userArtworkSalesSettingsReducer,
  UserArtworkSalesSettingsState
} from './reducer';
import { itemSalesTypes } from './userArtworkSalesSettings.configs';
import { 
  SmallLocationFormWrapper, 
  StyledArtistSummary, 
  StyledButton, 
  StyledFormField, 
  StyledFormSection 
} from './userArtworkSalesSettings.styled';

export const UserArtworkSalesSettingsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Record<string, string | undefined> = useParams();
  const { redirect }: UseRedirect = useRedirect();
  const { addToast }: UseNotifications = useNotifications();
  const [itemData, setItemData]: UseState<ItemWithAuctionsInterface | null> = useState<ItemWithAuctionsInterface | null>(null);
  const formRef: React.Ref<FormikProps<AddressFormData>> = useRef(null);
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const { formatDate }: UseFormatDate = useFormatDate(Language.Pl);
  const [salesSettingsState, salesSettingsDispatch]: [
    UserArtworkSalesSettingsState, Dispatch<UserArtworkSalesSettingsActions>
  ] = useReducer(userArtworkSalesSettingsReducer, userArtworkSalesSettingsInitialState);

  const setFieldValue = (field: string, value: FormFieldValue): void => {
    switch (field) {
      case 'itemSalesType':
        salesSettingsDispatch({ type: UserArtworkSalesSettingsAction.SetItemSalesType, payload: value as string });
        break;
      case 'acceptablePrice':
        salesSettingsDispatch({ type: UserArtworkSalesSettingsAction.SetAcceptablePrice, payload: value as string });
        break;
      case 'canParticipateEvent':
        salesSettingsDispatch({ type: UserArtworkSalesSettingsAction.SetCanParticipateEvent, payload: value === BooleanValue.Yes });
        break;
    }
  };

  useQuery(
    [QueryKey.ArtworkDetails],
    (): Promise<ItemWithAuctionsInterface> => getItemDetails(id ?? ''),
    {
      onSuccess: (data: ItemWithAuctionsInterface): void => {
        if (data.status !== ItemStatusEnum.Approved) {
          redirect(getRouteDetailsByName(RouteNameEnum.NotFound)?.url ?? '/');
          return;
        }
        setItemData(data);
        const activeAuction: AuctionDetailsInterface | undefined = getItemActiveAuctionPerTypes(
          data.auctions, [AuctionTypeEnum.BuyNow, AuctionTypeEnum.PriceProposal, AuctionTypeEnum.Bidding]
        );

        if (activeAuction) {
          const url: string = getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/', { id: activeAuction.id });
          const description: string = t(
            'onarte.website.userArtworkSalesSettings.activeAuctionDescription',
            {
              eventName: activeAuction.event?.name,
              from: formatDate(activeAuction.validity?.from ?? 0, 'DD-MM-YYYY'),
              to: formatDate(activeAuction.validity?.to ?? 0, 'DD-MM-YYYY'),
            }
          );
          salesSettingsDispatch({
            type: UserArtworkSalesSettingsAction.SetAll,
            payload: {
              activeAuction,
              activeAuctionDescription: description,
              itemSalesType: getItemSaleEditStatusByAuctionType(activeAuction.type),
              acceptablePrice: (activeAuction.acceptablePrice ?? '').toString(),
              canParticipateEvent: data.canParticipateEvent,
              activeAuctionUrl: url,
            }
          });
        } else {
          salesSettingsDispatch({ type: UserArtworkSalesSettingsAction.SetCanParticipateEvent, payload: data.canParticipateEvent });
        }
      },
      // TODO: add logger
      onError: () => undefined
    }
  );

  const submit = async (): Promise<void> => {
    if (!formRef?.current || !id) {
      return;
    }
    const formObject: FormikProps<AddressFormData> = formRef.current;
    setFormSubmitted();
    const isValid: boolean = !Object.keys((await formObject.validateForm())).length;

    if (
      isValid
      && (salesSettingsState.itemSalesType === ItemSaleEditStatus.BuyNow ? parseFloat(salesSettingsState.acceptablePrice) > 0 : true)
    ) {
      changeSalesSettings(
        id,
        {
          type: salesSettingsState.itemSalesType as unknown as ItemSaleEditStatus,
          ...(salesSettingsState.acceptablePrice ? { acceptablePrice: parseInt(salesSettingsState.acceptablePrice, 10) } : {}),
          canParticipateEvent: salesSettingsState.canParticipateEvent,
          location: {
            ...formObject.values,
            apartmentNumber: formObject.values.apartmentNumber || null,
            type: LocationTypeEnum.Strict,
            latitude: 0,
            longitude: 0,
          }
        }
      ).then((): void => {
        addToast({ content: t('onarte.website.userArtworkSalesSettings.messages.success') });
        redirect(getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/');
      }).catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
    }
  };

  return (
    <BaseViewWithBreadcrumbs
      pageTitleSettings={{
        title: t('onarte.website.meta.userArtworkSalesSettings.title'),
        linkLabel: t('onarte.common.cancel'),
        linkAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/'),
        buttonLabel: t('onarte.common.saveChanges'),
        buttonAction: submit
      }}
      breadcrumbs={[
        { label: t('onarte.website.meta.profile.title'), path: getRouteDetailsByName(RouteNameEnum.UserAccountSettings)?.url ?? '/' },
        { label: t('onarte.website.meta.userSalesList.title'), path: getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/' },
        { label: t('onarte.website.meta.userArtworkSalesSettings.title'), path: '' },
      ]}
    >
      {itemData && (
        <>
          <StyledFormSection title={t('onarte.website.userArtworkSalesSettings.statusSection.label')}>
            <StyledArtistSummary
              image={getImageThumbnail(
                itemData.attachments.length
                  ? itemData.attachments[0]
                  : undefined,
                ThumbnailAttachmentTypeEnum.Size_340xAuto
              )}
              boxTitleDetails={{
                author: itemData.manufacturer.name,
                itemDescriptiveAttributes: [
                  itemData.name,
                  itemData.label,
                ],
                startPrice: salesSettingsState.activeAuction?.type === AuctionTypeEnum.Bidding
                  ? salesSettingsState.activeAuction.startPrice ?? undefined
                  : undefined,
                minimalPrice: salesSettingsState.activeAuction?.type === AuctionTypeEnum.Bidding
                  ? salesSettingsState.activeAuction.acceptablePrice ?? undefined
                  : undefined,
                estimationPriceLow: salesSettingsState.activeAuction?.type === AuctionTypeEnum.Bidding
                  ? salesSettingsState.activeAuction?.estimation?.from
                  : undefined,
                estimationPriceHigh: salesSettingsState.activeAuction?.type === AuctionTypeEnum.Bidding
                  ? salesSettingsState.activeAuction?.estimation?.to
                  : undefined,
                price: salesSettingsState.activeAuction?.price,
                biddersNumber: salesSettingsState.activeAuction?.offers.length || undefined,
              }}
            />
            {salesSettingsState.itemSalesType === ItemSaleEditStatus.Bidding ? (
              <>
                <StyledFormField
                  disabled
                  label={t('onarte.website.userArtworkSalesSettings.itemSalesType.label')}
                  fieldType={FormFieldType.Input}
                  setFieldValue={(): void => undefined}
                  fieldName=''
                  value={t('onarte.website.userArtworkSalesSettings.itemSalesType.auctionValue')}
                  description={{
                    position: 'bottom',
                    content: salesSettingsState.activeAuctionDescription
                  }}
                />
                <StyledButton
                  label={t('onarte.website.userArtworkSalesSettings.showAuction')}
                  buttonTheme={ButtonTheme.SecondarySolidBig}
                  onClick={(): void => redirect(salesSettingsState.activeAuctionUrl)}
                />
              </>
            ) : (
              <>
                <StyledFormField
                  label={t('onarte.website.userArtworkSalesSettings.itemSalesType.label')}
                  fieldType={FormFieldType.Dropdown}
                  setFieldValue={setFieldValue}
                  fieldName='itemSalesType'
                  additionalFieldProps={{
                    options: itemSalesTypes.map((option: DropdownOption): DropdownOption => ({ ...option, label: t(option.label)}))
                  }}
                  value={salesSettingsState.itemSalesType}
                  description={{
                    position: 'bottom',
                    content: t(`onarte.website.userArtworkSalesSettings.itemSalesType.description.${salesSettingsState.itemSalesType}`)
                  }}
                />
                {salesSettingsState.itemSalesType === ItemSaleEditStatus.BuyNow && (
                  <StyledFormField
                    label={t('onarte.website.userArtworkSalesSettings.acceptablePrice.label')}
                    fieldType={FormFieldType.Input}
                    setFieldValue={setFieldValue}
                    value={salesSettingsState.acceptablePrice}
                    fieldName='acceptablePrice'
                    validationMessage={isFormSubmitted && !salesSettingsState.acceptablePrice.match(/^[1-9]{1}[\d\.]*$/)
                      ? t('onarte.common.wrongValue')
                      : undefined
                    }
                    description={{
                      position: 'bottom',
                      content: t('onarte.website.userArtworkSalesSettings.acceptablePrice.description')
                    }}
                    additionalFieldProps={{
                      withPriceParsing: true
                    }}
                  />
                )}
                <StyledFormField
                  label={t('onarte.website.userArtworkSalesSettings.canParticipateEvent.label')}
                  fieldType={FormFieldType.Dropdown}
                  setFieldValue={setFieldValue}
                  value={salesSettingsState.canParticipateEvent ? BooleanValue.Yes : BooleanValue.No}
                  fieldName='canParticipateEvent'
                  additionalFieldProps={{
                    options: [
                      { name: BooleanValue.Yes, label: t('onarte.common.yes') },
                      { name: BooleanValue.No, label: t('onarte.common.no') }
                    ],
                  }}
                  description={{
                    position: 'bottom',
                    content: t('onarte.website.userArtworkSalesSettings.canParticipateEvent.label'),
                  }}
                />
              </>
            )}
          </StyledFormSection>
          <StyledFormSection title={t('onarte.website.userArtworkSalesSettings.locationSettings.label')}>
            <SmallLocationFormWrapper>
              <SmallLocationForm
                initialValues={{
                  street: itemData.location.address.street ?? '',
                  houseNumber: itemData.location.address.houseNumber ?? '',
                  apartmentNumber: itemData.location.address.apartmentNumber ?? '',
                  postalCode: itemData.location.address.postalCode?.replace('-', '') ?? '',
                  city: itemData.location.address.city,
                  country: itemData.location.address.country,
                }}
                isFormSubmitted={isFormSubmitted}
                ref={formRef}
              />
            </SmallLocationFormWrapper>
          </StyledFormSection>
        </>
      )}
    </BaseViewWithBreadcrumbs>
  );
};
