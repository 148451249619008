import { ContactConfig } from './contact.types';

export const contactConfig: ContactConfig = {
  companyName: 'on[c:orange]*[/c]arte',
  address: 
    `ul. Garbary 67 
    61-758 Poznań`,
  supportEmail: 'support@onarte.pl',
  phone: '+48 61 111 0183',
  collaborationEmail: 'hello@onarte.pl',
};
