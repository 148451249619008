import { useRedirect, UseRedirect } from '@on-arte/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { BaseErrorView } from '@onArte/frontend/components';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const NotFoundView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();

  return (
    <BaseErrorView 
      title={t('onarte.website.notFoundView.errorMessage.label')}
      buttonLabel={t('onarte.website.notFoundView.home.label')}
      buttonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/')}
    />
  );
};
