import {
  AttachmentInterface,
  AttachmentModel,
  AttachmentTypeEnum,
  AttributeNameEnum,
  CategoryAttributeInterface,
  CategoryWithAttributesInterface,
  ItemAttributeInterface,
  ItemAttributeModel,
  ItemAttributeTypeEnum,
  ItemStatusEnum,
  ItemWithAuctionsInterface,
} from '@on-arte/core-types';
import {
  AddPhotoFile,
  FormFieldData,
  FormFieldType,
  FormFieldValue,
  FormSectionData,
  getSingleValueFromFormFieldValue,
  SuggestionItem,
  UseFormikForm,
  useFormikForm,
  UseLogger,
  useLogger,
  UseNotifications,
  useNotifications,
  useRedirect,
  UseRedirect,
  UseState
} from '@on-arte/ui';
import React, { useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { BaseSchema } from 'yup';

import { getItemDetails, getItemsCategories, updateItemDetails } from '@onArte/frontend/api/requests';
import { ArtworkAddForm, BaseViewWithBreadcrumbs } from '@onArte/frontend/components';
import { artworkEditForm } from '@onArte/frontend/constants';
import { QueryKey } from '@onArte/frontend/enums';
import { useObjectsTransformations } from '@onArte/frontend/hooks';
import { UseObjectsTranformations } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { FormContainer } from './userArtworkEdit.styled';

export const UserArtworkEditView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Record<string, string | undefined> = useParams();
  const [itemData, setItemData]: UseState<ItemWithAuctionsInterface | null> = useState<ItemWithAuctionsInterface | null>(null);
  const [itemCategories, setItemCategories]: UseState<CategoryWithAttributesInterface[]> = useState<CategoryWithAttributesInterface[]>([]);
  const { setFormSubmitted, setFormNotSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const [selectedCategoryAttributes, setSelectedCategoryAttributes]: UseState<CategoryAttributeInterface[]>
  = useState<CategoryAttributeInterface[]>([]);
  const [formValues, setFormValues]: UseState<Record<string, FormFieldValue>> = useState<Record<string, FormFieldValue>>({});
  const [formValidationSchema, setFormValidationSchema]: UseState<BaseSchema | null> = useState<BaseSchema | null>(null);
  const { transformAttachmentInterfaceToAddPhotoFile }: UseObjectsTranformations = useObjectsTransformations();
  const { logger }: UseLogger = useLogger();
  const { addToast }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();

  useQuery(
    [QueryKey.ItemCategories],
    (): Promise<CategoryWithAttributesInterface[]> => getItemsCategories(),
    {
      onSuccess: (data: CategoryWithAttributesInterface[]): void => setItemCategories(data),
      onError: (error: FrontendApiError): void => logger(QueryKey.ItemCategories, error, 'error')
    }
  );

  useQuery(
    [QueryKey.ArtworkDetails],
    (): Promise<ItemWithAuctionsInterface> => getItemDetails(id ?? ''),
    {
      onSuccess: (data: ItemWithAuctionsInterface): void => {
        if (data.status !== ItemStatusEnum.Approved) {
          redirect(getRouteDetailsByName(RouteNameEnum.NotFound)?.url ?? '/');
          return;
        }
        setItemData(data);
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.ArtworkDetails, error, 'error')
    }
  );

  useEffect(
    (): void => {
      const categoryId: number = parseInt(getSingleValueFromFormFieldValue<string>(itemData?.category?.id?.toString() ?? ''), 10);
      const categoryAttributes: CategoryAttributeInterface[] = itemCategories
        .find((category: CategoryWithAttributesInterface): boolean => category.id === categoryId)
        ?.attributes ?? [];
      setSelectedCategoryAttributes(categoryAttributes);
    },
    [itemData, itemCategories]
  );

  const handleFormChange: (values: Record<string, FormFieldValue | AddPhotoFile[]>) => void = (
    values: Record<string, FormFieldValue | AddPhotoFile[]>
  ): void => setFormValues(values);

  const getAttributeValueByName = (attributeName: string): FormFieldValue => {
    const attribute: CategoryAttributeInterface | undefined = selectedCategoryAttributes
      .find((item: CategoryAttributeInterface): boolean => item.name === attributeName);
    
    if (attribute === undefined) {
      return '';
    } else {
      const value: string = itemData?.attributes
        .find((item: ItemAttributeInterface): boolean => item.attributeId === attribute.id)?.value ?? '';

      return attribute.type === ItemAttributeTypeEnum.MultipleChoice
        ? value.split(',').filter((splitedValue: string): boolean => splitedValue !== '')
        : value;
    }
  };

  const validationSchemaUpdate: (schemaObject: BaseSchema) => void = (
    schemaObject: BaseSchema
  ): void => setFormValidationSchema(schemaObject);

  const save: () => Promise<void> = async (): Promise<void> => {
    setFormNotSubmitted();
    setTimeout((): void => setFormSubmitted(), 0);
    if (formValidationSchema) {
      const isValid: boolean = formValidationSchema.isValidSync(formValues);
      if (isValid) {
        if (!id || !itemData) {
          return;
        }

        const selectedCategoryId: number = parseInt(getSingleValueFromFormFieldValue<string>(formValues['category']), 10);
        const attributesFromCategory: CategoryAttributeInterface[] = itemCategories
          .find((category: CategoryWithAttributesInterface): boolean => category.id === selectedCategoryId)
          ?.attributes ?? [];
        let attachments: AttachmentModel[] = [];
        const attributes: ItemAttributeModel[] = [];

        artworkEditForm.forEach((section: FormSectionData): void => {
          section.children.forEach((field: FormFieldData): void => {
            if (field.type === FormFieldType.AddPhoto && formValues[field.name] !== undefined) {
              attachments = [...attachments, ...formValues[field.name] as AttachmentModel[]];
            }
          });
        });

        attributesFromCategory
          .filter((attr: CategoryAttributeInterface): boolean => formValues[attr.name] !== undefined)
          .forEach((attr: CategoryAttributeInterface): void => {
            if (Array.isArray(formValues[attr.name]) && (formValues[attr.name] as [])?.length > 1) {
              attributes.push({
                attributeId: attr.id,
                value: (formValues[attr.name] as string[]).map((value: string): string => value).join(','),
              });
            } else if (getSingleValueFromFormFieldValue<string>(formValues[attr.name])) {
              attributes.push({
                attributeId: attr.id,
                value: getSingleValueFromFormFieldValue<string>(formValues[attr.name])
              });
            }
          });

        const manufacturerValue: SuggestionItem = getSingleValueFromFormFieldValue<SuggestionItem>(formValues['manufacturer']);
        updateItemDetails(
          id,
          {
            attributes,
            attachments,
            type: itemData.type,
            categoryId: selectedCategoryId,
            name: getSingleValueFromFormFieldValue<string>(formValues['name']),
            year: getSingleValueFromFormFieldValue<string>(formValues['year']),
            manufacturer: {
              name: manufacturerValue.label,
              ...(manufacturerValue.id ? { id: manufacturerValue.id } : {}),
            },
            locationId: itemData.location.id,
            description: getSingleValueFromFormFieldValue<string>(formValues['description']),
          }
        )
          .then((): void => {
            addToast({ content: t('onarte.website.userArtworkEditView.saveSuccess', { name: itemData.name }) });
            redirect(getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/');
          })
          .catch((error: FrontendApiError): void => addToast({ content: error.message }));
      }
    }
  };

  const formFiles: Record<string, AddPhotoFile[]> = useMemo(
    (): Record<string, AddPhotoFile[]> => {
      if (!itemData) {
        return {
          artworkPhotos: [],
          signaturePhoto: [],
          authenticityCertificatePhoto: [],
        };
      }

      return {
        artworkPhotos: itemData.attachments
          .filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.ItemPhoto)
          .map(transformAttachmentInterfaceToAddPhotoFile),
        signaturePhoto: itemData.attachments
          .filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.ItemSignature)
          .map(transformAttachmentInterfaceToAddPhotoFile),
        authenticityCertificatePhoto: itemData.attachments
          .filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.ItemCertificate)
          .map(transformAttachmentInterfaceToAddPhotoFile),
      };
    },
    [itemData?.attachments]
  );

  return (
    <BaseViewWithBreadcrumbs
      withGrayBackground={false}
      pageTitleSettings={{
        title: t('onarte.website.meta.userArtworkEdit.title'),
        linkLabel: t('onarte.common.back'),
        buttonLabel: t('onarte.common.saveChanges'),
        linkAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/'),
        buttonAction: save,
      }}
      breadcrumbs={[
        { label: t('onarte.website.meta.profile.title'), path: getRouteDetailsByName(RouteNameEnum.UserAccountSettings)?.url ?? '/' },
        { label: t('onarte.website.meta.userSalesList.title'), path: getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/' },
        { label: t('onarte.website.meta.userArtworkEdit.title'), path: '' },
      ]}
    >
      {!!(itemCategories.length && itemData && selectedCategoryAttributes.length) && (
        <FormContainer>
          <ArtworkAddForm
            initialValues={{
              manufacturer: {
                id: itemData.manufacturer.id,
                label: itemData.manufacturer.name,
              },
              name: itemData.name,
              year: itemData.year ?? '',
              category: [itemData.category.id.toString()],
              description: itemData.description ?? '',
              artworkPhotos: formFiles.artworkPhotos,
              signaturePhoto: formFiles.signaturePhoto,
              authenticityCertificatePhoto: formFiles.authenticityCertificatePhoto,
              [AttributeNameEnum.SerialNumber]: getAttributeValueByName(AttributeNameEnum.SerialNumber),
              [AttributeNameEnum.SignedByArtist]: getAttributeValueByName(AttributeNameEnum.SignedByArtist),
              [AttributeNameEnum.SignatureDescription]: getAttributeValueByName(AttributeNameEnum.SignatureDescription),
              [AttributeNameEnum.Height]: getAttributeValueByName(AttributeNameEnum.Height),
              [AttributeNameEnum.Width]: getAttributeValueByName(AttributeNameEnum.Width),
              [AttributeNameEnum.Depth]: getAttributeValueByName(AttributeNameEnum.Depth),
              [AttributeNameEnum.Weight]: getAttributeValueByName(AttributeNameEnum.Weight),
              [AttributeNameEnum.IsFramed]: getAttributeValueByName(AttributeNameEnum.IsFramed),
              [AttributeNameEnum.HeightWithFrame]: getAttributeValueByName(AttributeNameEnum.HeightWithFrame),
              [AttributeNameEnum.WidthWithFrame]: getAttributeValueByName(AttributeNameEnum.WidthWithFrame),
              [AttributeNameEnum.DepthWithFrame]: getAttributeValueByName(AttributeNameEnum.DepthWithFrame),
              [AttributeNameEnum.FrameMaterial]: getAttributeValueByName(AttributeNameEnum.FrameMaterial),
              [AttributeNameEnum.Medium]: getAttributeValueByName(AttributeNameEnum.Medium),
              [AttributeNameEnum.Technique]: getAttributeValueByName(AttributeNameEnum.Technique),
              [AttributeNameEnum.TechniqueOther]: getAttributeValueByName(AttributeNameEnum.TechniqueOther),
              [AttributeNameEnum.Material]: getAttributeValueByName(AttributeNameEnum.Material),
              [AttributeNameEnum.MaterialOther]: getAttributeValueByName(AttributeNameEnum.MaterialOther),
              [AttributeNameEnum.Style]: getAttributeValueByName(AttributeNameEnum.Style),
              [AttributeNameEnum.StyleOther]: getAttributeValueByName(AttributeNameEnum.StyleOther),
              [AttributeNameEnum.Subject]: getAttributeValueByName(AttributeNameEnum.Subject),
              [AttributeNameEnum.SubjectOther]: getAttributeValueByName(AttributeNameEnum.SubjectOther),
              [AttributeNameEnum.Color]: getAttributeValueByName(AttributeNameEnum.Color),
              [AttributeNameEnum.AuthenticityCertificate]: getAttributeValueByName(AttributeNameEnum.AuthenticityCertificate),
            }}
            onValuesChange={handleFormChange}
            categories={itemCategories}
            isFormSubmitted={isFormSubmitted}
            formStructure={artworkEditForm}
            files={formFiles}
            validationSchemaUpdate={validationSchemaUpdate}
          />
        </FormContainer>
      )}
    </BaseViewWithBreadcrumbs>
  );
};
