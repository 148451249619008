import { HeaderMenu, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { StyledHeaderMenuProps } from './headerMenu.types';

export const Container: StyledComponent<'div'> = styled.div``;

export const StyledHeaderMenu: StyledComponent<typeof HeaderMenu, StyledHeaderMenuProps> = styled(HeaderMenu)<StyledHeaderMenuProps>`
  transition: ${({ $transitionEnabled }: StyledHeaderMenuProps): string => $transitionEnabled ? '0.3s' : 'none'};
  ${({ $top }: StyledHeaderMenuProps): string => $top !== null ? `
    position: fixed;
    top: ${$top}px;
    left: 0;
  ` : `
    position: block;
  `};
`;
