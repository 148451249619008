import {
  CategoryAttributeInterface,
  CategoryWithAttributesInterface,
  AttachmentModel,
  ItemTypeEnum,
  ItemAttributeModel,
} from '@on-arte/core-types';
import {
  FormFieldData,
  FormFieldType,
  FormFieldValue,
  FormSectionData,
  getSingleValueFromFormFieldValue,
  SuggestionItem,
  UseLocalStorage,
  useLocalStorage,
  useNotifications,
  UseNotifications,
  UseState
} from '@on-arte/ui';
import { useEffect, useState } from 'react';

import { createItem } from '@onArte/frontend/api/requests';
import { artworkBasicInfoForm, artworkDetailsForm } from '@onArte/frontend/constants';
import { LocalStorageKey } from '@onArte/frontend/enums';
import { FrontendApiError } from '@onArte/frontend/models';

import { ArtworkAddViewStage } from './userArtworkAdd.enums';
import { UserArtworkAddMethods } from './userArtworkAdd.types';

export const useUserArtworkAddMethods: () => UserArtworkAddMethods = (): UserArtworkAddMethods => {
  const { addToast }: UseNotifications = useNotifications();
  const [stage, setStage]: UseState<ArtworkAddViewStage> = useState<ArtworkAddViewStage>(ArtworkAddViewStage.BasicInfo);
  const [infoTextBoxVisibleValue, storeInfoTextBoxVisibleValue]: UseLocalStorage<boolean | null>
    = useLocalStorage<boolean | null>(LocalStorageKey.ArtworkMessage, null);
  const [infoTextBoxVisible, setInfoTextBoxVisible]: UseState<boolean> = useState<boolean>(infoTextBoxVisibleValue ?? false);
  const [isAddingActionInProgress, setIsAddingActionInProgress]: UseState<boolean> = useState<boolean>(false);

  useEffect((): void => setInfoTextBoxVisible(infoTextBoxVisibleValue ?? true), [infoTextBoxVisibleValue]);

  const hideTextBox: () => void = (): void => {
    setInfoTextBoxVisible(false);
    storeInfoTextBoxVisibleValue(false);
  };

  const saveValues = (formValues: Record<string, FormFieldValue>, categories: CategoryWithAttributesInterface[]): void => {
    setIsAddingActionInProgress(true);
    const selectedCategoryId: number = parseInt(getSingleValueFromFormFieldValue<string>(formValues['category']), 10);
    const attributesFromCategory: CategoryAttributeInterface[] = categories
      .find((category: CategoryWithAttributesInterface): boolean => category.id === selectedCategoryId)
      ?.attributes ?? [];
    let attachments: AttachmentModel[] = [];
    const attributes: ItemAttributeModel[] = [];

    artworkBasicInfoForm.forEach((section: FormSectionData): void => {
      section.children.forEach((field: FormFieldData): void => {
        if (field.type === FormFieldType.AddPhoto && formValues[field.name] !== undefined) {
          attachments = [...attachments, ...formValues[field.name] as AttachmentModel[]];
        }
      });
    });

    artworkDetailsForm.forEach((section: FormSectionData): void => {
      section.children.forEach((field: FormFieldData): void => {
        if (field.type === FormFieldType.AddPhoto && formValues[field.name] !== undefined) {
          attachments = [...attachments, ...formValues[field.name] as AttachmentModel[]];
        }
      });
    });

    attributesFromCategory
      .filter((attr: CategoryAttributeInterface): boolean => formValues[attr.name] !== undefined)
      .forEach((attr: CategoryAttributeInterface): void => {
        if (Array.isArray(formValues[attr.name]) && (formValues[attr.name] as [])?.length > 1) {
          attributes.push({
            attributeId: attr.id,
            value: (formValues[attr.name] as string[]).map((value: string): string => value).join(','),
          });
        } else if (getSingleValueFromFormFieldValue<string>(formValues[attr.name])) {
          attributes.push({
            attributeId: attr.id,
            value: getSingleValueFromFormFieldValue<string>(formValues[attr.name])
          });
        }
      });

    const manufacturerValue: SuggestionItem = getSingleValueFromFormFieldValue<SuggestionItem>(formValues['manufacturer']);
    createItem({
      attributes,
      attachments,
      type: ItemTypeEnum.Physical,
      categoryId: selectedCategoryId,
      name: getSingleValueFromFormFieldValue<string>(formValues['name']),
      year: getSingleValueFromFormFieldValue<string>(formValues['year']),
      manufacturer: {
        name: manufacturerValue.label,
        ...(manufacturerValue.id ? { id: manufacturerValue.id } : {}),
      },
      locationId: null,
      description: getSingleValueFromFormFieldValue<string>(formValues['description']),
    })
      .then((): void => {
        setIsAddingActionInProgress(false);
        setStage(ArtworkAddViewStage.Success);
      })
      .catch((error: FrontendApiError): void => {
        setIsAddingActionInProgress(false);
        setStage(ArtworkAddViewStage.Error);
        addToast({ content: error.message });
      });
  };

  return {
    stage,
    setStage,
    saveValues,
    hideTextBox,
    infoTextBoxVisible,
    isAddingActionInProgress
  };
};
