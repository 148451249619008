import { Button, Color, Input, StyledComponent, Heading } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${Color.Background};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  & + & {
    margin-top: 24px;
  }
`;

export const StyledHeading: StyledComponent<typeof Heading> = styled(Heading)`
  text-align: left;
  margin-bottom: 24px;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin: 24px 0;
`;
