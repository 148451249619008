import Cookies from 'js-cookie';
import { Location, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { sendConversionEvent } from '@onArte/frontend/api/requests';
import { EventParams, UseConversions } from '@onArte/frontend/interfaces';
import { FbqFunction } from '@onArte/frontend/types';
import { ConversionType } from '@onArte/shared/enums';

declare const fbq: FbqFunction;

export const useConversions: () => UseConversions = (): UseConversions => {
  const location: Location = useLocation();
  const isFbqAvailable: boolean = typeof fbq !== 'undefined';

  const getEventParams: () => EventParams = (): EventParams => {
    const randomNumber: number = new Date().getTime(); // for incognito mode
    return {
      browserId: Cookies.get('_fbp') ?? `fb.${randomNumber}.${randomNumber}.${randomNumber}`,
      sourceUrl: typeof window !== 'undefined'
        ? window.location.href
        : location.pathname,
      eventId: uuidv4()
    };
  };

  const sendPageViewEvent: () => void = (): void => {
    if (!isFbqAvailable) {
      return;
    }
    const { browserId, sourceUrl, eventId }: EventParams = getEventParams();
    
    fbq('track', ConversionType.PageView, {}, { eventID: eventId });
    void sendConversionEvent({ conversionType: ConversionType.PageView, sourceUrl, eventId,  browserId });
  };

  const sendViewContentEvent: () => void = (): void => {
    if (!isFbqAvailable) {
      return;
    }
    const { browserId, sourceUrl, eventId }: EventParams = getEventParams();

    fbq('track', ConversionType.ViewContent, {}, { eventID: eventId });
    void sendConversionEvent({ conversionType: ConversionType.ViewContent, sourceUrl, eventId,  browserId });
   
  };

  const sendInitiateCheckoutEvent: (cartId: string, cartAmount: number) => void = (cartId: string, cartAmount: number): void => {
    if (!isFbqAvailable) {
      return;
    }
    const { browserId, sourceUrl, eventId }: EventParams = getEventParams();

    fbq('track', ConversionType.InitiateCheckout, { value: cartAmount }, { eventID: eventId });
    void sendConversionEvent({
      conversionType: ConversionType.InitiateCheckout,
      sourceUrl,
      eventId,
      browserId,
      customData: { cartId }
    });
  };

  const sendPurchaseEvent: (cartId: string, cartAmount: number) => void = (cartId: string, cartAmount: number): void => {
    if (!isFbqAvailable) {
      return;
    }
    const { browserId, sourceUrl, eventId }: EventParams = getEventParams();

    fbq('track', ConversionType.Purchase, { value: cartAmount }, { eventID: eventId });
    void sendConversionEvent({
      conversionType: ConversionType.Purchase,
      sourceUrl,
      eventId,
      browserId,
      customData: { cartId }
    });
  };

  const sendAddToCartEvent: (auctionId: string, cartId: string, itemName: string, itemPrice: number | null) => void = (
    auctionId: string, cartId: string, itemName: string, itemPrice: number | null
  ): void => {
    if (!isFbqAvailable) {
      return;
    }
    const { browserId, sourceUrl, eventId }: EventParams = getEventParams();

    fbq(
      'track',
      ConversionType.AddToCart,
      { content_name: itemName,  ...(itemPrice !== null ? { value: itemPrice } : {}) },
      { eventID: eventId }
    );
    void sendConversionEvent({
      conversionType: ConversionType.AddToCart,
      sourceUrl,
      eventId,
      browserId,
      customData: { auctionId, cartId }
    });
  };

  const sendCompleteRegistrationEvent: () => void = (): void => {
    if (!isFbqAvailable) {
      return;
    }
    const { browserId, sourceUrl, eventId }: EventParams = getEventParams();

    fbq('track', ConversionType.CompleteRegistration, { status: true }, { eventID: eventId });
    void sendConversionEvent({ conversionType: ConversionType.CompleteRegistration, sourceUrl, eventId,  browserId });
  };

  return {
    isFbqAvailable,
    sendPageViewEvent,
    sendViewContentEvent,
    sendInitiateCheckoutEvent,
    sendPurchaseEvent,
    sendAddToCartEvent,
    sendCompleteRegistrationEvent,
  };
};
