import { AuctionTypeEnum, CartAuctionInterface, PromotionCodeInterface } from '@on-arte/core-types';
import { 
  BasketItem, 
  Breakpoint, 
  Color, 
  IconName,
  Input,
  InputTheme, 
  useBasketSummaryItems, 
  UseBasketSummaryItems, 
  UseState, 
  ValidationBarTheme 
} from '@on-arte/ui';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Params, useParams } from 'react-router-dom';

import { useBasket } from '@onArte/frontend/hooks';
import { UseBasket } from '@onArte/frontend/interfaces';
import { findAllCommissionCost } from '@onArte/frontend/utils';

import { 
  ApplyButton, 
  ApplyButtonContainer, 
  CartTitle, 
  Container, 
  DiscountCodesContainer, 
  DiscountContainer, 
  ItemsContainer, 
  StyledArtworkContainer, 
  StyledBasketSummary, 
  StyledChooseBox,
  StyledValidationBar,
  SummaryContainer,
  Title,
} from './basketColumn.styled';

export const BasketColumn: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Readonly<Params<string>> = useParams();
  const { 
    cartData, 
    cartPrices, 
    basketItems, 
    onSubmitDiscountCode, 
    onDeleteDiscountCode, 
    validationMessage, 
    setValidationMessage 
  }: UseBasket = useBasket(id);
  const { basketSummaryItems }: UseBasketSummaryItems = useBasketSummaryItems(
    cartPrices.auctionsBasePrice, 
    cartData?.finalPrice ?? 0, 
    cartPrices.deliveryPrice, 
    cartData?.promotionsCodes.length ? (cartData?.basePrice - cartData?.finalPrice) : undefined, 
    findAllCommissionCost(cartData?.auctions ?? [])
  );
  const [discountInputValue, setDiscountInputValue]: UseState<string> = useState<string>('');
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });

  const onDiscountApplyAction: () => void = (): void => {
    if (discountInputValue === '') {
      setValidationMessage(t('onarte.website.basketColumn.onDiscountApplyAction.emptyValue'));
    } else {
      setValidationMessage(validationMessage);
      onSubmitDiscountCode(discountInputValue);
    }
    setDiscountInputValue('');
  };

  const withDiscountInputAndWarning: boolean = useMemo(
    (): boolean => !!cartData?.auctions
      .every((cartAuction: CartAuctionInterface): boolean => cartAuction.auction.type !== AuctionTypeEnum.Bidding),
    [cartData?.auctions]
  );

  return (
    <Container>
      {!isMobile && (
        <CartTitle>
          <Title>{t('onarte.website.basketColumn.title')}</Title>
        </CartTitle>
      )}
      <ItemsContainer>
        {basketItems.map((basketItem: BasketItem): JSX.Element => (
          <StyledArtworkContainer 
            key={basketItem.id}
            artworkDetails={basketItem.artworkDetails}
          />
        ))}
      </ItemsContainer>
      {withDiscountInputAndWarning && (
        <DiscountContainer>
          <Input 
            label={t('onarte.website.basketColumn.discountContainer.input.label')} 
            inputTheme={InputTheme.Small} 
            labelSecondBackground={Color.Gray200}
            value={discountInputValue}
            onChange={setDiscountInputValue}
            validationMessage={validationMessage}
          />
          <ApplyButtonContainer>
            <ApplyButton onClick={onDiscountApplyAction}>
              {t('onarte.website.basketColumn.discountContainer.button')}
            </ApplyButton>
          </ApplyButtonContainer>
        </DiscountContainer>
      )}
      {!!cartData?.promotionsCodes.length && (
        <DiscountCodesContainer>
          {cartData.promotionsCodes.map((discount: PromotionCodeInterface): JSX.Element => (
            <StyledChooseBox 
              title={discount.code} 
              subtitle={`(${discount.name})`}
              icon={IconName.Promotion} 
              iconSize={50}
              onRemove={(): void => onDeleteDiscountCode(discount.code)}
              key={discount.code}
            />
          ))}
        </DiscountCodesContainer>
      )}
      <SummaryContainer>
        <StyledBasketSummary items={basketSummaryItems} />
      </SummaryContainer>
      {withDiscountInputAndWarning && (
        <StyledValidationBar 
          message={t('onarte.website.basketColumn.validationBar.message')}
          barTheme={ValidationBarTheme.Information}
        />
      )}
    </Container>
  );
};
