import { BillingAddressFormData } from '@on-arte/ui';

import { initialBaseAddressValues } from './initialBaseAddressValues.const';

export const initialBillingAddressValues: BillingAddressFormData = {
  ...initialBaseAddressValues,
  firstName: '',
  lastName: '',
  taxId: '',
  companyName: '',
};
