import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { UpdatePasswordFormInterface } from '@onArte/frontend/interfaces';
import { regexps } from '@onArte/shared/constants';

export const useUpdatePasswordFormValidation: () => Yup.SchemaOf<UpdatePasswordFormInterface> 
  = (): Yup.SchemaOf<UpdatePasswordFormInterface> => {
    const { t }: TransProps<never> = useTranslation();

    return Yup.object().shape({
      oldPassword: Yup.string()
        .required(t('onarte.common.requiredField'))
        .matches(regexps.Password, t('onarte.common.validations.password')),
      newPassword: Yup.string()
        .required(t('onarte.common.requiredField'))
        .matches(regexps.Password, t('onarte.common.validations.password'))
        .notOneOf([Yup.ref('oldPassword')], t('onarte.common.validations.newPasswordIsTheSame'))
    });
  };
