import { ApiErrorInterface } from '@onArte/shared/interfaces';

export class ApiError implements ApiErrorInterface {
  public message: string;
  public messageParams: Record<string, string>;
  public details?: Record<string, object>;

  constructor(
    message: string,
    messageParams: Record<string, string> = {},
    details?: Record<string, object>
  ) {
    this.message = message;
    this.messageParams = messageParams;
    this.details = details;
  }

  public toJson(): ApiErrorInterface {
    return {
      message: this.message,
      messageParams: this.messageParams,
      details: this.details,
    };
  }

  public static example(): ApiErrorInterface {
    const instance: ApiError = new ApiError('example.translation.key');

    return instance.toJson();
  }
}
