import { Breakpoint, Grid, InfoBox, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.FullHd} + 80px); 
  display: flex;
  padding: 0 40px 120px;
  margin: 0 auto;

  @media ${Breakpoint.Tablet} {
    padding: 0 32px 120px;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    padding: 0 20px 80px;
  }
`;

export const StyledInfoBox: StyledComponent<typeof InfoBox> = styled(InfoBox)`
  max-width: 100%;
`;
