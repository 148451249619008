import { CategoryWithAttributesInterface } from '@on-arte/core-types';
import {
  AddPhotoFile,
  Button,
  ButtonTheme,
  FormFieldValue,
  Icon,
  IconName,
  TextBoxTheme,
  UseFormikForm,
  useFormikForm,
  useRedirect,
  UseRedirect,
  UseState
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { BaseSchema } from 'yup';

import { getItemsCategories } from '@onArte/frontend/api/requests';
import { BaseViewWithBreadcrumbs } from '@onArte/frontend/components';
import { ArtworkAddForm } from '@onArte/frontend/components/artworkAddForm/artworkAddForm.component';
import { artworkBasicInfoForm, artworkDetailsForm } from '@onArte/frontend/constants';
import { QueryKey } from '@onArte/frontend/enums';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { ArtworkAddViewStage } from './userArtworkAdd.enums';
import { useUserArtworkAddMethods } from './userArtworkAdd.hook';
import {
  FormContainer,
  IconContainer,
  MessageDescription,
  MessageHeader,
  ResultContainer,
  ResultInnerContainer,
  StyledButton,
  StyledTextBox
} from './userArtworkAdd.styled';
import { UserArtworkAddMethods } from './userArtworkAdd.types';

export const UserArtworkAddView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [categories, setCategories]: UseState<CategoryWithAttributesInterface[]> = useState<CategoryWithAttributesInterface[]>([]);
  const [formValues, setFormValues]: UseState<Record<string, FormFieldValue>> = useState<Record<string, FormFieldValue>>({});
  const { setFormSubmitted, setFormNotSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const { redirect }: UseRedirect = useRedirect();
  const [formValidationSchema, setFormValidationSchema]: UseState<BaseSchema | null> = useState<BaseSchema | null>(null);
  const {
    stage,
    setStage,
    saveValues,
    hideTextBox,
    infoTextBoxVisible,
    isAddingActionInProgress
  }: UserArtworkAddMethods = useUserArtworkAddMethods();

  useQuery(
    [QueryKey.ItemCategories],
    (): Promise<CategoryWithAttributesInterface[]> => getItemsCategories(),
    {
      onSuccess: (data: CategoryWithAttributesInterface[]): void => setCategories(data),
      // TODO: add logger
      onError: () => undefined
    }
  );

  const handleFormChange = (values: Record<string, FormFieldValue | AddPhotoFile[]>) => {
    setFormValues(values);
  };

  const pageTitleButtonAction = async (): Promise<void> => {
    if (stage === ArtworkAddViewStage.BasicInfo) {
      setFormSubmitted();
      if (formValidationSchema) {
        const isValid: boolean = formValidationSchema.isValidSync(formValues);
        if (isValid) {
          setFormNotSubmitted();
          setStage(ArtworkAddViewStage.Details);
        }
      }
    }
    if (stage === ArtworkAddViewStage.Details) {
      setFormNotSubmitted();
      setTimeout((): void => setFormSubmitted(), 0);
      if (formValidationSchema) {
        const isValid: boolean = formValidationSchema.isValidSync(formValues);
        if (isValid) {
          setFormNotSubmitted();
          saveValues(formValues, categories);
        }
      }
    }
  };

  const validationSchemaUpdate: (schemaObject: BaseSchema) => void = (
    schemaObject: BaseSchema
  ): void => setFormValidationSchema(schemaObject);

  const navigateToSalesList: () => void = (): void => redirect(getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/');

  return (
    <BaseViewWithBreadcrumbs
      withGrayBackground={stage !== ArtworkAddViewStage.BasicInfo && stage !== ArtworkAddViewStage.Details}
      pageTitleSettings={{
        title: (stage === ArtworkAddViewStage.BasicInfo || stage === ArtworkAddViewStage.Details)
          ? t('onarte.website.meta.userArtworkAdd.title')
          : '',
        linkLabel: stage === ArtworkAddViewStage.Details ? t('onarte.common.back') : undefined,
        linkAction: stage === ArtworkAddViewStage.Details ? ((): void => setStage(ArtworkAddViewStage.BasicInfo)) : undefined,
      }}
      breadcrumbs={[
        { label: t('onarte.website.meta.profile.title'), path: getRouteDetailsByName(RouteNameEnum.UserAccountSettings)?.url ?? '/' },
        { label: t('onarte.website.meta.userSalesList.title'), path: getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/' },
        { label: t('onarte.website.meta.userArtworkAdd.title'), path: '' },
      ]}
    >
      {(stage === ArtworkAddViewStage.BasicInfo || stage === ArtworkAddViewStage.Details) ? (
        <>
          <FormContainer>
            {!!categories.length && (
              <ArtworkAddForm
                initialValues={formValues}
                onValuesChange={handleFormChange}
                categories={categories}
                isFormSubmitted={isFormSubmitted}
                formStructure={stage === ArtworkAddViewStage.BasicInfo ? artworkBasicInfoForm : artworkDetailsForm}
                validationSchemaUpdate={validationSchemaUpdate}
                files={{ artworkPhotos: formValues.artworkPhotos as AddPhotoFile[] }}
              />
            )}
          </FormContainer>
          <StyledTextBox
            textBoxTheme={TextBoxTheme.Orange}
            content={t('onarte.website.userArtworkAddView.infoTextBox.content')}
            buttonLabel={t('onarte.common.close')}
            buttonAction={hideTextBox}
            $visible={!!infoTextBoxVisible}
          />
        </>
      ) : (
        <ResultContainer>
          <ResultInnerContainer>
            <IconContainer>
              <Icon name={IconName.Image} size={60} />
            </IconContainer>
            <MessageHeader>
              {t(`onarte.website.userArtworkAddView.header.${stage}`)}
            </MessageHeader>
            <MessageDescription>
              {t(`onarte.website.userArtworkAddView.message.${stage}`)}
            </MessageDescription>
            <Button
              label={t(`onarte.website.userArtworkAddView.buttonLabel.${stage}`)}
              onClick={stage === ArtworkAddViewStage.Success
                ? navigateToSalesList
                : (): void => setStage(ArtworkAddViewStage.BasicInfo)
              }
            />
          </ResultInnerContainer>
        </ResultContainer>
      )}
      {[ArtworkAddViewStage.BasicInfo, ArtworkAddViewStage.Details].includes(stage) && (
        <StyledButton
          type='submit'
          label={t(`onarte.website.userArtworkAddView.submitButtonLabel.${stage}`)} 
          buttonTheme={ButtonTheme.PrimaryBig}
          onClick={pageTitleButtonAction}
          isLoading={isAddingActionInProgress}
        />
      )}
    </BaseViewWithBreadcrumbs>
  );
};
