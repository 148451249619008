import {
  AuctionInfoInterface,
  AuctionTypeEnum,
  EventListElementInterface,
  EventStatusEnum,
  ListPaginationInterface
} from '@on-arte/core-types';
import { 
  AuctionBox,
  BoxesSliderSlideSize, 
  getPathWithParams, 
  UseLogger, 
  useLogger, 
  UseNotifications, 
  useNotifications, 
  UseState 
} from '@on-arte/ui';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getAuctions, getEvents } from '@onArte/frontend/api/requests';
import { NewsletterBoxSection } from '@onArte/frontend/components';
import { QueryKey } from '@onArte/frontend/enums';
import { useObjectsTransformations } from '@onArte/frontend/hooks';
import { UseObjectsTranformations } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { 
  AuctionBoxesContainer, 
  Container, 
  EventsListContainer, 
  FirstAuctionBoxWrapper, 
  SecondAuctionBoxWrapper,
  StyledBoxesSliderSection, 
  StyledPageTitle
} from './eventsList.styled';

export const EventsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const { transformAuctionToBoxSlideType, transformEventToBoxSlideType }: UseObjectsTranformations = useObjectsTransformations();
  const [highlightedAuctions, setHighlightedAuctions]: UseState<AuctionInfoInterface[]> = useState<AuctionInfoInterface[]>([]);
  const [mostPopularAuctions, setMostPopularAuctions]: UseState<AuctionInfoInterface[]> = useState<AuctionInfoInterface[]>([]);
  const [eventsList, setEventsList]: UseState<EventListElementInterface[]> = useState<EventListElementInterface[]>([]);
  const [finishedEvents, setFinishedEvents]: UseState<EventListElementInterface[]> = useState<EventListElementInterface[]>([]);
  const { logger }: UseLogger = useLogger();

  useQuery(
    [QueryKey.BiddingAuctionsList],
    (): Promise<ListPaginationInterface<AuctionInfoInterface>> => getAuctions({
      limit: 100,
      offset: 0,
      type: AuctionTypeEnum.Bidding,
      hasActiveOffers: true
    }),
    {
      onSuccess: (auctionsData: ListPaginationInterface<AuctionInfoInterface>): void => {
        setMostPopularAuctions(auctionsData.list.sort((
          firstAuction: AuctionInfoInterface, secondAuction: AuctionInfoInterface
        ): number => secondAuction.offers - firstAuction.offers));
      },
      onError: (error: FrontendApiError) => logger(QueryKey.BiddingAuctionsList, error)
    }
  );

  useQuery(
    [QueryKey.HighlightedBiddingAuctionsList],
    (): Promise<ListPaginationInterface<AuctionInfoInterface>> => getAuctions(
      { limit: 100, offset: 0, type: AuctionTypeEnum.Bidding, highlighted: true, sortByHighlighted: true }
    ),
    {
      onSuccess: (auctionsData: ListPaginationInterface<AuctionInfoInterface>): void => setHighlightedAuctions(auctionsData.list),
      onError: (error: FrontendApiError): void => logger(QueryKey.HighlightedBiddingAuctionsList, error)
    }
  );

  useQuery(
    [QueryKey.EventsList],
    (): Promise<ListPaginationInterface<EventListElementInterface>> => getEvents({ limit: 100, offset: 0 }),
    {
      onSuccess: (eventsData: ListPaginationInterface<EventListElementInterface>): void => setEventsList(eventsData.list),
      onError: (error: FrontendApiError) => logger(QueryKey.EventsList, error)
    }
  );

  useQuery(
    [QueryKey.FinishedEventsList],
    (): Promise<ListPaginationInterface<EventListElementInterface>> => getEvents({ status: EventStatusEnum.Finished }),
    {
      onSuccess: (eventsData: ListPaginationInterface<EventListElementInterface>): void => setFinishedEvents(eventsData.list),
      onError: (error: FrontendApiError) => logger(QueryKey.FinishedEventsList, error)
    }
  );

  const firstAuctionBox: EventListElementInterface | null = useMemo((): EventListElementInterface | null => {
    return eventsList.find((event: EventListElementInterface): boolean => event.status === EventStatusEnum.InProgress)
      ?? eventsList.find((event: EventListElementInterface): boolean => event.status === EventStatusEnum.Incoming)
      ?? null;
  }, [eventsList]);

  const secondAuctionBox: EventListElementInterface | null = useMemo((): EventListElementInterface | null => {
    return eventsList.find((event: EventListElementInterface): boolean => (
      event.status === EventStatusEnum.InProgress && event.id !== firstAuctionBox?.id
    )) 
      ?? eventsList.find((event: EventListElementInterface): boolean => (
        event.status === EventStatusEnum.Incoming && event.id !== firstAuctionBox?.id
      ))
      ?? null;
  }, [eventsList, firstAuctionBox]);

  return (
    <Container>
      <StyledPageTitle 
        pageTitleSettings={{
          title: t('onarte.website.eventsListView.pageTitle.title'),
          withUnderline: false,
          // TODO: temporary hidden
          // linkLabel: t('onarte.website.eventsListView.pageTitle.link'),
          // linkAction: (): void => addToast({ content: t('onarte.common.functionalityCurrentlyNotAvailable') }),
          description: t('onarte.website.eventsListView.pageTitle.description')
        }}
      />
      <EventsListContainer>
        {!!firstAuctionBox && (
          <AuctionBoxesContainer>
            <FirstAuctionBoxWrapper 
              internalPath={firstAuctionBox?.route?.prettyUrl
                ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.EventDetails)?.url ?? '/', { id: firstAuctionBox?.id ?? '' })
              }
              $fullWidth={!secondAuctionBox}
            >
              <AuctionBox 
                title={firstAuctionBox?.name ?? ''}
                startTime={firstAuctionBox?.startTimestamp ?? 0}
                endTime={firstAuctionBox?.endTimestamp ?? 0}
                image={firstAuctionBox?.coverPhoto?.path ?? ''}
              />
            </FirstAuctionBoxWrapper>
            {!!secondAuctionBox && (
              <SecondAuctionBoxWrapper internalPath={secondAuctionBox?.route?.prettyUrl
                ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.EventDetails)?.url ?? '/', { id: secondAuctionBox?.id ?? '' })
              }>
                <AuctionBox 
                  title={secondAuctionBox?.name ?? ''}
                  startTime={secondAuctionBox?.startTimestamp ?? 0}
                  endTime={secondAuctionBox?.endTimestamp ?? 0}
                  image={secondAuctionBox?.coverPhoto?.path ?? ''}
                />
              </SecondAuctionBoxWrapper>
            )}
          </AuctionBoxesContainer>
        )}
        {!!highlightedAuctions?.length && (
          <StyledBoxesSliderSection 
            header={t('onarte.website.eventsListView.boxesSlider.highlighted.header')}
            slides={highlightedAuctions.map(transformAuctionToBoxSlideType)}
          />
        )}
        {!!mostPopularAuctions?.length && (
          <StyledBoxesSliderSection 
            header={t('onarte.website.eventsListView.boxesSlider.mostPopular.header')}
            slides={mostPopularAuctions.map(transformAuctionToBoxSlideType)}
          />
        )}
        {!!finishedEvents?.length && (
          <StyledBoxesSliderSection 
            header={t('onarte.website.eventsListView.boxesSlider.finishedAuctions.header')}
            slides={finishedEvents.map(transformEventToBoxSlideType)}
            slideSize={BoxesSliderSlideSize.Big}
          />
        )}
        <NewsletterBoxSection withTopBorder />
      </EventsListContainer>
    </Container>
  );
};
