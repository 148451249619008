import { SellerCreateModel } from '@on-arte/core-types';
import { checkIsTaxNumberCorrect } from '@on-arte/ui';
import { TransProps, useTranslation } from 'react-i18next';
import isIBAN from 'validator/lib/isIBAN';
import * as Yup from 'yup';

import { regexps } from '@onArte/shared/constants';

export const useUpdateDataForPayoutsFormValidation: () => Yup.SchemaOf<SellerCreateModel>
  = (): Yup.SchemaOf<SellerCreateModel> => {
    const { t }: TransProps<never> = useTranslation();

    return Yup.object().shape({
      bankNumber: Yup.string()
        .required(t('onarte.common.requiredField'))
        .matches(regexps.AccountNumber, t('onarte.common.validations.bankNumber'))
        .test('bankNumberSyntax', t('onarte.common.validations.bankNumber'), (value: string | undefined): boolean => value
          ? isIBAN(value)
          : false
        ),
      name: Yup.string()
        .min(2, t('onarte.common.validations.name'))
        .max(128, t('onarte.common.validations.maxLength'))
        .required(t('onarte.common.requiredField')),
      taxNumber: Yup.string()
        .min(10, t('onarte.common.validations.taxNumber'))
        .max(10, t('onarte.common.validations.maxLength'))
        .test(
          'taxNumberSyntax',
          t('onarte.common.validations.taxNumber'),
          (value: string | undefined): boolean => value 
            ? checkIsTaxNumberCorrect(value)
            : true
        )
        .optional(),
      address: Yup.string()
        .min(2, t('onarte.common.validations.address'))
        .max(128, t('onarte.common.validations.maxLength'))
        .required(t('onarte.common.requiredField')),
      postalCode: Yup.string()
        .required(t('onarte.common.requiredField'))
        .matches(regexps.PostalCode, t('onarte.common.validations.postalCode')),
      city: Yup.string()
        .min(2, t('onarte.common.validations.city'))
        .max(128, t('onarte.common.validations.maxLength'))
        .required(t('onarte.common.requiredField')),
      country: Yup.string()
        .min(2, t('onarte.common.validations.country'))
        .max(64, t('onarte.common.validations.maxLength'))
        .required(t('onarte.common.requiredField'))
    });
  };
