import {
  AttachmentInterface,
  AttachmentTypeEnum,
  ExternalAttachmentInterface,
  ManufacturerInterface,
  PublicProfileListElementInterface,
  PublicProfileStatusEnum,
  PublicProfileTypeEnum,
  PublicProfileVersionModel,
  ThumbnailAttachmentTypeEnum,
  UploadedAttachmentInterface,
  VersionStatusEnum
} from '@on-arte/core-types';
import {
  AccountSubmenuPosition,
  AddPhotoFile,
  Color,
  getPathWithParams,
  IconName,
  UseNotifications,
  useNotifications,
  useRedirect,
  UseRedirect,
  UseState
} from '@on-arte/ui';
import { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { updatePublicProfile, createPublicProfile, changePublicProfileActiveFlag } from '@onArte/frontend/api/requests';
import { appConfig } from '@onArte/frontend/app.config';
import { useAuth } from '@onArte/frontend/hooks';
import { PublicProfileForm, UseAuth } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { getImageThumbnail } from '@onArte/frontend/utils';
import { RouteNameEnum } from '@onArte/shared/enums';
import { PublicProfileDetails } from '@onArte/shared/interfaces';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { PublicProfileContextType } from '../userPublicProfileSettings.enums';
import { UsePublicProfileActions } from '../userPublicProfileSettings.types';

export const usePublicProfileActions: () => UsePublicProfileActions = (): UsePublicProfileActions => {
  const [activeProfile, setActiveProfile]: UseState<Partial<PublicProfileDetails>> = useState<Partial<PublicProfileDetails>>({});
  const [allowedPublicProfiles, setAllowedPublicProfiles]: UseState<PublicProfileListElementInterface[] | null>
   = useState<PublicProfileListElementInterface[] | null>(null);
  const { addToast, showSmallDialog, hideSmallDialog }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const { t }: TransProps<never> = useTranslation();
  const { userData }: UseAuth = useAuth();

  const submitAction: (
    contextId: string, contextType: PublicProfileContextType, data: PublicProfileForm
  ) => void = (
    contextId: string, contextType: PublicProfileContextType, data: PublicProfileForm
  ): void => {
    const attachmentsIds: string[] = [...data.avatar, ...data.cover, ...data.galleryPhoto]
      .map((attachment: AddPhotoFile): string => attachment.id);

    if (contextType === PublicProfileContextType.Profile) {
      updatePublicProfile(
        contextId,
        {
          bio: data.bio,
          description: data.description,
          name: data.name,
          attachments: attachmentsIds,
        }
      )
        .then((): void => {
          addToast({ content: t('onarte.website.usePublicProfileActionsHook.successfullyUpdated') });
          setActiveProfile({ ...activeProfile, hasChangesToVerified: true });
        })
        .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
    } else {
      createPublicProfile({
        bio: data.bio,
        description: data.description,
        name: data.name,
        attachments: attachmentsIds,
        type: data.type,
        relationId: contextId
      })
        .then((response: PublicProfileDetails): void => {
          addToast({ content: t('onarte.website.usePublicProfileActionsHook.successfullyCreated') });
          setActiveProfile(response);
          if (allowedPublicProfiles?.length) {
            setAllowedPublicProfiles([
              ...allowedPublicProfiles,
              {
                id: response.id,
                name: response.name,
                url: response.route?.prettyUrl ?? getPathWithParams(
                  getRouteDetailsByName(RouteNameEnum.UserPublicProfileSettings)?.url ?? '/', { id: response.id }
                ),
                status: response.status,
                hasChangesToVerified: !!response.hasChangesToVerified,
                type: response.type,
                relationId: response.relationId ?? '',
                avatar: response.attachments.find((
                  attachment: ExternalAttachmentInterface | UploadedAttachmentInterface
                ): boolean => attachment.type === AttachmentTypeEnum.PublicProfileAvatar) ?? null
              }
            ]);
          }
          redirect(getPathWithParams(getRouteDetailsByName(RouteNameEnum.UserPublicProfileSettings)?.url ?? '/', {
            contextId: response.id,
            contextType: PublicProfileContextType.Profile,
            contextName: '-'
          }));
        })
        .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
    }
  };

  const changeProfileActiveStatus: (
    shouldBeActive: boolean, contextId?: string, activeProfileName?: string
  ) => void = (
    shouldBeActive: boolean, contextId?: string, activeProfileName?: string
  ): void => {
    if (!contextId) {
      return;
    }

    showSmallDialog({
      withCloseButton: false,
      header: t(`onarte.common.changePublicProfileActiveFlagDialog.${shouldBeActive ? 'show' : 'hide'}.header`),
      content: t(
        `onarte.common.changePublicProfileActiveFlagDialog.${shouldBeActive ? 'show' : 'hide'}.content`,
        { name: activeProfileName }
      ),
      acceptButton: {
        label: t(`onarte.common.changePublicProfileActiveFlagDialog.${shouldBeActive ? 'show' : 'hide'}.acceptButton`),
        action: (): void => {
          changePublicProfileActiveFlag(contextId, { active: shouldBeActive })
            .then((updatedProfileData: PublicProfileDetails): void => {
              setActiveProfile(updatedProfileData);
              hideSmallDialog();
              addToast({
                content: t(
                  `onarte.common.changePublicProfileActiveFlagDialog.actionStatus.${shouldBeActive ? 'published' : 'hidden'}`,
                  { name: activeProfileName }
                )
              });
            })
            .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
        }
      },
      cancelButton: {
        label: t('onarte.common.cancel')
      }
    });
  };

  const getMenuPositions: (loggedUserManufacturers: ManufacturerInterface[]) => AccountSubmenuPosition[] = (
    loggedUserManufacturers: ManufacturerInterface[]
  ): AccountSubmenuPosition[] => {
    if (!allowedPublicProfiles) {
      return [];
    }

    let menuPositionsArray: AccountSubmenuPosition[] = [];
    const collectorProfile: PublicProfileListElementInterface | undefined = allowedPublicProfiles
      .find((item: PublicProfileListElementInterface): boolean => item.type === PublicProfileTypeEnum.Collector);

    if (!appConfig.hiddenModules.collectors) {
      menuPositionsArray = [
        ...(!collectorProfile ? [{
          name: userData?.id,
          label: `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`,
          icon: IconName.PublicProfile,
          isHighlighted: true,
          highlightedColor: Color.Error500,
          internalPath: getPathWithParams(
            getRouteDetailsByName(RouteNameEnum.UserPublicProfileSettings)?.url ?? '/',
            {
              contextId: userData?.id ?? '',
              contextType: PublicProfileContextType.Collector,
              contextName: encodeURIComponent(`${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`)
            }
          ),
        }] : [{
          name: collectorProfile.id,
          label: collectorProfile.name,
          icon: !collectorProfile.avatar?.path
            ? IconName.PublicProfile
            : undefined,
          image: getImageThumbnail(collectorProfile.avatar, ThumbnailAttachmentTypeEnum.Size_100x100),
          isHighlighted: true,
          highlightedColor: collectorProfile.status === PublicProfileStatusEnum.Active
            ? Color.Success500
            : Color.Error500,
          internalPath: getPathWithParams(
            getRouteDetailsByName(RouteNameEnum.UserPublicProfileSettings)?.url ?? '/',
            { contextId: collectorProfile.id, contextType: PublicProfileContextType.Profile, contextName: '-' }
          ),
        }]),
      ];
    }

    const manufacturersWithoutProfile: ManufacturerInterface[] = loggedUserManufacturers.filter(
      (item: ManufacturerInterface): boolean => (
        !allowedPublicProfiles
          .find((profile: PublicProfileListElementInterface): boolean => (
            profile.relationId === item.id
          ))
      )
    );
    
    menuPositionsArray = [
      ...menuPositionsArray,
      ...allowedPublicProfiles
        .filter((item: PublicProfileListElementInterface): boolean => item.type !== PublicProfileTypeEnum.Collector)
        .map((item: PublicProfileListElementInterface): AccountSubmenuPosition => ({
          name: item.id,
          label: item.name,
          icon: !item.avatar?.path
            ? IconName.PublicProfile
            : undefined,
          image: getImageThumbnail(item.avatar, ThumbnailAttachmentTypeEnum.Size_100x100),
          isHighlighted: true,
          highlightedColor: item.status === PublicProfileStatusEnum.Active
            ? Color.Success500
            : Color.Error500,
          internalPath: getPathWithParams(
            getRouteDetailsByName(RouteNameEnum.UserPublicProfileSettings)?.url ?? '/',
            { contextId: item.id, contextType: PublicProfileContextType.Profile, contextName: '-' }
          ),
        })),
      ...manufacturersWithoutProfile.map((item: ManufacturerInterface): AccountSubmenuPosition => ({
        name: item.id,
        label: item.name || item.id,
        icon: IconName.PublicProfile,
        isHighlighted: true,
        highlightedColor: Color.Error500,
        internalPath: getPathWithParams(
          getRouteDetailsByName(RouteNameEnum.UserPublicProfileSettings)?.url ?? '/',
          { contextId: item.id, contextType: PublicProfileContextType.Manufacturer, contextName: encodeURIComponent(item.name) }
        ),
      })),
    ];

    return menuPositionsArray;
  };

  const getAttachmentsFromProfile: (profileDetails: Partial<PublicProfileDetails>) => AttachmentInterface[] = (
    profileDetails: Partial<PublicProfileDetails>
  ): AttachmentInterface[] => {
    const versionWaitingForApproval: PublicProfileVersionModel | undefined = profileDetails
      ?.versions
      ?.find((version: PublicProfileVersionModel): boolean => version.status === VersionStatusEnum.WaitingForApproval);
    
    return versionWaitingForApproval
      ? versionWaitingForApproval.data.attachments
      : profileDetails?.attachments ?? [];
  };

  return {
    submitAction,
    changeProfileActiveStatus,
    getMenuPositions,
    activeProfile,
    setActiveProfile,
    getAttachmentsFromProfile,
    allowedPublicProfiles, 
    setAllowedPublicProfiles
  };
};
