import { Button, Icon } from '@on-arte/ui';
import React from 'react';

import { Container, IconContainer, MessageDescription, MessageTitle, Wrapper } from './baseSuccessView.styled';
import { BaseSuccessViewProps } from './baseSuccessView.types';

export const BaseSuccessView: React.FC<BaseSuccessViewProps> = (props: BaseSuccessViewProps): JSX.Element => {
  const { title, description, icon, buttonAction, buttonLabel, fullHeight = false, id = 'success-view' }: BaseSuccessViewProps = props;

  return (
    <Wrapper $fullHeight={fullHeight} id={id}>
      <Container>
        <IconContainer>
          <Icon name={icon} size={60} />
        </IconContainer>
        <MessageTitle>{title}</MessageTitle>
        <MessageDescription>{description}</MessageDescription> 
        {!!buttonLabel && !!buttonAction && <Button onClick={buttonAction} label={buttonLabel} />}
      </Container>
    </Wrapper>
  );
};
