import { NewsletterSubscribeModel } from '@on-arte/core-types';
import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';

import { api } from '../api';

export const subscribeToNewsletter: (data: NewsletterSubscribeModel) => Promise<void> = (
  data: NewsletterSubscribeModel
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/newsletter/subscribe', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
