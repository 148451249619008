import { Breakpoint, Color, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { BoxesSliderSection, NewsBoxesSection } from '@onArte/frontend/components';

export const Container: StyledComponent<'div'> = styled.div`
  background-color: ${Color.White};
  width: 100%;
`;

export const StyledBoxesSliderSection: StyledComponent<typeof BoxesSliderSection> = styled(BoxesSliderSection)`
  margin: 120px 0 200px;

  @media ${Breakpoint.Mobile} {
    margin: 80px 0 160px;
  }

  @media ${Breakpoint.SmallMobile} {
    margin: 80px 0 120px;
  }
`;

export const StyledNewsBoxesSection: StyledComponent<typeof NewsBoxesSection> = styled(NewsBoxesSection)`
  margin: 120px auto;

  @media ${Breakpoint.SmallMobile} {
    margin: 80px auto;
  }
`;
