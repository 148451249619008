import { ConfirmEmailModel, ExternalUserProfileWithContextTokenInterface, UserRegistrationModel } from '@on-arte/core-types';
import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';

import { api } from '../api';

export const registration: (data: UserRegistrationModel) => Promise<ExternalUserProfileWithContextTokenInterface> = (
  data: UserRegistrationModel
): Promise<ExternalUserProfileWithContextTokenInterface> => new Promise(
  (resolve: ApiResolve<ExternalUserProfileWithContextTokenInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/registration', data).then(
      (response: AxiosResponse<ExternalUserProfileWithContextTokenInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const confirmUserRegistration: (data: ConfirmEmailModel) => Promise<ExternalUserProfileWithContextTokenInterface> = (
  data: ConfirmEmailModel
): Promise<ExternalUserProfileWithContextTokenInterface> => new Promise(
  (resolve: ApiResolve<ExternalUserProfileWithContextTokenInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/registration/confirm', data).then(
      (response: AxiosResponse<ExternalUserProfileWithContextTokenInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
