export enum FileFromViews {
  // not found view
  OrangePattern = '/assets/views/notFound/orangePattern.svg',

  // cart billing view
  Blik = '/assets/views/cartBilling/blik.png',
  BlueMedia = '/assets/views/cartBilling/bluemedia.png',
  Mastercard = '/assets/views/cartBilling/mastercard.png',
  Visa = '/assets/views/cartBilling/visa.png',

  // handbook views
  AdCampaign = '/assets/views/handbook/adCampaign.jpg',
  AddArtwork = '/assets/views/handbook/addArtwork.jpg',
  Artwork = '/assets/views/handbook/artwork.jpg',
  ArtworkSettings = '/assets/views/handbook/artworkSettings.jpg',
  ArtworksList = '/assets/views/handbook/artworksList.jpg',
  ArtworkStatus = '/assets/views/handbook/artworkStatus.jpg',
  BuyerBackground = '/assets/views/handbook/buyerBackground.jpg',
  CreateAccount = '/assets/views/handbook/createAccount.jpg',
  Profile = '/assets/views/handbook/profile.jpg',
  SellerBackground = '/assets/views/handbook/sellerBackground.jpg',
  Shipping = '/assets/views/handbook/shipping.jpg',
  SoldArtwork = '/assets/views/handbook/soldArtwork.jpg',
  
  // contact view
  HighlightSectionPhoto = '/assets/views/contact/example.jpg',

  // about us view
  AboutUsBackground = '/assets/views/aboutUs/background.jpg',
  AboutUsFavourite = '/assets/views/aboutUs/favourite.jpg',
  CollectionImageFirst = '/assets/views/aboutUs/collection1.jpg',
  CollectionImageSecond = '/assets/views/aboutUs/collection2.jpg',
  CollectionImageThird = '/assets/views/aboutUs/collection3.jpg',
  CollectionImageFourth = '/assets/views/aboutUs/collection4.jpg',

  // regulations view
  CardPayments = '/assets/views/regulations/cardPayments.png'
}
