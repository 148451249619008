import { CartBillingState } from './cartBilling.interface';

export const cartBillingInitialState: CartBillingState = {
  paymentMethod: undefined,
  cartData: undefined,
  currentAddress: undefined,
  isInvoiceRequired: false,
  isPopupVisible: false,
  gatewayId: undefined
};
