import { IconName } from '@on-arte/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { UserMySalesPositions } from '@onArte/frontend/enums';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { BaseViewWithTabs } from '../baseViewWithTabs/baseViewWithTabs.component';

import { ContentContainer, InnerContainer, StyledAccountSubmenu, Title } from './baseUserMySalesView.styled';
import { BaseUserMySalesViewProps } from './baseUserMySalesView.types';

export const BaseUserMySalesView: React.FC<BaseUserMySalesViewProps> = (props: BaseUserMySalesViewProps): JSX.Element => {
  const { activePosition, title, withoutBottomBorder = false, children }: BaseUserMySalesViewProps = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <BaseViewWithTabs>
      <InnerContainer>
        <StyledAccountSubmenu
          menuPositions={[
            {
              label: t('onarte.website.meta.userSalesList.title'),
              internalPath: getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/',
              icon: IconName.Photo,
              isHighlighted: false,
              name: UserMySalesPositions.UserSalesList
            },
            {
              label: t('onarte.website.meta.userArtworkAdd.title'),
              internalPath: getRouteDetailsByName(RouteNameEnum.UserArtworkAdd)?.url ?? '/',
              icon: IconName.AddCircleOutline,
              isHighlighted: false,
              name: UserMySalesPositions.UserArtworkAdd
            },
            {
              label: t('onarte.website.meta.userSoldWorks.title'),
              internalPath: getRouteDetailsByName(RouteNameEnum.UserSoldArtworks)?.url ?? '/',
              icon: IconName.Receipt,
              isHighlighted: false,
              name: UserMySalesPositions.UserSoldArtworks
            },
            {
              label: t('onarte.website.meta.userDataForPayouts.title'),
              internalPath: getRouteDetailsByName(RouteNameEnum.UserDataForPayouts)?.url ?? '/',
              icon: IconName.Money,
              isHighlighted: false,
              name: UserMySalesPositions.UserDataForPayouts
            },
          ]}
          activePosition={activePosition}
        />
        <ContentContainer>
          <Title $withoutBottomBorder={withoutBottomBorder}>{title}</Title>
          {children}
        </ContentContainer>
      </InnerContainer>
    </BaseViewWithTabs>
  );
};
