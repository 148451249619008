import { EventDetailsInterface, EventListElementInterface, ListPaginationInterface } from '@on-arte/core-types';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { EventsListParams } from '@onArte/shared/interfaces';

import { api } from '../api';

export const getEvents: (params?: Partial<EventsListParams>) => Promise<ListPaginationInterface<EventListElementInterface>> = (
  params: Partial<EventsListParams> = {}
): Promise<ListPaginationInterface<EventListElementInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<EventListElementInterface>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/events?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<EventListElementInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getEventDetails: (id: string) => Promise<EventDetailsInterface> = (
  id: string
): Promise<EventDetailsInterface> => new Promise(
  (resolve: ApiResolve<EventDetailsInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/events/${id}`).then(
      (response: AxiosResponse<EventDetailsInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
