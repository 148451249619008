import { Match, match, MatchFunction } from 'path-to-regexp';

import { plRouting } from '@onArte/shared/constants';
import { RouteNameEnum } from '@onArte/shared/enums';
import { RouteInfo } from '@onArte/shared/interfaces';

export function getRouteDetailsByName(routeName: RouteNameEnum): RouteInfo | undefined {
  return plRouting.find((route: RouteInfo) => route.name === routeName);
}

export function getRouteDetailsByUrl(url: string): RouteInfo | undefined {
  return plRouting
    .filter((route: RouteInfo): boolean => route.name !== RouteNameEnum.NotFound)
    .find((route: RouteInfo): boolean => {
      const urlMatch: MatchFunction = match(route.url);
      const isMatched: Match<object> | false = urlMatch(url);

      return !!isMatched;
    });
}
