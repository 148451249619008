import {
  CategoryWithAttributesInterface,
  AttachmentInterface,
  AttachmentTypeEnum,
  CategoryAttributeInterface,
  ItemAttributeInterface,
  ItemWithAuctionsInterface,
} from '@on-arte/core-types';
import {
  UseState,
  TableDetailsRowData,
  DetailsTableValueType,
  DetailsTable,
  useNotifications,
  UseNotifications,
  UseRedirect,
  useRedirect,
  UseLogger,
  useLogger
} from '@on-arte/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getItemDetails, getItemsCategories } from '@onArte/frontend/api/requests';
import { BaseViewWithBreadcrumbs } from '@onArte/frontend/components';
import { FrontendResponseCode, QueryKey } from '@onArte/frontend/enums';
import { useItemsHelpers } from '@onArte/frontend/hooks';
import { ItemsHelpers } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { itemStatusConfig } from '@onArte/shared/constants';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const UserArtworkDetailsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Record<string, string | undefined> = useParams();
  const { getAttributeValueByType }: ItemsHelpers = useItemsHelpers();
  const [tableData, setTableData]: UseState<TableDetailsRowData[]> = useState<TableDetailsRowData[]>([]);
  const [itemData, setItemData]: UseState<ItemWithAuctionsInterface | null> = useState<ItemWithAuctionsInterface | null>(null);
  const [itemCategories, setItemCategories]: UseState<CategoryWithAttributesInterface[]> = useState<CategoryWithAttributesInterface[]>([]);
  const { redirect }: UseRedirect = useRedirect();
  const { addToast }: UseNotifications = useNotifications();
  const { logger }: UseLogger = useLogger();

  useQuery(
    [QueryKey.ItemCategories],
    (): Promise<CategoryWithAttributesInterface[]> => getItemsCategories(),
    {
      onSuccess: (data: CategoryWithAttributesInterface[]): void => setItemCategories(data),
      onError: (error: FrontendApiError): void => logger(QueryKey.ItemCategories, error)
    }
  );

  useQuery(
    [QueryKey.ArtworkDetails],
    (): Promise<ItemWithAuctionsInterface> => getItemDetails(id ?? ''),
    {
      onSuccess: (data: ItemWithAuctionsInterface): void => setItemData(data),
      onError: (error: FrontendApiError): void => {
        logger(QueryKey.ArtworkDetails, error);
        if (error.responseCode === FrontendResponseCode.NotFound) {
          redirect(getRouteDetailsByName(RouteNameEnum.NotFound)?.url ?? '/');
          return;
        } else {
          addToast({ content: t(error.message) });
        }
      }
    }
  );

  useEffect(
    (): void => {
      if (!itemData || !itemCategories) {
        return;
      }

      const tableRows: TableDetailsRowData[] = [
        { label: t('onarte.common.itemsAttributes.author.label'), value: itemData.manufacturer.name },
        { label: t('onarte.common.itemsAttributes.name.label'), value: itemData.name },
        {
          label: t('onarte.common.itemsAttributes.status.label'),
          value: {
            label: t(itemStatusConfig[itemData.status].label ?? itemData.status),
            background: itemStatusConfig[itemData.status].background,
            color: itemStatusConfig[itemData.status].color,
          },
          type: DetailsTableValueType.Status
        },
        { label: t('onarte.common.itemsAttributes.description.label'), value: itemData.description ?? '' },
        { label: t('onarte.common.itemsAttributes.owner.label'), value: itemData.owner.name },
        { label: t('onarte.common.itemsAttributes.year.label'), value: itemData.year ?? '' },
        { label: t('onarte.common.itemsAttributes.category.label'), value: t(`onarte.common.itemsCategories.${itemData.category.name}`) },
      ];
      const categoryProperties: CategoryWithAttributesInterface | undefined = itemCategories.find(
        (category: CategoryWithAttributesInterface): boolean => category.id === itemData.category.id
      );
      if (categoryProperties) {
        itemData.attributes.forEach((attribute: ItemAttributeInterface): void => {
          const attributeProperties: CategoryAttributeInterface | undefined = categoryProperties.attributes.find(
            (attributeItem: CategoryAttributeInterface): boolean => attributeItem.id === attribute.attributeId
          );
          if (attributeProperties) {
            tableRows.push({
              label: t(attributeProperties.label),
              value: getAttributeValueByType(attribute.value, attributeProperties),
            });
          }
        });
      }
      tableRows.push({
        label: t('onarte.common.itemsAttributes.uploaded_itemPhoto.label'),
        type: DetailsTableValueType.Image,
        value: itemData.attachments
          .filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.ItemPhoto)
          .map((attachment: AttachmentInterface): string => attachment.path)
      });
      tableRows.push({
        label: t('onarte.common.itemsAttributes.uploaded_itemCertificate.label'),
        type: DetailsTableValueType.Image,
        value: itemData.attachments
          .filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.ItemCertificate)
          .map((attachment: AttachmentInterface): string => attachment.path)
      });
      tableRows.push({
        label: t('onarte.common.itemsAttributes.uploaded_itemSignature.label'),
        type: DetailsTableValueType.Image,
        value: itemData.attachments
          .filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.ItemSignature)
          .map((attachment: AttachmentInterface): string => attachment.path)
      });
      setTableData(tableRows);
    },
    [itemCategories, itemData]
  );

  return (
    <BaseViewWithBreadcrumbs
      pageTitleSettings={{
        title: t('onarte.website.meta.userArtworkDetails.title'),
        linkLabel: t('onarte.common.cancel'),
        linkAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/'),
        // TODO: temporary hidden
        // buttonLabel: t('onarte.website.userArtworkDetailsView.artworkEdit'),
        // buttonAction: (): void => addToast({ content: t('onarte.common.functionalityCurrentlyNotAvailable') })
      }}
      breadcrumbs={[
        { label: t('onarte.website.meta.profile.title'), path: getRouteDetailsByName(RouteNameEnum.UserAccountSettings)?.url ?? '/' },
        { label: t('onarte.website.meta.userSalesList.title'), path: getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/' },
        { label: t('onarte.website.meta.userArtworkDetails.title'), path: '' },
      ]}
    >
      <DetailsTable rows={tableData} />
    </BaseViewWithBreadcrumbs>
  );
};
