import React from 'react';

import { Container, LoaderItem } from './loader.styled';
import { LoaderProps } from './loader.types';

export const Loader: React.FC<LoaderProps> = (props: LoaderProps): JSX.Element => {
  const { isVisible }: LoaderProps = props;

  return (
    <Container $isVisible={isVisible}>
      <LoaderItem />
    </Container>
  );
};
