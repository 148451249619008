import { AccountSubmenu, Breakpoint, Color, FontWeight, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { TitleProps } from './baseUserMySalesView.types';

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
  }
`;

export const StyledAccountSubmenu: StyledComponent<typeof AccountSubmenu> = styled(AccountSubmenu)`
  flex-shrink: 0;
  width: 100%;
  max-width: 350px;

  @media ${Breakpoint.Tablet} {
    max-width: none;
  }
`;

export const ContentContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  margin-left: 40px;

  @media ${Breakpoint.Tablet} {
    margin-left: 0;
    margin-top: 60px;
  }
`;

export const Title: StyledComponent<'div', TitleProps> = styled.div<TitleProps>`
  font-weight: ${FontWeight.Medium};
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: ${Color.Black};
  padding-bottom: 32px;
  border-bottom: ${({ $withoutBottomBorder }: TitleProps): string => $withoutBottomBorder ? 'unset' : `1px solid ${Color.Gray300}`};
  margin-bottom: ${({ $withoutBottomBorder }: TitleProps): string => $withoutBottomBorder ? '0' : '48px'};
`;
