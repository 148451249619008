import { AddressInterface, AddressTypeEnum, ListPaginationInterface, LocationTypeEnum } from '@on-arte/core-types';
import {
  BillingAddressFormData,
  DeliveryCompanyOrderFormData,
  ShippingAddressFormData,
  useFormatPhoneNumber,
  UseFormatPhoneNumber,
  useLogger,
  UseLogger,
  UseState
} from '@on-arte/ui';
import { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getAddresses } from '@onArte/frontend/api/requests';
import { QueryKey } from '@onArte/frontend/enums';
import { UseAddresses } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { emptyRequest } from '@onArte/frontend/utils';
import { AddressSaveWithLocationModel } from '@onArte/shared/models';

export const useAddresses: (withDataDownload?: boolean) => UseAddresses = (withDataDownload: boolean = false): UseAddresses => {
  const [deliveryAddresses, setDeliveryAddresses]: UseState<AddressInterface[]> = useState<AddressInterface[]>([]);
  const [billingAddresses, setBillingAddresses]: UseState<AddressInterface[]> = useState<AddressInterface[]>([]);
  const { logger }: UseLogger = useLogger();
  const { formatPhone }: UseFormatPhoneNumber = useFormatPhoneNumber();
  const { t }: TransProps<never> = useTranslation();

  useQuery(
    [QueryKey.AddressesList],
    (): Promise<ListPaginationInterface<AddressInterface> | void> => withDataDownload
      ? getAddresses()
      : emptyRequest(),
    {
      onSuccess: (data: ListPaginationInterface<AddressInterface> | undefined): void => {
        if (withDataDownload && data) {
          setDeliveryAddresses(data.list.filter((address: AddressInterface): boolean => address.type === AddressTypeEnum.Delivery));
          setBillingAddresses(data.list.filter((address: AddressInterface): boolean => address.type === AddressTypeEnum.Billing));
        }
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.AddressesList, error)
    }
  );

  const isCompanyAddress: (addressData: AddressInterface) => boolean = (addressData: AddressInterface): boolean => {
    return !!(addressData.taxId && addressData.companyName);
  };

  const addAddressToCache: (type: AddressTypeEnum, address: AddressInterface) => void = (
    type: AddressTypeEnum, address: AddressInterface
  ): void => {
    if (type === AddressTypeEnum.Billing) {
      setBillingAddresses([...billingAddresses, address]);
    } else {
      setDeliveryAddresses([...deliveryAddresses, address]);
    }
  };

  const removeAddressFromCache: (type: AddressTypeEnum, addressId: string) => void = (type: AddressTypeEnum, addressId: string): void => {
    if (type === AddressTypeEnum.Billing) {
      setBillingAddresses(billingAddresses.filter((address: AddressInterface): boolean => address.id !== addressId));
    } else {
      setDeliveryAddresses(deliveryAddresses.filter((address: AddressInterface): boolean => address.id !== addressId));
    }
  };

  const updateAddressInCache: (type: AddressTypeEnum, address: AddressInterface) => void = (
    type: AddressTypeEnum, address: AddressInterface
  ): void => {
    if (type === AddressTypeEnum.Billing) {
      const updatedAddress: AddressInterface | undefined = billingAddresses
        .find((item: AddressInterface): boolean => item.id === address.id);
      if (updatedAddress) {
        billingAddresses.splice(billingAddresses.indexOf(updatedAddress), 1, address);
      }
    } else {
      const updatedAddress: AddressInterface | undefined = deliveryAddresses
        .find((item: AddressInterface): boolean => item.id === address.id);
      if (updatedAddress) {
        deliveryAddresses.splice(deliveryAddresses.indexOf(updatedAddress), 1, address);
      }
    }
  };

  const transformShippingAddressDataToAddressWithLocation: (
    values: ShippingAddressFormData, addressId?: string
  ) => AddressSaveWithLocationModel = (
    values: ShippingAddressFormData, addressId?: string
  ): AddressSaveWithLocationModel => {
    return {
      id: addressId,
      type: AddressTypeEnum.Delivery,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone ?? '',
      location: {
        type: LocationTypeEnum.Approximate,
        latitude: 0,
        longitude: 0,
        country: values.country,
        city: values.city,
        postalCode: values.postalCode,
        street: values.street,
        houseNumber: values.houseNumber,
        apartmentNumber: values.apartmentNumber || null,
      }
    };
  };

  const transformBillingAddressDataToAddressWithLocation: (
    values: BillingAddressFormData, addressId?: string
  ) => AddressSaveWithLocationModel = (
    values: BillingAddressFormData, addressId?: string
  ): AddressSaveWithLocationModel => {
    return {
      id: addressId,
      type: AddressTypeEnum.Billing,
      firstName: values.firstName,
      lastName: values.lastName,
      ...(values.taxId ? { taxId: values.taxId } : {}),
      ...(values.companyName ? { companyName: values.companyName } : {}),
      location: {
        type: LocationTypeEnum.Approximate,
        latitude: 0,
        longitude: 0,
        country: values.country,
        city: values.city,
        postalCode: values.postalCode,
        street: values.street,
        houseNumber: values.houseNumber,
        apartmentNumber: values.apartmentNumber || null,
      }
    };
  };

  const transformAddressInterfaceToShippingAddressFormData: (values: AddressInterface) => ShippingAddressFormData = (
    values: AddressInterface
  ): ShippingAddressFormData => {
    return {
      firstName: values.firstName,
      lastName: values.lastName,
      street: values.location.address.street ?? '',
      houseNumber: values.location.address.houseNumber ?? '',
      apartmentNumber: values.location.address.apartmentNumber,
      postalCode: values.location.address.postalCode ?? '',
      city: values.location.address.city,
      country: values.location.address.country,
      phone: values.phone ?? '',
    };
  };

  const transformAddressInterfaceToBillingAddressFormData: (values: AddressInterface) => BillingAddressFormData = (
    values: AddressInterface
  ): BillingAddressFormData => {
    return {
      firstName: values.firstName,
      lastName: values.lastName,
      street: values.location.address.street ?? '',
      houseNumber: values.location.address.houseNumber ?? '',
      apartmentNumber: values.location.address.apartmentNumber,
      postalCode: values.location.address.postalCode ?? '',
      city: values.location.address.city,
      country: values.location.address.country,
      taxId: values.taxId ?? '',
      companyName: values.companyName ?? '',
    };
  };

  const transformAddressInterfaceToDeliveryCompanyOrderFormData: (values: AddressInterface) => DeliveryCompanyOrderFormData = (
    values: AddressInterface
  ): DeliveryCompanyOrderFormData => {
    return {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone ?? '',
      email: values.email ?? '',
      street: values.location.address.street ?? '',
      houseNumber: values.location.address.houseNumber ?? '',
      apartmentNumber: values.location.address.apartmentNumber,
      postalCode: values.location.address.postalCode ?? '',
      city: values.location.address.city,
      country: values.location.address.country,
    };
  };

  const getBillingAddressTitle: (addressData: AddressInterface) => string = (addressData: AddressInterface): string => {
    return t(`onarte.common.${isCompanyAddress(addressData) ? 'company' : 'privatePerson'}`);
  };

  const getPrettyAddressLines: (data: AddressInterface, type: AddressTypeEnum) => string[] = (
    data: AddressInterface, type: AddressTypeEnum
  ): string[] => {
    if (type === AddressTypeEnum.Delivery) {
      return [
        `${data.firstName} ${data.lastName}`,
        `${t('onarte.common.streetPrefix', { value: data.location.address.street ?? ''})}
        ${data.location.address.houseNumber ?? ''}
        ${data.location.address.apartmentNumber ? `/ ${data.location.address.apartmentNumber}` : ''}`,
        ` ${data.location.address.postalCode ?? ''} ${data.location.address.city}`,
        `${formatPhone(data.phone ?? '')}`,
      ];
    } else {
      let lines: string[] = [];
      if (isCompanyAddress(data)) {
        lines.push(data?.companyName ?? '');
        lines.push(t('onarte.common.taxIdPrefix', { value: data.taxId ?? ''}));
      }
      lines = [
        ...lines,
        ...[
          `${data.firstName} ${data.lastName}`,
          `${t('onarte.common.streetPrefix', { value: data.location.address.street ?? ''})}
          ${data.location.address.houseNumber ?? ''}
          ${data.location.address.apartmentNumber ? `/ ${data.location.address.apartmentNumber}` : ''}`,
          ` ${data.location.address.postalCode ?? ''} ${data.location.address.city}`,
        ]
      ];
      
      return lines;
    }
  };

  return {
    deliveryAddresses,
    billingAddresses,
    addAddressToCache,
    removeAddressFromCache,
    updateAddressInCache,
    getPrettyAddressLines,
    getBillingAddressTitle,
    transformBillingAddressDataToAddressWithLocation,
    transformShippingAddressDataToAddressWithLocation,
    transformAddressInterfaceToShippingAddressFormData,
    transformAddressInterfaceToBillingAddressFormData,
    transformAddressInterfaceToDeliveryCompanyOrderFormData
  };
};
