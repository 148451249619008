import { ListPaginationInterface, ManufacturerInterface } from '@on-arte/core-types';
import { SuggestionItem } from '@on-arte/ui';

import { getManufacturers } from '../api/requests';
import { AutosuggestActions } from '../interfaces';

export const useAutosuggestActions: () => AutosuggestActions = (): AutosuggestActions => {
  const searchManufacturersAction: (inputValue: string) => Promise<SuggestionItem[]> = (inputValue: string): Promise<SuggestionItem[]> => {
    return new Promise((resolve: (suggestions: SuggestionItem[]) => void, reject: () => void): void => {
      getManufacturers(inputValue)
        .then((data: ListPaginationInterface<ManufacturerInterface>): void => {
          resolve(data.list.map((manufacturerInfo: ManufacturerInterface): SuggestionItem => ({
            id: manufacturerInfo.id,
            label: manufacturerInfo.name,
            image: null,
          })));
        })
        .catch((): void => reject());
    });
  };

  return { searchManufacturersAction };
};
