import { ListPaginationInterface, PostDTO } from '@on-arte/core-types';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { ListParams } from '@onArte/shared/interfaces';

import { api } from '../api';

export const getPosts: (params: ListParams) => Promise<ListPaginationInterface<PostDTO>> = (
  params: ListParams
): Promise<ListPaginationInterface<PostDTO>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<PostDTO>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/blog/posts?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<PostDTO>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
