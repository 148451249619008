import { IsDefined, MinLength } from 'class-validator';

export class AddOrRemoveDiscoutCodeToCartModel {
  @IsDefined()
  @MinLength(2)
  public codeValue: string;

  constructor(
    codeValue: string,
  ) {
    this.codeValue = codeValue;
  }
}
