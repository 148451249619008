import { ExternalUserProfileInterface, SetPasswordModel, UserUpdateModel } from '@on-arte/core-types';
import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { UserProfileWithToken } from '@onArte/shared/interfaces';

import { api } from '../api';

export const getProfile: () => Promise<UserProfileWithToken> = (): Promise<UserProfileWithToken> => new Promise(
  (resolve: ApiResolve<UserProfileWithToken>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/profile').then(
      (response: AxiosResponse<UserProfileWithToken>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updateProfile: (data: UserUpdateModel) => Promise<ExternalUserProfileInterface> = (
  data: UserUpdateModel
): Promise<ExternalUserProfileInterface> => new Promise(
  (resolve: ApiResolve<ExternalUserProfileInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/profile', data).then(
      (response: AxiosResponse<ExternalUserProfileInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const changePassword: (data: SetPasswordModel) => Promise<ExternalUserProfileInterface> = (
  data: SetPasswordModel
): Promise<ExternalUserProfileInterface> => new Promise(
  (resolve: ApiResolve<ExternalUserProfileInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/profile/password', data).then(
      (response: AxiosResponse<ExternalUserProfileInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
