import {
  AddressTypeEnum,
  AuctionSoldInterface,
  CartAuctionInterface,
  CartCostInterface,
  CartCostTypeEnum,
  CartInterface,
  CartStatusEnum,
  DeliveryOptionInterface,
  ThumbnailAttachmentTypeEnum,
  TranslationsEnum,
} from '@on-arte/core-types';
import {
  getPathWithParams,
  Input,
  ItemShippingSettings,
  RadioOption,
  ShippingAddressForm,
  ShippingAddressFormData,
  ShippingBox,
  ShippingBoxTheme,
  useFormikForm,
  UseFormikForm,
  UseLogger, 
  useLogger,
  useNotifications,
  UseNotifications,
  useRedirect,
  UseRedirect,
} from '@on-arte/ui';
import { FormikProps } from 'formik';
import React, { Dispatch, useEffect, useMemo, useReducer, useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryClient, useQuery, useQueryClient } from 'react-query';
import { Params, useParams } from 'react-router-dom';

import { getCart, getDeliveryOptionsForAuctions } from '@onArte/frontend/api/requests';
import { CartBaseView } from '@onArte/frontend/components';
import { allowedCartsStatuses, initialShippingAddressValues } from '@onArte/frontend/constants';
import { QueryKey } from '@onArte/frontend/enums';
import { useAddresses, useAuth, useConversions, useSocket } from '@onArte/frontend/hooks';
import { UseAddresses, UseAuth, UseConversions, UseSocket } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { emptyRequest, getImageThumbnail } from '@onArte/frontend/utils';
import { RouteNameEnum, WebSocketCommand } from '@onArte/shared/enums';
import { UpdateDeliveryInCartAuctionModel, WebSocketSubscriptionDetails } from '@onArte/shared/models';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { AddressesDialog } from '../components';

import { useCartDelivery } from './cartDelivery.hook';
import {
  CommentToOrderInput,
  Container,
  Header,
  InnerContainer,
  ItemShippingSettingsContainer,
  StyledButton,
  UnderlineButton
} from './cartDelivery.styled';
import { UseCartDelivery } from './cartDelivery.types';
import { CartDeliveryAction, CartDeliveryActions, cartDeliveryInitialState, cartDeliveryReducer, CartDeliveryState } from './reducer';

export const CartDeliveryView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Readonly<Params<string>> = useParams();
  const { logger }: UseLogger = useLogger();
  const shippingFormRef: React.RefObject<FormikProps<ShippingAddressFormData>> = useRef(null);
  const { isFormSubmitted, setFormSubmitted, setFormNotSubmitted }: UseFormikForm = useFormikForm();
  const { userData }: UseAuth = useAuth();
  const currency: string = 'zł';
  // TODO => uncomment when BE adds isHidden flag
  // const [saveAddressCheckboxValue, setSaveAddressCheckboxValue]: UseState<boolean> = useState<boolean>(false);
  const { getPrettyAddressLines }: UseAddresses = useAddresses();
  const { redirect }: UseRedirect = useRedirect();
  const queryClient: QueryClient = useQueryClient();
  const { socket, connectedToSocket, setIsConnectedToSocket }: UseSocket = useSocket();
  const { addToast }: UseNotifications = useNotifications();
  const [cartDeliveryState, cartDeliveryDispatch]: [CartDeliveryState, Dispatch<CartDeliveryActions>]
    = useReducer(cartDeliveryReducer, cartDeliveryInitialState);
  const { sendInitiateCheckoutEvent }: UseConversions = useConversions();
  const {
    onAddressChoose,
    onItemShippingSettingsChange,
    onCommentToOrderChange,
    submit,
    redirectToHomePage
  }: UseCartDelivery = useCartDelivery(
    cartDeliveryState,
    cartDeliveryDispatch,
    shippingFormRef,
    setFormSubmitted,
    setFormNotSubmitted,
    id
  );

  const checkIfViewShouldBeAvailable: (dataCart: CartInterface) => void = (dataCart: CartInterface): void => {
    if (!allowedCartsStatuses.includes(dataCart.status) || !dataCart.auctions.length) {
      redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
    } else if ([CartStatusEnum.Closed].includes(dataCart.status)) {
      redirect(getRouteDetailsByName(RouteNameEnum.CartSuccess)?.url ?? '/');
    }
  };

  const isViewValid: boolean = useMemo(
    (): boolean => {
      const auctionWithDeliveries: number = Object.values(cartDeliveryState.chosenAuctionDeliverySettings)
        .reduce((acc: number, item: UpdateDeliveryInCartAuctionModel): number => acc + (item.deliveryType ? 1 : 0), 0);
      
      return cartDeliveryState.cartAuctions.length === auctionWithDeliveries;
    },
    [cartDeliveryState.cartAuctions.length, cartDeliveryState.chosenAuctionDeliverySettings]
  );

  useEffect(
    (): void => {
      if (!socket || connectedToSocket || !cartDeliveryState.cartAuctions.length) {
        return;
      }

      setIsConnectedToSocket();
      cartDeliveryState.cartAuctions.forEach((cartAuction: CartAuctionInterface): void => {
        socket.emit(WebSocketCommand.SubscribeAuction, { id: cartAuction.auction.auctionId } as WebSocketSubscriptionDetails);
      });
      socket.on(WebSocketCommand.AuctionSold, (data: AuctionSoldInterface): void => {
        if (data.buyer.id !== userData?.id) {
          addToast({ content: t('onarte.website.common.auctionInYourCartSold', { name: data.auction.name })});
          void queryClient.invalidateQueries(QueryKey.Cart);
        }
      });
    },
    [socket, cartDeliveryState.cartAuctions]
  );

  useEffect(
    (): (() => void) => {
      return (): void => {
        if (socket) {
          socket.off(WebSocketCommand.AuctionSold);
        }
      };
    },
    [socket]
  );

  useQuery(
    [QueryKey.Cart],
    (): Promise<CartInterface | void> => id
      ? getCart(id ?? '')
      : emptyRequest(),
    {
      onSuccess: (data: CartInterface): void => {
        if (!data) {
          return;
        }

        checkIfViewShouldBeAvailable(data);
        cartDeliveryDispatch({ type: CartDeliveryAction.SetCartAuctions, payload: data.auctions });
        if (data.auctions.length && data.auctions[0].deliveryAddress) {
          cartDeliveryDispatch({ type: CartDeliveryAction.SetCurrentAddress, payload: data.auctions[0].deliveryAddress });
        }
        if (data.commentToOrder) {
          cartDeliveryDispatch({ type: CartDeliveryAction.SetCommentToOrder, payload: data.commentToOrder });
        }
        data.auctions.forEach((cartAuction: CartAuctionInterface): void => {
          if (cartAuction.deliveryType || cartAuction.commentToSeller) {
            cartDeliveryDispatch({
              type: CartDeliveryAction.SetChosenAuctionDeliverySettings,
              payload: {
                [cartAuction.auction.auctionId]: {
                  commentToSeller: cartAuction.commentToSeller ?? undefined,
                  deliveryType: cartAuction.deliveryType ?? undefined,
                }
              }
            });
          }
        });
        if (data.auctions.every((auction: CartAuctionInterface): boolean => !auction.deliveryType)) {
          sendInitiateCheckoutEvent(data.id, data.finalPrice);
        }
      },
      onError: (error: FrontendApiError): void => {
        if (error.message === TranslationsEnum.CartNotEditable) {
          redirect(getRouteDetailsByName(RouteNameEnum.CartSuccess)?.url ?? '/');
        } else if (error.message === TranslationsEnum.AccessDenied) {
          redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
        } else {
          logger(QueryKey.Cart, error);
        }
      }
    }
  );

  useQuery(
    [QueryKey.DeliveryOptionsForAuctions],
    (): Promise<Record<string, DeliveryOptionInterface[]> | void> => id
      ? getDeliveryOptionsForAuctions(id ?? '')
      : emptyRequest(),
    {
      onSuccess: (data: Record<string, DeliveryOptionInterface[]> | undefined): void => {
        if (data) {
          cartDeliveryDispatch({ type: CartDeliveryAction.SetAuctionsAvailableDeliveries, payload: data });
        }
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.DeliveryOptionsForAuctions, error)
    }
  );

  const getCostByType: (costs: CartCostInterface[], type: CartCostTypeEnum) => CartCostInterface | undefined = (
    costs: CartCostInterface[], type: CartCostTypeEnum
  ): CartCostInterface | undefined => {
    return costs.find((cost: CartCostInterface): boolean => cost.type === type);
  };
  
  return (
    <CartBaseView
      breadcrumbs={[
        { label: t('onarte.website.meta.shop.title'), path: getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/' },
        {
          label: t('onarte.website.meta.cartDelivery.title'),
          path: getRouteDetailsByName(RouteNameEnum.CartDelivery)?.url ?? '/',
          active: true
        },
        {
          label: t('onarte.website.meta.cartBilling.title'),
          path: isViewValid
            ? getPathWithParams(getRouteDetailsByName(RouteNameEnum.CartBilling)?.url ?? '/', { id: id ?? '' })
            : ''
        },
      ]}
    >
      <Container>
        <InnerContainer>
          <Header>{t('onarte.website.cartDelivery.userData.header')}</Header>
          <Input 
            label={t('onarte.website.cartDelivery.email.inputLabel')}
            value={userData?.email ?? '-'}
            disabled
          />
          <Header>{t('onarte.website.cartDelivery.shippingData.header')}</Header>
          {cartDeliveryState.currentAddress ? (
            <ShippingBox
              addressLines={getPrettyAddressLines(cartDeliveryState.currentAddress, AddressTypeEnum.Delivery)}
              boxTheme={ShippingBoxTheme.Dark}
              onChangeClick={(): void => cartDeliveryDispatch({ type: CartDeliveryAction.SetIsPopupVisible, payload: true })}
            />
          ) : (
            <>
              <ShippingAddressForm
                initialValues={initialShippingAddressValues}
                isFormSubmitted={isFormSubmitted}
                ref={shippingFormRef}
              />
              {/* TODO => uncomment when BE implements isHidden flag */}
              {/* <StyledCheckbox
                label={t('onarte.website.cartDelivery.saveDelivery.inputLabel')}
                onChange={setSaveAddressCheckboxValue}
              /> */}
            </>
          )}
          <CommentToOrderInput 
            label={t('onarte.website.cartDelivery.orderAdnotations.inputLabel')}
            value={cartDeliveryState.commentToOrder}
            onBlur={onCommentToOrderChange}
          />
          <Header>{t('onarte.website.cartDelivery.shippingOptions.header')}</Header>
          {cartDeliveryState.cartAuctions.map((data: CartAuctionInterface): JSX.Element => (
            <ItemShippingSettingsContainer key={data.auction.auctionId}>
              <ItemShippingSettings
                name={data.auction.auctionId}
                initialInputValue={cartDeliveryState.chosenAuctionDeliverySettings[data.auction.auctionId]?.commentToSeller}
                initialRadioValue={cartDeliveryState.chosenAuctionDeliverySettings[data.auction.auctionId]?.deliveryType}
                itemDetails={{
                  boxTitleDetails: {
                    itemName: data.auction.name,
                    itemAttribute: t(data.auction.label)
                  },
                  image: getImageThumbnail(data.auction.coverPhoto, ThumbnailAttachmentTypeEnum.Size_100x100),
                  price: getCostByType(data.costs, CartCostTypeEnum.Item)?.basePrice ?? 0,
                }}
                shippingOptions={(cartDeliveryState.auctionAvailableDeliveries[data.auction.auctionId] ?? [])
                  .map((item: DeliveryOptionInterface): RadioOption => ({
                    label: t(item.name),
                    optionName: item.type,
                    additionalAttribute: `${item.price} ${currency}`,
                  }))
                }
                onChange={
                  (optionName: string, message: string): void => onItemShippingSettingsChange(optionName, message, data.auction.auctionId)
                }
              />
            </ItemShippingSettingsContainer>
          ))}
          <StyledButton
            label={t('onarte.website.cartDelivery.submit.buttonLabel')}
            onClick={submit}
            disabled={!isViewValid}
          />
          <UnderlineButton onClick={redirectToHomePage}>{t('onarte.website.cartDelivery.goBack.buttonLabel')}</UnderlineButton>
        </InnerContainer>
      </Container>
      {cartDeliveryState.currentAddress && (
        <AddressesDialog
          formType={AddressTypeEnum.Delivery}
          activeAddressId={cartDeliveryState.currentAddress?.id}
          onActiveAddressChange={onAddressChoose}
          onClose={(): void => cartDeliveryDispatch({ type: CartDeliveryAction.SetIsPopupVisible, payload: false })}
          isVisible={cartDeliveryState.isPopupVisible}
        />
      )}
    </CartBaseView>
  );
};
