import { UserProfileInterface } from '@on-arte/core-types';
import { LocationState, useLocalStorage, UseLocalStorage, useRedirect, UseRedirect } from '@on-arte/ui';
import { useContext } from 'react';
import { useLocation, Location } from 'react-router-dom';

import { setAuthToken } from '@onArte/frontend/api/api';
import { logoutRequest } from '@onArte/frontend/api/requests';
import { AuthContext } from '@onArte/frontend/contexts';
import { AuthReducerAction, LocalStorageKey } from '@onArte/frontend/enums';
import { UseAuth, AuthContextState } from '@onArte/frontend/interfaces';
import { AuthContextData } from '@onArte/frontend/types';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const useAuth: () => UseAuth = (): UseAuth => {
  const [state, dispatch]: AuthContextData = useContext(AuthContext);
  const [data, storeAuthData]: UseLocalStorage<AuthContextState | null> = useLocalStorage<AuthContextState | null>(
    LocalStorageKey.AuthData, null
  );
  const location: Location = useLocation();
  const { redirect }: UseRedirect = useRedirect();
  const pathnameAfterSignIn: string =
    (
      (location.state as LocationState)?.from?.pathname !== getRouteDetailsByName(RouteNameEnum.Logout)?.url
        ? (location.state as LocationState)?.from?.pathname
        : undefined
    )
    || getRouteDetailsByName(RouteNameEnum.Home)?.url
    || '';

  const signIn: (
    userData: UserProfileInterface, token: string, tokenExpiration: number, pathnameToRedirect?: string
  ) => void = (
    userData: UserProfileInterface, token: string, tokenExpiration: number, pathnameToRedirect?: string
  ): void => {
    setAuthToken(token);
    dispatch({ type: AuthReducerAction.SignIn, payload: { userData, token, tokenExpiration } });
    storeAuthData({ userData, token, tokenExpiration });
    redirect(pathnameToRedirect ?? pathnameAfterSignIn);
  };

  const signOut: (redirectPath?: string) => void = (redirectPath?: string): void => {
    const logoutCleanup: () => void = (): void => {
      dispatch({ type: AuthReducerAction.SignOut, payload: null });
      storeAuthData(null);
      setAuthToken(null);
      redirect(redirectPath ?? getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
    };

    logoutRequest()
      .then((): void => logoutCleanup())
      .catch((): void => logoutCleanup());
  };

  const refreshProfileData: (
    refreshedUserData: UserProfileInterface
  ) => void = (refreshedUserData: UserProfileInterface): void => {
    dispatch({ 
      type: AuthReducerAction.UpdateProfile, 
      payload: { userData: refreshedUserData, token: state.token, tokenExpiration: state.tokenExpiration } 
    });
    if (data) {
      storeAuthData({ userData: refreshedUserData, token: data.token, tokenExpiration: data.tokenExpiration });
    }
  };

  const isUserLoggedIn: () => boolean = (): boolean => {
    return !!state.token;
  };

  return {
    signIn,
    signOut,
    isUserLoggedIn,
    token: state?.token ?? null,
    tokenExpiration: state?.tokenExpiration ?? null,
    userData: state?.userData ?? null,
    refreshProfileData
  };
};
