import {
  AuctionInfoInterface,
  AuctionOfferAddedInterface,
  AuctionOfferListElementDTO,
  AuctionOfferStatusEnum,
  AuctionSoldInterface,
  AuctionStatusEnum,
  CartStatusEnum,
  ListPaginationInterface,
  ThumbnailAttachmentTypeEnum
} from '@on-arte/core-types';
import {
  PaginationDetails,
  useLogger,
  UseLogger,
  usePagination,
  UseState,
  Status,
  Color,
  UseRedirect,
  useRedirect,
  getPathWithParams,
  MessageBox,
  UseNotifications,
  useNotifications,
  UseFormatDate,
  useFormatDate,
  Language,
} from '@on-arte/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from 'react-query';

import { getOffersList } from '@onArte/frontend/api/requests';
import { BaseViewWithTabs } from '@onArte/frontend/components';
import { QueryKey } from '@onArte/frontend/enums';
import { useAuth, useSocket } from '@onArte/frontend/hooks';
import { UseAuth, UseSocket } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { getImageThumbnail } from '@onArte/frontend/utils';
import { RouteNameEnum, WebSocketCommand } from '@onArte/shared/enums';
import { WebSocketSubscriptionDetails } from '@onArte/shared/models';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { Header, OffersContainer, StyledPagination, StyledArtworkBiddingBox } from './userBiddingsList.styled';

export const UserBiddingsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [offersInProgress, setOffersInProgress]: UseState<AuctionOfferListElementDTO[]> = useState<AuctionOfferListElementDTO[]>([]);
  const [endedOffers, setEndedOffers]: UseState<AuctionOfferListElementDTO[]> = useState<AuctionOfferListElementDTO[]>([]);
  const { setMaxItems, setPage, itemsPerPage, maxItems, offset }: PaginationDetails = usePagination();
  const { logger }: UseLogger = useLogger();
  const { redirect }: UseRedirect = useRedirect();
  const { socket, connectedToSocket, setIsConnectedToSocket }: UseSocket = useSocket();
  const { userData }: UseAuth = useAuth();
  const { addToast }: UseNotifications = useNotifications();
  const currency: string = 'zł';
  const { formatDate }: UseFormatDate = useFormatDate(Language.Pl);

  const activeOffersListQuery: UseQueryResult = useQuery(
    [QueryKey.ActiveOffersList],
    (): Promise<ListPaginationInterface<AuctionOfferListElementDTO>> => getOffersList({
      offset, limit: itemsPerPage, status: AuctionStatusEnum.Active
    }),
    {
      onSuccess: (data: ListPaginationInterface<AuctionOfferListElementDTO>): void => setOffersInProgress(data.list),
      onError: (error: FrontendApiError): void => logger(QueryKey.AddressesList, error)
    }
  );

  const finishedOffersListQuery: UseQueryResult = useQuery(
    [QueryKey.FinishedOffersList],
    (): Promise<ListPaginationInterface<AuctionOfferListElementDTO>> => getOffersList({
      offset, limit: itemsPerPage, status: AuctionStatusEnum.Finished
    }),
    {
      onSuccess: (data: ListPaginationInterface<AuctionOfferListElementDTO>): void => {
        setEndedOffers(data.list);
        setMaxItems(data.amount);
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.AddressesList, error)
    }
  );

  useEffect(
    (): void => {
      if (!socket || connectedToSocket || !offersInProgress.length) {
        return;
      }

      setIsConnectedToSocket();
      offersInProgress.forEach((offer: AuctionOfferListElementDTO): void => {
        socket.emit(WebSocketCommand.SubscribeAuction, { id: offer.auction.id } as WebSocketSubscriptionDetails);
      });
      socket.on(WebSocketCommand.AuctionOfferAdded, (data: AuctionOfferAddedInterface): void => {
        void activeOffersListQuery.refetch().then((): void => {
          addToast({
            content: t(
              'onarte.website.userBiddingsListView.auctionOfferAdded',
              { name: data.auction.name, price: `${data.offer.price} ${currency}`}
            )
          });
        });
      });
      socket.on(WebSocketCommand.AuctionOfferUpdatedEndDatetime, (data: AuctionInfoInterface): void => {
        void activeOffersListQuery.refetch().then((): void => {
          if (data.validity?.to) {
            addToast({
              content: t(
                'onarte.website.userBiddingsListView.auctionOfferUpdatedEndDatetime',
                { name: data.name, date: formatDate(data.validity.to, 'DD.MM.YY HH:mm:ss') }
              )
            });
          }
        });
      });
      socket.on(WebSocketCommand.AuctionSold, (data: AuctionSoldInterface): void => {
        void Promise.all([activeOffersListQuery.refetch(), finishedOffersListQuery.refetch()]).then((): void => {
          addToast({
            content: t(
              data.buyer.id === userData?.id
                ? 'onarte.website.userBiddingsListView.auctionSoldToMe'
                : 'onarte.website.userBiddingsListView.auctionSoldToOther',
              { name: data.auction.name }
            )
          });
        });
      });
    },
    [socket, offersInProgress]
  );

  useEffect(
    (): (() => void) => {
      return (): void => {
        if (socket) {
          offersInProgress.forEach((offer: AuctionOfferListElementDTO): void => {
            socket.emit(WebSocketCommand.UnsubscribeAuction, { id: offer.auction.id } as WebSocketSubscriptionDetails);
          });
          socket.off(WebSocketCommand.AuctionOfferAdded);
          socket.off(WebSocketCommand.AuctionOfferUpdatedEndDatetime);
          socket.off(WebSocketCommand.AuctionSold);
        }
      };
    },
    [socket]
  );

  const offerBadges: (
    offer: AuctionOfferListElementDTO, isFinished?: boolean
  ) => Status[] = (offer: AuctionOfferListElementDTO, isFinished?: boolean): Status[] => {
    const badges: Status[] = [];
    if (offer.contextStatus === AuctionOfferStatusEnum.Submitted) {
      if (!isFinished) {
        badges.push({
          color: Color.Blue500,
          background: Color.Blue100,
          label: t('onarte.website.userBiddingsListView.status.submitted'),
        });
      }
      badges.push({
        color: Color.Error500,
        background: Color.Error100,
        label: t('onarte.website.userBiddingsListView.status.minimalPriceNotReached')
      });
    } else if (offer.contextStatus === AuctionOfferStatusEnum.Won) {
      badges.push({
        color: isFinished
          ? Color.Success500
          : Color.Blue500,
        background: isFinished
          ? Color.Success100
          : Color.Blue100,
        label: isFinished
          ? t('onarte.website.userBiddingsListView.status.wonAndFinished')
          : t('onarte.website.userBiddingsListView.status.wins')
      });
    } else if (offer.contextStatus === AuctionOfferStatusEnum.Outbid) {
      badges.push({
        color: Color.Error500,
        background: Color.Error100,
        label: t('onarte.website.userBiddingsListView.status.outbid')
      });
    }

    return badges;
  };

  const showAuction: (auctionId: string) => void = (auctionId: string): void => {
    redirect(getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/', { id: auctionId }));
  };

  const completeOrderDetails: (cartId?: string) => void = (cartId?: string): void => {
    if (cartId) {
      redirect(getPathWithParams(getRouteDetailsByName(RouteNameEnum.CartDelivery)?.url ?? '/', { id: cartId }));
    }
  };

  const orderDetails: (cartId?: string) => void = (cartId?: string): void => {
    if (cartId) {
      redirect(`${getRouteDetailsByName(RouteNameEnum.UserMyShopping)?.url ?? '/'}?orderId=${cartId}`);
    }
  };

  return (
    <BaseViewWithTabs>
      <OffersContainer>
        <Header>{t('onarte.website.userBiddingsListView.offersInProgress.header')}</Header>
        {(!activeOffersListQuery.isLoading && !offersInProgress.length) ? (
          <MessageBox message={t('onarte.website.userBiddingsListView.noOffersInProgress')} />
        ) : (
          offersInProgress.map((offer: AuctionOfferListElementDTO): JSX.Element => (
            <StyledArtworkBiddingBox
              badges={offerBadges(offer)}
              price={offer.auction.price}
              maxPrice={offer.contextMaxPrice}
              image={getImageThumbnail(offer.auction.coverPhoto, ThumbnailAttachmentTypeEnum.Size_100x100)}
              artworkDetails={{
                author: offer.auction.manufacturer.name,
                itemName: offer.auction.name,
                itemDescriptiveAttributes: [t(offer.auction.label)],
                biddersNumber: offer.auction.offers
              }}
              endDate={offer.auction.validity?.to ?? 0}
              actions={{
                bid: offer.contextStatus === AuctionOfferStatusEnum.Outbid
                  ? (): void => showAuction(offer.auction.id)
                  : undefined,
                showAuction: [
                  AuctionOfferStatusEnum.Accepted,
                  AuctionOfferStatusEnum.Submitted,
                  AuctionOfferStatusEnum.Won,
                  AuctionOfferStatusEnum.Rejected
                ].includes(offer.contextStatus)
                  ? (): void => showAuction(offer.auction.id)
                  : undefined,
              }}
              isAuctionFinished={false}
            />
          ))
        )}
      </OffersContainer>
      <OffersContainer>
        <Header>{t('onarte.website.userBiddingsListView.endedOffers.header')}</Header>
        {(!finishedOffersListQuery.isLoading && !endedOffers.length) ? (
          <MessageBox message={t('onarte.website.userBiddingsListView.noEndedOffers')} />
        ) : (
          endedOffers.map((offer: AuctionOfferListElementDTO): JSX.Element => (
            <StyledArtworkBiddingBox
              isAuctionFinished
              badges={offerBadges(offer, true)}
              price={offer.auction.price}
              maxPrice={offer.contextMaxPrice}
              image={getImageThumbnail(offer.auction.coverPhoto, ThumbnailAttachmentTypeEnum.Size_100x100)}
              artworkDetails={{
                author: offer.auction.manufacturer.name,
                itemName: offer.auction.name,
                itemDescriptiveAttributes: [t(offer.auction.label)],
                biddersNumber: offer.auction.offers
              }}
              endDate={offer.auction.validity?.to ?? 0}
              actions={{
                completeOrderDetails: offer.cartId && offer.cartStatus !== CartStatusEnum.Closed
                  ? (): void => completeOrderDetails(offer.cartId)
                  : undefined,
                orderDetails: offer.cartId && offer.cartStatus === CartStatusEnum.Closed
                  ? (): void => orderDetails(offer.cartId)
                  : undefined,
              }}
            />
          ))
        )}
        {maxItems > itemsPerPage && (
          <StyledPagination
            onPageChange={setPage}
            maxItems={maxItems}
          />
        )}
      </OffersContainer>
    </BaseViewWithTabs>
  );
};
