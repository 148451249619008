import { ExternalUserProfileWithContextTokenInterface } from '@on-arte/core-types';
import { UseNotifications, useNotifications, useRedirect, UseRedirect } from '@on-arte/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { Params, useParams } from 'react-router-dom';

import { confirmUserRegistration } from '@onArte/frontend/api/requests';
import { useAuth, useConversions } from '@onArte/frontend/hooks';
import { UseAuth, UseConversions } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const RegistrationConfirmView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const { token }: Readonly<Params<string>> = useParams();
  const { redirect }: UseRedirect = useRedirect();
  const { signIn, isUserLoggedIn }: UseAuth = useAuth();
  const { sendCompleteRegistrationEvent }: UseConversions = useConversions();

  const alertAndNavigate: (content: string) => void = (content: string): void => {
    addToast({ content });
    redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
  };

  useEffect(
    (): void => {
      if (token) {
        confirmUserRegistration({ token })
          .then((response: ExternalUserProfileWithContextTokenInterface): void => {
            alertAndNavigate(t('onarte.website.registrationConfirmView.confirmUserRegistrationSuccess'));
            if (!isUserLoggedIn()) {
              signIn(
                {
                  id: response.id,
                  hash: response.hash,
                  firstName: response.firstName,
                  lastName: response.lastName,
                  email: response.email,
                  phone: response.phone,
                },
                response.contextToken,
                response.expiration,
                getRouteDetailsByName(RouteNameEnum.Home)?.url
              );
            }
            sendCompleteRegistrationEvent();
          })
          .catch((error: FrontendApiError): void => {
            alertAndNavigate(t(error.message));
          });
      }
    },
    []
  );

  return (
    <></>
  );
};
