import { Button, Input, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  & + & {
    margin-top: 24px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 24px;
`;
