import { 
  ListPaginationInterface, 
  AuctionInfoWithItemIdInterface,
  AuctionTypeEnum, 
  SlidersArrayInterface, 
  WebsiteSliderTypeEnum, 
  WebsiteSliderButtonPathTypeEnum, 
  SliderWithItemDataDTO,
  ReactionUsageContextTypeEnum
} from '@on-arte/core-types';
import { useLogger, UseLogger, useRedirect, UseRedirect, UseState } from '@on-arte/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getAuctions, getSliders } from '@onArte/frontend/api/requests';
import { InfoBoxes, NewsletterBoxSection } from '@onArte/frontend/components';
import { FiltersKeys, QueryKey } from '@onArte/frontend/enums';
import { useReactions, useObjectsTransformations, useConversions } from '@onArte/frontend/hooks';
import { UseReactions, UseObjectsTranformations, HighlightSectionSettingsWithItemId, UseConversions } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { emptyRequest } from '@onArte/frontend/utils';
import { ItemSaleEditStatus, RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { FullscreenSliderSection } from './components';
import { quotes } from './home.configs';
import {
  Container,
  StyledBoxesSliderSection,
  StyledCollectionSectionWithTitle,
  StyledHighlightSection,
  StyledNewsBoxesSection,
  StyledQuoteCarousel
} from './home.styled';

export const HomeView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { transformAuctionToBoxSlideType }: UseObjectsTranformations = useObjectsTransformations();
  const { logger }: UseLogger = useLogger();
  const [buyNowOffers, setBuyNowOffers]: UseState<AuctionInfoWithItemIdInterface[]> = useState<AuctionInfoWithItemIdInterface[]>([]);
  const [biddingOffers, setBiddingOffers]: UseState<AuctionInfoWithItemIdInterface[]> = useState<AuctionInfoWithItemIdInterface[]>([]);
  const [highlightSectionData, setHighlightSectionData]: UseState<HighlightSectionSettingsWithItemId | null>
    = useState<HighlightSectionSettingsWithItemId | null>(null);
  const { redirect }: UseRedirect = useRedirect();
  const { isFavourite, changeLikeState, favoritesReadyState, userFavouriteItemsIds }: UseReactions = useReactions();
  const { isFbqAvailable, sendPageViewEvent }: UseConversions = useConversions();

  useQuery(
    [QueryKey.Sliders, favoritesReadyState],
    (): Promise<SlidersArrayInterface[] | void> => favoritesReadyState ? getSliders() : emptyRequest(),
    {
      onSuccess: (sliders: SlidersArrayInterface[] | void): void => {
        if (!sliders) {
          return;
        }

        const slider: SlidersArrayInterface | undefined = sliders.find(
          (item: SlidersArrayInterface): boolean => item.type === WebsiteSliderTypeEnum.RecommendationsHome
        );

        if (!slider?.slides?.[0]?.config) {
          return;
        }

        const slideConfig: SliderWithItemDataDTO = slider.slides[0].config;
        const isSlideLinkedItemFavourite: boolean = isFavourite(slideConfig.linkedItem?.id ?? '', ReactionUsageContextTypeEnum.Item);

        setHighlightSectionData({
          itemId: slideConfig.linkedItem?.id,
          favouriteBadgeDetails: slideConfig.linkedItem
            ? {
              author: slideConfig.linkedItem?.manufacturerName ?? '',
              description: slideConfig.linkedItem.name ?? '',
              isSelected: isSlideLinkedItemFavourite,
              onClick: (): void => changeLikeState(
                slideConfig.linkedItem?.id ?? '', isSlideLinkedItemFavourite, ReactionUsageContextTypeEnum.Item
              )
            }
            : undefined,
          header: slideConfig.header,
          backgroundImage: slideConfig.image.path,
          buttonSettings: { 
            internalPath: slideConfig.buttonSettings?.path?.type === WebsiteSliderButtonPathTypeEnum.Internal
              ? slideConfig.buttonSettings.path.value
              : undefined,
            externalUrl: slideConfig.buttonSettings?.path?.type === WebsiteSliderButtonPathTypeEnum.External
              ? slideConfig.buttonSettings.path.value
              : undefined,
            label: slideConfig.buttonSettings?.label
          },
          description: slideConfig.description ?? '',
        });
      },
      onError: (error: FrontendApiError) => logger(QueryKey.Sliders, error)
    }
  );

  useEffect(
    (): void => {
      if (!highlightSectionData) {
        return;
      }

      setHighlightSectionData({
        ...highlightSectionData,
        favouriteBadgeDetails: {
          author: highlightSectionData.favouriteBadgeDetails?.author ?? '',
          description: highlightSectionData.favouriteBadgeDetails?.description ?? '',
          isSelected: !!userFavouriteItemsIds.includes(highlightSectionData.itemId ?? ''),
          onClick: (): void => changeLikeState(
            highlightSectionData.itemId ?? '', 
            !!userFavouriteItemsIds.includes(highlightSectionData.itemId ?? ''), 
            ReactionUsageContextTypeEnum.Item
          )
        }
      });
    },
    [userFavouriteItemsIds]
  );

  useQuery(
    [QueryKey.HighlightedBiddingAuctionsList],
    (): Promise<ListPaginationInterface<AuctionInfoWithItemIdInterface>> => getAuctions(
      { limit: 100, offset: 0, type: AuctionTypeEnum.Bidding, highlighted: true, sortByHighlighted: true }
    ),
    {
      onSuccess: (auctionsData: ListPaginationInterface<AuctionInfoWithItemIdInterface>): void => setBiddingOffers(auctionsData.list),
      onError: (error: FrontendApiError): void => logger(QueryKey.HighlightedBiddingAuctionsList, error)
    }
  );

  useQuery(
    [QueryKey.HighlightedBuyNowAuctionsList],
    (): Promise<ListPaginationInterface<AuctionInfoWithItemIdInterface>> => getAuctions(
      { limit: 100, offset: 0, type: AuctionTypeEnum.BuyNow, highlighted: true, sortByHighlighted: true }
    ),
    {
      onSuccess: (auctionsData: ListPaginationInterface<AuctionInfoWithItemIdInterface>): void => setBuyNowOffers(auctionsData.list),
      onError: (error: FrontendApiError): void => logger(QueryKey.HighlightedBuyNowAuctionsList, error)
    }
  );

  useEffect(
    (): void => {
      if (isFbqAvailable) {
        sendPageViewEvent();
      }
    },
    [isFbqAvailable]
  );

  return (
    <Container>
      <FullscreenSliderSection />
      {!!buyNowOffers.length && (
        <StyledBoxesSliderSection
          header={t('onarte.common.buyNow')}
          buttonLabel={t('onarte.common.showMore')}
          buttonAction={(): void => redirect(
            `${getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/'}?${FiltersKeys.Type}=${ItemSaleEditStatus.BuyNow}`
          )}
          slides={buyNowOffers.map(transformAuctionToBoxSlideType)}
          id='boxes-slider-section-buy-now'
        />
      )}
      {!!biddingOffers.length && (
        <StyledBoxesSliderSection
          header={t('onarte.common.bid')}
          buttonLabel={t('onarte.common.showMore')}
          buttonAction={(): void => redirect(
            `${getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/'}?${FiltersKeys.Type}=${ItemSaleEditStatus.Bidding}`
          )}
          slides={biddingOffers.map(transformAuctionToBoxSlideType)}
          id='boxes-slider-section-bidding'
        />
      )}
      <StyledCollectionSectionWithTitle />
      {!!highlightSectionData && <StyledHighlightSection {...highlightSectionData} />}
      <StyledQuoteCarousel quotes={quotes} />
      <StyledNewsBoxesSection />
      <NewsletterBoxSection />
      <InfoBoxes />
    </Container>
  );
};
