/* eslint-disable max-lines */
import { 
  CategoryAttributeInterface, 
  AttributeNameEnum, 
  ItemAttributeAllowedValueInterface, 
  AuctionTypeEnum, 
  ListPaginationInterface, 
  ThumbnailAttachmentTypeEnum, 
  CategoryWithAttributesInterface,
  ReactionUsageContextTypeEnum,
  AuctionInfoWithItemIdInterface
} from '@on-arte/core-types';
import { 
  FilterDetails, 
  PaginationDetails, 
  UseLogger,
  UseState, 
  getPathWithParams, 
  useLogger, 
  usePagination
} from '@on-arte/ui';
import { ParsedQuery, parse } from 'query-string';
import { RefObject, useEffect, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { getAttributes, getAuctions, getItemsCategories } from '@onArte/frontend/api/requests';
import { colorAttributeValues } from '@onArte/frontend/constants';
import { QueryKey } from '@onArte/frontend/enums';
import { useReactions } from '@onArte/frontend/hooks';
import { ArtworkMosaicItemDetailsWithItemId, FilterAttributeSettings, UseReactions } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { UrlSearchParams } from '@onArte/frontend/types';
import { emptyRequest, getImageThumbnail } from '@onArte/frontend/utils';
import { ItemSaleEditStatus, RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { rangeFilters } from './artworksList.configs';
import { UseArtworksList } from './artworksList.types';

export const useArtworksList: () => UseArtworksList = (): UseArtworksList => {
  const { 
    setMaxItems, 
    maxItems,
    itemsPerPage,
    setPage,
    offset,
    page
  }: PaginationDetails = usePagination(20);
  const { logger }: UseLogger = useLogger();
  const { t }: TransProps<never> = useTranslation();
  const { changeLikeState, isFavourite, favoritesReadyState, userFavouriteItemsIds }: UseReactions = useReactions();
  const [searchParams, setSearchParams]: UrlSearchParams = useSearchParams();
  const [submittedFilters, setSubmittedFilters]: UseState<Record<string, number | string | string[]> | null>
    = useState<Record<string, number | string | string[]> | null>(null);
  const [attributes, setAttributes]: UseState<Record<string, CategoryAttributeInterface> | null> 
    = useState<Record<string, CategoryAttributeInterface> | null>(null);
  const [defaultFilters, setDefaultFilters]: UseState<Record<string, string[]> | undefined>
    = useState<Record<string, string[]> | undefined>(undefined);
  const [filters, setFilters]: UseState<FilterDetails[]> = useState<FilterDetails[]>([]);
  const [mosaicItems, setMosaicItems]: UseState<ArtworkMosaicItemDetailsWithItemId[] | null> 
    = useState<ArtworkMosaicItemDetailsWithItemId[] | null>(null);
  const [initialPageNumber, setInitialPageNumber]: UseState<number | null> = useState<number | null>(null);
  const [lowestLoadedPage, setLowestLoadedPage]: UseState<number | null> = useState<number | null>(null);
  const [highestLoadedPage, setHighestLoadedPage]: UseState<number | null> = useState<number | null>(null);
  const mosaicItemRef: RefObject<HTMLDivElement> = useRef(null);

  const { refetch }: QueryObserverResult = useQuery(
    [QueryKey.AuctionsList, offset, submittedFilters, favoritesReadyState, initialPageNumber],
    (): Promise<ListPaginationInterface<AuctionInfoWithItemIdInterface> | void> => favoritesReadyState 
      ? getAuctions({
        ...submittedFilters,
        offset,
        limit: itemsPerPage,
      }) 
      : emptyRequest(),
    {
      onSuccess: (data: ListPaginationInterface<AuctionInfoWithItemIdInterface>): void => {
        setMaxItems(data.amount);
        const mosaicItemsList: ArtworkMosaicItemDetailsWithItemId[] = data.list
          .map((item: AuctionInfoWithItemIdInterface): ArtworkMosaicItemDetailsWithItemId => {
            const isItemLiked: boolean = isFavourite(item.itemId, ReactionUsageContextTypeEnum.Item);

            return {
              itemId: item.itemId,
              image: getImageThumbnail(item.coverPhoto, ThumbnailAttachmentTypeEnum.Size_340xAuto),
              internalPath: item.route?.prettyUrl
                ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/', { id: item.id }),
              boxTitleDetails: {
                itemName: item.name,
                author: item.manufacturer.name,
                price: item.type === AuctionTypeEnum.PriceProposal ? undefined : item.price,
                biddersNumber: item.type === AuctionTypeEnum.Bidding ? item.offers : undefined,
                bottomButtonLabel: item.type === AuctionTypeEnum.PriceProposal ? t('onarte.common.priceProposal') : undefined,
                itemDescriptiveAttributes: [t(item.label)],
              },
              isLiked: isItemLiked,
              onLikeClick: (): void => changeLikeState(item.itemId, isItemLiked, ReactionUsageContextTypeEnum.Item),
              action: (): void => {
                searchParams.set('id', item.itemId);
                setSearchParams(searchParams);
              }
            };
          });

        setMosaicItems([
          ...(initialPageNumber && page < initialPageNumber ? mosaicItemsList : []),
          ...(mosaicItems ?? []),
          ...(initialPageNumber && page >= initialPageNumber ? mosaicItemsList : [])
        ]);
        searchParams.set('page', String(page));
        setSearchParams(searchParams);
      },
      onError: (error: FrontendApiError): void => logger('getAuctionsAction', error)
    }
  );

  useEffect(
    (): void => {
      if (!mosaicItems) {
        return;
      }

      setMosaicItems(mosaicItems.map((item: ArtworkMosaicItemDetailsWithItemId): ArtworkMosaicItemDetailsWithItemId => {
        const isItemLiked: boolean = !!userFavouriteItemsIds.find((value: string) => value === item.itemId);
        return { 
          ...item, 
          isLiked: isItemLiked,
          onLikeClick: (): void => changeLikeState(item.itemId, isItemLiked, ReactionUsageContextTypeEnum.Item)
        };
      }));
    },
    [userFavouriteItemsIds]
  );

  useQuery(
    [QueryKey.ItemAttributes],
    (): Promise<Record<string, CategoryAttributeInterface>> => getAttributes(),
    {
      onSuccess: (data: Record<string, CategoryAttributeInterface>) => setAttributes(data),
      onError: (error: FrontendApiError) => logger(QueryKey.ItemAttributes, error)
    }
  );

  useQuery(
    [QueryKey.ItemCategories, attributes],
    (): Promise<CategoryWithAttributesInterface[]> => getItemsCategories(),
    {
      onSuccess: (data: CategoryWithAttributesInterface[]): void => {
        if (!Object.keys(attributes ?? {}).length) {
          return;
        }

        setFilters([
          {
            name: 'categoryIds',
            label: t('onarte.common.itemsAttributes.category.label'),
            options: data.map((category: CategoryWithAttributesInterface) => ({
              name: String(category.id),
              label: t(`onarte.common.itemsCategories.${category.name}`)
            }))
          },
          {
            name: 'subjectValues',
            label: t(`onarte.common.itemsAttributes.${AttributeNameEnum.Subject}.label`),
            options: getAttributesFiltersOptions(AttributeNameEnum.Subject)
          },
          {
            name: 'manufacturerIds',
            label: t('onarte.common.itemsAttributes.author.label'),
            autosuggestLabel: 'Wprowadź nazwę artysty',
          },
          {
            name: 'price',
            label: t('onarte.common.priceFilters.label'),
            options: [
              {
                name: '1-2500',
                label: t('onarte.common.priceFilters.lowestPrice.label')
              },
              {
                name: '2500-5000',
                label: t('onarte.common.priceFilters.lowPrice.label'),
              },
              {
                name: '5000-10000',
                label: t('onarte.common.priceFilters.mediumPrice.label'),
              },
              {
                name: 'over-10000',
                label: t('onarte.common.priceFilters.highPrice.label'),
              },
            ],
            isSingleChoice: true
          },
          {
            name: 'type',
            label: t('onarte.common.itemType.label'),
            options: [
              {
                name: ItemSaleEditStatus.Bidding,
                label: t('onarte.common.itemType.bidding.label'),
              },
              {
                name: ItemSaleEditStatus.BuyNow,
                label: t('onarte.common.itemType.buyNow.label'),
              },
              {
                name: ItemSaleEditStatus.PriceProposal,
                label: t('onarte.common.itemType.priceProposal.label'),
              }
            ],
            isSingleChoice: true
          },
          {
            name: 'materialValues',
            label: t(`onarte.common.itemsAttributes.${AttributeNameEnum.Material}.label`),
            options: getAttributesFiltersOptions(AttributeNameEnum.Material)
          },
          {
            name: 'colorValues',
            label: t(`onarte.common.itemsAttributes.${AttributeNameEnum.Color}.label`),
            options: getAttributesFiltersOptions(AttributeNameEnum.Color)
          },
          {
            name: 'size',
            label: t('onarte.common.size.label'),
            options: [
              {
                name: 'small',
                label: t('onarte.common.size.small.label'),
              },
              {
                name: 'medium',
                label: t('onarte.common.size.medium.label'),
              },
              {
                name: 'large',
                label: t('onarte.common.size.large.label'),
              },
            ],
            isSingleChoice: true
          },
          {
            name: 'techniqueValues',
            label: t(`onarte.common.itemsAttributes.${AttributeNameEnum.Technique}.label`),
            options: getAttributesFiltersOptions(AttributeNameEnum.Technique)
          },
          {
            name: 'styleValues',
            label: t(`onarte.common.itemsAttributes.${AttributeNameEnum.Style}.label`),
            options: getAttributesFiltersOptions(AttributeNameEnum.Style)
          },
        ]);
      },
      onError: (error: FrontendApiError) => logger(QueryKey.ItemCategories, error)
    } 
  );

  useEffect(
    (): void => {
      const parsedParams: ParsedQuery<string> = parse(searchParams.toString(), { arrayFormat: 'separator', arrayFormatSeparator: ',' });

      if (searchParams.get('page')) {
        setPage(Number(searchParams.get('page')));
        if (!initialPageNumber) {
          setInitialPageNumber(Number(searchParams.get('page')));
        }
        if (!lowestLoadedPage) {
          setLowestLoadedPage(Number(searchParams.get('page')));
        }
        if (!highestLoadedPage) {
          setHighestLoadedPage(Number(searchParams.get('page')));
        }
      }
      
      if (parsedParams && !defaultFilters) {
        setDefaultFilters(paramsToObject(parsedParams));
        onFilterHandler(paramsToObject(parsedParams));
      }
    },
    [searchParams.toString()]
  );

  useEffect(
    (): void => {
      if (mosaicItems?.length) {
        searchParams.delete('page');
        setSearchParams(searchParams);
        changePageAction(1);
        setMosaicItems(null);
      }

      void refetch();
    }, 
    [submittedFilters]
  );
    
  const paramsToObject: (entries: ParsedQuery<string>) => Record<string, string[]> = (
    entries: ParsedQuery<string>
  ): Record<string, string[]> => {
    let paramsObject: Record<string, string[]> = {};
    for (const [key, value] of Object.entries(entries)) {
      if (!['id', 'page'].includes(key)) {
        paramsObject = { 
          ...paramsObject,
          ...(typeof value === 'string' ? { [key]: [value] } : {}),
          ...(
            !!value?.length && !!Array.isArray(value)
              ? { [key]: [...value.map((val: string | null): string => val ?? '')] } :
              {}
          )
        };
      }
    }
    return paramsObject;
  };

  const onFilterHandler: (filtersValues: Record<string, string[]>) => void = (filtersValues: Record<string, string[]>): void => {
    let filtersValuesObject: Record<string, number | string | string[]> = {};
    let filtersSearchParams: Record<string, number | string | string[]> = {};

    Object.keys(filtersValues).forEach((filterKey: string): void => {
      if (filterKey === 'price') {
        if (rangeFilters.price[filtersValues[filterKey][0]].from) {
          filtersValuesObject['minPrice'] = rangeFilters.price[filtersValues[filterKey][0]].from ?? 0;
          filtersSearchParams['minPrice'] = rangeFilters.price[filtersValues[filterKey][0]].from ?? 0;
        }
        if (rangeFilters.price[filtersValues[filterKey][0]].to) {
          filtersValuesObject['maxPrice'] = rangeFilters.price[filtersValues[filterKey][0]].to ?? 0;
          filtersSearchParams['maxPrice'] = rangeFilters.price[filtersValues[filterKey][0]].to ?? 0;
        }
      } else if (filterKey === 'size') {
        if (rangeFilters.size[filtersValues[filterKey][0]].from) {
          filtersValuesObject['minHeight'] = rangeFilters.size[filtersValues[filterKey][0]].from ?? 0;
          filtersSearchParams['minHeight'] = rangeFilters.size[filtersValues[filterKey][0]].from ?? 0;
        }
        if (rangeFilters.size[filtersValues[filterKey][0]].to) {
          filtersValuesObject['maxHeight'] = rangeFilters.size[filtersValues[filterKey][0]].to ?? 0;
          filtersSearchParams['maxHeight'] = rangeFilters.size[filtersValues[filterKey][0]].to ?? 0;
        }
        if (rangeFilters.size[filtersValues[filterKey][0]].from) {
          filtersValuesObject['minWidth'] = rangeFilters.size[filtersValues[filterKey][0]].from ?? 0;
          filtersSearchParams['minWidth'] = rangeFilters.size[filtersValues[filterKey][0]].from ?? 0;
        }
        if (rangeFilters.size[filtersValues[filterKey][0]].to) {
          filtersValuesObject['maxWidth'] = rangeFilters.size[filtersValues[filterKey][0]].to ?? 0;
          filtersSearchParams['maxWidth'] = rangeFilters.size[filtersValues[filterKey][0]].to ?? 0;
        }
      } else {
        filtersValuesObject = { ...filtersValuesObject, [filterKey]: filtersValues[filterKey] };
        filtersSearchParams = { ...filtersValuesObject, [filterKey]: filtersValues[filterKey] };
      }
    });

    setSubmittedFilters(filtersValuesObject);
    
    if (!Object.entries(filtersSearchParams).length && !searchParams.get('id')) {
      setSearchParams({});
    } else {
      Object.entries(filtersSearchParams)
        .forEach((filter: [string, string | number | string[]]): void => {
          searchParams.set(filter[0], String(filter[1]));
        });
      setSearchParams(searchParams);
    }
  };

  const getAttributesFiltersOptions: (attributeName: AttributeNameEnum) => FilterAttributeSettings[] = (
    attributeName: AttributeNameEnum
  ): FilterAttributeSettings[] => {
    return attributes
      ? attributes[attributeName].values.map((attributeValueObject: ItemAttributeAllowedValueInterface): FilterAttributeSettings => ({
        name: attributeValueObject.value,
        label: t(`onarte.common.itemsAttributes.${attributeName}.options.${attributeValueObject.value}.label`),
        ...(attributeName === AttributeNameEnum.Color ? { color: colorAttributeValues[attributeValueObject.value] } : {})
      }))
      : [];
  };

  const scrollToPreviousAction: () => void = (): void => {
    if (!!submittedFilters && !!searchParams.get('id') && mosaicItems) {
      setMosaicItems(mosaicItems.map((item: ArtworkMosaicItemDetailsWithItemId): ArtworkMosaicItemDetailsWithItemId => {
        return { 
          ...item, 
          ref: searchParams.get('id') === item.itemId ? mosaicItemRef : undefined
        };
      }));
      mosaicItemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  };

  const changePageAction: (newPage: number) => void = (newPage: number): void => {
    setPage(newPage);
    if (initialPageNumber && newPage >= initialPageNumber) {
      setHighestLoadedPage(newPage);
    } else {
      setLowestLoadedPage(newPage);
    }
  };

  return {
    onFilterHandler,
    scrollToPreviousAction,
    mosaicItems,
    filters,
    defaultFilters,
    maxItems,
    changePageAction,
    page,
    itemsPerPage,
    initialPageNumber,
    lowestLoadedPage,
    highestLoadedPage,
  };
};
