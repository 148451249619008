import {
  SlidersArrayInterface,
  WebsiteSliderTypeEnum,
  WebsiteSliderButtonPathTypeEnum,
  SliderWithItemDataDTO,
  ReactionUsageContextTypeEnum
} from '@on-arte/core-types';
import { 
  BadgeSize, 
  BadgeTheme, 
  Color, 
  HighlightSection, 
  highlightTextFragments, 
  IconName, 
  UseLogger, 
  useLogger, 
  UseState 
} from '@on-arte/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getSliders } from '@onArte/frontend/api/requests';
import { QueryKey } from '@onArte/frontend/enums';
import { useReactions } from '@onArte/frontend/hooks';
import { UseReactions, HighlightSectionSettingsWithItemId } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { emptyRequest } from '@onArte/frontend/utils';

import { contactConfig } from './contact.configs';
import {
  Address,
  Container,
  InnerContainer,
  Section,
  StyledCircleBadges,
  StyledPageTitle,
  Title
} from './contact.styled';

export const ContactView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const { favoritesReadyState, isFavourite, changeLikeState, userFavouriteItemsIds }: UseReactions = useReactions();
  const [highlightSectionData, setHighlightSectionData]: UseState<HighlightSectionSettingsWithItemId | null>
    = useState<HighlightSectionSettingsWithItemId | null>(null);

  useQuery(
    [QueryKey.Sliders, favoritesReadyState],
    (): Promise<SlidersArrayInterface[] | void> => favoritesReadyState ? getSliders() : emptyRequest(),
    {
      onSuccess: (sliders: SlidersArrayInterface[]): void => {
        const slider: SlidersArrayInterface | undefined = sliders.find(
          (item: SlidersArrayInterface): boolean => item.type === WebsiteSliderTypeEnum.RecommendationsContact
        );
        if (!slider?.slides?.[0]?.config) {
          return;
        }
        const slideConfig: SliderWithItemDataDTO = slider.slides[0].config;
        const isSlideLinkedItemFavourite: boolean = isFavourite(slideConfig.linkedItem?.id ?? '', ReactionUsageContextTypeEnum.Item);

        setHighlightSectionData({
          itemId: slideConfig.linkedItem?.id,
          favouriteBadgeDetails: slideConfig.linkedItem 
            ? {
              author: slideConfig.linkedItem?.manufacturerName,
              description: slideConfig.linkedItem.name ?? '',
              isSelected: isSlideLinkedItemFavourite,
              onClick: (): void => changeLikeState(
                slideConfig.linkedItem?.id ?? '', isSlideLinkedItemFavourite, ReactionUsageContextTypeEnum.Item
              )
            }
            : undefined,
          header: slideConfig.header,
          backgroundImage: slideConfig.image.path,
          buttonSettings: { 
            internalPath: slideConfig.buttonSettings?.path?.type === WebsiteSliderButtonPathTypeEnum.Internal
              ? slideConfig.buttonSettings.path.value
              : undefined,
            externalUrl: slideConfig.buttonSettings?.path?.type === WebsiteSliderButtonPathTypeEnum.External
              ? slideConfig.buttonSettings.path.value
              : undefined,
            label: slideConfig.buttonSettings?.label
          },
          description: slideConfig.description ?? '',
        });
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.Sliders, error)
    }
  );

  useEffect(
    (): void => {
      if (!highlightSectionData) {
        return;
      }

      setHighlightSectionData({
        ...highlightSectionData,
        favouriteBadgeDetails: {
          author: highlightSectionData.favouriteBadgeDetails?.author ?? '',
          description: highlightSectionData.favouriteBadgeDetails?.description ?? '',
          isSelected: !!userFavouriteItemsIds.includes(highlightSectionData.itemId ?? ''),
          onClick: (): void => changeLikeState(
            highlightSectionData.itemId ?? '', 
            !!userFavouriteItemsIds.includes(highlightSectionData.itemId ?? ''), 
            ReactionUsageContextTypeEnum.Item
          )
        }
      });
    },
    [userFavouriteItemsIds]
  );

  return (
    <Container>
      <StyledPageTitle 
        pageTitleSettings={{
          title: t('onarte.website.meta.contact.title'),
          withUnderline: false,
        }} 
      />
      <InnerContainer>
        <Section>
          <Address
            text={highlightTextFragments(
              `${contactConfig.companyName}
              ${contactConfig.address}`
            )}
          />
        </Section>
        <Section $withLeftPadding>
          <Title text={highlightTextFragments(t('onarte.website.contactView.problemReport.title'))} />
          <StyledCircleBadges
            badges={[
              { label: contactConfig.supportEmail, icon: IconName.CollectorFilled, color: Color.Magenta },
              { label: `${t('onarte.common.phoneNumberPrefix')}: ${contactConfig.phone}`, icon: IconName.Colon, color: Color.Blue }
            ]}
            size={BadgeSize.Medium}
            badgesTheme={BadgeTheme.Transparent}
          />
        </Section>
      </InnerContainer>
      <InnerContainer>
        <Section $withLeftPadding>
          <Title text={highlightTextFragments(t('onarte.website.contactView.startCollaboration.title'))} />
          <StyledCircleBadges
            badges={[{ label: contactConfig.collaborationEmail, icon: IconName.Dollar }]}
            size={BadgeSize.Medium}
            badgesTheme={BadgeTheme.Transparent}
          />
        </Section>
      </InnerContainer>
      {!!highlightSectionData && <HighlightSection {...highlightSectionData} />}
    </Container>
  );
};
