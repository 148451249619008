import { Breakpoint, Button, Color, FontWeight, StyledComponent, ZIndex } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.Gray200};
`;

export const ErrorContainer: StyledComponent<'div'> = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ErrorHeading: StyledComponent<'h2'> = styled.h2`
  max-width: 478px;
  font-size: 40px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.Black};
  line-height: 44px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-bottom: 32px;
  z-index: ${ZIndex.Min};

  @media ${Breakpoint.SmallMobile} {
    font-size: 30px;
    line-height: 38px;
    max-width: 340px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  z-index: ${ZIndex.Min};
`;

export const BackgroundShape: StyledComponent<'img'> = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 565px;
  z-index: ${ZIndex.Min};

  @media ${Breakpoint.SmallMobile} {
    height: 332px;
  }
`;
