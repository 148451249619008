import {
  CartAuctionInterface,
  CartCostInterface,
  CartCostTypeEnum,
  CartInterface,
  ThumbnailAttachmentTypeEnum,
} from '@on-arte/core-types';
import {
  BasketItem,
  useLogger,
  UseLogger,
  UseNotifications,
  useNotifications,
  useRedirect,
  UseRedirect,
  UseState
} from '@on-arte/ui';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';

import { addCartItem, deleteCartItem, deleteDiscountCode, getCart, updateDiscountCode } from '@onArte/frontend/api/requests';
import { BasketSidePanelContext } from '@onArte/frontend/contexts';
import { FrontendResponseCode, QueryKey } from '@onArte/frontend/enums';
import { CartPrices, UseAuth, UseBasket, UseConversions, UseSocket } from '@onArte/frontend/interfaces/hooks';
import { BasketSidePanelContextState } from '@onArte/frontend/interfaces/internal';
import { FrontendApiError } from '@onArte/frontend/models';
import { emptyRequest, getImageThumbnail } from '@onArte/frontend/utils';
import { RouteNameEnum, WebSocketCommand } from '@onArte/shared/enums';
import { WebSocketSubscriptionDetails } from '@onArte/shared/models';
import { getCostFinalPriceByType, getRouteDetailsByName } from '@onArte/shared/utils';

import { useAuth } from './useAuth.hook';
import { useConversions } from './useConversions.hook';
import { useSocket } from './useSocket.hook';

export const useBasket: (cartId?: string) => UseBasket = (cartId: string = ''): UseBasket => {
  const [cartData, setCartData]: UseState<CartInterface | null> = useState<CartInterface | null>(null);
  const [validationMessage, setValidationMessage]: UseState<string> = useState<string>('');
  const [basketItems, setBasketItems]: UseState<BasketItem[]> = useState<BasketItem[]>([]);
  const { logger }: UseLogger = useLogger();
  const { addToast }: UseNotifications = useNotifications();
  const { t }: TransProps<never> = useTranslation();
  const { isUserLoggedIn }: UseAuth = useAuth();
  const basketSideContext: BasketSidePanelContextState = useContext(BasketSidePanelContext);
  const { redirect }: UseRedirect = useRedirect();
  const { socket }: UseSocket = useSocket();
  const { sendAddToCartEvent }: UseConversions = useConversions();

  const { refetch }: QueryObserverResult = useQuery(
    [QueryKey.Cart],
    (): Promise<CartInterface | void> => isUserLoggedIn()
      ? getCart(cartId)
      : emptyRequest(),
    {
      onSuccess: (dataCart: CartInterface | undefined): void => {
        if (isUserLoggedIn() && dataCart) {
          setCartData(dataCart);
        }
      },
      onError: (error: FrontendApiError): void => {
        logger(QueryKey.Cart, error);
        if (error.responseCode === FrontendResponseCode.NotFound) {
          redirect(getRouteDetailsByName(RouteNameEnum.NotFound)?.url ?? '/');
          return;
        } else {
          addToast({ content: t(error.message) });
        }
      }
    }
  );

  const onSubmitDiscountCode: (value: string) => void = (value: string): void => {
    if (value && cartData) {
      updateDiscountCode(cartData.id, value)
        .then((): void => {
          setValidationMessage('');
          void refetch();
        })
        .catch((): void => setValidationMessage(t('onarte.website.basketColumn.onDiscountApplyAction.emptyValue')));
    }
  };

  const onDeleteDiscountCode: (value: string) => void = (value: string): void => {
    if (!cartData) {
      return;
    }

    deleteDiscountCode(cartData.id, value)
      .then((): void => void refetch())
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  const onDeleteCartItem: (id: string) => void = (id: string): void => {
    if (!cartData) {
      return;
    }

    deleteCartItem(cartData.id, id)
      .then((): void => {
        if (socket) {
          socket.emit(WebSocketCommand.UnsubscribeAuction, { id } as WebSocketSubscriptionDetails);
        }
        void refetch();
      })
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  const onAddToCartItem: (auctionId: string, itemName: string, itemPrice: number | null) => void = (
    auctionId: string, itemName: string, itemPrice: number | null
  ): void => {
    if (isUserLoggedIn()) {
      if (!cartData) {
        return;
      }
      
      addCartItem(cartData.id, auctionId)
        .then((): void => {
          sendAddToCartEvent(auctionId, cartData.id, itemName, itemPrice);
          if (socket) {
            socket.emit(WebSocketCommand.SubscribeAuction, { id: auctionId } as WebSocketSubscriptionDetails);
          }
          void refetch()
            .then((): void => basketSideContext.setIsBasketSidePanelOpen(true));
        })
        .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
    } else {
      redirect(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/', { withReplaceState: true });
    }
  };

  const cartPrices: CartPrices = useMemo((): CartPrices => {
    let deliveryPrice: number = 0;
    let auctionsBasePrice: number = 0;

    cartData?.auctions.forEach((auction: CartAuctionInterface): void => {
      deliveryPrice += getCostFinalPriceByType(auction.costs, CartCostTypeEnum.Delivery);
      auctionsBasePrice += getCostFinalPriceByType(auction.costs, CartCostTypeEnum.Item);
    });

    return {
      deliveryPrice,
      auctionsBasePrice
    };
  }, [cartData, onAddToCartItem, onDeleteCartItem]);

  useEffect((): void => {
    setBasketItems(cartData?.auctions.map((auction: CartAuctionInterface): BasketItem => ({
      id: auction.auction.auctionId,
      artworkDetails: {
        boxTitleDetails: {
          author: auction.auction.manufacturer.name,
          itemName: auction.auction.name,
          itemDescriptiveAttributes: [t(auction.auction.label)],
          price: auction.costs.find((cost: CartCostInterface): boolean => cost.type === CartCostTypeEnum.Item)?.finalPrice
        },
        image: getImageThumbnail(auction.auction.coverPhoto, ThumbnailAttachmentTypeEnum.Size_340xAuto),
      }
    })) ?? []);
  }, [cartData]);

  useEffect((): void => {
    if (isUserLoggedIn()) {
      if (!basketSideContext.isBasketSidePanelOpen) {
        setValidationMessage('');
      } else {
        void refetch();
      }
    }
  }, [basketSideContext.isBasketSidePanelOpen]);

  return {
    cartData,
    onSubmitDiscountCode,
    onDeleteCartItem,
    onDeleteDiscountCode,
    onAddToCartItem,
    cartPrices,
    basketItems,
    validationMessage,
    setValidationMessage
  };
};
