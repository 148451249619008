import {
  AccountSubmenu,
  Breakpoint,
  Button,
  FormField,
  FormSection,
  InformationBox,
  MessageBox,
  StyledComponent
} from '@on-arte/ui';
import styled from 'styled-components';

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
  }
`;

export const StyledAccountSubmenu: StyledComponent<typeof AccountSubmenu> = styled(AccountSubmenu)`
  flex-shrink: 0;
  width: 100%;
  max-width: 350px;

  @media ${Breakpoint.Tablet} {
    max-width: none;
  }
`;

export const ContentContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  margin-left: 40px;

  @media ${Breakpoint.Tablet} {
    margin-left: 0;
    margin-top: 60px;
  }
`;

export const StyledFormField: StyledComponent<typeof FormField> = styled(FormField)`
  & + & {
    margin-top: 24px;
  }
`;

export const StyledFormSection: StyledComponent<typeof FormSection> = styled(FormSection)`
  margin: 0;

  & + & {
    margin-top: 60px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 40px;
`;

export const StyledMessageBox: StyledComponent<typeof MessageBox> = styled(MessageBox)`
  margin-bottom: 40px;
  width: 100%;
`;

export const TopInformationBox: StyledComponent<typeof InformationBox> = styled(InformationBox)`
  margin-bottom: 40px;
`;

export const BottomInformationBox: StyledComponent<typeof InformationBox> = styled(InformationBox)`
  margin-top: 60px;
`;
