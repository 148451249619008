import { Breakpoint, Color, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { PageTitle } from '@onArte/frontend/theme';

import { ProgressBarProps } from './artworksList.types';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Color.Gray200};
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin-bottom: 0;
  padding-bottom: 40px;
`;

export const ItemsMosaicContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: auto;
  padding: 40px 20px;
  background-color: ${Color.White};

  @media ${Breakpoint.Tablet} {
    padding: 40px 16px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 40px 10px;
  }
`;

export const ButtonContainer: StyledComponent<'div'> = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  & + & {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid ${Color.Gray200};
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const MosaicWrapper: StyledComponent<'div'> = styled.div`
  margin-top: 40px;
  overflow: hidden;
`;

export const NoAuctionMessage: StyledComponent<'div'> = styled.div`
  padding: 40px 20px 0;
  
  @media ${Breakpoint.Mobile} {
    text-align: center;
  }
`;

export const ProgressBarContainer: StyledComponent<'div'> = styled.div`
  width: 230px;
  height: 2px;
  background-color: ${Color.Gray200};
  margin: 24px 0;
`;

export const ProgressBar: StyledComponent<'div', ProgressBarProps> = styled.div<ProgressBarProps>`
  width: ${({ $width }: ProgressBarProps): number => $width}%;
  height: 100%;
  background-color: ${Color.Black};
`;

export const ArtworksAmountText: StyledComponent<'div'> = styled.div`
  color: ${Color.Gray500};
  font-size: 12px;
  line-height: 16px;
`;
