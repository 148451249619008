import { AuctionOfferStatusEnum } from '@on-arte/core-types';
import { UseLogger, useLogger, UseNotifications, useNotifications, useRedirect, UseRedirect } from '@on-arte/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { Params, useParams } from 'react-router-dom';

import { setOfferStatus } from '@onArte/frontend/api/requests';
import { BaseErrorView } from '@onArte/frontend/components';
import { FrontendApiError } from '@onArte/frontend/models';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const PriceProposalRejectedView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { id }: Readonly<Params<string>> = useParams();
  const { logger }: UseLogger = useLogger();
  const { addToast }: UseNotifications = useNotifications();

  useEffect(
    (): void => {
      if (!id) {
        return;
      }

      setOfferStatus(id, AuctionOfferStatusEnum.Rejected)
        .then((): void => addToast({ content: t('onarte.website.priceProposalRejectedView.setOfferStatus.success') }))
        .catch((error: FrontendApiError): void => {
          addToast({ content: error.message });
          logger('setOfferStatus', error);
          redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
        });
    },
    [id]
  );

  return (
    <BaseErrorView 
      title={t('onarte.website.priceProposalRejectedView.title')}
      buttonLabel={t('onarte.website.priceProposalRejectedView.buttonLabel')}
      buttonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/')}
    />
  );
};
