import { GlobalStyle, NotificationsProvider, UseState } from '@on-arte/ui';
import React, { useEffect, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import io, { Socket } from 'socket.io-client';

import { appConfig } from '@onArte/frontend/app.config';
import { AppWrapper, CheckAuth, Loader } from '@onArte/frontend/components';
import { SocketContext, TokenContext } from '@onArte/frontend/contexts';
import {
  AboutUsView,
  SetPasswordView,
  HomeView,
  NotFoundView,
  RegistrationView,
  RemindPasswordView,
  SignInView,
  RegistrationConfirmView,
  ArtistDetailsView,
  ArtistsListView,
  ArtworkDetailsView,
  ArtworksListView,
  UserAccountSettingsView,
  UserSalesListView,
  UserArtworkAddView,
  UserArtworkDetailsView,
  UserArtworkSalesSettingsView,
  LogoutView,
  EventDetailsView,
  UserBiddingsListView,
  UserDeliverySettingsView,
  UserFavoriteArtworksView,
  UserMyShoppingView,
  UserPublicProfileSettingsView,
  CollectionsListView,
  CartBillingView,
  CartDeliveryView,
  HandbookBuyerView,
  HandbookSellerView,
  EventsListView,
  ContactView,
  CartSuccessView,
  FaqView,
  PrivacyPolicyView,
  CartPaymentErrorView,
  CartMissingItemView,
  UserDataForPayoutsView,
  UserSoldArtworksView,
  RegulationsView,
  UserArtworkEditView,
  CollectionView,
  CollectorDetailsView,
  CollectorsListView,
  PriceProposalAcceptedView,
  PriceProposalRejectedView,
  SearchView,
  ArtistsView
} from '@onArte/frontend/views';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

const App: React.FC = (): JSX.Element => {
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(true);
  const [socket, setSocket]: UseState<Socket | undefined> = useState<Socket | undefined>();
  const [gtmIdInitialized, setGtmIdInitialized]: UseState<boolean> = useState<boolean>(false);
  const queryClient: QueryClient = useMemo(
    (): QueryClient => new QueryClient({
      defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
    }),
    []
  );

  useEffect(
    (): (() => void) => {
      const socketIo: Socket = io(appConfig.websocket, { path: '/ws' });
      socketIo.on('connect', (): void => setSocket(socketIo));
      socketIo.on('disconnect', (): void => setSocket(undefined));

      return (): void => {
        socketIo.off('connect');
        socketIo.off('disconnect');
        socketIo.close();
      };
    },
    []
  );

  useEffect(
    (): void => {
      if (appConfig.gtmId && !gtmIdInitialized) {
        TagManager.initialize({ gtmId: appConfig.gtmId });
        setGtmIdInitialized(true);
      }
    },
    []
  );

  useEffect(
    (): void => {
      // TODO: change setTimeout to event listener
      setTimeout((): void => setIsLoading(false), 1500);
    },
    []
  );

  return (
    <SocketContext.Provider value={socket}>
      <Loader isVisible={isLoading} />
      <TokenContext.Consumer>
        {(tokenIncjectedBySSR: string): JSX.Element => (
          <QueryClientProvider client={queryClient}>
            <NotificationsProvider>
              <AppWrapper token={tokenIncjectedBySSR}>
                <GlobalStyle />
                <Routes>
                  <Route path={getRouteDetailsByName(RouteNameEnum.AboutUs)?.url} element={
                    <CheckAuth><AboutUsView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.ArtistDetails)?.url} element={
                    <CheckAuth><ArtistDetailsView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.Artists)?.url} element={
                    <CheckAuth><ArtistsView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.ArtistsList)?.url} element={
                    <CheckAuth><ArtistsListView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url} element={
                    <CheckAuth><ArtworkDetailsView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url} element={
                    <CheckAuth><ArtworksListView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.CartBilling)?.url} element={
                    <CheckAuth><CartBillingView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.CartDelivery)?.url} element={
                    <CheckAuth><CartDeliveryView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.CartMissingItem)?.url} element={
                    <CheckAuth><CartMissingItemView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.CartPaymentError)?.url} element={
                    <CheckAuth><CartPaymentErrorView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.CartSuccess)?.url} element={
                    <CheckAuth><CartSuccessView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.ChoosePassword)?.url} element={
                    <CheckAuth><SetPasswordView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.Collection)?.url} element={
                    <CheckAuth><CollectionView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.CollectionsList)?.url} element={
                    <CheckAuth><CollectionsListView /></CheckAuth>
                  } />
                  {!appConfig.hiddenModules.collectors && (
                    <Route path={getRouteDetailsByName(RouteNameEnum.CollectorDetails)?.url} element={
                      <CheckAuth><CollectorDetailsView /></CheckAuth>
                    } />
                  )}
                  {!appConfig.hiddenModules.collectors && (
                    <Route path={getRouteDetailsByName(RouteNameEnum.CollectorsList)?.url} element={
                      <CheckAuth><CollectorsListView /></CheckAuth>
                    } />
                  )}
                  <Route path={getRouteDetailsByName(RouteNameEnum.Contact)?.url} element={
                    <CheckAuth><ContactView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.EventDetails)?.url} element={
                    <CheckAuth><EventDetailsView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.EventsList)?.url} element={
                    <CheckAuth><EventsListView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.HandbookBuyer)?.url} element={
                    <CheckAuth><HandbookBuyerView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.HandbookSeller)?.url} element={
                    <CheckAuth><HandbookSellerView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.Faq)?.url} element={
                    <CheckAuth><FaqView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.Home)?.url} element={
                    <CheckAuth><HomeView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.Logout)?.url} element={
                    <CheckAuth><LogoutView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.PriceProposalAccepted)?.url} element={
                    <CheckAuth><PriceProposalAcceptedView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.PriceProposalRejected)?.url} element={
                    <CheckAuth><PriceProposalRejectedView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.PrivacyPolicy)?.url} element={
                    <CheckAuth><PrivacyPolicyView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.Registration)?.url} element={
                    <CheckAuth><RegistrationView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.RegistrationConfirm)?.url} element={
                    <CheckAuth><RegistrationConfirmView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.Regulations)?.url} element={
                    <CheckAuth><RegulationsView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.RemindPassword)?.url} element={
                    <CheckAuth><RemindPasswordView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserAccountSettings)?.url} element={
                    <CheckAuth><UserAccountSettingsView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserArtworkAdd)?.url} element={
                    <CheckAuth><UserArtworkAddView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserArtworkDetails)?.url} element={
                    <CheckAuth><UserArtworkDetailsView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserArtworkEdit)?.url} element={
                    <CheckAuth><UserArtworkEditView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserArtworkSalesSettings)?.url} element={
                    <CheckAuth><UserArtworkSalesSettingsView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserBiddingsList)?.url} element={
                    <CheckAuth><UserBiddingsListView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserDataForPayouts)?.url} element={
                    <CheckAuth><UserDataForPayoutsView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserDeliverySettings)?.url} element={
                    <CheckAuth><UserDeliverySettingsView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserFavoriteArtworks)?.url} element={
                    <CheckAuth><UserFavoriteArtworksView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserMyShopping)?.url} element={
                    <CheckAuth><UserMyShoppingView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserPublicProfileSettings)?.url} element={
                    <CheckAuth><UserPublicProfileSettingsView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url} element={
                    <CheckAuth><UserSalesListView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.UserSoldArtworks)?.url} element={
                    <CheckAuth><UserSoldArtworksView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.Search)?.url} element={
                    <CheckAuth><SearchView /></CheckAuth>
                  } />
                  <Route path={getRouteDetailsByName(RouteNameEnum.SignIn)?.url} element={
                    <CheckAuth><SignInView /></CheckAuth>
                  } />
                  <Route path='*' element={<NotFoundView />} />
                </Routes>
              </AppWrapper>
            </NotificationsProvider>
          </QueryClientProvider>
        )}
      </TokenContext.Consumer>
    </SocketContext.Provider>
  );
};

export default App;
