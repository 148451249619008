import { InitResetPasswordModel } from '@on-arte/core-types';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useRemindPasswordValidation: () => Yup.SchemaOf<InitResetPasswordModel> = (): Yup.SchemaOf<InitResetPasswordModel> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    email: Yup.string()
      .required(t('onarte.common.requiredField'))
      .email(t('onarte.common.validations.email')),
  });
};
