import { Breakpoint, Button, CollectionBoxes, HighlightSection, highlightTextFragments, QuoteCarousel, toMarkdown } from '@on-arte/ui';
import React, { useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { appConfig } from '@onArte/frontend/app.config';
import { InfoBoxes } from '@onArte/frontend/components';
import { FileFromViews } from '@onArte/frontend/enums';

import { collectionBoxesItems, youtubeHash } from './aboutUs.config';
import { 
  BackgroundContainer, 
  BackgroundContentWrapper, 
  BackgroundImage, 
  BackgroundTitle, 
  CollectionBoxesContainer, 
  Container, 
  QuoteContainer, 
  StyledNewsletterBoxSection, 
  Title,
  TitleContainer,
  VideoContainer,
  VideoContentWrapper,
  VideoDescription,
  VideoPlayer,
  VideoPlayerBox,
  VideoTitle
} from './aboutUs.styled';

export const AboutUsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.SmallMobile });
  const videoContentRef: React.Ref<HTMLDivElement> = useRef(null);

  return (
    <Container>
      <BackgroundContainer>
        <BackgroundContentWrapper>
          <BackgroundTitle text={highlightTextFragments(t('onarte.website.aboutUsView.backgroundContent.title'))} />
          <Button 
            label={t('onarte.website.aboutUsView.backgroundContent.button.label')} 
            onClick={(): void | null => videoContentRef.current?.scrollIntoView({ behavior: 'smooth' })}
            fullWidth={isSmallMobile} 
          />
        </BackgroundContentWrapper>
        <BackgroundImage src={FileFromViews.AboutUsBackground} alt={t('onarte.website.aboutUsView.backgroundContent.title')} />
      </BackgroundContainer>
      <QuoteContainer>
        <QuoteCarousel quotes={[{ content: toMarkdown(highlightTextFragments(t('onarte.website.aboutUsView.quoteCarousel.quote'))) }]} />
      </QuoteContainer>
      <CollectionBoxesContainer>
        <CollectionBoxes items={collectionBoxesItems} withMobileMosaicTheme />
      </CollectionBoxesContainer>
      <TitleContainer>
        <Title text={highlightTextFragments(t('onarte.website.aboutUsView.title'))} />
      </TitleContainer>
      <VideoContainer>
        <VideoTitle text={highlightTextFragments(t('onarte.website.aboutUsView.videoContainer.title'))} />
        <VideoContentWrapper ref={videoContentRef}>
          <VideoDescription text={highlightTextFragments(t('onarte.website.aboutUsView.videoContainer.description'))} />
          <VideoPlayerBox>
            <VideoPlayer width='100%' height='100%' src={`https://www.youtube.com/embed/${youtubeHash}`} />
          </VideoPlayerBox>
        </VideoContentWrapper>
      </VideoContainer>
      <HighlightSection 
        backgroundImage={FileFromViews.AboutUsFavourite} 
        header={t('onarte.website.aboutUsView.highlightSection.header')}
        buttonSettings={!isSmallMobile 
          ? { 
            externalUrl: appConfig.magazineUrl,
            label: t('onarte.website.aboutUsView.highlightSection.button.label')
          } 
          : undefined
        }
      />
      <StyledNewsletterBoxSection />
      <InfoBoxes />
    </Container>
  );
};
