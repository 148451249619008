import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { Collection } from '@onArte/shared/interfaces';

import { api } from '../api';

export const getCollections: () => Promise<Collection[]> = (): Promise<Collection[]> => new Promise(
  (resolve: ApiResolve<Collection[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/collections').then(
      (response: AxiosResponse<Collection[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getCollection: (id: string) => Promise<Collection> = (id: string): Promise<Collection> => new Promise(
  (resolve: ApiResolve<Collection>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/collections/${id}`).then(
      (response: AxiosResponse<Collection>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
