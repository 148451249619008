/* eslint-disable max-len */
import { Paragraph } from '@onArte/shared/interfaces';

export const regulations: Paragraph[] = [
  {
    header: 'Regulamin świadczenia usług drogą elektroniczną'
  },
  {
    header: 'A. Postanowienia wstępne',
    content: [
      `1. Niniejszy Regulamin określa sposoby zawierania umów sprzedaży za pośrednictwem portalu onarte.pl (dalej „portal ON ARTE”), w których zawieraniu pośredniczy ON ARTE oraz warunki ogólne funkcjonowania i korzystania ze wskazanego portalu, prawa i obowiązki, jak i odpowiedzialność ON ARTE oraz jego Użytkowników.
    2. Każdy użytkownik portalu ON ARTE poprzez swoje przystąpienie do rejestracji konta wyraża jednocześnie zgodę na wszelkie postanowienia niniejszego regulaminu.
    3. Regulamin określa zasady przeprowadzenia oraz uczestnictwa w zakupie Obiektów, których przedmiotem jest sprzedaż dzieł sztuki oraz innych obiektów kolekcjonerskich, powierzonych przez Sprzedających ON ARTE sp. z o.o. z siedzibą przy ul. Garbary 67, 61-758 w Poznaniu, zarejestrowaną pod numerem NIP: 783-185-44-35, REGON: 512616841, wpisaną do Krajowego Rejestru Sądowego Sądu Rejonowego Poznań – Nowe Miasto i Wilda w Poznaniu pod numerem: 0000960838, zwaną dalej ON ARTE., adres email do kontaktów: support@onarte.pl, telefon kontaktowy: +48 61 111 0183.
    4. Niniejszy regulamin może być w każdym czasie zmieniony. Zmiany te dotyczą jedynie umów zawartych po ich wprowadzeniu.
    5. ON ARTE nie jest stroną umów sprzedaży zawieranych za pośrednictwem Portalu ON ARTE.
    6. ON ARTE działając w imieniu Sprzedających i Nabywców pośredniczy w ramach usług świadczonych w portalu ON ARTE w zawieraniu umów sprzedaży Obiektów pomiędzy Sprzedającymi a Nabywcami, w tym przyjmuje w imieniu Sprzedających płatności (cenę sprzedaży i opłaty dodatkowe).`,
    ]
  },
  {
    header: 'B. Definicje',
    content: [
      ` - ON ARTE – On Arte sp. z o.o. z siedzibą w Poznaniu, wpisaną do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego pod numerem: 0000960838.
    - Obiekt – dzieło sztuki lub inny obiekt kolekcjonerski, znajdujący się na terytorium Rzeczypospolitej Polskiej, wykonany własnoręcznie, dający się zaklasyfikować zgodnie z istniejącymi kategoriami, spełniający pewne subiektywne wymagania postawione przez ON ARTE, będący przedmiotem sprzedaży za pomocą niniejszego portalu.
    - Użytkownik portalu ON ARTE - każda osoba fizyczna, która ukończyła 18 lat lub osoba prawna, a także jednostka organizacyjna nieposiadająca zdolności prawnej, której właściwe przepisy nadają zdolność do czynności prawnych.
    - Sprzedający – osoba zgłaszająca Obiekt do sprzedaży.
    - Uczestnik Aukcji – osoba zarejestrowana, biorąca udział w Aukcji stacjonarnej bądź
    online na portalu ON ARTE, między innymi poprzez licytację Obiektów.
    - Kupujący:
    a) Uczestnik Aukcji stacjonarnej, który w trakcie licytacji Obiektu, złoży najwyższą ofertę przyjętą przez Aukcjonera, w wyniku czego pomiędzy nim a Sprzedającym zawarta zostaje umowa sprzedaży lub warunkowa umowa sprzedaży.
    b) Użytkownik portalu, który dokonuje zakupu Obiektu za pomocą portalu ON ARTE w innej formie niż aukcja.
    - Aukcjoner – osoba wyznaczona przez ON ARTE do prowadzenia Aukcji stacjonarnej.
    - Aukcja – zorganizowana forma sprzedaży lub zakupu, polegająca na składaniu ON ARTE na zasadach i warunkach określonych w niniejszym Regulaminie konkurencyjnych ofert nabycia poszczególnych Obiektów przez Uczestników Aukcji, w której zwycięski Nabywca jest zobowiązany do zawarcia umowy sprzedaży lub warunkowej umowy sprzedaży. Aukcja odbywa się bądź stacjonarnie, bądź za pomocą portalu ON ARTE (Aukcja online).
    - Sprzedaż „kup-teraz” – procedura dokonana za pomocą portalu ON ARTE, prowadząca do zawarcia umowy sprzedaży poprzez sieć Internet w formie umowy zawieranej na odległość polegającą na zaakceptowaniu przez Kupującego oznaczonej ceny Obiektu.
    - Sprzedaż „złóż ofertę” procedura dokonana za pomocą portalu ON ARTE, prowadząca do zawarcia umowy sprzedaży poprzez sieć Internet w formie umowy zawieranej na odległość polegającą na zaakceptowaniu przez Sprzedającego oferty złożonej przez Kupującego.
    - Katalog – przygotowany przez ON ARTE dokument zawierający opisy Obiektów wystawionych na sprzedaż na Aukcji stacjonarnej.
    - Stan Obiektu – przedstawiony przez ON ARTE opis Obiektu, z zastrzeżeniem, że nie prezentuje on pełnego stanu zachowania danego Obiektu. Brak takiej informacji nie jest równoznaczny z tym, że Obiekt jest wolny od wad i uszkodzeń. Jeśli Obiekt sprzedawany jest w ramie ON ARTE nie ponosi odpowiedzialności za jej stan.
    - Wystawa przedaukcyjna – wystawa Obiektów bezpłatnie dostępna dla oglądających, w galeriach przygotowujących daną Aukcję stacjonarną.
    - Cena wywoławcza – kwota, od której rozpoczyna się licytacja Obiektu.
    - Estymacja – wartość szacunkowa Obiektu podana na podstawie aktualnych rynkowych notowań przedmiotów analogicznych do licytowanego. W ramach Estymacji nie są zawarte Opłaty aukcyjne i opłaty dodatkowe.
    - Opłata aukcyjna – (dotyczy zarówno sprzedaży stacjonarnej, jak i sprzedaży online) opłata doliczana do kwoty, na której zakończyła się licytacja Obiektu stanowiąca wynagrodzenie ON ARTE z tytułu przeprowadzonej Aukcji. Jest częścią końcowej ceny Obiektu. Opłata aukcyjna obowiązuje również w sprzedaży poaukcyjnej, w przypadku kiedy Obiekt nie został sprzedany w ramach Aukcji. Opłata aukcyjna zawiera podatek od towarów i usług VAT.
    Opłata aukcyjna wynosi: 22% plus VAT po stronie Sprzedającego i [biddingCommission]% po stronie Kupującego. W szczególnych przypadkach, przed przeprowadzeniem Aukcji, wysokość prowizji może zostać ustalona indywidualnie. Informacja o indywidualnym ustalaniu prowizji zostanie zamieszczona na Portalu w opisie Obiektu.
    - Cena zakupu:
    a) w ramach sprzedaży zawartej w formie sprzedaży na odległość – procedura „kup teraz”– całkowita cena obejmującą cenę obiektu, wraz z prowizją ON ARTE jak i opłatą z tytułu droit de suite, które to ponosi sprzedający.
    b) w ramach sprzedaży zawartej w formie sprzedaży na odległość – procedura „złóż ofertę” – całkowita cena obejmująca zaakceptowaną przez Sprzedającego ofertę Kupującego wraz z prowizją ON ARTE, jak i opłatą z tytułu droit de suite, które to ponosi sprzedający.
    Prowizja portalu ON ARTE, o których mowa w podpunktach a) i b) wynosi 22% plus VAT ceny Obiektu (bądź oznaczonej przez Sprzedającego w ramach procedury „kup teraz”, bądź ceny zaproponowanej przez Kupującego, a zaakceptowanej przez Sprzedającego w ramach procedury „złóż ofertę” ) i w każdym przypadku obciąża Sprzedającego.
    - Cena gwarancyjna – (dotyczy zarówno aukcji stacjonarnej, jak i aukcji na portalu ON ARTE) – minimalna cena, za którą wylicytowany Obiekt może zostać sprzedany. Jest ceną poufną uzgodnioną między ON ARTE i Sprzedającym. Jeżeli podczas licytacji nie zostanie ona osiągnięta, zakończenie licytacji skutkuje zawarciem Umowy sprzedaży warunkowej, co zostaje ogłoszone bądź przez prowadzącego Aukcję stacjonarną po uderzeniu młotkiem, bądź stosowną informacją, która pojawi się na koncie Kupującego przy licytowaniu na portalu ON ARTE.
    - Umowa sprzedaży warunkowej – w wypadku gdy kwota wylicytowana jest niższa niż Cena gwarancyjna, umowa pomiędzy Sprzedającym a Kupującym, który zaoferował najwyższą cenę, jest zawierana warunkowo. Transakcja dochodzi do skutku, jeżeli Sprzedający zgodzi się na sprzedaż Obiektu po cenie niższej niż gwarancyjna lub też Kupujący podwyższy ofertę do Ceny gwarancyjnej. W przeciwnym wypadku transakcja warunkowa nie dochodzi do skutku, a Obiekt może zostać wystawiony do sprzedaży poaukcyjnej.
    - Droit de suite - opłata z tytułu prawa twórcy utworu plastycznego lub fotograficznego do otrzymywania wynagrodzenia określanego jako procent ceny dzieła odsprzedawanego.`,
    ]
  },
  {
    header: 'C. Sprzedaż',
    content: [
      'W ramach prowadzonej działalności ON ARTE oferuje działając w imieniu Sprzedających: sprzedaż licytacyjną (stacjonarną, czy też za pośrednictwem portalu ON ARTE), bądź też możliwość zawierania umów sprzedaży za pośrednictwem portalu ON ARTE w ramach procedury „kup teraz” lub „złóż ofertę”.',
      '**I. Obiekty przeznaczone do licytacji**',
      'Wszystkie Obiekty zaprezentowane na portalu ON ARTE przeznaczone są do sprzedaży na warunkach określonych w niniejszym Regulaminie.',
      'Przedmiotem Aukcji są Obiekty będące w posiadaniu Sprzedających (ON ARTE działa jako pełnomocnik Sprzedających).',
      'Zgodnie z oświadczeniem Sprzedającego wystawione na Aukcję Obiekty stanowią jego własność, bądź też Sprzedający ma prawo do rozporządzania nimi; ponadto – zgodnie z tym oświadczeniem Obiekty te nie są objęte jakimkolwiek postępowaniem sądowym i skarbowym, są wolne od zajęcia i zastawu oraz innych ograniczonych praw rzeczowych, a także jakichkolwiek roszczeń osób trzecich.',
      'Każdy Obiekt objęty jest szczegółowym opisem przygotowanym przez ON ARTE. Wykonywane są one w najlepszej wierze z wykorzystaniem doświadczenia i fachowej wiedzy jego pracowników oraz współpracujących ekspertów. Mimo uwagi poświęcanej każdemu z Obiektów w procesie opracowywania dokumentacji pochodzenia, historii wystaw i bibliografii przedstawione informacje mogą być niewyczerpujące, a w niektórych przypadkach pewne fakty odnoszące się do kolejnych właścicieli, ekspozycji oraz publikacji, w ramach których Obiekt był prezentowany, mogą być nieujawnione. ON ARTE ma prawo do dowolnego rozdzielania lub łączenia Obiektów oraz do ich wycofania z Aukcji bez podania przyczyn, także w trakcie jej trwania.',
      'Aukcja zarówno stacjonarna jak i na portalu ON ARTE jest prowadzona w języku polskim i zgodnie z prawem obowiązującym na terenie Rzeczypospolitej Polskiej.',
      'Wszelkie podane ceny na portalu ON ARTE (przy procedurze „kup teraz” lub „złóż ofertę”) są podawane w polskich złotych i są cenami brutto obejmującymi wszystkie opłaty wynikające z postanowień niniejszego regulaminu.',
      'Ceny podane na aukcji stacjonarnej i aukcji online na portalu onarte.pl są cenami netto i nie uwzględniają wszelkich opłat wynikających z postanowień niniejszego regulaminu.',
      '**II. Aukcja stacjonarna i online**',
      '1. Rejestracja na Aukcję stacjonarną',
      'Warunkiem udziału w aukcji jest zaakceptowanie przez Uczestnika Aukcji treści zawartych w niniejszym Regulaminie w całości i bez jakichkolwiek zastrzeżeń.',
      'Wszyscy Uczestnicy Aukcji muszą zarejestrować się przed aukcją, dostarczyć wymagane informacje przewidziane w formularzu rejestracji (imię i nazwisko Uczestnika Aukcji, adres email, numer telefonu, adres do rozliczeń i korespondencji, numer dokumentu tożsamości), okazać dokument potwierdzający tożsamość (dowód osobisty lub paszport) oraz odebrać tabliczkę z numerem licytacyjnym. W przypadku powzięcia uzasadnionych wątpliwości co do tożsamości Uczestnika Aukcji lub możliwości zawarcia przez niego ważnej umowy sprzedaży, ON ARTE ma prawo poprosić Uczestnika Aukcji (np. w celu sprawdzenia jego wypłacalności, poświadczenia jego tożsamości lub w celu uniknięcia fałszerstwa) o przedstawienie dodatkowych dokumentów, pozyskać dane o Uczestniku Aukcji od osób trzecich lub określić sumę wadium, jaką Uczestnik Aukcji powinien uiścić celem zabezpieczenia. Dane osobowe Uczestnika Aukcji są informacjami poufnymi i pozostają do wyłącznej wiadomości ON ARTE.',
      'Do udziału w aukcji i zawierania umów sprzedaży upoważnione są wszystkie osoby fizyczne posiadające pełną zdolność do czynności prawnych, które ukończyły 18 lat, a także osoby przez nie upoważnione oraz wspólnicy spółek osobowych w rozumieniu ustawy z dnia 15 września 2000 r. Kodeks spółek handlowych (t.j. Dz. U. z 2017 r. poz. 1577, z 2018 r. poz. 398, 650, 1544, 2219, 2244.)',
      'ON ARTE może odmówić dopuszczenia niektórych osób do udziału w aukcji lub sprzedaży poaukcyjnej, jeżeli zachodzi niewyjaśniona wątpliwość co do ich tożsamości, możliwości zawarcia przez nich ważnej umowy sprzedaży, zachodzi podejrzenie możliwości popełnienia czynu zabronionego lub dana osoba swoim zachowaniem może zakłócać prawidłowy przebieg aukcji.',
      'ON ARTE dokonując czynności w ramach aukcji działa w imieniu i na rzecz Sprzedających i Nabywców.',
      '2. Rejestracja na aukcję online na portalu ON ARTE',
      'Osoby zainteresowane licytacją za pośrednictwem platformy licytacyjnej ON ARTE na stronie www: onarte.pl oraz aplikacji mobilnej muszą zarejestrować się poprzez utworzenie konta osobistego za pomocą portalu onarte.pl podając dane potrzebne do weryfikacji tożsamości i obsługi płatności.',
      'Po pozytywnej weryfikacji przez ON ARTE Uczestnik Aukcji może uczestniczyć za pośrednictwem Internetu na bieżąco w licytacji Obiektów aukcyjnych na tych samych zasadach jak podczas licytacji osobistej.',
      'ON ARTE dokonując czynności w ramach aukcji działa w imieniu i na rzecz Sprzedających i Nabywców.',
      '3. Dodatkowe dane Uczestnika Aukcji stacjonarnej i online.',
      'ON ARTE zastrzega sobie prawo do żądania od Uczestnika Aukcji dodatkowych danych jego identyfikujących, których uzyskanie jest wymagane przepisami prawa w celu przeprowadzenia transakcji. Dane te mogą dotyczyć zarówno osoby kupującej jak i innych podmiotów (np. beneficjent rzeczywisty spółki kapitałowej).',
      '4. Odpowiedzialność Uczestnika Aukcji - dotyczy Aukcji stacjonarnej i online',
      'Podczas licytacji Uczestnik Aukcji bierze osobistą odpowiedzialność za zapłatę za wylicytowane Obiekty, chyba że przed rozpoczęciem Aukcji zostało wyraźnie uzgodnione na piśmie (pod rygorem nieważności) z ON ARTE, że oferent jest pełnomocnikiem zidentyfikowanej osoby trzeciej zaakceptowanej przez ON ARTE.',
      '5. Tabela postąpień - dotyczy Aukcji stacjonarnej i online',
      'Licytacja rozpoczyna się od Ceny wywoławczej.',
      'Aukcjoner bądź moderator aukcji online, podaje kolejne postąpienia według poniższej tabeli. W przypadku Aukcji stacjonarnej, w zależności od jej przebiegu, Aukcjoner może wedle własnego uznania zdecydować o innej wysokości postąpienia.',
      '[stepPrices]',
      '**III. Sprzedaż poaukcyjna**',
      `Niektóre Obiekty niesprzedane na Aukcji wystawione są do sprzedaży poaukcyjnej.
    W cenie sprzedaży Obiektów oferowanych poaukcyjnie zawarta jest także Opłata aukcyjna i inne opłaty dodatkowe. Procedura sprzedaży poaukcyjnej podlega przepisom niniejszego Regulaminu, stosowanym odpowiednio.`,
      '**IV. Sprzedaż „kup teraz”.**',
      ` 1. Użytkownicy mają możliwość zakupu określonych obiektów umieszczonych na portalu ON ARTE za pośrednictwem procedury sprzedaży „kup teraz”, która prowadzi do zawarcia umowy kupna-sprzedaży pomiędzy Sprzedającym a Nabywcą w formie umowy zawieranej na odległość.
    2. W przypadku sprzedaży „kup teraz” Sprzedający oznacza cenę sztywną przy danym Obiekcie, której akceptacja przez Kupującego oznacza zawarcie umowy sprzedaży.
    3. Kupujący ma 7 dni na zrealizowanie płatności za zakupiony przez niego obiekt.
    4. Procedura sprzedaży „kup teraz” podlega przepisom niniejszego Regulaminu, stosowanym
    odpowiednio.`,
      '**V. Sprzedaż „złóż ofertę”.**',
      ` 1. Użytkownicy mają możliwość zakupu określonych obiektów umieszczonych na portalu ON ARTE za pośrednictwem procedury sprzedaży „złóż ofertę”, która prowadzi do zawarcia umowy kupna-sprzedaży pomiędzy Sprzedającym a Nabywcą w formie umowy zawieranej na odległość.
    2. Obiekty wystawione na portalu ON ARTE nieposiadające oznaczenia ceny są dostępne do zakupu w opcji „złóż ofertę”. Zalogowany Kupujący za pomocą przycisku „złóż ofertę” na stronie Obiektu, może złożyć ofertę poprzez oznaczenie ceny za jaką będzie gotowy dany Obiekt kupić.
    3. Sprzedający w ciągu 48 godzin od złożenia oferty przez Kupującego może ją zaakceptować. Kupujący dostanie wtedy niezwłocznie zawiadomienie o akceptacji jego oferty. W ciągu 48 godzin od otrzymania ww. zawiadomienia, Kupujący będzie zobowiązany do zakończenia transakcji kupna zgodnie z instrukcją otrzymaną z portalu ON ARTE.
    4. Niezaakceptowana przez Sprzedającego oferta, po upływie 48 godzin od jej złożenia przez Kupującego przestaje Kupującego wiązać.
    5. Procedura sprzedaży „złóż ofertę” podlega przepisom niniejszego Regulaminu, stosowanym odpowiednio.`,
      '**VI. Odstąpienie od umowy zawieranej na odległość.**',
      ` 1. Przepisy niniejszego ustępu dotyczą umów sprzedaży zawartej za pomocą portalu onarte.pl w formie aukcji online, procedury „kup teraz”, jak i procedury „złóż ofertę”.
    2. W powyższych przypadkach Kupujący, który zawarł Umowę na odległość lub Umowę poza lokalem ON ARTE - działającego w imieniu Sprzedających - ma prawo odstąpienia od umowy bez podania przyczyny w terminie 14 dni od daty doręczenia Obiektu. Uprawnienie to przysługuje niezależnie od tego czy stroną umowy jest przedsiębiorca czy konsument.
    3. Prawo odstąpienia od umowy jest skuteczne, jeżeli Kupujący wyśle drogą mailową na adres support@onarte.pl w terminie 14 dni od daty doręczenia Obiektu oświadczenie o odstąpieniu od umowy, które jest załącznikiem do niniejszego regulaminu. Sprzedający upoważniają ON ARTE do odbioru oświadczeń w ich imieniu.
    4. Nabywca zwraca Obiekt na adres Sprzedającego w terminie 14 dni od złożenia oświadczenia o odstąpieniu od umowy na swój koszt. Nabywca jest zobowiązany odpowiednio zabezpieczyć przesyłkę jak i bierze odpowiedzialność za stan Obiektu.
    5. W ciągu 7 dni roboczych od otrzymania przesyłki Sprzedający sprawdzi stan przekazanego Obiektu.
    6. W terminie 7 dni od sprawdzenia towaru i braku zastrzeżeń po stronie Sprzedającego co do jego stanu ON ARTE zwraca Kupującemu w imieniu Sprzedającego zapłaconą kwotę. Kupujący powinien wskazać numer rachunku bankowego, na jaki ma zostać przelana zwracana kwota.
    7. W braku złożenia oświadczenia przez Nabywcę o odstąpieniu od umowy, ON ARTE wypłaca Sprzedającemu Cenę Zakupu zgodnie z postanowieniami niniejszego regulaminu w terminie 7 dni od upływu terminu na odstąpienie przez Nabywcę od umowy.
    8. Prawo odstąpienia od umowy zawartej pomiędzy Sprzedawcą a Uczestnikiem Aukcji (Kupującym) w drodze aukcji stacjonarnej nie przysługuje.`
    ]
  },
  {
    header: 'D. Płatności',
    content: [
      '**I. Czas płatności**',
      ` 1. Uczestnik Aukcji lub Kupujący, który w wyniku przyjęcia jego oferty zawarł ze Sprzedającym umowę sprzedaży, jest zobowiązany do zapłaty ceny powiększonej o stosowne opłaty dodatkowe wynikające z niniejszego regulaminu w terminie 7 dni od dnia Aukcji. ON ARTE wypłaci Sprzedającemu w terminie dalszych 3 dni - w przypadku Aukcji stacjonarnej i 35 dni w przypadku Aukcji online, cenę wylicytowanego obiektu pomniejszoną o opłatę aukcyjną i ewentualnie o opłatę z tytułu droit de suite zgodnie z postanowieniami niniejszego regulaminu.
    2. W sytuacji zawarcia Umowy sprzedaży przez Kupującego w ramach procedury „kup teraz” cenę zakupu należy uiścić najpóźniej w terminie 7 dni od zakończenia tej procedury – zgodnie z instrukcjami na stronie internetowej, na jaką Kupujący zostanie przekierowany po wybraniu tej opcji. ON ARTE wypłaci Sprzedającemu w terminie dalszych 35 dni cenę obiektu pomniejszoną o prowizję ON ARTE i ewentualnie o opłatę z tytułu droit de suite zgodnie z postanowieniami niniejszego regulaminu.
    3. W sytuacji zawarcia Umowy sprzedaży przez Kupującego w ramach procedury „złóż ofertę” cenę zakupu należy uiścić najpóźniej w terminie 7 dni od otrzymania przez Kupującego informacji od Sprzedającego o akceptacji jego oferty. ON ARTE wypłaci Sprzedającemu w terminie dalszych 35 dni cenę obiektu pomniejszoną o prowizję ON ARTE i ewentualnie o opłatę z tytułu droit de suite zgodnie z postanowieniami niniejszego regulaminu.
    4. W sytuacji zawarcia Umowy sprzedaży warunkowej termin dla Kupującego do uiszczenia ceny zakupu wynosi 7 dni i biegnie od chwili poinformowania Kupującego przez ON ARTE o zaakceptowaniu jego oferty przez Sprzedającego. ON ARTE wypłaci Sprzedającemu w terminie dalszych 35 dni cenę obiektu pomniejszoną o prowizję ON ARTE i ewentualnie o opłatę z tytułu droit de suite zgodnie z postanowieniami niniejszego regulaminu.
    5. Obowiązek uiszczenia ceny w każdym z powyższych przypadków jest niezależny od uzyskania pozwolenia na eksport czy otrzymania innych pozwoleń.
    6. ON ARTE jest uprawnione do naliczenia odsetek ustawowych za opóźnienie w płatności.
    7. Przejście własności obiektu w każdym przypadku zawarcia umowy sprzedaży za pośrednictwem ON ARTE jest uwarunkowane uiszczeniem pełnej ceny za obiekt wraz ze wszelkimi dodatkowymi opłatami wynikającymi z zapisów niniejszego regulaminu.
    8. Terminem płatności jest data zaksięgowania środków na koncie ON ARTE.`,
      '**II. Formy płatności**',
      ` 1. ON ARTE przyjmuje następujące formy płatności: karta płatnicza (Visa, Visa Electron, Mastercard, Mastercard Electronic, Maestro), BLIK oraz przelew na rachunek bankowy (na konto ON ARTE), oraz wszelkie inne formy zapłaty dopuszczone przez operatora płatności, który obsługuje ON ARTE.
    2. Podmiotem świadczącym usługę online jest Blue Media S.A.
    [cardPayments]
    3. W przypadku wykonania przelewów za dzień zapłaty rozumie się wpływ środków na rachunek bankowy ON ARTE.
    4. Sprzedawca upoważnia ON ARTE do przyjmowania płatności w jego imieniu.`,
      '**III. Zwroty Płatności**',
      ` 1. Zwroty kwot związanych z zawarciem Umów sprzedaży, a następnie odstąpieniem od tych Umów oraz kwot związanych z wszelkimi inny opłatami, dokonywane są na warunkach i zasadach zgodnych z obowiązującymi w tym zakresie przepisami prawa.
    2. Sprzedawca dokonuje zwrotu płatności przy użyciu takiego samego sposobu płatności, jakiego użył Kupujący, chyba że Kupujący wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami.
    3. W przypadku wystąpienia konieczności zwrotu środków za transakcję dokonaną przez klienta kartą płatniczą sprzedający dokonana zwrotu na rachunek bankowy przypisany do karty płatniczej Kupującego.`,
      '**IV. Brak Płatności**',
      ` 1. W przypadku gdy Uczestnik Aukcji w terminie 7 dni od daty konieczności uiszczenia ceny sprzedaży na rzecz Sprzedawcy, nie uiści jej w całej wysokości (zawierającej wszelkie opłaty dodatkowe wynikające z niniejszego regulaminu) ON ARTE działając w imieniu Sprzedawcy bez uszczerbku dla innych praw Sprzedawcy może zastosować jeden lub kilka z poniższych środków prawnych:
    a) przechować Obiekt w galeriach ON ARTE lub w innym miejscu na ryzyko i koszt Uczestnika Aukcji, bez uzyskania ewentualnej zgody Sądu powszechnego;
    b) po bezskutecznym upływie dodatkowego 7 dniowego terminu na uiszczenie pełnej ceny zakupu może od umowy odstąpić. O wyznaczeniu tego dodatkowego ostatecznego terminu administrator portalu ON ARTE powiadamia Uczestnika Aukcji drogą mailową.
    W tym przypadku Sprzedający może zatrzymać dotychczasowe ewentualne wpłaty od Uczestnika Aukcji na pokrycie ewentualnych szkód, czy roszczeń wynikających z niniejszego regulaminu.
    c) odrzucić zlecenie Uczestnika Aukcji w przyszłości lub zrealizować takie zlecenie pod warunkiem uiszczenia wadium; w przypadku ponownego opóźnienia w płatności przez Uczestnika Aukcji ON ARTE może zawiesić jego konto na czas do 7 dni. W przypadku zaistnienia trzeciego opóźnienia w płatności ON ARTE może usunąć konto Uczestnika Aukcji i nie zezwalać na utworzenie nowego konta dla tej osoby (podmiotu) w przyszłości.
    d) naliczać odsetki maksymalne od dnia wymagalności płatności do dnia zapłaty pełnej Ceny zakupu;
    e) odsprzedać Obiekt na platformie ON ARTE lub prywatnie z Estymacjami i ceną minimalną ustaloną przez ON ARTE. Jeśli Obiekt w wyniku tak dokonanej sprzedaży zostanie zbyty za kwotę niższą niż cena, za którą Uczestnik Aukcji był zobowiązany kupić Obiekt, Uczestnik Aukcji pozostający w zwłoce będzie zobowiązany do pokrycia różnicy wraz z kosztami przeprowadzenia dodatkowej umowy sprzedaży przez ON ARTE;
    Sprzedający upoważnia ON ARTE do dokonania wszystkich wyżej wymienionych czynności w jego imieniu.`,
      '**V. Opłata droit de suite**',
      ` 1. Do opłat dodatkowych zalicza się opłatę z tytułu tzw. droit de suite, tj. prawa twórcy i jego spadkobierców do otrzymywania wynagrodzenia z tytułu dokonanych zawodowo odsprzedaży oryginalnych egzemplarzy dzieł. Opłata będzie obliczana, gdy kwota wylicytowana przekroczy równowartość 100 euro. Powyżej tej kwoty opłata będzie sumą stawek: 5% części ceny sprzedaży, jeżeli ta część jest zawarta w przedziale do równowartości 50 000 euro oraz 3% części ceny sprzedaży, jeżeli ta część jest zawarta w przedziale od równowartości 50 000,01 euro do równowartości 200 000 euro oraz 1% części ceny sprzedaży, jeżeli ta część jest zawarta w przedziale od równowartości 200 000,01 euro do równowartości 350 000 euro, oraz 0,5% części ceny sprzedaży, jeżeli ta część jest zawarta w przedziale od równowartości 350 000,01 euro do równowartości 500 000 euro oraz 0,25% części ceny sprzedaży, jeżeli ta część jest zawarta w przedziale przekraczającym równowartość 500 000 euro, jednak w kwocie nie wyższej niż równowartość 12 500 euro. Opłata ta ustalana jest przy zastosowaniu średniego kursu euro ogłoszonego przez NBP w dniu poprzedzającym dzień Aukcji lub - w przypadku odbycia Aukcji w dniu przypadającym po dniu, w którym nie są publikowane średnie kursy walut NBP - przy zastosowaniu ostatniego średniego kursu euro opublikowanego przez NBP przed dniem Aukcji.
    2. Opłata droit de suite obciąża Sprzedającego.`,
      '**VI. Płatność w innych walutach/Transakcje transgraniczne**',
      ` 1. Na portalu ON ARTE nie może dojść do zawarcia żadnej transakcji transgranicznej. Oznacza to, że wszelkie podmioty, które rejestrują swoje konto na portalu ON ARTE muszą mieć miejsce zamieszkania lub swoją siedzibę w Polsce.
    2. Wszystkie płatności są przyjmowane wyłącznie w polskich złotych.`
    ]
  },
  {
    header: 'E. Przeniesienie prawa własności Obiektu',
    content: [
      ` 1. Własność zakupionego Obiektu nie przejdzie na Kupującego dopóki Sprzedający nie otrzyma pełnej Ceny zakupu za Obiekt, w tym wszelkich opłat dodatkowych wynikających z niniejszego regulaminu.
    2. Przeniesienie prawa własności dotyczy tylko i wyłącznie Obiektu. Prawa autorskie majątkowe twórcy pozostają nienaruszone.
    3. Wcześniejsze przekazanie Obiektu Uczestnikowi Aukcji, bądź Kupującemu nie jest równoznaczne z przeniesieniem prawa własności Obiektu na niego ani zwolnieniem z obowiązku zapłaty Ceny zakupu i opłat dodatkowych.`,
    ]
  },
  {
    header: 'F. Odbiór i transport',
    content: [
      '**I. Dostawa kurierska**',
      'W przypadku zdecydowania się przez Kupującego na transport Obiektu za pomocą dostawy kurierskiej Kupujący korzysta z dostawcy DPD Polska sp. z o.o. (nr KRS 0000028368) na zasadach i warunkach określonych przez wyżej wymienioną firmę kurierską. Koszt takiego transportu pokrywa ON ARTE. Sprzedający ma obowiązek nadać Obiekt najpóźniej 3 dnia od sfinalizowania transakcji na portalu onarte.pl.',
      '**II. Odbiór własny**',
      'Jeżeli Sprzedający zaznaczy możliwość odbioru w siedzibie Sprzedającego Kupujący może jej dokonać po uprzednim kontakcie ze Sprzedającym drogą E-mail w celu ustalenia szczegółów odbioru. Odbiór Obiektu od Sprzedającego musi nastąpić najpóźniej 7 dnia od sfinalizowania transakcji na portalu onarte.pl.',
      '**III. Transport ON ARTE**',
      'W pewnych przypadkach transport za pomocą kuriera może okazać się niemożliwy. W takich przypadkach ON ARTE zapewnia transport własnymi siłami. Jeżeli Kupujący w takim przypadku nie skorzysta z opcji odbioru własnego koszt transportu ON ARTE obciąża Kupującego. Koszt transportu ON ARTE na terenie Polski wynosi 450 zł brutto. Pracownicy ON ARTE skontaktują się bezpośrednio z Kupującym celem ustalenia warunków transportu.',
      'Zmiana wysokości opłaty za transport ON ARTE nie stanowi zmiany niniejszego Regulaminu.',
      '**IV. Pozwolenie na wywóz i dokumenty wywozowe**',
      'W wypadku niektórych Obiektów przepisy prawa wymagają dodatkowych pozwoleń na wywóz poza granice kraju. Kwestię tę reguluje ustawa z dnia 23 lipca 2003 r. o ochronie zabytków i opiece nad zabytkami (Dz.U. nr 162 poz. 1568, z późn. zm.), zgodnie z którą wywóz określonych obiektów poza granice kraju wymaga zgody odpowiednich władz; w szczególności dotyczy to obrazów starszych niż 50 lat o wartości powyżej 40 000 złotych. Kupujący jest zobowiązany do przestrzegania przepisów w tym zakresie, a niemożliwość uzyskania odpowiednich dokumentów lub opóźnienie w ich uzyskaniu nie uzasadniają odstąpienia od sprzedaży ani opóźnienia w uiszczeniu pełnej Ceny zakupu za obiekt.',
      '**V. Zagrożone gatunki**',
      'Przedmioty wykonane z materiału roślinnego lub zwierzęcego albo zawierające m.in. koralowiec, skórę krokodyla, kość słoniową, kość wielorybia, róg nosorożca, skorupę żółwia, niezależnie od wieku, procentowej zawartości, mogą wymagać dodatkowych pozwoleń lub certyfikatów przed wywozem. Uzyskanie dokumentów umożliwiających eksport nie jest równoznaczne z możliwością importu do innego państwa. Kupujący jest zobowiązany do przestrzegania przepisów w tym zakresie, a niemożliwość uzyskania odpowiednich dokumentów lub opóźnienie w ich uzyskaniu nie uzasadniają odstąpienia od sprzedaży ani opóźnienia w uiszczeniu pełnej Ceny zakupu za Obiekt. ON ARTE nie ponosi odpowiedzialności za błędy lub uchybienia w oznaczeniu przedmiotów zawierających elementy wytworzone z chronionych lub regulowanych prawem gatunków roślin i zwierząt.'
    ]
  },
  {
    header: 'G. Informacje ogólne',
    content: [
      '**I. Dane osobowe**',
      'ON ARTE w związku ze świadczonymi usługami oraz wymogami prawnymi związanymi z przeprowadzeniem Aukcji, czy też umów sprzedaży na podstawie niniejszego regulaminu może wymagać od Uczestników Aukcji (Kupujących) podania danych osobowych lub w niektórych przypadkach (np. w celu sprawdzenia wypłacalności, poświadczenia tożsamości klienta lub w celu uniknięcia fałszerstwa) pozyskać dane o Uczestniku Aukcji (Kupującym) od osób trzecich. ON ARTE może również wykorzystać dane osobowe dostarczone przez Uczestnika Aukcji (Kupującego), za jego zgodą, w celach marketingowych, dostarczając materiały o Obiektach, usługach bądź wydarzeniach organizowanych przez ON ARTE.',
      'W zakresie przetwarzania danych osobowych, zastosowanie znajdują postanowienia klauzuli informacyjnej dostępnej pod adresem: onarte.pl. Poprzez akceptację niniejszego Regulaminu Uczestnik Aukcji (Kupujący) potwierdza, że zapoznał się z ww. klauzulą i nie wnosi do niej uwag.',
      'Każdy Użytkownik portalu onarte.pl ma prawo do żądania od ON ARTE dostępu do danych osobowych dotyczących siebie, ich sprostowania, usunięcia lub ograniczenia przetwarzania; oraz posiada prawo do wniesienia sprzeciwu wobec przetwarzania, a także prawo do przenoszenia danych – na zasadach wskazanych w obowiązujących przepisach dotyczących ochrony danych osobowych, w szczególności RODO.',
      'Szczegółowe zasady administrowania danymi osobowymi Uczestników Aukcji (Kupujących) - w rozumieniu RODO - zostały przedstawione w zakładce „Polityka Prywatności” znajdującą się na portalu ON ARTE.',
      'Dane osobowe Uczestników Aukcji (Kupujących) nie będą przekazywane żadnym odbiorcom, z wyłączeniem firm kurierskich oraz odbiorców będących państwowymi organami uprawnionymi na podstawie odpowiednich przepisów do żądania wydania danych osobowych Uczestników Aukcji (Kupujących).',
      '**II. Ograniczenia odpowiedzialności ON ARTE**',
      'ON ARTE wyłącza wszelkie gwarancje inne niż zawarte w niniejszym Regulaminie w najszerszym zakresie dopuszczonym prawem.',
      'ON ARTE nie jest odpowiedzialne za autentyczność Obiektów prezentowanych w portalu ON ARTE.',
      'ON ARTE zastrzega sobie możliwość korzystania i dowolnego dysponowania materiałami promocyjnymi (tekstami, zdjęciami i video), dokumentującymi Obiekty zgłoszone przez Sprzedających do sprzedaży.',
      'ON ARTE zastrzega sobie możliwość modyfikacji/uzupełnienia informacji szczegółowych o Obiekcie, jak również niedopuszczenia Obiektu do sprzedaży bez podania przyczyny.',
      'ON ARTE nie jest odpowiedzialny za kopiowanie i rozpowszechnianie materiałów promocyjnych (tekstów, zdjęć i video), dokumentujących Obiekty zgłoszone przez Sprzedających do sprzedaży przez osoby trzecie.',
      'ON ARTE nie jest odpowiedzialne za przejęzyczenia lub pomyłki pisemne w informacjach podanych klientom oraz nie ponosi odpowiedzialności wobec żadnego Uczestnika Aukcji (Kupującego) za błędy w trakcie prowadzonej Aukcji lub popełnione w innym zakresie związanym ze sprzedażą Obiektu.',
      'ON ARTE nie ponosi odpowiedzialności za to, że wystawiony na portalu onarte.pl lub zgłoszony na Aukcję stacjonarną Obiekt został pozyskany przez Sprzedającego za pomocą czynu zabronionego.',
    ]
  },
  {
    header: 'H. Reklamacje',
    content: [
      'Podmiotem zobowiązanym wobec Kupującego z tytułu rękojmi za wady Obiektów, jest Sprzedający.',
      'Wszelkie możliwe reklamacje rozpatrywane są zgodnie z przepisami prawa polskiego, tj. w terminie 14 dnia od daty zgłoszenia.  Sprzedawca odpowiada z tytułu rękojmi, jeżeli wada fizyczna Obiektu zostanie stwierdzona przed upływem dwóch lat. Reklamację z tytułu niezgodności używanego Obiektu z umową Kupujący będący konsumentem może zgłosić w ciągu jednego roku od wydania Obiektu. Wobec osób niebędących bezpośrednimi Nabywcami na Aukcji, ON ARTE nie ponosi odpowiedzialności za ukryte wady fizyczne oraz wady prawne zakupionych Obiektów.',
      'Sprzedawca jest zwolniony od odpowiedzialności z tytułu rękojmi, jeżeli Kupujący wiedział o wadzie w chwili zawarcia umowy.',
      'W przypadku uznania przez Sprzedającego reklamacji jest on zobowiązany do zwrotu całości płatności jakiej dokonał Kupujący bądź na aukcji stacjonarnej lub za pośrednictwem portalu ON ARTE. W przypadku naliczenia jakichkolwiek opłat na rzecz portalu ON ARTE będą one podlegać zwrotowi w przypadku zasadności reklamacji.',
      '**I. Prawa autorskie**',
      'Sprzedający nie przekazują wraz z Obiektem prawa autorskiego ani prawa do reprodukowania Obiektu.',
      'Prawa autorskie do wszystkich zdjęć, ilustracji i tekstów związanych z Obiektem, sporządzonych przez lub dla ON ARTE, włączając zawartość Katalogów, stanowią własność ON ARTE, bądź są przez ON ARTE używane na podstawie uzyskanych licencji. Nie mogą być one wykorzystane przez Kupującego, ani inne osoby bez uprzedniej pisemnej zgody ON ARTE.',
      'Akceptując niniejszy Regulamin, użytkownik portalu onarte.pl udziela nieodpłatnie ON ARTE bezwarunkowej zgody na wykorzystanie wizerunku Obiektów umieszczonych na portalu jak i danych dotyczących jego osoby na potrzeby działań marketingowych i promocyjnych portalu onarte.pl. Zgoda ta obowiązuje od momentu utworzenia konta użytkownika i nie jest ograniczona w czasie. Powyższe nie dotyczy danych osobowych użytkownika, co do których mają zastosowanie ograniczenia wynikające z powszechnie obowiązujących prawa.'
    ]
  },
  {
    header: 'J. Zawieszenie oraz usunięcie konta Użytkownika.',
    content: [
      'ON ARTE zastrzega sobie prawo do przesłania ostrzeżenia i zawieszenia konta Uczestnika Aukcji na okres 1 tygodnia w przypadku złamania któregokolwiek z postanowień niniejszego Regulaminu, w szczególności naruszenia zasad kultury osobistej, spammingu, przesyłania niezamówionej informacji handlowej, promocyjnej, reklamowej (w tym reklam konkurencyjnych do portalu ON ARTE).',
      'W przypadku dwukrotnego zawieszenia konta Uczestnika Aukcji, konto może zostać zablokowane dożywotnio.',
      'Na życzenie każdego Użytkownika, portal ON ARTE usunie jego konto wraz z przypisanymi do niego danymi osobowymi, z zastrzeżeniem, że zapis dokonanych przez niego transakcji zostanie przetworzona do realizacji celów Ustawy z dnia 1 marca 2018 roku o przeciwdziałaniu praniu pieniędzy i finansowaniu terroryzmu oraz do celów statystycznych (bez przetwarzania danych osobowych).'
    ]
  },
  {
    header: 'K. Obowiązujące przepisy prawa',
    content: [
      'Niniejszy Regulamin podlega prawu polskiemu i zgodnie z nim będzie interpretowany.',
      'Niniejszy Regulamin stanowi całość uzgodnień pomiędzy ON ARTE, a Uczestnikami Aukcji, Kupującymi i Sprzedającymi. Wszelkie inne ewentualne uzgodnienia pomiędzy tymi podmiotami wymagają formypisemnej pod rygorem nieważności i muszą stanowić wyraźne, że modyfikują tym samym niniejszy regulamin.',
      'Jeżeli jakakolwiek część niniejszego Regulaminu zostanie uznana przez sąd właściwy lub inny upoważniony podmiot za nieważną, podlegającą unieważnieniu, pozbawioną mocy prawnej, nieobowiązującą lub niewykonalną, pozostałe części niniejszego Regulaminu będą nadal uważane za w pełni obowiązujące i wiążące.'
    ]
  },
  {
    header: 'L. Inne',
    content: [
      'ON ARTE w szczególności zwraca uwagę na przepisy:',
      ` - ustawy z dnia 23 lipca 2003r o ochronie zabytków i opiece nad zabytkami (Dz.U. Nr 162 poz.
      1568) – wywóz określonych Obiektów poza granice kraju wymaga zgody odpowiednich władz,
    - ustawy z dnia 21 listopada 1996r, o muzeach (Dz.U. z 1997r Nr5, poz.24, z późn. zm.) – muzea rejestrowane mają prawo pierwokupu zabytków bezpośrednio na Aukcji za kwotę wylicytowaną powiększoną o Opłatę aukcyjną i ewentualnie o opłaty dodatkowe
    - ustawy z dnia 25 maja 2017 r. o restytucji narodowych dóbr kultury (Dz. U. z 2017 r. poz. 1086) – Minister właściwy do spraw kultury i ochrony dziedzictwa narodowego występuje o zwrot wyprowadzonego z naruszeniem prawa z terytorium Rzeczypospolitej Polskiej narodowego dobra kultury RP
    - ustawy z dnia 1 marca 2018 r. o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu (Dz.U. 2018 poz. 723 z późn. zm.) – ON ARTE jest zobowiązane do zbierania danych osobowych Nabywców dokonujących transakcji w kwocie powyżej 10 000 euro.`,
      'ON ARTE zastrzega sobie prawo do żądania od Użytkownika portalu onarte.pl dodatkowych danych jego identyfikujących, których uzyskanie jest wymagane przepisami prawa w celu przeprowadzenia transakcji. Dane te mogą dotyczyć zarówno osoby kupującej jak i innych podmiotów (np. beneficjent rzeczywisty spółki kapitałowej).'
    ]
  },
  {
    header: 'M. Postanowienia końcowe',
    content: [
      ` 1. Niniejszy Regulamin wyczerpuje całość praw i obowiązków pomiędzy stronami w odniesieniu
    do sprzedaży aukcyjnej, jak i innej sprzedaży Obiektu dokonanej na portalu ON ARTE.
    2. Wszelkie zawiadomienia powinny być kierowane na piśmie na adres ON ARTE. Powiadomienia kierowane do Uczestników Aukcji (Kupujących), które wymagają ich wysłania za pośrednictwem operatora pocztowego będą przesyłane na adres korespondencyjny podany na koncie danej osoby utworzonym na portalu ON ARTE. Pozostała komunikacja pisemna będzie odbywać się za pomocą poczty E-Mail – analogicznie na adres wskazany w portalu danej osoby.
    3. W przypadku sporu sądowego – jeżeli stroną umowy sprzedaży jest przedsiębiorca, a umowa ta pozostaje w związku z jej działalnością – wyłącznie właściwym będzie Sąd właściwy rzeczowo i miejscowo dla siedziby ON ARTE sp. z o. o.
    4. ON ARTE wystawi z tytułu świadczonych przez siebie usług każdorazowo paragon fiskalny bądź fakturę VAT zgodnie z obowiązującymi przepisami prawa.`
    ]
  },
];
