import { DeliveryTypeEnum } from '@on-arte/core-types';
import { IsDefined, IsEnum, IsOptional } from 'class-validator';

export class UpdateDeliveryInCartAuctionModel {
  @IsDefined()
  @IsEnum(DeliveryTypeEnum)
  public deliveryType?: DeliveryTypeEnum;

  @IsOptional()
  public commentToSeller?: string;

  constructor(
    deliveryType?: DeliveryTypeEnum,
    commentToSeller?: string,
  ) {
    this.deliveryType = deliveryType;
    this.commentToSeller = commentToSeller;
  }
}
