import {
  AuctionDetailsInterface,
  AuctionTypeEnum,
  ItemSearchInterface,
  ItemStatusEnum,
  ListPaginationInterface,
  ThumbnailAttachmentTypeEnum
} from '@on-arte/core-types';
import {
  IconName,
  UseState,
  PaginationDetails,
  usePagination,
  getPathWithParams,
  useNotifications,
  UseNotifications,
  Color,
  useRedirect,
  UseRedirect,
  UseLogger,
  useLogger,
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { searchItems } from '@onArte/frontend/api/requests';
import { BaseUserMySalesView } from '@onArte/frontend/components';
import { QueryKey, UserMySalesPositions } from '@onArte/frontend/enums';
import { FrontendApiError } from '@onArte/frontend/models';
import { getImageThumbnail } from '@onArte/frontend/utils';
import { itemStatusConfig } from '@onArte/shared/constants';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getItemActiveAuctionPerTypes, getRouteDetailsByName } from '@onArte/shared/utils';

import {
  StyledArtworkBoxManage,
  StyledPagination,
} from './userSalesList.styled';
import { ArtworksBoxData } from './userSalesList.types';

export const UserSalesListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [artworks, setArtworks]: UseState<ArtworksBoxData[]> = useState<ArtworksBoxData[]>([]);
  const { setMaxItems, setPage, itemsPerPage, maxItems, offset }: PaginationDetails = usePagination();
  const { addToast }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const { logger }: UseLogger = useLogger();

  useQuery(
    [QueryKey.ArtworksList, offset],
    (): Promise<ListPaginationInterface<ItemSearchInterface>> => searchItems({ limit: itemsPerPage, offset }),
    {
      onSuccess: (data: ListPaginationInterface<ItemSearchInterface>): void => {
        setMaxItems(data.amount);
        setArtworks(data.list.map((artwork: ItemSearchInterface): ArtworksBoxData => {
          const activeAuction: AuctionDetailsInterface | undefined = getItemActiveAuctionPerTypes(
            artwork.auctions,
            [AuctionTypeEnum.Bidding, AuctionTypeEnum.BuyNow, AuctionTypeEnum.PriceProposal],
          );

          return {
            id: artwork.id,
            badges: [
              ...(!activeAuction && artwork.status === ItemStatusEnum.Approved
                ? [
                  {
                    label: t('onarte.common.itemSalesTypes.disabled'),
                    color: Color.Gray500,
                    background: Color.Gray200,
                  },
                ]
                : []
              ),
              ...(activeAuction?.type === AuctionTypeEnum.Bidding
                ? [
                  {
                    label: t('onarte.website.userSalesListView.customBadges.auction.label'),
                    color: Color.White,
                    background: Color.Error500,
                  },
                ]
                : []
              ),
              ...(artwork.canParticipateEvent && activeAuction?.type !== AuctionTypeEnum.Bidding
                ? [
                  {
                    label: t('onarte.website.userSalesListView.customBadges.canParticipateEvent.label'),
                    color: Color.Gray500,
                    background: Color.White,
                    border: Color.Gray300
                  },
                ]
                : []
              ),
              ...(activeAuction && [AuctionTypeEnum.BuyNow, AuctionTypeEnum.PriceProposal].includes(activeAuction.type)
                ? [
                  {
                    label: t(`onarte.common.itemSalesTypes.${activeAuction.type}`),
                    color: Color.Success500,
                    background: Color.Success100,
                  },
                ]
                : []
              ),
              ...(artwork.hasChangesToVerify
                ? [
                  {
                    label: t('onarte.common.itemStatuses.hasChangesToVerify'),
                    color: Color.Warning500,
                    background: Color.Warning100,
                  },
                ]
                : []
              ),
              {
                label: t(itemStatusConfig[artwork.status].label),
                color: itemStatusConfig[artwork.status].color,
                background: itemStatusConfig[artwork.status].background,
              },
            ],
            artworkDetails: {
              image: getImageThumbnail(artwork.image, ThumbnailAttachmentTypeEnum.Size_100x100),
              onClick: activeAuction
                ? ((): void => redirect(
                  getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/', { id: activeAuction.id })
                ))
                : undefined,
              boxTitleDetails: {
                author: artwork.manufacturer.name,
                itemDescriptiveAttributes: [artwork.label],
                itemName: artwork.name,
                startPrice: activeAuction?.type === AuctionTypeEnum.Bidding
                  ? activeAuction.startPrice ?? undefined
                  : undefined,
                minimalPrice: activeAuction?.type === AuctionTypeEnum.Bidding
                  ? activeAuction.acceptablePrice ?? undefined
                  : undefined,
                estimationPriceLow: activeAuction?.type === AuctionTypeEnum.Bidding
                  ? activeAuction?.estimation?.from
                  : undefined,
                estimationPriceHigh: activeAuction?.type === AuctionTypeEnum.Bidding
                  ? activeAuction?.estimation?.to
                  : undefined,
                price: (activeAuction?.type === AuctionTypeEnum.PriceProposal && !activeAuction?.price) 
                  ? undefined 
                  : activeAuction?.price,
                biddersNumber: activeAuction?.type === AuctionTypeEnum.Bidding
                  ? activeAuction?.offers.length 
                  : undefined,
              }
            },
            contextMenuPositions: [
              {
                label: t('onarte.website.meta.userArtworkDetails.title'),
                internalPath: getPathWithParams(
                  getRouteDetailsByName(RouteNameEnum.UserArtworkDetails)?.url ?? '/',
                  { id: artwork.id }
                ),
                icon: IconName.Description
              },
              ...(artwork.status === ItemStatusEnum.Approved
                ? [
                  {
                    label: t('onarte.website.meta.userArtworkSalesSettings.title'),
                    internalPath: getPathWithParams(
                      getRouteDetailsByName(RouteNameEnum.UserArtworkSalesSettings)?.url ?? '/',
                      { id: artwork.id }
                    ),
                    icon: IconName.Dollar
                  },
                  {
                    label: t('onarte.common.edit'),
                    internalPath: getPathWithParams(
                      getRouteDetailsByName(RouteNameEnum.UserArtworkEdit)?.url ?? '/',
                      { id: artwork.id }
                    ),
                    icon: IconName.Edit
                  },
                ]
                : []
              ),
              ...(activeAuction
                ? [
                  {
                    label: t('onarte.website.userSalesListView.showOffer'),
                    externalUrl: getPathWithParams(
                      getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/',
                      { id: activeAuction.id }
                    ),
                    shouldOpenInNewTab: true,
                    icon: IconName.Image
                  },
                ]
                : []
              ),
              // TODO: temporary hidden
              // {
              //  label: t('onarte.common.delete'),
              //  action: (): void => addToast({ content: t('onarte.common.functionalityCurrentlyNotAvailable') }),
              //  icon: IconName.Delete
              // },
            ]
          };
        }));
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.ArtworksList, error)
    }
  );

  return (
    <BaseUserMySalesView activePosition={UserMySalesPositions.UserSalesList} title={t('onarte.website.meta.userSalesList.title')}>
      {artworks.map((artwork: ArtworksBoxData): JSX.Element => (
        <StyledArtworkBoxManage
          key={artwork.id}
          {...artwork}
        />
      ))}
      <StyledPagination
        onPageChange={setPage}
        maxItems={maxItems}
      />
    </BaseUserMySalesView>
  );
};
