export enum QueryKey {
  Sliders = 'sliders',
  ArtworkDetails = 'artworkDetails',
  ArtworksList = 'artworksList',
  Cart = 'cart',
  ItemAttributes = 'itemAttributes',
  ItemCategories = 'itemCategories',
  ItemDetails = 'itemDetails',
  ItemManufacturers = 'itemManufacturers',
  AuctionDetails = 'auctionDetails',
  AuctionsList = 'auctionsList',
  AddressesList = 'addressesList',
  BiddingAuctionsList = 'BiddingAuctionsList',
  HighlightedBiddingAuctionsList = 'highlightedBiddingAuctionsList',
  HighlightedBuyNowAuctionsList = 'highlightedBuyNowAuctionsList',
  EventDetails = 'eventDetails',
  EventsList = 'eventsList',
  FinishedEventsList = 'finishedEventsList',
  CreditCards = 'creditCards',
  DeliveryOptionsForAuctions = 'deliveryOptionsForAuctions',
  SalesList = 'salesList',
  PurchasesList = 'purchasesList',
  ActiveOffersList = 'activeOffersList',
  FinishedOffersList = 'finishedOffersList',
  Settings = 'settings',
  Profile = 'profile',
  Agreements = 'agreements',
  Collection = 'collection',
  Collections = 'collections',
  Posts = 'posts',
  PublicProfileDetails = 'publicProfileDetails',
  PublicProfileItems = 'publicProfileItems',
  PublicProfilesList = 'publicProfilesList',
  PublicProfilesPaginationList = 'publicProfilesPaginationList',
  Search = 'search',
  DataForPayouts = 'dataForPayouts',
  FavouriteItemsList = 'favouriteItemsList',
  FavouriteManufacturersList = 'favouriteManufacturersList',
  ManufacturersForLoggedUser = 'manufacturersForLoggedUser',
  AllowedPublicProfiles = 'allowedPublicProfiles',
  BlueMediaGateways = 'blueMediaGateways'
}
