import { ArtistTitle, ArtworkDescription, Badge, Breakpoint, Color, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { EventPhotoBackgroundContainerProps } from './eventDetails.props';

export const Container: StyledComponent<'div'> = styled.div`
  max-width: 100%;
  padding-bottom: 40px;
`;

export const EventDetailsContainer: StyledComponent<'div'> = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 40px 0;

  @media ${Breakpoint.Tablet} {
    padding: 40px 32px 0;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 40px 20px 0;
  }
`;

export const StyledArtistTitle: StyledComponent<typeof ArtistTitle> = styled(ArtistTitle)`
  padding-bottom: 40px;
  border-bottom: 1px solid ${Color.Gray300};
`;

export const StyledArtworkDescription: StyledComponent<typeof ArtworkDescription> = styled(ArtworkDescription)`
  margin: 60px 0 120px 0;

  @media ${Breakpoint.Mobile} {
    margin-bottom: 80px;
  }
`;

export const EventPhotoBackgroundContainer: StyledComponent<'div', EventPhotoBackgroundContainerProps> 
  = styled.div<EventPhotoBackgroundContainerProps>`
    position: relative;
    max-width: 100%;
    height: 430px;
    background-image: ${({ $imgUrl }: EventPhotoBackgroundContainerProps): string => $imgUrl && `url(${$imgUrl})`};
    background-size: cover;
    background-position: center;
`;

export const StyledBadge: StyledComponent<typeof Badge> = styled(Badge)`
  position: absolute;
  bottom: 40px;
  left: 40px;

  @media ${Breakpoint.Tablet} {
    bottom: 32px;
    left: 32px;
  }

  @media ${Breakpoint.Mobile} {
    bottom: 20px;
    left: 20px;
  }
`;

export const NoArtworksMessage: StyledComponent<'div'> = styled.div`
  padding: 40px 40px 0;

  @media ${Breakpoint.Tablet} {
    padding: 40px 32px 0;
  }

  @media ${Breakpoint.Mobile} {
    text-align: center;
  }
`;

export const MosaicWrapper: StyledComponent<'div'> = styled.div`
  overflow: hidden;
  padding: 0 20px;

  @media ${Breakpoint.Tablet} {
    padding: 0 16px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 0 10px;
  }
`;
