import { ShippingAddressFormData } from '@on-arte/ui';

import { initialBaseAddressValues } from './initialBaseAddressValues.const';

export const initialShippingAddressValues: ShippingAddressFormData = {
  ...initialBaseAddressValues,
  firstName: '',
  lastName: '',
  phone: '',
};
