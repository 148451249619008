import { StyledComponent, PageTitle as BasePageTitle, Breakpoint } from '@on-arte/ui';
import styled from 'styled-components';

export const PageTitle: StyledComponent<typeof BasePageTitle> = styled(BasePageTitle)`
  margin: 0 40px 132px;
  padding-top: 40px;
  width: calc(100% - 80px);

  @media ${Breakpoint.Tablet} {
    margin: 0 32px 132px;
    width: calc(100% - 64px);
  }

  @media ${Breakpoint.Mobile} {
    margin: 0 20px 132px;
    width: calc(100% - 40px);
  }
`;
