import { RouteNameEnum } from '@onArte/shared/enums';
import { RouteInfo } from '@onArte/shared/interfaces';

export const plRouting: RouteInfo[] = [
  {
    name: RouteNameEnum.AboutUs,
    url: '/o-nas',
    title: 'onarte.website.meta.aboutUs.title',
    description: 'onarte.website.meta.aboutUs.description'
  },
  {
    name: RouteNameEnum.ArtistDetails,
    url: '/artysci/:id',
    title: 'onarte.website.meta.artistDetails.title',
    description: 'onarte.website.meta.artistDetails.description'
  },
  {
    name: RouteNameEnum.Artists,
    url: '/artysci',
    title: 'onarte.website.meta.artists.title',
    description: 'onarte.website.meta.artists.description'
  },
  {
    name: RouteNameEnum.ArtistsList,
    url: '/lista-artystow',
    title: 'onarte.website.meta.artistsList.title',
    description: 'onarte.website.meta.artistsList.description'
  },
  {
    name: RouteNameEnum.ArtworkDetails,
    url: '/dziela/:id',
    title: 'onarte.website.meta.artworkDetails.title',
    description: 'onarte.website.meta.artworkDetails.description'
  },
  {
    name: RouteNameEnum.ArtworksList,
    url: '/dziela',
    title: 'onarte.website.meta.artworksList.title',
    description: 'onarte.website.meta.artworksList.description'
  },
  {
    name: RouteNameEnum.CartBilling,
    url: '/zamowienie/:id/dane-rozliczeniowe',
    title: 'onarte.website.meta.cartBilling.title',
    description: 'onarte.website.meta.cartBilling.description',
    secured: true,
  },
  {
    name: RouteNameEnum.CartDelivery,
    url: '/zamowienie/:id/dostawa',
    title: 'onarte.website.meta.cartDelivery.title',
    description: 'onarte.website.meta.cartDelivery.description',
    secured: true,
  },
  {
    name: RouteNameEnum.Contact,
    url: '/kontakt',
    title: 'onarte.website.meta.contact.title',
    description: 'onarte.website.meta.contact.description'
  },
  {
    name: RouteNameEnum.CartMissingItem,
    url: '/brak-produktu',
    title: 'onarte.website.meta.cartMissingItem.title',
    description: 'onarte.website.meta.cartMissingItem.description',
    shouldHideInSeoFiles: true,
  },
  {
    name: RouteNameEnum.CartPaymentError,
    url: '/platnosc-odrzucona',
    title: 'onarte.website.meta.cartPaymentError.title',
    description: 'onarte.website.meta.cartPaymentError.description',
    shouldHideInSeoFiles: true,
  },
  {
    name: RouteNameEnum.CartSuccess,
    url: '/zamowienie-zlozone',
    title: 'onarte.website.meta.cartSuccess.title',
    description: 'onarte.website.meta.cartSuccess.description',
    shouldHideInSeoFiles: true,
  },
  {
    name: RouteNameEnum.ChoosePassword,
    url: '/ustaw-haslo/:token',
    title: 'onarte.website.meta.choosePassword.title',
    description: 'onarte.website.meta.choosePassword.description',
    shouldHideInSeoFiles: true,
  },
  {
    name: RouteNameEnum.CollectorDetails,
    url: '/kolekcjonerzy/:id',
    title: 'onarte.website.meta.collectorDetails.title',
    description: 'onarte.website.meta.collectorDetails.description'
  },
  {
    name: RouteNameEnum.CollectorsList,
    url: '/kolekcjonerzy',
    title: 'onarte.website.meta.collectorsList.title',
    description: 'onarte.website.meta.collectorsList.description'
  },
  {
    name: RouteNameEnum.Collection,
    url: '/art-drop/:id',
    title: 'onarte.website.meta.collection.title',
    description: 'onarte.website.meta.collection.description'
  },
  {
    name: RouteNameEnum.CollectionsList,
    url: '/art-drop',
    title: 'onarte.website.meta.collectionsList.title',
    description: 'onarte.website.meta.collectionsList.description'
  },
  {
    name: RouteNameEnum.EventDetails,
    url: '/aukcje/:id',
    title: 'onarte.website.meta.eventDetails.title',
    description: 'onarte.website.meta.eventDetails.description'
  },
  {
    name: RouteNameEnum.EventsList,
    url: '/aukcje',
    title: 'onarte.website.meta.eventsList.title',
    description: 'onarte.website.meta.eventsList.description'
  },
  {
    name: RouteNameEnum.HandbookBuyer,
    url: '/poradnik-kupujacego',
    title: 'onarte.website.meta.handbookBuyer.title',
    description: 'onarte.website.meta.handbookBuyer.description'
  },
  {
    name: RouteNameEnum.HandbookSeller,
    url: '/poradnik-sprzedajacego',
    title: 'onarte.website.meta.handbookSeller.title',
    description: 'onarte.website.meta.handbookSeller.description'
  },
  {
    name: RouteNameEnum.Faq,
    url: '/baza-wiedzy',
    title: 'onarte.website.meta.faq.title',
    description: 'onarte.website.meta.faq.description'
  },
  {
    name: RouteNameEnum.Home,
    url: '/',
    title: 'onarte.website.meta.home.title',
    description: 'onarte.website.meta.home.description'
  },
  {
    name: RouteNameEnum.Logout,
    url: '/wylogowanie',
    title: 'onarte.website.meta.logout.title',
    description: 'onarte.website.meta.logout.description',
    shouldHideInSeoFiles: true,
  },
  {
    name: RouteNameEnum.NotFound,
    url: '/nie-znaleziono',
    title: 'onarte.website.meta.notFound.title',
    description: 'onarte.website.meta.notFound.description',
    shouldHideInSeoFiles: true,
  },
  {
    name: RouteNameEnum.PriceProposalAccepted,
    url: '/akceptuj-oferte/:id',
    title: 'onarte.website.meta.priceProposalAccepted.title',
    description: 'onarte.website.meta.priceProposalAccepted.description',
    shouldHideInSeoFiles: true
  },
  {
    name: RouteNameEnum.PriceProposalRejected,
    url: '/odrzuc-oferte/:id',
    title: 'onarte.website.meta.priceProposalRejected.title',
    description: 'onarte.website.meta.priceProposalRejected.description',
    shouldHideInSeoFiles: true
  },
  {
    name: RouteNameEnum.PrivacyPolicy,
    url: '/polityka-prywatnosci',
    title: 'onarte.website.meta.privacyPolicy.title',
    description: 'onarte.website.meta.privacyPolicy.description',
    shouldHideInSeoFiles: true
  },
  {
    name: RouteNameEnum.Registration,
    url: '/rejestracja',
    title: 'onarte.website.meta.registration.title',
    description: 'onarte.website.meta.registration.description',
    shouldHideInSeoFiles: true
  },
  {
    name: RouteNameEnum.RegistrationConfirm,
    url: '/weryfikacja-rejestracji/:token',
    title: 'onarte.website.meta.registrationConfirm.title',
    description: 'onarte.website.meta.registrationConfirm.description',
    shouldHideInSeoFiles: true,
  },
  {
    name: RouteNameEnum.Regulations,
    url: '/regulamin',
    title: 'onarte.website.meta.regulations.title',
    description: 'onarte.website.meta.regulations.description',
    shouldHideInSeoFiles: true
  },
  {
    name: RouteNameEnum.RemindPassword,
    url: '/przypomnij-haslo',
    title: 'onarte.website.meta.remindPassword.title',
    description: 'onarte.website.meta.remindPassword.description',
    shouldHideInSeoFiles: true,
  },
  {
    name: RouteNameEnum.UserAccountSettings,
    url: '/ustawienia-konta',
    title: 'onarte.website.meta.userAccountSettings.title',
    description: 'onarte.website.meta.userAccountSettings.description',
    secured: true,
  },
  {
    name: RouteNameEnum.UserArtworkAdd,
    url: '/moja-kolekcja/dodaj-dzielo',
    title: 'onarte.website.meta.userArtworkAdd.title',
    description: 'onarte.website.meta.userArtworkAdd.description',
    secured: true,
  },
  {
    name: RouteNameEnum.UserArtworkDetails,
    url: '/moja-kolekcja/:id',
    title: 'onarte.website.meta.userArtworkDetails.title',
    description: 'onarte.website.meta.userArtworkDetails.description',
    secured: true,
  },
  {
    name: RouteNameEnum.UserArtworkEdit,
    url: '/moja-kolekcja/:id/edycja',
    title: 'onarte.website.meta.userArtworkEdit.title',
    description: 'onarte.website.meta.userArtworkEdit.description',
    secured: true,
  },
  {
    name: RouteNameEnum.UserArtworkSalesSettings,
    url: '/moja-kolekcja/:id/ustawienia-sprzedazy',
    title: 'onarte.website.meta.userArtworkSalesSettings.title',
    description: 'onarte.website.meta.userArtworkSalesSettings.description',
    secured: true,
  },
  {
    name: RouteNameEnum.UserBiddingsList,
    url: '/licytuje',
    title: 'onarte.website.meta.userBiddingsList.title',
    description: 'onarte.website.meta.userBiddingsList.description',
    secured: true,
  },
  {
    name: RouteNameEnum.UserDataForPayouts,
    url: '/dane-do-wplat',
    title: 'onarte.website.meta.userDataForPayouts.title',
    description: 'onarte.website.meta.userDataForPayouts.description',
    secured: true,
  },
  {
    name: RouteNameEnum.UserDeliverySettings,
    url: '/ustawienia-dostawy',
    title: 'onarte.website.meta.userDeliverySettings.title',
    description: 'onarte.website.meta.userDeliverySettings.description',
    secured: true,
  },
  {
    name: RouteNameEnum.UserFavoriteArtworks,
    url: '/ulubione',
    title: 'onarte.website.meta.userFavoriteArtworks.title',
    description: 'onarte.website.meta.userFavoriteArtworks.description',
    secured: true,
  },
  {
    name: RouteNameEnum.UserMyShopping,
    url: '/moje-zakupy',
    title: 'onarte.website.meta.userMyShopping.title',
    description: 'onarte.website.meta.userMyShopping.description',
    secured: true,
  },
  {
    name: RouteNameEnum.UserPublicProfileSettings,
    url: '/profile-publiczne/:contextId/:contextType/:contextName',
    title: 'onarte.website.meta.userPublicProfileSettings.title',
    description: 'onarte.website.meta.userPublicProfileSettings.description',
    secured: true,
  },
  {
    name: RouteNameEnum.UserSalesList,
    url: '/moja-sprzedaz',
    title: 'onarte.website.meta.userSalesList.title',
    description: 'onarte.website.meta.userSalesList.description',
    secured: true,
  },
  {
    name: RouteNameEnum.UserSoldArtworks,
    url: '/sprzedane-prace',
    title: 'onarte.website.meta.userSoldArtworks.title',
    description: 'onarte.website.meta.userSoldArtworks.description',
    secured: true,
  },
  {
    name: RouteNameEnum.Search,
    url: '/szukaj',
    title: 'onarte.website.meta.search.title',
    description: 'onarte.website.meta.search.description',
    shouldHideInSeoFiles: true
  },
  {
    name: RouteNameEnum.SignIn,
    url: '/logowanie',
    title: 'onarte.website.meta.signIn.title',
    description: 'onarte.website.meta.signIn.description',
    shouldHideInSeoFiles: true
  },
  {
    name: RouteNameEnum.StaticSubscriptionConfirmation,
    url: '/potwierdzenie-zapisu',
    staticViewPath: 'mailings/subscriptionConfirmation.html',
    title: '',
    description: '',
    shouldHideInSeoFiles: true
  },
  {
    name: RouteNameEnum.StaticUnsubscriptionConfirmation,
    url: '/rezygnacja-z-newslettera',
    staticViewPath: 'mailings/unsubscriptionConfirmation.html',
    title: '',
    description: '',
    shouldHideInSeoFiles: true
  },
  {
    name: RouteNameEnum.StaticSubscriptionError,
    url: '/blad-zapisu',
    staticViewPath: 'mailings/subscriptionError.html',
    title: '',
    description: '',
    shouldHideInSeoFiles: true
  },
];
