import { ReactionTypeEnum } from '@on-arte/core-types';
import { AxiosResponse } from 'axios';

import { UpdateReactionInterface } from '@onArte/frontend/interfaces/api';
import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { ExtendedManufacturerItem, ItemWithAuctionDetails } from '@onArte/shared/interfaces';

import { api } from '../api';

export const getFavouriteItems: (
  type: ReactionTypeEnum
) => Promise<ItemWithAuctionDetails[]> = (type: ReactionTypeEnum): Promise<ItemWithAuctionDetails[]> => new Promise(
  (resolve: ApiResolve<ItemWithAuctionDetails[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/reactions/items/${type}`).then(
      (response: AxiosResponse<ItemWithAuctionDetails[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getFavouriteManufacturers: (
  type: ReactionTypeEnum
) => Promise<ExtendedManufacturerItem[]> = (type: ReactionTypeEnum): Promise<ExtendedManufacturerItem[]> => new Promise(
  (resolve: ApiResolve<ExtendedManufacturerItem[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/reactions/manufacturers/${type}`).then(
      (response: AxiosResponse<ExtendedManufacturerItem[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const addToFavourites: (data: UpdateReactionInterface) => Promise<void> = (
  data: UpdateReactionInterface
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/reactions/${data.contextType}/${data.contextId}/${data.reactionType}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const removeFromFavourites: (
  data: UpdateReactionInterface
) => Promise<void> = (data: UpdateReactionInterface): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.delete(`/reactions/${data.contextType}/${data.contextId}/${data.reactionType}`).then((): void => resolve())
      .catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
