import { ArtworkBiddingBox, Color, FontWeight, Pagination, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const OffersContainer: StyledComponent<'div'> = styled.div`
  & + & {
    margin-top: 60px;
  }
`;

export const Header: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: ${Color.Black};
  margin-bottom: 32px;
`;

export const StyledPagination: StyledComponent<typeof Pagination> = styled(Pagination)`
  margin-top: 20px;
`;

export const StyledArtworkBiddingBox: StyledComponent<typeof ArtworkBiddingBox> = styled(ArtworkBiddingBox)`
  & + & {
    margin-top: 20px;
  }
`;
