import { Color, FontWeight, Pagination, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const Header: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.01em;
  margin-bottom: 32px;
  color: ${Color.Black};
`;

export const ArtworkBoxSoldSummaryWrapper: StyledComponent<'div'> = styled.div`
  & + & {
    margin-top: 20px;
  }
`;

export const StyledPagination: StyledComponent<typeof Pagination> = styled(Pagination)`
  margin-top: 20px;
`;
