import { LocationModel } from '@on-arte/core-types';
import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDefined,
  IsEnum,
  IsNotEmptyObject,
  IsNumber, IsObject, IsOptional, IsPositive, ValidateNested } from 'class-validator';

import { ItemSaleEditStatus } from '@onArte/shared/enums';

export class UpdateAuctionRequestModel {
  @IsDefined()
  @IsEnum(ItemSaleEditStatus)
  public type: ItemSaleEditStatus;

  @IsDefined()
  @IsBoolean()
  public canParticipateEvent: boolean;

  @IsDefined()
  @IsNotEmptyObject()
  @IsObject()
  @ValidateNested()
  @Type(() => LocationModel)
  public location: LocationModel;

  @IsOptional()
  @IsNumber()
  @IsPositive()
  public acceptablePrice?: number;

  constructor(
    type: ItemSaleEditStatus,
    canParticipateEvent: boolean,
    location: LocationModel,
    acceptablePrice?: number,
  ) {
    this.type = type;
    this.canParticipateEvent = canParticipateEvent;
    this.location = location;
    this.acceptablePrice = acceptablePrice;
  }
}
