import { RangeInterface } from '@on-arte/core-types';

export const rangeFilters: Record<'price' | 'size', Record<string, Partial<RangeInterface>>> = {
  price: {
    '1-2500': { from: 1, to: 2500 },
    '2500-5000': { from: 2500, to: 5000 },
    '5000-10000': { from: 5000, to: 10000 },
    'over-10000': { from: 10000 }
  },
  size: {
    'small': { to: 50 },
    'medium': { from: 50, to: 100 },
    'large': { from: 100 }
  }
};
