/* eslint-disable max-lines */
import {
  AttachmentInterface,
  AttachmentTypeEnum,
  AttributeNameEnum,
  AuctionInfoInterface,
  AuctionOfferAddedInterface,
  AuctionSoldInterface,
  AuctionTypeEnum,
  CartAuctionInterface,
  CategoryAttributeInterface,
  CategoryWithAttributesInterface,
  DeliveryOptionInterface,
  ItemAttributeInterface,
  ListPaginationInterface,
  SettingsInterface,
  StepPriceInterface,
} from '@on-arte/core-types';
import {
  ArtworkSummaryTheme,
  AttributeWithValue,
  BoxSlide,
  getPathWithParams,
  Language,
  useFormatDate,
  UseFormatDate,
  UseLogger,
  useLogger,
  UseNotifications,
  useNotifications,
  UseParsers,
  useParsers,
  UseRedirect,
  useRedirect,
  UseState
} from '@on-arte/ui';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from 'react-query';

import { 
  addCartItem, 
  getAuctionDetails,
  getAuctions, 
  getItemsCategories, 
  getSettings, 
  makeOffer 
} from '@onArte/frontend/api/requests';
import { ArtworkDescriptionEnum, FrontendResponseCode, QueryKey } from '@onArte/frontend/enums';
import { useAuth, useBasket, useConversions, useItemsHelpers, useObjectsTransformations, useSocket } from '@onArte/frontend/hooks';
import { ItemsHelpers, UseAuth, UseBasket, UseConversions, UseObjectsTranformations, UseSocket } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { emptyRequest, findAttributeValueByName, findStepPriceElement } from '@onArte/frontend/utils';
import { RouteNameEnum, WebSocketCommand } from '@onArte/shared/enums';
import { AuctionPublicDetails, RouteInfo } from '@onArte/shared/interfaces';
import { WebSocketSubscriptionDetails } from '@onArte/shared/models';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { BiddingExamples, UseArtworkDetails } from './artworkDetails.types';

export const useArtworkDetails: (artworkId?: string, currentRouteObject?: RouteInfo) => UseArtworkDetails = (
  artworkId: string = '', currentRouteObject?: RouteInfo
): UseArtworkDetails => {
  const [artworkDetailsData, setArtworkDetailsData]: UseState<AuctionPublicDetails | null> = useState<AuctionPublicDetails | null>(null);
  const [artworkPhotos, setArtworkPhotos]: UseState<string[]> = useState<string[]>([]);
  const [commissionPercent, setCommissionPercent]: UseState<number> = useState<number>(0);
  const [popupValidationMessage, setPopupValidationMessage]: UseState<string> = useState<string>('');
  const [stepPrices, setStepPrices]: UseState<StepPriceInterface[]> = useState<StepPriceInterface[]>([]);
  const [categories, setCategories]: UseState<CategoryWithAttributesInterface[]> = useState<CategoryWithAttributesInterface[]>([]);
  const [authorMoreArtworks, setAuthorMoreArtworks]: UseState<BoxSlide[]> = useState<BoxSlide[]>([]);
  const { logger }: UseLogger = useLogger();
  const { getAttributeValueByType }: ItemsHelpers = useItemsHelpers();
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { addToast }: UseNotifications = useNotifications();
  const { isUserLoggedIn, userData }: UseAuth = useAuth();
  const { cartData }: UseBasket = useBasket();
  const { transformAuctionToBoxSlideType }: UseObjectsTranformations = useObjectsTransformations();
  const { parsePrice }: UseParsers = useParsers();
  const [isPopupVisible, setIsPopupVisible]: UseState<boolean> = useState<boolean>(false);
  const [inputValue, setInputValue]: UseState<string> = useState<string>('');
  const [artworkSummaryTheme, setArtworkSummaryTheme]: UseState<ArtworkSummaryTheme>
    = useState<ArtworkSummaryTheme>(ArtworkSummaryTheme.BuyNow);
  const offerPrice: number = useMemo((): number => parseFloat(inputValue), [inputValue]);
  const { socket, connectedToSocket, setIsConnectedToSocket }: UseSocket = useSocket();
  const { formatDate }: UseFormatDate = useFormatDate(Language.Pl);
  const auctionsIdsToNotUnsubscribe: MutableRefObject<string[]> = useRef([]);
  const currency: string = 'zł';
  const { sendAddToCartEvent }: UseConversions = useConversions();

  useEffect(
    (): void => {
      if (!socket || connectedToSocket || !artworkDetailsData || !artworkId || currentRouteObject?.name !== RouteNameEnum.ArtworkDetails) {
        return;
      }

      setIsConnectedToSocket();
      socket.emit(WebSocketCommand.SubscribeAuction, { id: artworkId } as WebSocketSubscriptionDetails);
      socket.on(WebSocketCommand.AuctionOfferAdded, (data: AuctionOfferAddedInterface): void => {
        setArtworkDetailsData({ ...artworkDetailsData, price: data.offer.price });
        if (data.offer.user.id !== userData?.id) {
          addToast({ content: t('onarte.website.artworkDetailsView.auctionOfferAdded', { price: `${data.offer.price} ${currency}`}) });
        }
      });
      socket.on(WebSocketCommand.AuctionOfferUpdatedEndDatetime, (data: AuctionInfoInterface): void => {
        if (data.validity?.to) {
          setArtworkDetailsData({ ...artworkDetailsData, validity: data.validity });
          addToast({
            content: t(
              'onarte.website.artworkDetailsView.auctionOfferUpdatedEndDatetime',
              { date: formatDate(data.validity.to, 'DD.MM.YY HH:mm:ss') }
            )
          });
        }
      });
      socket.on(WebSocketCommand.AuctionSold, (data: AuctionSoldInterface): void => {
        void auctionDetailsQuery.refetch().then((): void => {
          const translationKeyType: string = data.auction.type === AuctionTypeEnum.Bidding ? 'bidding' : 'otherSales';
          addToast({
            content: t(
              data.buyer.id === userData?.id
                ? `onarte.website.artworkDetailsView.auctionSold.${translationKeyType}.toMe`
                : `onarte.website.artworkDetailsView.auctionSold.${translationKeyType}.toOther`,
              { name: data.auction.name }
            )
          });
        });
      });
    },
    [socket, artworkDetailsData, artworkId, currentRouteObject]
  );

  useEffect(
    (): (() => void) => {
      return (): void => {
        if (socket) {
          if (!auctionsIdsToNotUnsubscribe.current.includes(artworkId)) {
            socket.emit(WebSocketCommand.UnsubscribeAuction, { id: artworkId } as WebSocketSubscriptionDetails);
          }
        }
      };
    },
    [socket]
  );

  useEffect(
    (): (() => void) => {
      return (): void => {
        if (socket) {
          socket.off(WebSocketCommand.AuctionOfferAdded);
          socket.off(WebSocketCommand.AuctionOfferUpdatedEndDatetime);
          socket.off(WebSocketCommand.AuctionSold);
        }
      };
    },
    [socket]
  );

  useEffect(
    (): void => {
      if (cartData) {
        auctionsIdsToNotUnsubscribe.current = cartData.auctions
          .map((cartAuction: CartAuctionInterface): string => cartAuction.auction.auctionId);
      }
    },
    [cartData?.auctions]
  );

  useQuery(
    [QueryKey.ItemCategories],
    (): Promise<CategoryWithAttributesInterface[]> => getItemsCategories(),
    {
      onSuccess: (data: CategoryWithAttributesInterface[]): void => setCategories(data),
      onError: (error: FrontendApiError): void => logger(QueryKey.ItemCategories, error)
    }
  );

  useQuery(
    [QueryKey.Settings],
    (): Promise<SettingsInterface> => getSettings(),
    {
      onSuccess: (data: SettingsInterface): void => {
        setCommissionPercent(data.commissionForBidding);
        setStepPrices(data.stepPrices);
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.Settings, error)
    }
  );

  const getAttachmentsByType: (attachments: AttachmentInterface[], type: AttachmentTypeEnum) => AttachmentInterface[] = (
    attachments: AttachmentInterface[], type: AttachmentTypeEnum
  ): AttachmentInterface[] => {
    return attachments.filter((attachment: AttachmentInterface): boolean => attachment.type === type);
  };

  const auctionDetailsQuery: UseQueryResult = useQuery(
    [QueryKey.AuctionDetails, artworkId],
    (): Promise<AuctionPublicDetails | void> => artworkId && currentRouteObject?.name === RouteNameEnum.ArtworkDetails
      ? getAuctionDetails(artworkId)
      : emptyRequest(),
    {
      onSuccess: (data: AuctionPublicDetails | undefined): void => {
        if (!data) {
          return;
        }

        const photos: AttachmentInterface[] = [
          ...getAttachmentsByType(data.itemDetails.attachments, AttachmentTypeEnum.ItemPhoto),
          ...getAttachmentsByType(data.itemDetails.attachments, AttachmentTypeEnum.ItemCertificate),
          ...getAttachmentsByType(data.itemDetails.attachments, AttachmentTypeEnum.ItemSignature),
        ];
        setArtworkPhotos(photos.map((attachment: AttachmentInterface): string => attachment.path));
        setArtworkDetailsData(data);
        setArtworkSummaryTheme(data.type === AuctionTypeEnum.PriceProposal
          ? ArtworkSummaryTheme.MakeOffer
          : data.type === AuctionTypeEnum.Bidding
            ? ArtworkSummaryTheme.Auction
            : ArtworkSummaryTheme.BuyNow
        );
      },
      onError: (error: FrontendApiError): void => {
        logger(QueryKey.AuctionDetails, error);
        if (error.responseCode === FrontendResponseCode.NotFound) {
          redirect(getRouteDetailsByName(RouteNameEnum.NotFound)?.url ?? '/');
          return;
        } else {
          addToast({ content: t(error.message) });
        }
      }
    }
  );

  useQuery(
    [QueryKey.AuctionsList, artworkId],
    (): Promise<ListPaginationInterface<AuctionInfoInterface>> => getAuctions(
      { limit: 100, offset: 0, manufacturerIds: [artworkDetailsData?.itemDetails.manufacturer.id ?? ''] }
    ),
    {
      enabled: !!artworkDetailsData?.itemDetails.manufacturer.id && !!artworkId,
      onSuccess: (data: ListPaginationInterface<AuctionInfoInterface>): void => setAuthorMoreArtworks(
        data.list
          .filter((auction: AuctionInfoInterface): boolean => auction.id !== artworkId)
          .map(transformAuctionToBoxSlideType)
      ),
      onError: (error: FrontendApiError): void => logger(QueryKey.AuctionsList, error),
    }
  );

  const categoryAttributes: CategoryAttributeInterface[] = useMemo((): CategoryAttributeInterface[] => {
    return categories.find((category: CategoryWithAttributesInterface) => (
      category.name === artworkDetailsData?.itemDetails.category.name)
    )?.attributes ?? [];
  }, [artworkDetailsData?.itemDetails.category, categories]);

  const attributesWithValues: AttributeWithValue[] = useMemo((): AttributeWithValue[] => {
    return artworkDetailsData?.itemDetails?.attributes?.map((attribute: ItemAttributeInterface): AttributeWithValue => {
      const attributeProperties: CategoryAttributeInterface | undefined = categoryAttributes.find(
        (attributeItem: CategoryAttributeInterface): boolean => attributeItem.id === attribute.attributeId
      );

      return {
        label: t(attributeProperties?.label ?? ''),
        value: attributeProperties
          ? getAttributeValueByType(attribute.value, attributeProperties)
          : '',
      };
    }) ?? [];
  }, [categoryAttributes, artworkDetailsData]);

  const artworkDimensions: string = useMemo((): string => {
    const height: string | undefined = findAttributeValueByName(
      artworkDetailsData?.itemDetails.attributes ?? [],
      categoryAttributes,
      AttributeNameEnum.Height
    );
    const width: string | undefined = findAttributeValueByName(
      artworkDetailsData?.itemDetails.attributes ?? [],
      categoryAttributes,
      AttributeNameEnum.Width
    );

    return height && width
      ? `${height} x ${width}cm`
      : '';
  }, [artworkDetailsData, categoryAttributes]);

  const buyNowAction: () => void = (): void => {
    if (isUserLoggedIn()) {
      if (cartData && artworkDetailsData) {
        addCartItem(cartData.id, artworkDetailsData.id)
          .then((): void => {
            sendAddToCartEvent(artworkDetailsData.id, cartData.id, artworkDetailsData.itemDetails.name, artworkDetailsData.price ?? null);
            redirect(getPathWithParams(getRouteDetailsByName(RouteNameEnum.CartDelivery)?.url ?? '/', { id: cartData.id ?? '' }));
          })
          .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
      }
    } else {
      redirect(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/', { withReplaceState: true });
    }
  };

  const openPopupAction: (value?: string) => void = (value?: string): void => {
    if (isUserLoggedIn()) {
      if (value && artworkDetailsData?.type === AuctionTypeEnum.Bidding) {
        setInputValue(value);
        setIsPopupVisible(true);
      } else if (artworkDetailsData?.type === AuctionTypeEnum.PriceProposal) {
        setIsPopupVisible(true);
      }
    } else {
      redirect(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/', { withReplaceState: true });
    }
  };

  const getBiddingExamples: (stepPrice: StepPriceInterface | undefined) => BiddingExamples = (
    stepPrice: StepPriceInterface | undefined
  ): BiddingExamples => {
    if (artworkDetailsData?.price && stepPrice) {
      const lowerDivisibleValue: number = Math.floor(offerPrice / stepPrice.value) * stepPrice.value;
      const higherDivisibleValue: number = Math.ceil(offerPrice / stepPrice.value) * stepPrice.value;
      if (lowerDivisibleValue > artworkDetailsData.price) {
        return { lowerValue: lowerDivisibleValue, higherValue: higherDivisibleValue, stepPrice: stepPrice.value };
      } else {
        return { lowerValue: higherDivisibleValue, higherValue: higherDivisibleValue + stepPrice.value, stepPrice: stepPrice.value };
      }
    } else {
      return { lowerValue: 0, higherValue: 0, stepPrice: 0 };
    }
  };

  const makeBiddingOfferAction: () => void = (): void => {
    if (artworkId && offerPrice && artworkDetailsData?.price) {
      const stepPrice: StepPriceInterface | undefined = findStepPriceElement(stepPrices, offerPrice);
      const biddingExamples: BiddingExamples = getBiddingExamples(stepPrice);
      
      if (offerPrice <= artworkDetailsData.price) {
        setPopupValidationMessage(t('onarte.website.artworkDetailsView.wrongBiddingValue'));
      } else if (stepPrice && offerPrice % stepPrice.value !== 0) {
        setPopupValidationMessage(`${t('onarte.website.artworkDetailsView.notDivisibleByStepPrices', biddingExamples)}`);
      } else {
        makeOffer(artworkId, { price: offerPrice })
          .then((): void => {
            addToast({ content: t('onarte.website.artworkDetailsView.offerPrice', { currency, value: offerPrice }) });
            setIsPopupVisible(false);
            redirect(getRouteDetailsByName(RouteNameEnum.UserBiddingsList)?.url ?? '/');
          })
          .catch((error: FrontendApiError): void => {
            setPopupValidationMessage(t(error.message));
          });
      }
    }
  };

  const makePriceProposalOfferAction: () => void = (): void => {
    if (!offerPrice) {
      setPopupValidationMessage(t('onarte.website.artworkDetailsView.tooSmallValue'));
    }

    if (artworkId && offerPrice && artworkDetailsData) {      
      makeOffer(artworkId, { price: offerPrice })
        .then((): void => {
          addToast({ content: t('onarte.website.artworkDetailsView.offerPrice', { currency, value: offerPrice }) });
          setIsPopupVisible(false);
        })
        .catch((error: FrontendApiError): void => {
          setPopupValidationMessage(t(error.message));
        });
    }
  };

  const onPopupInputChange: (value: string) => void = (value: string): void => {
    setInputValue(value);
    setPopupValidationMessage('');
  };

  const nextPrice: number | undefined = useMemo((): number | undefined => {
    if (!artworkDetailsData?.price) {
      return;
    }

    const stepPriceElement: StepPriceInterface | undefined = findStepPriceElement(stepPrices, artworkDetailsData.price);
    if (stepPriceElement) {
      return Math.round((artworkDetailsData.price + stepPriceElement.value) / stepPriceElement.value) * stepPriceElement.value;
    }
  }, [artworkDetailsData?.price]);

  const artworkDescriptionAttributes: Record<ArtworkDescriptionEnum, AttributeWithValue> = useMemo(
    (): Record<ArtworkDescriptionEnum, AttributeWithValue> => ({
      [ArtworkDescriptionEnum.Location]: {
        label: t('onarte.website.useArtworkDetails.artworkDescriptionAttributes.location.label'),
        value: artworkDetailsData?.itemDetails.location.address.city ?? ''
      },
      // TODO update with api
      [ArtworkDescriptionEnum.Contact]: {
        label: t('onarte.website.useArtworkDetails.artworkDescriptionAttributes.contact.label'),
        value: '+48 61 111 0183'
      },
      [ArtworkDescriptionEnum.Return]: {
        label: t('onarte.website.useArtworkDetails.artworkDescriptionAttributes.return.label'),
        value: '14 dni na zwrot'
      },
      [ArtworkDescriptionEnum.DeliveryTime]: {
        label: t('onarte.website.useArtworkDetails.artworkDescriptionAttributes.deliveryTime.label'),
        value: '48h od momentu nadania'
      },
      [ArtworkDescriptionEnum.Delivery]: {
        label: t('onarte.website.useArtworkDetails.artworkDescriptionAttributes.delivery.label'),
        value: artworkDetailsData?.itemDetails.deliveryOptions
          .map((delivery: DeliveryOptionInterface): string => `${t(delivery.name)}: ${parsePrice(delivery.price)} zł`)
          .join('\n') ?? '',
      },
      [ArtworkDescriptionEnum.Commission]: {
        label: t('onarte.website.useArtworkDetails.artworkDescriptionAttributes.commission.label'),
        value: t('onarte.website.useArtworkDetails.artworkDescriptionAttributes.commission.value')
      }
    }),
    [artworkDetailsData]
  );

  return {
    artworkSummaryTheme,
    attributesWithValues,
    artworkDimensions,
    buyNowAction,
    openPopupAction,
    makeBiddingOfferAction,
    makePriceProposalOfferAction,
    onPopupInputChange,
    nextPrice,
    popupValidationMessage,
    artworkDetailsData,
    artworkPhotos,
    offerPrice,
    commissionPercent,
    isPopupVisible,
    setIsPopupVisible,
    inputValue,
    authorMoreArtworks,
    artworkDescriptionAttributes
  };
};
