import {
  AttachmentTypeEnum,
  AuctionTypeEnum,
  ExternalAttachmentInterface,
  ReactionTypeEnum,
  ReactionUsageContextTypeEnum,
  ThumbnailAttachmentTypeEnum,
  UploadedAttachmentInterface
} from '@on-arte/core-types';
import {
  ArtworkMosaicItemDetails,
  ItemsMosaic,
  ItemsMosaicTheme,
  UseState,
  BoxSlide,
  BoxesSliderSlideSize,
  useLogger,
  UseLogger,
  UseNotifications,
  useNotifications,
  getPathWithParams
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery, QueryObserverResult } from 'react-query';

import { getFavouriteItems, getFavouriteManufacturers, removeFromFavourites } from '@onArte/frontend/api/requests';
import { BaseViewWithTabs, BoxesSliderSection } from '@onArte/frontend/components';
import { QueryKey } from '@onArte/frontend/enums';
import { FrontendApiError } from '@onArte/frontend/models';
import { getImageThumbnail } from '@onArte/frontend/utils';
import { RouteNameEnum } from '@onArte/shared/enums';
import { ExtendedManufacturerItem, ItemWithAuctionDetails } from '@onArte/shared/interfaces';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { Section, SectionTitle, StyledMessageBox } from './userFavoriteArtworks.styled';

export const UserFavoriteArtworksView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const { addToast }: UseNotifications = useNotifications();
  const [favouriteManufacturers, setFavouriteManufacturers]: UseState<BoxSlide[]> = useState<BoxSlide[]>([]);
  const [favouriteItems, setFavouriteItems]: UseState<ArtworkMosaicItemDetails[]> = useState<ArtworkMosaicItemDetails[]>([]);

  const { refetch: refetchFavouriteItems }: QueryObserverResult = useQuery(
    [QueryKey.FavouriteItemsList],
    (): Promise<ItemWithAuctionDetails[]> => getFavouriteItems(ReactionTypeEnum.Like),
    {
      onSuccess: (data: ItemWithAuctionDetails[]): void => {
        const favouriteItemsDetails: ArtworkMosaicItemDetails[] = data.map((item: ItemWithAuctionDetails): ArtworkMosaicItemDetails => ({
          image: getImageThumbnail(item.attachment, ThumbnailAttachmentTypeEnum.Size_340xAuto),
          internalPath: item.auctionId
            ? item.route?.prettyUrl
              ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/', { id: item.auctionId ?? '' })
            : undefined,
          action: !item.auctionId
            ? (): void => addToast({ content: t('onarte.common.mosaicItems.noAuction') })
            : undefined,
          boxTitleDetails: {
            author: item.manufacturer.name,
            itemName: item.name,
            price: item.auctionType === AuctionTypeEnum.PriceProposal ? undefined : item.price,
            bottomButtonLabel: item.auctionType === AuctionTypeEnum.PriceProposal ? t('onarte.common.priceProposal') : undefined,
            itemDescriptiveAttributes: [item.label]
          },
          isLiked: true,
          onLikeClick: (): Promise<void> => removeFromFavourites({
            contextType: ReactionUsageContextTypeEnum.Item,
            contextId: item.itemId,
            reactionType: ReactionTypeEnum.Like
          })
            .then((): void => void refetchFavouriteItems())
            .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }))
        }));

        setFavouriteItems(favouriteItemsDetails);
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.FavouriteItemsList, error)
    }
  );

  const { refetch: refetchFavouriteManufacturers }: QueryObserverResult = useQuery(
    [QueryKey.FavouriteManufacturersList],
    (): Promise<ExtendedManufacturerItem[]> => getFavouriteManufacturers(ReactionTypeEnum.Like),
    {
      onSuccess: (data: ExtendedManufacturerItem[]): void => {
        const favouriteManufacturersDetails: BoxSlide[] = data.map((manufacturer: ExtendedManufacturerItem): BoxSlide => {
          const filteredAttachments: (
            ExternalAttachmentInterface | UploadedAttachmentInterface
          )[] = manufacturer.publicProfileDetails?.attachments
            .filter((
              img: ExternalAttachmentInterface | UploadedAttachmentInterface
            ): boolean => img.type === AttachmentTypeEnum.PublicProfileAvatar) ?? [];

          return {
            image: getImageThumbnail(filteredAttachments[0], ThumbnailAttachmentTypeEnum.Size_340xAuto),
            internalPath: manufacturer.publicProfileDetails?.id
              ? manufacturer.route?.prettyUrl
                ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtistDetails)?.url ?? '', {
                  id: manufacturer.publicProfileDetails?.id ?? ''
                })
              : undefined,
            boxTitleDetails: {
              author: manufacturer.name,
              bottomButtonLabel: t('onarte.website.userFavoriteArtworksView.unfollowButtonLabel'),
              bottomButtonOnClick: (): Promise<void> => removeFromFavourites({
                contextType: ReactionUsageContextTypeEnum.Manufacturer,
                contextId: manufacturer.id,
                reactionType: ReactionTypeEnum.Like
              })
                .then((): void => void refetchFavouriteManufacturers())
                .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }))
            }
          };
        });

        setFavouriteManufacturers(favouriteManufacturersDetails);
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.FavouriteManufacturersList, error)
    }
  );

  return (
    <BaseViewWithTabs withContentWithoutPadding>
      <Section>
        <SectionTitle>{t('onarte.website.userFavoriteArtworksView.favouriteItems')}</SectionTitle>
        {!favouriteItems?.length ? (
          <StyledMessageBox message={t('onarte.website.userFavoriteArtworks.messages.noItems')} />
        ) : (
          <ItemsMosaic items={favouriteItems} theme={ItemsMosaicTheme.Mosaic} />
        )}
      </Section>
      <BoxesSliderSection
        header={t('onarte.website.userFavoriteArtworksView.favouriteManufacturers')}
        slides={favouriteManufacturers}
        slideSize={BoxesSliderSlideSize.Big}
        noSlidesMessage={t('onarte.website.userFavoriteArtworks.messages.noManufacturers')}
        withSliderWithoutMargin
      />
    </BaseViewWithTabs>
  );
};
