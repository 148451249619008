import { Breakpoint, HighlightSection, QuoteCarousel, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { BoxesSliderSection, NewsBoxesSection } from '@onArte/frontend/components';

import { CollectionSectionWithTitle } from './components';

export const Container: StyledComponent<'div'> = styled.div``;

export const StyledBoxesSliderSection: StyledComponent<typeof BoxesSliderSection> = styled(BoxesSliderSection)`
  margin-top: 120px;

  @media ${Breakpoint.SmallMobile} {
    margin-top: 80px;
  }
`;

export const StyledCollectionSectionWithTitle: StyledComponent<typeof CollectionSectionWithTitle> = styled(CollectionSectionWithTitle)`
  margin: 120px 0 200px;

  @media ${Breakpoint.SmallMobile} {
    margin: 80px 0 120px;
  }
`;

export const StyledQuoteCarousel: StyledComponent<typeof QuoteCarousel> = styled(QuoteCarousel)`
  margin: 0 auto;

  @media ${Breakpoint.Tablet} {
    padding: 0 32px 120px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 20px 120px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 0 20px 80px;
  }
`;

export const StyledHighlightSection: StyledComponent<typeof HighlightSection> = styled(HighlightSection)`
  margin-bottom: 120px;
  margin-top: 78px;
`;

export const StyledNewsBoxesSection: StyledComponent<typeof NewsBoxesSection> = styled(NewsBoxesSection)`
  margin: 120px auto;

  @media ${Breakpoint.SmallMobile} {
    margin: 80px auto;
  }
`;
