/* eslint-disable max-len */
import { AccordionItemInterface, DirectionEnum, NewsSectionData, useRedirect, UseRedirect } from '@on-arte/ui';
import { TransProps, useTranslation } from 'react-i18next';

import { FileFromViews } from '@onArte/frontend/enums';
import { UseHandbook } from '@onArte/frontend/interfaces';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const useHandbookBuyer: () => UseHandbook = (): UseHandbook => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();

  const newsSections: NewsSectionData[] = [
    {
      title: t('onarte.website.handbookBuyer.buyerNewsSection.createAccount.title'),
      description: t('onarte.website.handbookBuyer.buyerNewsSection.createAccount.description'),
      buttonLabel: t('onarte.website.handbookBuyer.buyerNewsSection.createAccount.buttonLabel'),
      buttonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.Registration)?.url ?? '/'),
      image: FileFromViews.CreateAccount,
      imagePosition: DirectionEnum.Right
    },
    {
      title: t('onarte.website.handbookBuyer.buyerNewsSection.artwork.title'),
      description: t('onarte.website.handbookBuyer.buyerNewsSection.artwork.description'),
      buttonLabel: t('onarte.website.handbookBuyer.buyerNewsSection.artwork.buttonLabel'),
      buttonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.CollectionsList)?.url ?? '/'),
      image: FileFromViews.Artwork,
      imagePosition: DirectionEnum.Left
    },
    {
      title: t('onarte.website.handbookBuyer.buyerNewsSection.artworksList.title'),
      description: t('onarte.website.handbookBuyer.buyerNewsSection.artworksList.description'),
      buttonLabel: t('onarte.website.handbookBuyer.buyerNewsSection.artworksList.buttonLabel'),
      buttonAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/'),
      image: FileFromViews.ArtworksList,
      imagePosition: DirectionEnum.Right
    },
    {
      title: t('onarte.website.handbookBuyer.buyerNewsSection.shipping.title'),
      description: t('onarte.website.handbookBuyer.buyerNewsSection.shipping.description'),
      image: FileFromViews.Shipping,
      imagePosition: DirectionEnum.Left
    },
    {
      title: t('onarte.website.handbookBuyer.buyerNewsSection.artworksSettings.title'),
      description: t('onarte.website.handbookBuyer.buyerNewsSection.artworksSettings.description'),
      image: FileFromViews.ArtworkSettings,
      imagePosition: DirectionEnum.Right
    },
  ];

  const faqContent: AccordionItemInterface[] = [
    {
      title: 'Jak szybko otrzymam zamówiony przedmiot?',
      content: 'His works encompass what a tender dance between color and form would look like. Highly realistic yet minimal, her painted figures naturally coexist among sharp spontaneous geometry. She paints to create an intense, desirable, and euphoric mood. The femme, her primary  subject, dominates and inspires her work. \n\n The body becomes a series of shapes that come together as an intricate puzzle that requires an audience to put in place. The work deconstructs the beautiful and recomposes it with a new armor that resonates an indestructible landscape. \n\n His works encompass what a tender dance between color and form would look like. Highly realistic yet minimal, her painted figures naturally coexist among sharp spontaneous geometry. \n\n The body becomes a series of shapes that come together as an intricate puzzle that requires an audience to put in place. The work deconstructs the beautiful and recomposes.'
    },
    {
      title: 'Czy zakupy są bezpieczne?',
      content: 'His works encompass what a tender dance between color and form would look like. Highly realistic yet minimal, her painted figures naturally coexist among sharp spontaneous geometry. She paints to create an intense, desirable, and euphoric mood. The femme, her primary  subject, dominates and inspires her work. \n\n The body becomes a series of shapes that come together as an intricate puzzle that requires an audience to put in place. The work deconstructs the beautiful and recomposes it with a new armor that resonates an indestructible landscape. \n\n His works encompass what a tender dance between color and form would look like. Highly realistic yet minimal, her painted figures naturally coexist among sharp spontaneous geometry. \n\n The body becomes a series of shapes that come together as an intricate puzzle that requires an audience to put in place. The work deconstructs the beautiful and recomposes.'
    },
    {
      title: 'Kiedy mogę licytować?',
      content: 'His works encompass what a tender dance between color and form would look like. Highly realistic yet minimal, her painted figures naturally coexist among sharp spontaneous geometry. She paints to create an intense, desirable, and euphoric mood. The femme, her primary  subject, dominates and inspires her work. \n\n The body becomes a series of shapes that come together as an intricate puzzle that requires an audience to put in place. The work deconstructs the beautiful and recomposes it with a new armor that resonates an indestructible landscape. \n\n His works encompass what a tender dance between color and form would look like. Highly realistic yet minimal, her painted figures naturally coexist among sharp spontaneous geometry. \n\n The body becomes a series of shapes that come together as an intricate puzzle that requires an audience to put in place. The work deconstructs the beautiful and recomposes.'
    },
    {
      title: 'Czy on*arte pobiera prowizję od kupującego?',
      content: 'His works encompass what a tender dance between color and form would look like. Highly realistic yet minimal, her painted figures naturally coexist among sharp spontaneous geometry. She paints to create an intense, desirable, and euphoric mood. The femme, her primary  subject, dominates and inspires her work. \n\n The body becomes a series of shapes that come together as an intricate puzzle that requires an audience to put in place. The work deconstructs the beautiful and recomposes it with a new armor that resonates an indestructible landscape. \n\n His works encompass what a tender dance between color and form would look like. Highly realistic yet minimal, her painted figures naturally coexist among sharp spontaneous geometry. \n\n The body becomes a series of shapes that come together as an intricate puzzle that requires an audience to put in place. The work deconstructs the beautiful and recomposes.'
    },
    {
      title: 'Czy jest możliwy zwrot kupionego lub wylicytowanego przedmiotu?',
      content: 'His works encompass what a tender dance between color and form would look like. Highly realistic yet minimal, her painted figures naturally coexist among sharp spontaneous geometry. She paints to create an intense, desirable, and euphoric mood. The femme, her primary  subject, dominates and inspires her work. \n\n The body becomes a series of shapes that come together as an intricate puzzle that requires an audience to put in place. The work deconstructs the beautiful and recomposes it with a new armor that resonates an indestructible landscape. \n\n His works encompass what a tender dance between color and form would look like. Highly realistic yet minimal, her painted figures naturally coexist among sharp spontaneous geometry. \n\n The body becomes a series of shapes that come together as an intricate puzzle that requires an audience to put in place. The work deconstructs the beautiful and recomposes.'
    },
  ]; 

  return {
    newsSections,
    faqContent
  };
};
