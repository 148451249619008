import { getPathWithParams } from '@on-arte/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { useAuth } from '@onArte/frontend/hooks';
import { UseAuth } from '@onArte/frontend/interfaces';
import { plRouting } from '@onArte/shared/constants';
import { RouteNameEnum } from '@onArte/shared/enums';
import { RouteInfo } from '@onArte/shared/interfaces';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { Container, Content, ContentContainer, StyledAccountHeadline } from './baseViewWithTabs.styled';
import { BaseViewWithTabsProps } from './baseViewWithTabs.types';

export const BaseViewWithTabs: React.FC<BaseViewWithTabsProps> = (props: BaseViewWithTabsProps): JSX.Element => {
  const { children, withContentWithoutPadding = false }: BaseViewWithTabsProps = props;
  const { userData }: UseAuth = useAuth();
  const pathObject: RouteInfo[] = plRouting.filter(
    (route: RouteInfo): boolean => route.name !== RouteNameEnum.NotFound && !!useMatch(route.url)
  );
  const { t }: TransProps<never> = useTranslation();

  return (
    <Container>
      <StyledAccountHeadline
        username={userData?.firstName ?? ''}
        tabs={[
          {
            label: t('onarte.website.meta.userAccountSettings.title'),
            internalPath: getRouteDetailsByName(RouteNameEnum.UserAccountSettings)?.url ?? '/',
            isActive: pathObject[0].name === RouteNameEnum.UserAccountSettings,
            withTransparentUnderline: pathObject[0].name !== RouteNameEnum.UserAccountSettings
          },
          {
            label: t('onarte.website.meta.userMyShopping.title'),
            internalPath: getRouteDetailsByName(RouteNameEnum.UserMyShopping)?.url ?? '/',
            isActive: pathObject[0].name === RouteNameEnum.UserMyShopping,
            withTransparentUnderline: pathObject[0].name !== RouteNameEnum.UserMyShopping
          },
          {
            label: t('onarte.website.meta.userBiddingsList.title'),
            internalPath: getRouteDetailsByName(RouteNameEnum.UserBiddingsList)?.url ?? '/',
            isActive: pathObject[0].name === RouteNameEnum.UserBiddingsList,
            withTransparentUnderline: pathObject[0].name !== RouteNameEnum.UserBiddingsList
          },
          {
            label: t('onarte.website.meta.userFavoriteArtworks.title'),
            internalPath: getRouteDetailsByName(RouteNameEnum.UserFavoriteArtworks)?.url ?? '/',
            isActive: pathObject[0].name === RouteNameEnum.UserFavoriteArtworks,
            withTransparentUnderline: pathObject[0].name !== RouteNameEnum.UserFavoriteArtworks
          },
          {
            label: t('onarte.website.meta.userDeliverySettings.title'),
            internalPath: getRouteDetailsByName(RouteNameEnum.UserDeliverySettings)?.url ?? '/',
            isActive: pathObject[0].name === RouteNameEnum.UserDeliverySettings,
            withTransparentUnderline: pathObject[0].name !== RouteNameEnum.UserDeliverySettings
          },
          {
            label: t('onarte.website.meta.userSalesList.title'),
            internalPath: getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/',
            isActive: pathObject[0].name === RouteNameEnum.UserSalesList,
            withTransparentUnderline: pathObject[0].name !== RouteNameEnum.UserSalesList
          },
          {
            label: t('onarte.website.meta.userPublicProfileSettings.title'),
            internalPath: getPathWithParams(
              getRouteDetailsByName(RouteNameEnum.UserPublicProfileSettings)?.url ?? '/',
              { contextId: '-', contextType: '-', contextName: '-' }
            ),
            isActive: pathObject[0].name === RouteNameEnum.UserPublicProfileSettings,
            withTransparentUnderline: pathObject[0].name !== RouteNameEnum.UserPublicProfileSettings
          },
        ]}
      />
      <ContentContainer>
        <Content $withoutPadding={withContentWithoutPadding}>
          {children}
        </Content>
      </ContentContainer>
    </Container>
  );
};
