import {
  CartCostTypeEnum,
  DeliveryStatusEnum,
  DeliveryTypeEnum,
  ListPaginationInterface,
  OrderAuctionDetailsDTO,
  OrderStatusEnum,
  PaymentStatusEnum,
  PurchasesListElementDTO,
  ThumbnailAttachmentTypeEnum
} from '@on-arte/core-types';
import {
  ArtworkBoxSoldSummary,
  MessageBox,
  PaginationDetails,
  PurchasedArtworkSummary,
  Status,
  useLogger,
  UseLogger,
  UseNotifications,
  useNotifications,
  usePagination,
  useRedirect,
  UseRedirect,
  UseState
} from '@on-arte/ui';
import React, { useEffect, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { getPurchases, setParcelInAuctionAsDelivered } from '@onArte/frontend/api/requests';
import { BaseViewWithTabs } from '@onArte/frontend/components';
import { orderStatuses } from '@onArte/frontend/constants';
import { QueryKey } from '@onArte/frontend/enums';
import { useAddresses } from '@onArte/frontend/hooks';
import { UseAddresses } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { UrlSearchParams } from '@onArte/frontend/types';
import { getImageThumbnail } from '@onArte/frontend/utils';
import { getCostFinalPriceByType } from '@onArte/shared/utils';

import { ArtworkBoxSoldSummaryWrapper, Header, StyledPagination } from './userMyShopping.styled';

export const UserMyShoppingView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const [searchParams]: UrlSearchParams = useSearchParams();
  const [purchases, setPurchases]: UseState<PurchasesListElementDTO[]> = useState<PurchasesListElementDTO[]>([]);
  const { setMaxItems, setPage, itemsPerPage, maxItems, offset }: PaginationDetails = usePagination();
  const { addToast }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const highlightedOrderRefs: React.MutableRefObject<Record<string, HTMLDivElement | null>> = useRef({});
  const [highlightedOrderId, setHighlightedOrderId]: UseState<string> = useState<string>('');
  const {
    transformAddressInterfaceToShippingAddressFormData,
    transformAddressInterfaceToBillingAddressFormData,
  }: UseAddresses = useAddresses();

  useEffect(
    (): void => {
      const orderId: string | null = searchParams.get('orderId');
      if (orderId) {
        setHighlightedOrderId(orderId);
      }
    },
    [searchParams.toString()]
  );

  useEffect(
    (): void => {
      if (highlightedOrderId && highlightedOrderRefs.current[highlightedOrderId]) {
        highlightedOrderRefs.current[highlightedOrderId]?.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [highlightedOrderId, Object.keys(highlightedOrderRefs.current)]
  );

  const { refetch, isLoading }: QueryObserverResult = useQuery(
    [QueryKey.PurchasesList, offset],
    (): Promise<ListPaginationInterface<PurchasesListElementDTO>> => getPurchases({ offset, limit: itemsPerPage }),
    {
      onSuccess: (data: ListPaginationInterface<PurchasesListElementDTO>): void => {
        setPurchases(data.list);
        setMaxItems(data.amount);
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.AddressesList, error)
    }
  );

  const deliveryConfirmAction: (orderId: string, auctionId: string) => void = (orderId: string, auctionId: string): void => {
    setParcelInAuctionAsDelivered(orderId, auctionId)
      .then((): void => {
        void refetch();
        addToast({ content: t('onarte.website.userMyShoppingView.deliveryConfirmActionSuccess') });
      })
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  return (
    <BaseViewWithTabs>
      <Header>{t('onarte.website.meta.userMyShopping.title')}</Header>
      {(!isLoading && !purchases.length) ? (
        <MessageBox message={t('onarte.website.userMyShoppingView.noPurchases')} />
      ) : (
        purchases
          // TODO: temporary api fix (all purchases should have deliveryAddress, when this happens remove this line)
          .filter((purchase: PurchasesListElementDTO): boolean => !!purchase.auctions[0].deliveryAddress)
          .map((purchase: PurchasesListElementDTO, index: number): JSX.Element => (
            <ArtworkBoxSoldSummaryWrapper
              ref={(instance: HTMLDivElement | null): void => {
                highlightedOrderRefs.current = {...highlightedOrderRefs.current, [purchase.id]: instance };
              }}
              id={`purchase-transaction-${index}`}
            >
              <ArtworkBoxSoldSummary
                createdDate={purchase.orderedAt}
                badges={[orderStatuses[purchase.status]].map((status: Status): Status => ({ ...status, label: t(status.label) }))}
                items={purchase.auctions.map((auction: OrderAuctionDetailsDTO): PurchasedArtworkSummary => ({
                  artworkDetails: {
                    author: auction.auction.manufacturer.name,
                    itemName: auction.auction.name,
                    itemDescriptiveAttributes: [t(auction.auction.label)],
                  },
                  image: getImageThumbnail(auction.auction.coverPhoto, ThumbnailAttachmentTypeEnum.Size_100x100),
                  costsDetails: {
                    price: getCostFinalPriceByType(auction.costs, CartCostTypeEnum.Item),
                    commission: getCostFinalPriceByType(auction.costs, CartCostTypeEnum.Commission),
                    deliveryCost: getCostFinalPriceByType(auction.costs, CartCostTypeEnum.Delivery),
                    deliveryTypeLabel: t(`onarte.common.deliveryType.${auction.deliveryType}.name`),
                  },
                  commentToSeller: auction.commentToSeller ?? '',
                  isDeliveryCompleted: auction.delivered ?? undefined,
                  actions: {
                    deliveryConfirmAction: (
                      !auction.delivered
                      && purchase.status === OrderStatusEnum.Paid
                      && [DeliveryTypeEnum.OnArteTransport, DeliveryTypeEnum.SelfPickup].includes(auction.deliveryType)
                    )
                      ? (): void => deliveryConfirmAction(purchase.id, auction.auction.auctionId)
                      : undefined,
                  },
                  sellerEmail: auction.sellerEmail ?? undefined,
                  trackingUrl: auction.trackingUrl ?? undefined,
                  trackingId: auction.trackingCode ?? undefined,
                }))}
                ordersDetails={{
                  id: purchase.no,
                  hasInvoice: purchase.auctions[0]?.invoiceRequired ?? false,
                  paymentStatusLabel: t(`onarte.common.paymentStatus.${purchase.payment.paymentStatus ?? PaymentStatusEnum.Failed}`),
                  paymentTypeLabel: t(`onarte.common.paymentType.${purchase.payment.paymentType}`),
                  commentToOrder: purchase?.commentToOrder ?? '',
                  shippingAddress: transformAddressInterfaceToShippingAddressFormData(purchase.auctions[0].deliveryAddress!),
                  billingAddress: transformAddressInterfaceToBillingAddressFormData(purchase.auctions[0].billingAddress!),
                }}
                payAction={(purchase.status === OrderStatusEnum.Unpaid && purchase.payment.paymentUrl)
                  ? (): void => redirect(purchase.payment.paymentUrl ?? '', { isExternal: true })
                  : undefined
                }
                finalPrice={purchase.finalPrice}
                isExpandedOnInit={highlightedOrderId === purchase.id}
              />
            </ArtworkBoxSoldSummaryWrapper>
          ))
      )}
      {maxItems > itemsPerPage && (
        <StyledPagination
          onPageChange={setPage}
          maxItems={maxItems}
        />
      )}
    </BaseViewWithTabs>
  );
};
