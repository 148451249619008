import { CircleBadges, Breakpoint, Color, FontWeight, Markdown, PageTitle, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { SectionProps } from './contact.types';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  margin: 60px 40px 0;
  justify-content: flex-end;

  & + & {
    border-top: 1px solid ${Color.Gray300};
    padding-top: 60px;
    margin-bottom: 60px;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    margin: 60px 32px 0;
  }

  @media ${Breakpoint.SmallMobile} {
    margin: 40px 20px 0;

    & + & {
      padding-top: 40px;
      margin-bottom: 40px;
    }
  }
`;

export const Section: StyledComponent<'div', SectionProps> = styled.div<SectionProps>`
  width: 50%;
  padding-left: ${({ $withLeftPadding }: SectionProps): string => $withLeftPadding ? '40px' : '0'};

  @media ${Breakpoint.Mobile} {
    width: 100%;
    padding-left: 0;

    & + & {
      padding-top: 60px;
      margin-top: 60px;
      border-top: 1px solid ${Color.Gray300};
    }
  }
  
  @media ${Breakpoint.SmallMobile} {
    & + & {
      margin-top: 40px;
      padding-top: 40px;
    }
  }
`;

export const Title: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.Medium};
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.01em;

  @media ${Breakpoint.SmallMobile} {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  background-color: ${Color.Gray200};
  padding: 80px 40px 40px;

  @media ${Breakpoint.Mobile} {
    padding: 80px 32px 40px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 40px 20px;
  }
`;

export const StyledCircleBadges: StyledComponent<typeof CircleBadges> = styled(CircleBadges)`
  margin-top: 24px;
`;

export const Address: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.Medium};
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.01em;
`;
