import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { UpdateProfileFormInterface } from '@onArte/frontend/interfaces';
import { regexps } from '@onArte/shared/constants';

export const useUpdateProfileFormValidation: () => Yup.SchemaOf<UpdateProfileFormInterface> 
  = (): Yup.SchemaOf<UpdateProfileFormInterface> => {
    const { t }: TransProps<never> = useTranslation();

    return Yup.object().shape({
      firstName: Yup.string()
        .required(t('onarte.common.requiredField'))
        .matches(regexps.FirstName, t('onarte.common.validations.firstName')),
      lastName: Yup.string()
        .required(t('onarte.common.requiredField'))
        .matches(regexps.LastName, t('onarte.common.validations.lastName')),
      phone: Yup.string()
        .required(t('onarte.common.requiredField'))
        .matches(regexps.Phone, t('onarte.common.validations.phone')),
    });
  };
