import { PageTitle } from '@on-arte/ui';
import React from 'react';

import { BaseViewWithAlphabetProps } from './baseViewWithAlphabet.props';
import { 
  Children, 
  Container, 
  LetterButton, 
  PageTitleWrapper 
} from './baseViewWithAlphabet.styled';

export const BaseViewWithAlphabet: React.FC<BaseViewWithAlphabetProps> = (props: BaseViewWithAlphabetProps): JSX.Element => {
  const { pageTitle, pageDescription, children, activeLetter, setActiveLetter, alphabet, letterAction }: BaseViewWithAlphabetProps = props;

  const onLetterClickAction: (letter: string) => void = (letter: string): void => {
    if (setActiveLetter) {
      setActiveLetter(letter);
    } else if (letterAction) {
      letterAction(letter);
    }
  };

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle 
          pageTitleSettings={{
            title: pageTitle,
            description: pageDescription,
            withUnderline: false,
          }}
          children={(
            <Children>
              {alphabet && Object.keys(alphabet).map((letter: string): JSX.Element => (
                <LetterButton
                  key={letter}
                  onClick={(): void => onLetterClickAction(letter)}
                  $active={!activeLetter && !!setActiveLetter ? (Object.keys(alphabet)[0] === letter) : (activeLetter === letter)}
                >
                  {letter}
                </LetterButton>
              ))}
            </Children>
          )}
        />
      </PageTitleWrapper>
      {children}
    </Container>
  );
};
