import { CartDeliveryAction } from './cartDelivery.enum';
import { CartDeliveryState } from './cartDelivery.interface';
import { CartDeliveryActions } from './cartDelivery.type';

export const cartDeliveryReducer = (
  state: CartDeliveryState, action: CartDeliveryActions
): CartDeliveryState => {
  switch (action.type) {
    case CartDeliveryAction.SetCartAuctions:
      return { ...state, cartAuctions: action.payload };
    case CartDeliveryAction.SetAddressFormValues:
      return { ...state, addressFormValues: action.payload };
    case CartDeliveryAction.SetAuctionsAvailableDeliveries:
      return { ...state, auctionAvailableDeliveries: action.payload };
    case CartDeliveryAction.SetChosenAuctionDeliverySettings:
      return { ...state, chosenAuctionDeliverySettings: { ...state.chosenAuctionDeliverySettings, ...action.payload } };
    case CartDeliveryAction.SetCommentToOrder:
      return { ...state, commentToOrder: action.payload };
    case CartDeliveryAction.SetCurrentAddress:
      return { ...state, currentAddress: action.payload };
    case CartDeliveryAction.SetIsPopupVisible:
      return { ...state, isPopupVisible: action.payload };
    default:
      return state;
  }
};
