import { ListPaginationInterface } from '@on-arte/core-types';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { ListParams, SearchItemDTO } from '@onArte/shared/interfaces';

import { api } from '../api';

export const search: (
  query: string, params: ListParams
) => Promise<ListPaginationInterface<SearchItemDTO>> = (
  query: string, params: ListParams
): Promise<ListPaginationInterface<SearchItemDTO>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<SearchItemDTO>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/search?${stringify({ ...params, query }, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<SearchItemDTO>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
