import { OrderStatusEnum } from '@on-arte/core-types';
import { Color, Status } from '@on-arte/ui';

export const orderStatuses: Record<OrderStatusEnum, Status> = {
  [OrderStatusEnum.Closed]: {
    label: 'onarte.common.orderStatuses.closed',
    color: Color.Success500,
    background: Color.Success100,
  },
  [OrderStatusEnum.Paid]: {
    label: 'onarte.common.orderStatuses.paid',
    color: Color.Blue500,
    background: Color.Blue100,
  },
  [OrderStatusEnum.Unpaid]: {
    label: 'onarte.common.orderStatuses.unpaid',
    color: Color.Error500,
    background: Color.Error100,
  },
  [OrderStatusEnum.InProgress]: {
    label: 'onarte.common.orderStatuses.inProgress',
    color: Color.Warning500,
    background: Color.Warning100,
  },
};
