import { AccordionGroup, highlightTextFragments, NewsSectionData, toMarkdown } from '@on-arte/ui';
import React from 'react';

import { InfoBoxes } from '../infoBoxes/infoBoxes.component';

import { 
  AccordionGroupContainer,
  AccordionTitle,
  BackgroundImage, 
  BackgroundImageContainer, 
  Container, 
  ContentContainer, 
  NewsSectionsContainer, 
  StyledBigPhotoBox, 
  StyledNewsSection, 
  StyledPageTitle, 
  Title 
} from './baseHandbookView.styled';
import { BaseHandbookViewProps } from './baseHandbookView.types';

export const BaseHandbookView: React.FC<BaseHandbookViewProps> = (props: BaseHandbookViewProps): JSX.Element => {
  const { 
    pageTitleSettings, 
    backgroundImage, 
    title, 
    newsBoxes, 
    accordionTitle, 
    accordionContent,
    bigPhotoBoxData
  }: BaseHandbookViewProps = props;

  return (
    <Container>
      <StyledPageTitle pageTitleSettings={pageTitleSettings} />
      <BackgroundImageContainer>
        <BackgroundImage src={backgroundImage} alt={pageTitleSettings.title} />
      </BackgroundImageContainer>
      <ContentContainer>
        <Title 
          dangerouslySetInnerHTML={{
            __html: toMarkdown(highlightTextFragments(title))
          }}
        />
        <NewsSectionsContainer>
          {newsBoxes.map((news: NewsSectionData): JSX.Element => <StyledNewsSection {...news} key={news.title} />)}
        </NewsSectionsContainer>
        {accordionTitle && accordionContent && (
          <AccordionGroupContainer>
            <AccordionTitle 
              dangerouslySetInnerHTML={{
                __html: toMarkdown(highlightTextFragments(accordionTitle))
              }}
            />
            <AccordionGroup accordionItems={accordionContent} />
          </AccordionGroupContainer>
        )}
        <StyledBigPhotoBox 
          title={bigPhotoBoxData.title}
          image={bigPhotoBoxData.image}
          buttonLabel={bigPhotoBoxData.label}
          buttonAction={bigPhotoBoxData.action}
        />
        <InfoBoxes />
      </ContentContainer>
    </Container>
  );
};
