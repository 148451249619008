import {
  HeadingType,
  UseFormikForm,
  useFormikForm,
  UseNotifications,
  useNotifications,
  useRedirect,
  UseRedirect,
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { Params, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { resetPassword } from '@onArte/frontend/api/requests';
import { LoginFormWrapper } from '@onArte/frontend/components/loginFormWrapper/loginFormWrapper.component';
import { FrontendApiError } from '@onArte/frontend/models';
import { FormikForm } from '@onArte/frontend/theme';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { useSetPasswordValidation } from './setPassword.hooks';
import { Container, StyledButton, StyledInput, StyledHeading } from './setPassword.styled';
import { SetPasswordForm } from './setPassword.types';

export const SetPasswordView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const SetPasswordFormValidationSchema: Yup.SchemaOf<SetPasswordForm> = useSetPasswordValidation();
  const { token }: Readonly<Params<string>> = useParams();
  const { addToast }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();

  const choosePasswordAction: (data: SetPasswordForm) => void = (data: SetPasswordForm): void => {
    if (token) {
      resetPassword({ token, password: data.password })
        .then((): void => {
          addToast({ content: t('onarte.website.setPasswordView.resetPasswordSuccessMessage') });
          setTimeout((): void => redirect(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/', { withReplaceState: true }), 1000);
        })
        .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
    }
  };

  return (
    <Container>
      <LoginFormWrapper>
        <Formik
          initialValues={{ password: '', repeatedPassword: '' }}
          onSubmit={choosePasswordAction}
          validationSchema={SetPasswordFormValidationSchema}
          validateOnChange={isFormSubmitted}
          validateOnBlur={isFormSubmitted}
        >
          {({ handleSubmit, setFieldValue, errors }: FormikProps<SetPasswordForm>) => (
            <FormikForm onSubmit={handleSubmit}>
              <StyledHeading type={HeadingType.H4} text={t('onarte.common.setNewPassword')} />
              <StyledInput
                label={t('onarte.common.password')}
                type='password'
                onChange={(value: string): void => setFieldValue('password', value)}
                onBlur={(value: string): void => setFieldValue('password', value)}
                validationMessage={errors.password || ''}
                description={t('onarte.common.validations.passwordDescription')}
              />
              <StyledInput
                label={t('onarte.common.repeatPassword')}
                type='password'
                onChange={(value: string): void => setFieldValue('repeatedPassword', value)}
                onBlur={(value: string): void => setFieldValue('repeatedPassword', value)}
                validationMessage={errors.repeatedPassword || ''}
              />
              <StyledButton
                label={t('onarte.common.setNewPassword')}
                onClick={setFormSubmitted}
                type='submit'
                fullWidth
              />
            </FormikForm>
          )}
        </Formik>
      </LoginFormWrapper>
    </Container>
  );
};
