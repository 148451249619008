import { AuthReducerAction } from '@onArte/frontend/enums';
import { AuthContextState } from '@onArte/frontend/interfaces';
import { AuthReducerActions } from '@onArte/frontend/types';

export const authReducer = (state: AuthContextState, action: AuthReducerActions): AuthContextState => {
  switch (action.type) {
    case AuthReducerAction.SignIn:
      return { ...state, ...action.payload };
    case AuthReducerAction.SignOut:
      return { token: null, tokenExpiration: null, userData: null };
    case AuthReducerAction.UpdateProfile:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
