import { Color, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  background-color: ${Color.Orange};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageContainer: StyledComponent<'div'> = styled.div`
  background-color: ${Color.White};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 100px;

  * + * {
    margin-top: 20px;
  }
`;
