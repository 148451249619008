export enum WebSocketCommand {
  // data updates commands
  AuctionOfferAdded = 'auctionOfferAdded',
  AuctionOfferUpdatedEndDatetime = 'auctionOfferUpdatedEndDatetime',
  AuctionSold = 'auctionSold',
  // subscription commands
  SubscribeAuction = 'subscribeAuction',
  UnsubscribeAuction = 'unsubscribeAuction',
  // other
  StatusMessage = 'statusMessage',
  NotImplemented = 'notImplemented',
}
