import { 
  ListPaginationInterface, 
  PublicProfileActivityUpdateModel, 
  PublicProfileCreateModel,
  PublicProfileListElementInterface,
  PublicProfileTypeEnum, 
  PublicProfileUpdateModel 
} from '@on-arte/core-types';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import {
  ItemWithAuctionDetails, 
  ListParams, 
  PublicProfileDetails, 
  PublicProfileExtendedListItem, 
  PublicProfileList, 
  PublicProfileListModel 
} from '@onArte/shared/interfaces';

import { api } from '../api';

export const getPublicProfilesList: (type: PublicProfileTypeEnum, char?: string) => Promise<PublicProfileList> = (
  type: PublicProfileTypeEnum, char?: string
): Promise<PublicProfileList> => new Promise(
  (resolve: ApiResolve<PublicProfileList>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/public-profiles?${stringify({ type, char }, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<PublicProfileList>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getPublicProfilesPaginationList: (
  params: PublicProfileListModel
) => Promise<ListPaginationInterface<PublicProfileExtendedListItem>> = (
  params: PublicProfileListModel
): Promise<ListPaginationInterface<PublicProfileExtendedListItem>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<PublicProfileExtendedListItem>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/public-profiles/list?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<PublicProfileExtendedListItem>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const createPublicProfile: (data: PublicProfileCreateModel) => Promise<PublicProfileDetails> = (
  data: PublicProfileCreateModel
): Promise<PublicProfileDetails> => new Promise(
  (resolve: ApiResolve<PublicProfileDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/public-profiles', data).then(
      (response: AxiosResponse<PublicProfileDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getPublicProfileDetails: (profileId: string) => Promise<PublicProfileDetails> = (
  profileId: string
): Promise<PublicProfileDetails> => new Promise(
  (resolve: ApiResolve<PublicProfileDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/public-profiles/${profileId}`).then(
      (response: AxiosResponse<PublicProfileDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updatePublicProfile: (profileId: string, data: PublicProfileUpdateModel) => Promise<void> = (
  profileId: string, data: PublicProfileUpdateModel
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.patch(`/public-profiles/${profileId}`, data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getPublicProfileItems: (
  params: Partial<ListParams>, profileId: string
) => Promise<ListPaginationInterface<ItemWithAuctionDetails>> = (
  params: Partial<ListParams>, profileId: string
): Promise<ListPaginationInterface<ItemWithAuctionDetails>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<ItemWithAuctionDetails>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/public-profiles/${profileId}/items?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<ItemWithAuctionDetails>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getAllowedPublicProfiles: () => Promise<PublicProfileListElementInterface[]>
  = (): Promise<PublicProfileListElementInterface[]> => new Promise(
    (resolve: ApiResolve<PublicProfileListElementInterface[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
      api.get('/public-profiles/allowed-profiles').then(
        (response: AxiosResponse<PublicProfileListElementInterface[]>): void => { resolve(response?.data); }
      ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
    )
  );

export const changePublicProfileActiveFlag: (profileId: string, data: PublicProfileActivityUpdateModel) => Promise<PublicProfileDetails> = (
  profileId: string, data: PublicProfileActivityUpdateModel
): Promise<PublicProfileDetails> => new Promise(
  (resolve: ApiResolve<PublicProfileDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.patch(`/public-profiles/${profileId}/active`, data).then(
      (response: AxiosResponse<PublicProfileDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
