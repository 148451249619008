import { ExternalUserProfileInterface, TranslationsEnum } from '@on-arte/core-types';
import { Breakpoint, useFormikForm, UseFormikForm, UseNotifications, useNotifications } from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import { changePassword } from '@onArte/frontend/api/requests';
import { QueryKey } from '@onArte/frontend/enums';
import { useAuth } from '@onArte/frontend/hooks';
import { UpdatePasswordFormInterface, UseAuth } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { FormikForm } from '@onArte/frontend/theme';

import { useUpdatePasswordFormValidation } from './updatePasswordForm.hook';
import { StyledButton, StyledInput } from './updatePasswordForm.styled';
import { UpdatePasswordFormProps } from './updatePasswordForm.types';

export const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = (props: UpdatePasswordFormProps): JSX.Element => {
  const { userHash, updateProfileData }: UpdatePasswordFormProps = props;
  const { t }: TransProps<never> = useTranslation();
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const { addToast }: UseNotifications = useNotifications();
  const { refreshProfileData }: UseAuth = useAuth();
  const updatePasswordFormValidationSchema: Yup.SchemaOf<UpdatePasswordFormInterface> = useUpdatePasswordFormValidation();
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.SmallMobile });
  const queryClient: QueryClient = useQueryClient();

  const updatePasswordAction: (data: UpdatePasswordFormInterface) => void = (data: UpdatePasswordFormInterface): void => {
    if (!userHash) {
      return;
    }

    changePassword({ hash: userHash, ...data })
      .then((response: ExternalUserProfileInterface): void => {
        const { agreements, ...user }: ExternalUserProfileInterface = response;
        refreshProfileData(user);
        updateProfileData(user);
        addToast({ content: t('onarte.website.userAccountSettingsView.updatePasswordSuccess') });
      })
      .catch((error: FrontendApiError): void => {
        addToast({ content: t(error.message) });

        if (error.message === TranslationsEnum.WrongUserHash) {
          void queryClient.invalidateQueries(QueryKey.Profile);
        }
      });
  };

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: ''
      }}
      onSubmit={updatePasswordAction}
      validationSchema={updatePasswordFormValidationSchema}
      validateOnChange={isFormSubmitted}
      validateOnBlur={isFormSubmitted}
      enableReinitialize
    >
      {({ handleSubmit, setFieldValue, errors }: FormikProps<UpdatePasswordFormInterface>) => (
        <FormikForm onSubmit={handleSubmit}>
          <StyledInput
            label={t('onarte.common.oldPassword')}
            onChange={(value: string): void => setFieldValue('oldPassword', value)}
            validationMessage={errors.oldPassword ?? ''}
            type='password'
          />
          <StyledInput
            label={t('onarte.common.newPassword')}
            onChange={(value: string): void => setFieldValue('newPassword', value)}
            validationMessage={errors.newPassword ?? ''}
            type='password'
          />
          <StyledButton
            label={t('onarte.common.saveChanges')}
            fullWidth={isSmallMobile}
            type='submit'
            onClick={setFormSubmitted}
          />
        </FormikForm>
      )}
    </Formik>
  );
};
