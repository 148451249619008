import { IsString, MinLength, ValidateIf } from 'class-validator';

export class ConversionEventCustomData {
  @IsString()
  @MinLength(2)
  @ValidateIf((object: unknown, value: string | undefined): boolean => value !== undefined)
  public cartId?: string;

  @IsString()
  @MinLength(2)
  @ValidateIf((object: unknown, value: string | undefined): boolean => value !== undefined)
  public auctionId?: string;

  constructor(
    cartId?: string,
    auctionId?: string,
  ) {
    this.cartId = cartId;
    this.auctionId = auctionId;
  }
}
