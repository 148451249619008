import { Breakpoint, Color, FontWeight, MenuPosition, StyledComponent } from '@on-arte/ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { TabItemProps } from './loginFormWrapper.types';

export const Container: StyledComponent<'div'> = styled.div`
  width: 740px;
  max-width: 100%;
  margin: 88px;

  @media ${Breakpoint.Mobile} {
    margin: 88px 20px;
  }

  @media ${Breakpoint.SmallMobile} {
    margin: 24px 0;
  }
`;

export const LogoButton: StyledComponent<'button'> = styled.button`
  margin: 0 auto 32px;
  display: block;
`;

export const Tabs: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TabItem: StyledComponent<typeof Link, TabItemProps> = styled(Link)<TabItemProps>`
  font-weight: ${FontWeight.Bold};
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${Color.Gray500};
  padding: 25px 0;
  background-color: ${Color.White};
  transition: 0.3s;
  width: 50%;
  border-bottom: 1px solid ${Color.Gray300};
  text-decoration: none;

  ${({ $isActive }: TabItemProps): string | false => $isActive && `
    color: ${Color.Black};
    border-color: ${Color.Error500};
  `}
`;

export const FormWrapper: StyledComponent<'div'> = styled.div`
  background-color: ${Color.White};
  padding: 60px 20px;
`;

export const FormInnerWrapper: StyledComponent<'div'> = styled.div`
  max-width: 408px;
  margin: 0 auto;
`;

export const LinksContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto 0;
`;

export const BottomLink: StyledComponent<typeof MenuPosition> = styled(MenuPosition)`
  font-size: 12px;
  line-height: 16px;
  color: ${Color.Gray500};
  font-weight: ${FontWeight.Regular};
`;

export const DotSeparator: StyledComponent<'div'> = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: ${Color.Gray500};
  margin: 0 12px;
`;
