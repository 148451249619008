import { AuctionInfoInterface, AuctionTypeEnum, EventDetailsInterface } from '@on-arte/core-types';
import { 
  ArtworkMosaicItemDetails, 
  Color, 
  getPathWithParams, 
  IconName, 
  InfinitePaginationDetails, 
  ItemsMosaic, 
  ItemsMosaicTheme, 
  Language, 
  LoadingSpinner, 
  useFormatDate, 
  UseFormatDate, 
  useInfinitePagination, 
  useLogger, 
  UseLogger, 
  UseNotifications, 
  useNotifications, 
  UseRedirect, 
  useRedirect, 
  UseState 
} from '@on-arte/ui';
import React, { useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQuery } from 'react-query';

import { getEventDetails } from '@onArte/frontend/api/requests';
import { FrontendResponseCode, QueryKey } from '@onArte/frontend/enums';
import { useRouteInfo } from '@onArte/frontend/hooks';
import { RoutingState } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { emptyRequest } from '@onArte/frontend/utils';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { MosaicWrapper } from '../collections/collection/collection.styled';

import { 
  Container, 
  EventDetailsContainer, 
  EventPhotoBackgroundContainer, 
  NoArtworksMessage, 
  StyledArtistTitle, 
  StyledArtworkDescription, 
  StyledBadge 
} from './eventDetails.styled';

export const EventDetailsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { formatDate }: UseFormatDate = useFormatDate(Language.Pl);
  const { addToast }: UseNotifications = useNotifications();
  const { 
    setMaxItems, 
    hasMoreItems, 
    fetchMoreData, 
    paginationOffset, 
    initialItemsCount,
  }: InfinitePaginationDetails = useInfinitePagination(20);
  const [eventDetails, setEventDetails]: UseState<EventDetailsInterface | null> = useState<EventDetailsInterface | null>(null);
  const [slicedMosaicItems, setSlicedMosaicItems]: UseState<ArtworkMosaicItemDetails[]> = useState<ArtworkMosaicItemDetails[]>([]);
  const [allMosaicItems, setAllMosaicItems]: UseState<ArtworkMosaicItemDetails[]> = useState<ArtworkMosaicItemDetails[]>([]);
  const todaysDate: number = new Date().getTime();
  const { elementParamsFromRouting, currentRouteObject }: RoutingState = useRouteInfo();
  const { logger }: UseLogger = useLogger();
  const { redirect }: UseRedirect = useRedirect();

  useQuery(
    [QueryKey.EventDetails, elementParamsFromRouting.id],
    (): Promise<EventDetailsInterface | void> => elementParamsFromRouting.id && currentRouteObject?.name === RouteNameEnum.EventDetails
      ? getEventDetails(elementParamsFromRouting.id)
      : emptyRequest(),
    {
      onSuccess: (data: EventDetailsInterface): void => {
        if (data) {
          setEventDetails(data);
          setMaxItems(data.auctions.length);
          const allMosaicItemsArray: ArtworkMosaicItemDetails[] = data.auctions
            .map((item: AuctionInfoInterface): ArtworkMosaicItemDetails => ({
              image: item.coverPhoto?.path ?? '',
              internalPath: item.route?.prettyUrl
                ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/', { id: item.id }),
              boxTitleDetails: {
                itemName: item.name,
                author: item.manufacturer.name,
                itemDescriptiveAttributes: [t(item.label)],
                price: item.type === AuctionTypeEnum.PriceProposal ? undefined : item.price,
                biddersNumber: item.type === AuctionTypeEnum.Bidding ? item.offers : undefined,
                bottomButtonLabel: item.type === AuctionTypeEnum.PriceProposal ? t('onarte.common.priceProposal') : undefined,
              }
            }));
          setAllMosaicItems(allMosaicItemsArray);
          setSlicedMosaicItems(allMosaicItemsArray.slice(0, initialItemsCount));
        }
      },
      onError: (error: FrontendApiError): void => {
        logger(QueryKey.EventDetails, error);
        if (error.responseCode === FrontendResponseCode.NotFound) {
          redirect(getRouteDetailsByName(RouteNameEnum.NotFound)?.url ?? '/');
          return;
        } else {
          addToast({ content: t(error.message) });
        }
      }
    }
  );

  const eventBadgeLabel: string = useMemo(
    (): string => {
      if (eventDetails?.endTimestamp && eventDetails?.startTimestamp) {
        const daysLeft: number = Number(((eventDetails.endTimestamp - todaysDate) / (1000 * 60 * 60 * 24)).toFixed());

        if (todaysDate > eventDetails.endTimestamp) {
          return t('onarte.common.eventStatuses.finished');
        } else if (eventDetails.startTimestamp > todaysDate) {
          return t('onarte.common.eventStatuses.incoming');
        } else {
          return daysLeft === 0 
            ? t('onarte.common.todayEnd')
            : `${t('onarte.common.daysLeft', { value: daysLeft })}`;
        }
      }

      return '';
    },
    [eventDetails]
  );

  useEffect(
    (): void => {
      setMaxItems(allMosaicItems.length);
      setSlicedMosaicItems(allMosaicItems.slice(0, (initialItemsCount + paginationOffset)));
    },
    [paginationOffset]
  );

  return (
    <Container>
      {eventDetails && (
        <>
          <EventPhotoBackgroundContainer 
            $imgUrl={eventDetails.coverPhoto?.path ?? ''}
          >
            {eventBadgeLabel && (
              <StyledBadge 
                label={t(eventBadgeLabel)} 
                background={Color.Error500} 
                color={Color.White} 
                icon={IconName.InfoIcon} 
              />
            )}
          </EventPhotoBackgroundContainer>
          <EventDetailsContainer>
            <StyledArtistTitle 
              title={eventDetails.name} 
              subtitle={
                eventDetails.endTimestamp && eventDetails.startTimestamp ? 
                  `${todaysDate > eventDetails.endTimestamp
                    ? t('onarte.common.auction.deadline.ended') 
                    : eventDetails.startTimestamp > todaysDate 
                      ? t('onarte.common.auction.deadline.incoming', { value: formatDate(eventDetails.startTimestamp, 'DD MMMM, HH:mm') })
                      : t('onarte.common.auction.deadline.until', { value: formatDate(eventDetails.endTimestamp, 'DD MMMM, HH:mm') })}`
                  : ''
              }
              // TODO: temporary hidden
              // buttonLabel={t('onarte.website.eventDetailsView.button.howToBid')}
              // buttonIcon={IconName.Money}
              // buttonAction={(): void => addToast({ content: t('onarte.common.functionalityCurrentlyNotAvailable') })}
              iconName={IconName.Gavel}
            />
            <StyledArtworkDescription
              title={t('onarte.website.eventDetailsView.about.title')}
              informationBoxDetails={{
                name: t('onarte.website.eventDetailsView.about.informationBoxName'),
                subName: '+48 61 111 0183',
                onClick: (): string => window.location.href = 'mailto:support@onarte.pl',
                buttonLabel: t('onarte.website.eventDetailsView.about.informationBoxButton'),
                iconName: IconName.CollectorFilled
              }}
              description={eventDetails.description ?? undefined}
              withReadMore 
            />
          </EventDetailsContainer>
          {slicedMosaicItems.length ? (
            <InfiniteScroll
              dataLength={slicedMosaicItems.length}
              next={fetchMoreData}
              hasMore={hasMoreItems}
              loader={
                <LoadingSpinner />
              }
              endMessage={false}
            >
              <MosaicWrapper>
                <ItemsMosaic 
                  items={slicedMosaicItems}
                  theme={ItemsMosaicTheme.Mosaic}
                />
              </MosaicWrapper>
            </InfiniteScroll>
          ) : (
            <NoArtworksMessage>{t('onarte.website.eventDetailsView.noArtworksMessage')}</NoArtworksMessage>
          )}
        </>
      )}
    </Container>
  );
};
