import { SettingsInterface, StepPriceInterface } from '@on-arte/core-types';
import { toMarkdown, useLogger, UseLogger, UseState } from '@on-arte/ui';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getSettings } from '@onArte/frontend/api/requests';
import { BaseStaticView } from '@onArte/frontend/components';
import { FileFromViews, QueryKey } from '@onArte/frontend/enums';
import { FrontendApiError } from '@onArte/frontend/models';
import { regulations } from '@onArte/shared/constants';
import { Paragraph } from '@onArte/shared/interfaces';

import { Content, Header, MarkdownContainer } from './regulations.styled';

export const RegulationsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const [settings, setSettings]: UseState<SettingsInterface | null> = useState<SettingsInterface | null>(null);

  useQuery(
    [QueryKey.Settings],
    (): Promise<SettingsInterface> => getSettings(),
    {
      onSuccess: (data: SettingsInterface): void => setSettings(data),
      onError: (error: FrontendApiError): void => logger(QueryKey.Settings, error)
    }
  );

  const regulationsContent: Paragraph[] = useMemo((): Paragraph[] => {
    if (!settings) {
      return [];
    }

    let stepPriceContent: string = '';
    const cardsImg: string = `<img src=${FileFromViews.CardPayments} />`;

    settings.stepPrices.forEach((stepPrice: StepPriceInterface): void => {
      stepPriceContent += `
  <tr>
    <td>${stepPrice.to 
    ? `${stepPrice.from} - ${stepPrice.to}` 
    : `${t('onarte.website.regulationsView.stepPrices.abovePrefix', { value: stepPrice.from })}`}</td>
    <td>${stepPrice.value}</td>
  </tr>
      `;
    });
  
    const stepPricesTable: string = `
  <table>
    <tr>
      <th>${t('onarte.website.regulationsView.stepPricesTable.price')}</th>
      <th>${t('onarte.website.regulationsView.stepPricesTable.priceProgress')}</th>
    </tr>
    ${stepPriceContent}
  </table>  
    `;

    regulations
      .map((paragraph: Paragraph): (string | undefined)[] | undefined => paragraph.content
        ?.map((content: string, index: number): string | undefined => paragraph.content && (
          paragraph.content[index] = content
            .replace('[stepPrices]', stepPricesTable)
            .replace('[cardPayments]', cardsImg)
            .replace('[biddingCommission]', `${settings.commissionForBidding * 100}`)))
      );

    return regulations;
  }, [settings]);

  return (
    <BaseStaticView title={t('onarte.website.regulationsView.title')}>
      {regulationsContent.map((markdown: Paragraph): JSX.Element => (
        <MarkdownContainer key={markdown.header}>
          <Header text={markdown.header} />
          {!!markdown.content && markdown.content.map((content: string, index: number): JSX.Element => (
            <Content 
              dangerouslySetInnerHTML={{__html: toMarkdown(content)}}
              key={index}
            />
          ))}
        </MarkdownContainer>
      ))}
    </BaseStaticView>
  );
};
