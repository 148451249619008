import { UseNotifications, useNotifications } from '@on-arte/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { useAuth } from '@onArte/frontend/hooks';
import { UseAuth } from '@onArte/frontend/interfaces';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const LogoutView: React.FC = (): JSX.Element => {
  const { addToast }: UseNotifications = useNotifications();
  const { signOut }: UseAuth = useAuth();
  const { t }: TransProps<never> = useTranslation();

  useEffect(
    (): void => {
      signOut(getRouteDetailsByName(RouteNameEnum.SignIn)?.url);
      addToast({ content: t('onarte.website.errors.accountInactivity') });
    },
    []
  );

  return (
    <></>
  );
};
