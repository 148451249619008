import { ListPaginationInterface, PostDTO } from '@on-arte/core-types';
import {
  Breakpoint,
  DirectionEnum,
  NewsBoxType,
  SectionTitle,
  useLogger,
  UseLogger,
  useRedirect,
  UseRedirect,
  UseState
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';

import { getPosts } from '@onArte/frontend/api/requests';
import { appConfig } from '@onArte/frontend/app.config';
import { QueryKey } from '@onArte/frontend/enums';
import { FrontendApiError } from '@onArte/frontend/models';

import { Container, NewsBoxes, StyledNewsBox } from './newsBoxesSection.styled';
import { News, NewsBoxesSectionProps } from './newsBoxesSection.types';

export const NewsBoxesSection: React.FC<NewsBoxesSectionProps> = (props: NewsBoxesSectionProps) => {
  const { t }: TransProps<never> = useTranslation();
  const { className }: NewsBoxesSectionProps = props;
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const [blogPosts, setBlogPosts]: UseState<News[]> = useState<News[]>([]);
  const { logger }: UseLogger = useLogger();
  const { redirect }: UseRedirect = useRedirect();

  useQuery(
    [QueryKey.Posts],
    (): Promise<ListPaginationInterface<PostDTO>> => getPosts({ limit: 2, offset: 0}),
    {
      onSuccess: (data: ListPaginationInterface<PostDTO>): void => {
        setBlogPosts(data.list.slice(0, 2).map((post: PostDTO): News => ({
          header: post.categories[0] ?? 'Rynek sztuki',
          title: post.title,
          description: post.shortDescription,
          cover: post.cover ?? '',
          url: post.link,
        })));
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.Posts, error)
    }
  );

  return blogPosts.length >= 2 ? (
    <Container className={className}>
      <SectionTitle
        header={t('onarte.website.newsBoxesSection.header')}
        buttonLabel={t('onarte.common.showMore')}
        buttonAction={(): void => redirect(appConfig.magazineUrl, { isExternal: true, inNewTab: true })}
      />
      <NewsBoxes>
        {blogPosts.map((blogPost: News, index: number): JSX.Element => (
          <StyledNewsBox
            header={blogPost.header}
            title={blogPost.title}
            description={blogPost.description}
            cover={blogPost.cover}
            type={isMobile ? NewsBoxType.Vertical : NewsBoxType.Horizontal}
            $position={index % 2 === 0 ? DirectionEnum.Left : DirectionEnum.Right}
            onClick={(): void => redirect(blogPost.url, { isExternal: true, inNewTab: true })}
            key={`${blogPost.title}${index}`}
          />
        ))}
      </NewsBoxes>
    </Container>
  ) : <></>;
};
