import { 
  AddressTypeEnum,
  CartAuctionInterface,
  PaymentTypeEnum, 
  AuctionSoldInterface,
  PaymentGatewayInterface,
} from '@on-arte/core-types';
import { 
  getPathWithParams, 
  ShippingBoxTheme, 
  useNotifications, 
  UseNotifications,       
  ValidationBarTheme 
} from '@on-arte/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from 'react-query';
import { Params, useParams } from 'react-router-dom';

import { CartBaseView } from '@onArte/frontend/components';
import { FileFromViews, QueryKey } from '@onArte/frontend/enums';
import { useAddresses, useAuth, useConversions, useSocket } from '@onArte/frontend/hooks';
import { UseAddresses, UseAuth, UseConversions, UseSocket } from '@onArte/frontend/interfaces';
import { RouteNameEnum, WebSocketCommand } from '@onArte/shared/enums';
import { WebSocketSubscriptionDetails } from '@onArte/shared/models';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { AddressesDialog } from '../components';

import { useCartBilling } from './cartBilling.hooks';
import { 
  ButtonsContainer, 
  Container, 
  DeliveryAddress, 
  GatewayIcon, 
  GatewayItem, 
  GatewaysContainer, 
  StyledButton, 
  StyledChooseBox, 
  StyledShippingBox, 
  StyledValidationBar, 
  Subtitle, 
  UnderlineButton 
} from './cartBilling.styled';
import { UseCartBilling } from './cartBilling.types';
import { CartBillingAction } from './reducer';

export const CartBillingView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { id }: Readonly<Params<string>> = useParams();
  const { addToast }: UseNotifications = useNotifications();
  const { getPrettyAddressLines, getBillingAddressTitle }: UseAddresses = useAddresses();
  const { socket, connectedToSocket, setIsConnectedToSocket }: UseSocket = useSocket();
  const { userData }: UseAuth = useAuth();
  const { 
    onlineGateways,
    blikGateway,
    cardGateway,
    onInvoiceRequiredChange,
    goToDelivery,
    isFinishButtonLoading,
    onPaymentMethodChangeAction,
    deliveryAddress,
    onFinishCartAction,
    onAddressChoose,
    cartBillingState,
    cartBillingDispatch
  }: UseCartBilling = useCartBilling(id ?? '');
  const queryClient: QueryClient = useQueryClient();

  useEffect(
    (): void => {
      if (!socket || connectedToSocket || !cartBillingState.cartData) {
        return;
      }

      setIsConnectedToSocket();
      cartBillingState.cartData.auctions.forEach((cartAuction: CartAuctionInterface): void => {
        socket.emit(WebSocketCommand.SubscribeAuction, { id: cartAuction.auction.auctionId } as WebSocketSubscriptionDetails);
      });
      socket.on(WebSocketCommand.AuctionSold, (data: AuctionSoldInterface): void => {
        if (data.buyer.id !== userData?.id) {
          addToast({ content: t('onarte.website.common.auctionInYourCartSold', { name: data.auction.name })});
          void queryClient.invalidateQueries(QueryKey.Cart);
        }
      });
    },
    [socket, cartBillingState.cartData]
  );

  useEffect(
    (): (() => void) => {
      return (): void => {
        if (socket) {
          socket.off(WebSocketCommand.AuctionSold);
        }
      };
    },
    [socket]
  );

  useEffect(
    (): (() => void) => {
      return (): void => {
        if (socket) {
          socket.off(WebSocketCommand.AuctionSold);
        }
      };
    },
    [socket]
  );

  return (
    <CartBaseView
      breadcrumbs={[
        { label: t('onarte.website.meta.shop.title'), path: getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/' },
        { 
          label: t('onarte.website.meta.cartDelivery.title'),
          path: getPathWithParams(
            getRouteDetailsByName(RouteNameEnum.CartDelivery)?.url ?? '/', { id: cartBillingState.cartData?.id ?? '' }
          ), 
        },
        { label: t('onarte.website.meta.cartBilling.title'), path: getRouteDetailsByName(RouteNameEnum.CartBilling)?.url ?? '/' },
      ]}
    >
      <Container>
        <DeliveryAddress>
          {t('onarte.website.cartBilling.deliveryAddress')} {deliveryAddress}
          <UnderlineButton onClick={goToDelivery}>
            {t('onarte.common.change')}
          </UnderlineButton>
        </DeliveryAddress>
        <Subtitle>{t('onarte.website.cartBilling.paymentType')}</Subtitle>
        {cardGateway && (
          <StyledChooseBox 
            title={cardGateway.gatewayName}
            logo={cardGateway.iconURL}
            onChange={(): void => onPaymentMethodChangeAction(PaymentTypeEnum.CardPayment, String(cardGateway.gatewayID))}
            isActive={cartBillingState.paymentMethod === PaymentTypeEnum.CardPayment}
          />
        )}
        {blikGateway && (
          <StyledChooseBox 
            title={blikGateway.gatewayName}
            logo={blikGateway.iconURL} 
            onChange={(): void => onPaymentMethodChangeAction(PaymentTypeEnum.Blik, String(blikGateway.gatewayID))}
            isActive={cartBillingState.paymentMethod === PaymentTypeEnum.Blik}
          />
        )}
        {!!onlineGateways.length && (
          <StyledChooseBox 
            title={t('onarte.website.cartBilling.onlinePayment')}
            logo={FileFromViews.BlueMedia}
            onChange={(): void => onPaymentMethodChangeAction(
              PaymentTypeEnum.OnlinePayment, 
              String(onlineGateways[0].gatewayID)
            )}
            isActive={cartBillingState.paymentMethod === PaymentTypeEnum.OnlinePayment}
            children={
              <GatewaysContainer>
                {onlineGateways
                  .map((gateway: PaymentGatewayInterface): JSX.Element => (
                    <GatewayItem 
                      key={gateway.gatewayID} 
                      onClick={(): void => onPaymentMethodChangeAction(PaymentTypeEnum.OnlinePayment, String(gateway.gatewayID))}
                      $isActive={cartBillingState.gatewayId === String(gateway.gatewayID)}
                    >
                      <GatewayIcon src={gateway.iconURL} />
                    </GatewayItem>
                  ))}
              </GatewaysContainer>
            }
          />
        )}
        <Subtitle>{t('onarte.website.cartBilling.invoiceRequired.subtitle')}</Subtitle>
        <StyledChooseBox
          onChange={onInvoiceRequiredChange}
          title={t('onarte.website.cartBilling.invoiceRequired.chooseBox')}
          isActive={cartBillingState.isInvoiceRequired}
          isCheckbox
        />
        {cartBillingState.isInvoiceRequired && (
          cartBillingState.currentAddress ? (
            <StyledShippingBox
              title={t(getBillingAddressTitle(cartBillingState.currentAddress))}
              addressLines={getPrettyAddressLines(cartBillingState.currentAddress, AddressTypeEnum.Billing)}
              boxTheme={ShippingBoxTheme.Dark}
              onChangeClick={(): void => cartBillingDispatch({ type: CartBillingAction.SetIsPopupVisible, payload: true })}
            />
          ) : (
            <StyledShippingBox
              addressLines={[t('onarte.website.cartBilling.chooseAddress')]}
              boxTheme={ShippingBoxTheme.Dark}
              onChangeClick={(): void => cartBillingDispatch({ type: CartBillingAction.SetIsPopupVisible, payload: true })}
            />
          )
        )}
        <StyledValidationBar 
          message={t('onarte.website.cartBilling.validationBar')}
          barTheme={ValidationBarTheme.Information}
        />
        <ButtonsContainer>
          <StyledButton 
            label={t('onarte.website.cartBilling.finishButton.label')} 
            fullWidth 
            onClick={onFinishCartAction} 
            isLoading={isFinishButtonLoading}
            disabled={isFinishButtonLoading || !cartBillingState.paymentMethod}
            id='cart-finish-button'
          />
          <UnderlineButton onClick={goToDelivery}>
            {t('onarte.website.cartBilling.underlineButton.label')}
          </UnderlineButton>
        </ButtonsContainer>
      </Container>
      <AddressesDialog
        formType={AddressTypeEnum.Billing}
        activeAddressId={cartBillingState.currentAddress?.id}
        onActiveAddressChange={onAddressChoose}
        onClose={(): void => cartBillingDispatch({ type: CartBillingAction.SetIsPopupVisible, payload: false })}
        isVisible={cartBillingState.isPopupVisible}
      />
    </CartBaseView>
  );
};
