import { CartBillingAction } from './cartBilling.enum';
import { CartBillingState } from './cartBilling.interface';
import { CartBillingActions } from './cartBilling.type';

export const cartBillingReducer = (
  state: CartBillingState, action: CartBillingActions
): CartBillingState => {
  switch (action.type) {
    case CartBillingAction.SetPaymentMethod:
      return { ...state, paymentMethod: action.payload };
    case CartBillingAction.SetCartData:
      return { ...state, cartData: action.payload };
    case CartBillingAction.SetCurrentAddress:
      return { ...state, currentAddress: action.payload };
    case CartBillingAction.SetIsInvoiceRequired:
      return { ...state, isInvoiceRequired: action.payload };
    case CartBillingAction.SetIsPopupVisible:
      return { ...state, isPopupVisible: action.payload };
    case CartBillingAction.SetGatewayId:
      return { ...state, gatewayId: action.payload };
    default:
      return state;
  }
};
