import { ArrowMenu, Breakpoint, Color, FontWeight, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Gray200};
`;

export const Title: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.01em;
  color: ${Color.Black};
  padding: 80px 40px 40px;

  @media ${Breakpoint.Tablet} {
    padding: 80px 32px 40px;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    padding: 80px 20px 40px;
  }
  
  @media ${Breakpoint.SmallMobile} {
    padding: 40px 20px;
  }
`;

export const StyledArrowMenu: StyledComponent<typeof ArrowMenu> = styled(ArrowMenu)`
  min-width: 300px;
  margin-right: 40px;

  @media ${Breakpoint.Tablet} {
    min-width: 0;
    max-width: 260px;
    width: 100%;
  }

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
    margin-right: 0;
  }
`;

// TODO: calc min-height with footer
export const ContentContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  padding: 60px 40px;
  background-color: ${Color.White};

  @media ${Breakpoint.Tablet} {
    padding: 60px 32px;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    padding: 60px 20px;
  }
`;

export const ChildrenContainer: StyledComponent<'div'> = styled.div`
  max-width: 740px;
  min-width: 600px;
  margin: 0 auto;
  width: 100%;

  @media ${Breakpoint.Tablet} {
    min-width: 0;
  }

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
    margin: 60px 0 0;
  }
`;
