import { Color, FontWeight, Markdown, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const MarkdownContainer: StyledComponent<'div'> = styled.div`
  & + & {
    margin-top: 40px;
  }
`;

export const Header: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.Medium};
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: ${Color.Black};
  display: inline-block;
  margin-bottom: 24px;
`;

export const Content: StyledComponent<typeof Markdown> = styled(Markdown)`
  p {
    font-weight: ${FontWeight.Light};
    font-size: 16px;
    line-height: 22px;
    color: ${Color.Black};
    margin-bottom: 24px;
  }

  a {
    color: ${Color.Black};
    text-decoration-line: underline;
    font-weight: ${FontWeight.Medium};
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 24px;

    li {
      font-weight: ${FontWeight.Light};
      font-size: 16px;
      line-height: 22px;
      color: ${Color.Black};
    }

    li + li {
      margin-top: 4px;
    }
  }
`;
