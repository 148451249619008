import { AddressInterface, AddressTypeEnum, DeliveryTypeEnum } from '@on-arte/core-types';
import {
  getPathWithParams,
  ShippingAddressFormData,
  UseNotifications,
  useNotifications,
  useRedirect,
  UseRedirect
} from '@on-arte/ui';
import { FormikProps } from 'formik';
import { Dispatch } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from 'react-query';

import {
  saveAddress,
  updateAddressIdInOrder,
  updateCommentToOrder,
  updateDeliveryInCartAuction
} from '@onArte/frontend/api/requests';
import { QueryKey } from '@onArte/frontend/enums';
import { useAddresses } from '@onArte/frontend/hooks';
import { UseAddresses } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { RouteNameEnum } from '@onArte/shared/enums';
import { AddressSaveWithLocationModel, UpdateDeliveryInCartAuctionModel } from '@onArte/shared/models';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { UseCartDelivery } from './cartDelivery.types';
import { CartDeliveryAction, CartDeliveryActions, CartDeliveryState } from './reducer';

export const useCartDelivery: (
  state: CartDeliveryState,
  dispatch: Dispatch<CartDeliveryActions>,
  formRef: React.RefObject<FormikProps<ShippingAddressFormData>>,
  setFormSubmitted: () => void,
  setFormNotSubmitted: () => void,
  cartId?: string) => UseCartDelivery
= (
  state: CartDeliveryState,
  dispatch: Dispatch<CartDeliveryActions>,
  formRef: React.RefObject<FormikProps<ShippingAddressFormData>>,
  setFormSubmitted: () => void,
  setFormNotSubmitted: () => void,
  cartId: string = '',
): UseCartDelivery => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { addToast }: UseNotifications = useNotifications();
  const {
    transformShippingAddressDataToAddressWithLocation,
    transformBillingAddressDataToAddressWithLocation,
  }: UseAddresses = useAddresses();
  const queryClient: QueryClient = useQueryClient();

  const onAddressChoose: (address: AddressInterface) => void = (address: AddressInterface): void => {
    updateAddressIdInOrder(cartId, { addressType: AddressTypeEnum.Delivery, addressId: address.id })
      .then((): void => {
        dispatch({ type: CartDeliveryAction.SetCurrentAddress, payload: address });
        dispatch({ type: CartDeliveryAction.SetIsPopupVisible, payload: false });
      })
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  const saveNewAddressMethod: () => Promise<void> = (): Promise<void> => {
    return new Promise(async (resolve: (() => void), reject: (() => void)): Promise<void> => {
      if (!formRef?.current) {
        return;
      }

      setFormSubmitted();
      const formObject: FormikProps<ShippingAddressFormData> = formRef.current;
      const isValid: boolean = !Object.keys((await formObject.validateForm())).length;

      if (isValid) {
        const shippingAddress: AddressSaveWithLocationModel = transformShippingAddressDataToAddressWithLocation(formObject.values);
        const billingAddress: AddressSaveWithLocationModel = transformBillingAddressDataToAddressWithLocation(formObject.values);
        Promise.all([
          new Promise<void>((addressResolve: (() => void), addressReject: (() => void)): void => {
            saveAddress(shippingAddress)
              .then((data: AddressInterface): void => {
                dispatch({ type: CartDeliveryAction.SetCurrentAddress, payload: data });
                setFormNotSubmitted();
                formRef?.current?.resetForm();
                updateAddressIdInOrder(cartId, { addressType: AddressTypeEnum.Delivery, addressId: data.id })
                  .then((): void => addressResolve())
                  .catch((error: FrontendApiError): void => {
                    addToast({ content: t(error.message) });
                    addressReject();
                  });
              })
              .catch((error: FrontendApiError): void => {
                addToast({ content: t(error.message) });
                addressReject();
              });
          }),
          new Promise<void>((addressResolve: (() => void), addressReject: (() => void)): void => {
            saveAddress(billingAddress)
              .then((data: AddressInterface): void => {
                updateAddressIdInOrder(cartId, { addressType: AddressTypeEnum.Billing, addressId: data.id })
                  .then((): void => addressResolve())
                  .catch((error: FrontendApiError): void => {
                    addToast({ content: t(error.message) });
                    addressReject();
                  });
              })
              .catch((error: FrontendApiError): void => {
                addToast({ content: t(error.message) });
                addressReject();
              });
          }),
        ])
          .then((): void => resolve())
          .catch((): void => undefined);
      }
    });
  };

  const onItemShippingSettingsChange: (deliveryType: string, commentToSeller: string, auctionId: string) => void = (
    deliveryType: string, commentToSeller: string, auctionId: string
  ): void => {
    updateDeliveryInCartAuction(cartId, auctionId, { commentToSeller, deliveryType: deliveryType as DeliveryTypeEnum })
      .then((): void => {
        const editedData: UpdateDeliveryInCartAuctionModel | undefined = state.chosenAuctionDeliverySettings[auctionId];
        if (editedData) {
          editedData.deliveryType = deliveryType as DeliveryTypeEnum;
          editedData.commentToSeller = commentToSeller;
        } else {
          dispatch({
            type: CartDeliveryAction.SetChosenAuctionDeliverySettings,
            payload: { [auctionId]: { commentToSeller, deliveryType: deliveryType as DeliveryTypeEnum } }
          });
        }
        void queryClient.invalidateQueries(QueryKey.Cart);
      })
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  const onCommentToOrderChange: (commentToOrder: string) => void = (commentToOrder: string): void => {
    updateCommentToOrder(cartId, { commentToOrder })
      .then((): void => {
        dispatch({ type: CartDeliveryAction.SetCommentToOrder, payload: commentToOrder });
      })
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  const submit: () => void = (): void => {
    if (!state.currentAddress) {
      saveNewAddressMethod()
        .then((): void => {
          redirect(getPathWithParams(getRouteDetailsByName(RouteNameEnum.CartBilling)?.url ?? '/', { id: cartId ?? '' }));
        })
        .catch((): void => undefined);
    } else {
      redirect(getPathWithParams(getRouteDetailsByName(RouteNameEnum.CartBilling)?.url ?? '/', { id: cartId ?? '' }));
    }
  };

  const redirectToHomePage: () => void = (): void => redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');

  return {
    onAddressChoose,
    onItemShippingSettingsChange,
    onCommentToOrderChange,
    submit,
    redirectToHomePage
  };
};
