import { PublicProfileTypeEnum } from '@on-arte/core-types';
import { UseLogger, UseState, useLogger } from '@on-arte/ui';
import { useMemo, useState } from 'react';
import { UseQueryResult, useQuery } from 'react-query';

import { FrontendApiError } from '@onArte/frontend/models';
import { PublicProfileList, PublicProfileListItem } from '@onArte/shared/interfaces';

import { getPublicProfilesList } from '../api/requests';
import { QueryKey } from '../enums';
import { UsePublicProfilesListActions } from '../interfaces';

export const usePublicProfilesListActions: (type: PublicProfileTypeEnum) => UsePublicProfilesListActions = (
  type: PublicProfileTypeEnum
): UsePublicProfilesListActions => {
  const { logger }: UseLogger = useLogger();
  const [activeLetter, setActiveLetter]: UseState<string> = useState<string>('');
  const [alphabet, setAlphabet]: UseState<Record<string, number>> = useState<Record<string, number>>({});
  const [profilesList, setProfilesList]: UseState<PublicProfileListItem[]> = useState<PublicProfileListItem[]>([]);

  const { isLoading }: UseQueryResult = useQuery(
    [QueryKey.PublicProfilesList, activeLetter],
    (): Promise<PublicProfileList> => getPublicProfilesList(type, activeLetter),
    {
      onSuccess: (data: PublicProfileList): void => {
        setAlphabet(data.pagination);
        setProfilesList(data.elements);
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.PublicProfilesList, error)
    }
  );

  const initialActiveLetter: string = useMemo(
    (): string => {
      return Object.keys(alphabet)[0] ?? '';
    },
    [alphabet]
  );

  return {
    alphabet,
    activeLetter,
    setActiveLetter,
    isLoading,
    profilesList,
    initialActiveLetter
  };
};
