import { Breakpoint, Button, Color, FontWeight, FormField, FormSection, Grid, StyledComponent, TextBox, ZIndex } from '@on-arte/ui';
import styled from 'styled-components';

import { StyledTextBoxProps } from './userArtworkAdd.types';

export const FormContainer: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.FullHd};
  background-color: ${Color.White};
`;

export const StyledFormField: StyledComponent<typeof FormField> = styled(FormField)`
  & + & {
    margin-top: 24px;
  }
`;

export const StyledFormSection: StyledComponent<typeof FormSection> = styled(FormSection)`
  & + & {
    margin-top: 60px;
  }
`;

export const StyledTextBox: StyledComponent<typeof TextBox, StyledTextBoxProps> = styled(TextBox)<StyledTextBoxProps>`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: ${ZIndex.FixedTextBoxes};
  transition: 0.3s;

  ${({ $visible }: StyledTextBoxProps): string => $visible ? `
    opacity: 1;
    pointer-events: initial;
  ` : `
    opacity: 0;
    pointer-events: none;
  `}
`;

export const ResultContainer: StyledComponent<'div'> = styled.div`
  width: calc(100% - 80px);
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${Color.Gray300};
  background-color: ${Color.Gray200};

  @media ${Breakpoint.Tablet} {
    margin: 0 32px;
    width: calc(100% - 64px);
  }

  @media ${Breakpoint.SmallMobile} {
    margin: 0 20px;
    width: calc(100% - 40px);
  }
`;

export const ResultInnerContainer: StyledComponent<'div'> = styled.div`
  max-width: 480px;
  width: 100%;
  margin: 120px auto 0;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const IconContainer: StyledComponent<'div'> = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid ${Color.Gray300};
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

export const MessageHeader: StyledComponent<'div'> = styled.div`
  margin-bottom: 16px;
  font-weight: ${FontWeight.Medium};
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${Color.Black};
`;

export const MessageDescription: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Light};
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: ${Color.Black};
  margin-bottom: 32px;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: ${ZIndex.FixedButton};
`;
