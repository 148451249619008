import { ObjectIterator } from '@on-arte/ui';

export const regexps: ObjectIterator<RegExp>  = {
  // eslint-disable-next-line max-len
  Email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*^()\-+|,\.;:'"])(?=.*\d).{8,}$/,
  Phone: /^((\+?)(?<prefix>(48)))?(?<number>\d{9})$/i,
  FirstName: /^[a-zA-Ząęóżźćńłś]{2,64}$/i,
  LastName: /^[a-zA-Ząęóżźćńłś \-\p{L}]{2,64}$/iu,
  PostalCode: /\d{2}-\d{3}/,
  AccountNumber: /[A-Z]{2}\d{26}/
};
