import { AddressTypeEnum, phoneRegexp, LocationModel } from '@on-arte/core-types';
import { Type } from 'class-transformer';
import {
  IsDefined,
  IsEnum,
  IsMobilePhone,
  IsNotEmptyObject,
  IsObject,
  IsOptional,
  Length,
  Matches,
  ValidateNested
} from 'class-validator';

export class AddressSaveWithLocationModel {
  @IsOptional()
  public id?: string;

  @IsEnum(AddressTypeEnum)
  public type: AddressTypeEnum;

  @Length(2, 64)
  public firstName: string;

  @Length(2, 128)
  public lastName: string;

  @IsOptional()
  @Length(9, 12)
  @Matches(phoneRegexp)
  @IsMobilePhone()
  public phone?: string;

  @IsOptional()
  @Length(2, 128)
  public companyName?: string;

  @IsOptional()
  @Length(2, 16)
  public taxId?: string;

  @IsDefined()
  @IsNotEmptyObject()
  @IsObject()
  @ValidateNested()
  @Type(() => LocationModel)
  public location: LocationModel;

  constructor(
    type: AddressTypeEnum,
    firstName: string,
    lastName: string,
    location: LocationModel,
    phone?: string,
    companyName?: string,
    taxId?: string,
    id?: string,
  ) {
    this.type = type;
    this.firstName = firstName;
    this.lastName = lastName;
    this.location = location;
    this.phone = phone;
    this.companyName = companyName;
    this.taxId = taxId;
    this.id = id;
  }
}
