import { IsDefined } from 'class-validator';

export class UpdateCommentToOrderModel {
  @IsDefined()
  public commentToOrder: string;

  constructor(
    commentToOrder: string,
  ) {
    this.commentToOrder = commentToOrder;
  }
}
