import { PublicProfileTypeEnum } from '@on-arte/core-types';
import { getPathWithParams } from '@on-arte/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { BaseViewWithAlphabet } from '@onArte/frontend/components';
import { usePublicProfilesListActions } from '@onArte/frontend/hooks';
import { UsePublicProfilesListActions } from '@onArte/frontend/interfaces';
import { UrlSearchParams } from '@onArte/frontend/types';
import { RouteNameEnum } from '@onArte/shared/enums';
import { PublicProfileListItem } from '@onArte/shared/interfaces';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { ListContainer, ListItem, ListItemButton } from './artistsList.styled';

export const ArtistsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    alphabet,
    activeLetter,
    setActiveLetter,
    isLoading,
    profilesList,
    initialActiveLetter
  }: UsePublicProfilesListActions = usePublicProfilesListActions(PublicProfileTypeEnum.Manufacturer);
  const [searchParams]: UrlSearchParams = useSearchParams();

  useEffect(
    (): void => {
      const query: string | null = searchParams.get('query');

      if (query && query.length === 1) {
        setActiveLetter(query);
      }
    },
    [searchParams.toString()]
  );
  
  return (
    <BaseViewWithAlphabet
      pageTitle={t(
        'onarte.website.artistsListView.pageTitle.title',
        { value: (activeLetter || initialActiveLetter).toUpperCase() }
      )}
      pageDescription={t('onarte.website.artistsView.pageTitle.description')}
      alphabet={alphabet}
      activeLetter={activeLetter}
      setActiveLetter={setActiveLetter}
    >
      <ListContainer>
        {!isLoading && (
          profilesList.length ? (
            profilesList.map((profile: PublicProfileListItem): JSX.Element => (
              <ListItemButton 
                internalPath={profile.route?.prettyUrl 
                  ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtistDetails)?.url ?? '/', { id: profile.id })}
                key={profile.id}
              >
                <ListItem>
                  {profile.name}
                </ListItem>
              </ListItemButton>
            ))
          ) : (
            <ListItem>{t('onarte.website.artistsListView.listItem.emptyList')}</ListItem>
          )
        )}
      </ListContainer>
    </BaseViewWithAlphabet>
  );
};
