import {
  AttachmentInterface,
  AuctionInfoInterface,
  AuctionTypeEnum,
  EventListElementInterface,
  PostDTO,
  ThumbnailAttachmentTypeEnum
} from '@on-arte/core-types';
import { AddPhotoFile, BoxSlide, Color, getPathWithParams, hexToRgba } from '@on-arte/ui';
import { TransProps, useTranslation } from 'react-i18next';

import { UseObjectsTranformations } from '@onArte/frontend/interfaces';
import { getImageThumbnail } from '@onArte/frontend/utils';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const useObjectsTransformations: () => UseObjectsTranformations = (): UseObjectsTranformations => {
  const { t }: TransProps<never> = useTranslation();

  const transformAuctionToBoxSlideType: (auction: AuctionInfoInterface) => BoxSlide = (auction: AuctionInfoInterface): BoxSlide => {
    return {
      image: getImageThumbnail(auction.coverPhoto, ThumbnailAttachmentTypeEnum.Size_340xAuto),
      boxTitleDetails: {
        author: auction.manufacturer.name,
        itemDescriptiveAttributes: [
          auction.name,
          t(auction.label),
        ],
        price: auction.type === AuctionTypeEnum.PriceProposal ? undefined : auction.price,
        bottomButtonLabel: auction.type === AuctionTypeEnum.PriceProposal ? t('onarte.common.priceProposal') : undefined,
        biddersNumber: auction.type === AuctionTypeEnum.Bidding
          ? auction.offers
          : undefined,
      },
      internalPath: auction.route?.prettyUrl
        ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/', { id: auction.id }),
    };
  };

  const transformEventToBoxSlideType: (event: EventListElementInterface) => BoxSlide = (event: EventListElementInterface): BoxSlide => {
    return {
      image: event.coverPhoto?.path ?? '',
      boxTitleDetails: {
        eventName: event.name,
        eventEndTimestamp: event.endTimestamp ?? undefined,
      },
      badge: {
        label: t('onarte.common.eventStatuses.finished'),
        color: Color.White,
        background: hexToRgba(Color.Black, 0.25)
      },
      internalPath: event.route?.prettyUrl
        ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.EventDetails)?.url ?? '/', { id: event.id }),
    };
  };

  const transformAttachmentInterfaceToAddPhotoFile: (item: AttachmentInterface) => AddPhotoFile = (
    item: AttachmentInterface
  ): AddPhotoFile => ({
    id: item.id,
    type: item.type,
    path: item.path,
    name: item.name ?? '',
    objectName: null,
  });

  const transformPostDtoToBoxSlideType: (post: PostDTO) => BoxSlide = (post: PostDTO): BoxSlide => {
    return {
      image: post.cover ?? '',
      boxTitleDetails: {
        newsTitle: post.title,
        tagLabel: post.categories[0],
      },
      externalUrl: post.link,
      shouldOpenInNewTab: true,
    };
  };

  return { 
    transformAuctionToBoxSlideType,
    transformEventToBoxSlideType,
    transformAttachmentInterfaceToAddPhotoFile,
    transformPostDtoToBoxSlideType
  };
};
