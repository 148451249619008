import { ItemStatusEnum } from '@on-arte/core-types';
import { Color, Status } from '@on-arte/ui';

export const itemStatusConfig: Record<ItemStatusEnum, Status> = {
  [ItemStatusEnum.WaitingForApproval]: {
    label: 'onarte.common.itemStatuses.waitingForApproval',
    background: Color.Warning100,
    color: Color.Warning500
  },
  [ItemStatusEnum.Approved]: {
    label: 'onarte.common.itemStatuses.approved',
    background: Color.Success100,
    color: Color.Success500
  },
  [ItemStatusEnum.Rejected]: {
    label: 'onarte.common.itemStatuses.rejected',
    background: Color.Error100,
    color: Color.Error500
  },
  [ItemStatusEnum.Deleted]: {
    label: 'onarte.common.itemStatuses.deleted',
    background: Color.Gray200,
    color: Color.Gray500
  },
};
