import { Logo, useRedirect, UseRedirect } from '@on-arte/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import {
  BottomLink,
  Container,
  DotSeparator,
  FormInnerWrapper,
  FormWrapper,
  LinksContainer,
  LogoButton,
  TabItem,
  Tabs
} from './loginFormWrapper.styled';
import { LoginFormWrapperProps, Tab } from './loginFormWrapper.types';

export const LoginFormWrapper: React.FC<LoginFormWrapperProps> = (props: LoginFormWrapperProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { children, tabs, activeTab }: LoginFormWrapperProps = props;
  const { redirect }: UseRedirect = useRedirect();
  const navigateToHome: () => void = (): void => {
    redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
  };

  return (
    <Container>
      <LogoButton onClick={navigateToHome}>
        <Logo />
      </LogoButton>
      {!!tabs?.length && (
        <Tabs>
          {tabs.map((tab: Tab): JSX.Element => (
            <TabItem key={tab.name} to={tab.path} $isActive={activeTab === tab.name}>
              {tab.label}
            </TabItem>
          ))}
        </Tabs>
      )}
      <FormWrapper>
        <FormInnerWrapper>
          {children}
        </FormInnerWrapper>
      </FormWrapper>
      <LinksContainer> 
        <BottomLink internalPath={getRouteDetailsByName(RouteNameEnum.PrivacyPolicy)?.url ?? '/'}>
          {t('onarte.common.privacyPolicy')}
        </BottomLink>
        <DotSeparator />
        <BottomLink internalPath={getRouteDetailsByName(RouteNameEnum.Regulations)?.url ?? '/'}>
          {t('onarte.common.regulations')}
        </BottomLink>
      </LinksContainer>
    </Container>
  );
};
