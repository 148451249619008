import { Color, FontWeight, Input, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const SettingsContainer: StyledComponent<'div'> = styled.div`
  max-width: 408px;
  width: 100%;
`;

export const FormContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  
  & + & {
    margin-top: 60px;
  }
`;

export const FormTitle: StyledComponent<'div'> = styled.div`
  margin-bottom: 32px;
  font-weight: ${FontWeight.Medium};
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: ${Color.Black};
`;
