import { AgreementInterface, AgreementTypeEnum, ExternalUserProfileWithContextTokenInterface } from '@on-arte/core-types';
import {
  HeadingType,
  IconName,
  UseFormikForm,
  useFormikForm,
  UseLogger,
  useLogger,
  UseNotifications,
  useNotifications,
  UseRedirect, 
  useRedirect,
  UseState
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import * as Yup from 'yup';

import { getAgreements, registration } from '@onArte/frontend/api/requests';
import { BaseSuccessView, LoginFormWrapper } from '@onArte/frontend/components';
import { QueryKey } from '@onArte/frontend/enums';
import { useAuth } from '@onArte/frontend/hooks';
import { UseAuth } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { FormikForm } from '@onArte/frontend/theme';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { useRegistrationValidation } from './registration.hooks';
import {
  Checkboxes,
  Container,
  StyledButton,
  StyledCheckbox,
  StyledInput,
  StyledSocialMediaLoginBar,
  StyledHeading,
} from './registration.styled';
import { UserRegistrationForm } from './registration.types';

export const RegistrationView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const SignupFormValidationSchema: Yup.SchemaOf<UserRegistrationForm> = useRegistrationValidation();
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const { addToast }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const { logger }: UseLogger = useLogger();
  const [agreements, setAgreements]: UseState<Record<AgreementTypeEnum, AgreementInterface | null>>
    = useState<Record<AgreementTypeEnum, AgreementInterface | null>>({
      personalDataAgreement: null,
      marketingUsageAgreement: null
    });
  const [isRegistrationSuccess, setIsRegistrationSuccess]: UseState<boolean> = useState<boolean>(false);

  useQuery(
    [QueryKey.Agreements],
    (): Promise<AgreementInterface[]> => getAgreements(),
    {
      onSuccess: (data: AgreementInterface[]): void => {
        let agreementsObject: Record<AgreementTypeEnum, AgreementInterface | null> = {
          personalDataAgreement: null,
          marketingUsageAgreement: null
        };
        data.forEach((agreement: AgreementInterface): void => {
          agreementsObject = {
            ...agreementsObject,
            [agreement.type]: {
              ...agreement,
              label: injectUrls(agreement.label)
            }
          };
        });
        setAgreements(agreementsObject);
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.Agreements, error)
    }
  );

  const registrationAction: (data: UserRegistrationForm) => void = (data: UserRegistrationForm): void => {
    registration({
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      password: data.password,
      agreements: [
        {
          type: AgreementTypeEnum.PersonalData,
          version: agreements[AgreementTypeEnum.PersonalData]?.version ?? '',
          selected: data[AgreementTypeEnum.PersonalData]
        },
        {
          type: AgreementTypeEnum.MarketingUsage,
          version: agreements[AgreementTypeEnum.PersonalData]?.version ?? '',
          selected: data[AgreementTypeEnum.MarketingUsage]
        }
      ]
    })
      .then((): void => {
        addToast({ content: t('onarte.website.registrationView.registrationSuccessful') });
        setIsRegistrationSuccess(true);
      })
      .catch((error: FrontendApiError): void => {
        addToast({ content: t(error.message) });
      });
  };

  const injectUrls: (content: string) => string = (content: string): string => {
    return t(content)
      .replace('[regulationsPath]', getRouteDetailsByName(RouteNameEnum.Regulations)?.url ?? '/')
      .replace('[privacyPolicyPath]', getRouteDetailsByName(RouteNameEnum.PrivacyPolicy)?.url ?? '/');
  };

  if (isRegistrationSuccess) {
    return (
      <BaseSuccessView 
        title={t('onarte.website.registrationView.baseSuccessView.message.title')}
        description={t('onarte.website.registrationView.baseSuccessView.message.description')}
        icon={IconName.Collector}
        buttonLabel={t('onarte.website.registrationView.baseSuccessView.buttonLabel')}
        buttonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/')}
        id='registration-success-view'
        fullHeight
      />
    );
  }

  return (
    <Container>
      <LoginFormWrapper
        activeTab='registration'
        tabs={[
          {
            name: 'registration',
            label: t('onarte.website.common.signInTabs.signUp'),
            path: getRouteDetailsByName(RouteNameEnum.Registration)?.url ?? '/'
          },
          {
            name: 'signIn',
            label: t('onarte.website.common.signInTabs.signIn'),
            path: getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/'
          },
        ]}
      >
        {/* TODO: temporary hidden */}
        {/* <StyledHeading type={HeadingType.H4} text={t('onarte.website.registrationView.header')} /> */}
        {/* <StyledSocialMediaLoginBar barLabel={t('onarte.website.registrationView.socialMediaLoginBarLabel')} position='bottom' /> */}
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            password: '',
            personalDataAgreement: false,
            marketingUsageAgreement: false,
          }}
          onSubmit={registrationAction}
          validationSchema={SignupFormValidationSchema}
          validateOnChange={isFormSubmitted}
          validateOnBlur={isFormSubmitted}
        >
          {({ handleSubmit, setFieldValue, errors }: FormikProps<UserRegistrationForm>) => (
            <FormikForm onSubmit={handleSubmit}>
              <StyledInput
                label={t('onarte.common.firstName')}
                onChange={(value: string): void => setFieldValue('firstName', value)}
                onBlur={(value: string): void => setFieldValue('firstName', value)}
                validationMessage={errors.firstName ? errors.firstName : ''}
              />
              <StyledInput
                label={t('onarte.common.lastName')}
                onChange={(value: string): void => setFieldValue('lastName', value)}
                onBlur={(value: string): void => setFieldValue('lastName', value)}
                validationMessage={errors.lastName || ''}
              />
              <StyledInput
                label={t('onarte.common.phone')}
                onChange={(value: string): void => setFieldValue('phone', value)}
                onBlur={(value: string): void => setFieldValue('phone', value)}
                validationMessage={errors.phone || ''}
                withOnlyNumbersAllowed
              />
              <StyledInput
                label={t('onarte.common.email')}
                onChange={(value: string): void => setFieldValue('email', value)}
                onBlur={(value: string): void => setFieldValue('email', value)}
                validationMessage={errors.email || ''}
              />
              <StyledInput
                label={t('onarte.common.password')}
                onChange={(value: string): void => setFieldValue('password', value)}
                onBlur={(value: string): void => setFieldValue('password', value)}
                validationMessage={errors.password || ''}
                description={t('onarte.common.validations.passwordDescription')}
                type='password'
              />
              <Checkboxes>
                <StyledCheckbox
                  label={agreements[AgreementTypeEnum.PersonalData]?.label ?? ''}
                  id={AgreementTypeEnum.PersonalData}
                  onChange={(value: boolean): void => setFieldValue(AgreementTypeEnum.PersonalData, value)}
                  validationMessage={errors[AgreementTypeEnum.PersonalData] || ''}
                />
                <StyledCheckbox
                  label={agreements[AgreementTypeEnum.MarketingUsage]?.label ?? ''}
                  id={AgreementTypeEnum.MarketingUsage}
                  onChange={(value: boolean): void => setFieldValue(AgreementTypeEnum.MarketingUsage, value)}
                  validationMessage={errors[AgreementTypeEnum.MarketingUsage] || ''}
                />
              </Checkboxes>
              <StyledButton
                label={t('onarte.website.registrationView.submitButtonLabel')}
                fullWidth
                type='submit'
                onClick={setFormSubmitted}
              />
            </FormikForm>
          )}
        </Formik>
      </LoginFormWrapper>
    </Container>
  );
};
