import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { regexps } from '@onArte/shared/constants';

import { UserRegistrationForm } from './registration.types';

export const useRegistrationValidation: () => Yup.SchemaOf<UserRegistrationForm> = (): Yup.SchemaOf<UserRegistrationForm> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    firstName: Yup.string()
      .required(t('onarte.common.requiredField'))
      .matches(regexps.FirstName, t('onarte.common.validations.firstName')),
    lastName: Yup.string()
      .required(t('onarte.common.requiredField'))
      .matches(regexps.LastName, t('onarte.common.validations.lastName')),
    phone: Yup.string()
      .required(t('onarte.common.requiredField'))
      .matches(regexps.Phone, t('onarte.common.validations.phone')),
    email: Yup.string()
      .required(t('onarte.common.requiredField'))
      .email(t('onarte.common.validations.email')),
    password: Yup.string()
      .required(t('onarte.common.requiredField'))
      .matches(regexps.Password, t('onarte.common.validations.password')),
    personalDataAgreement: Yup.boolean()
      .isTrue(t('onarte.common.requiredField'))
      .required(t('onarte.common.requiredField')),
    marketingUsageAgreement: Yup.boolean()
      .required(t('onarte.common.requiredField')),
  });
};
