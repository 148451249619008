import { IconName, useLogger, UseLogger, useRedirect, UseRedirect } from '@on-arte/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { updatePaymentStatus } from '@onArte/frontend/api/requests';
import { BaseSuccessView } from '@onArte/frontend/components';
import { FrontendApiError } from '@onArte/frontend/models';
import { UrlSearchParams } from '@onArte/frontend/types';
import { BlueMediaSearchParam, RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const CartSuccessView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const [searchParams]: UrlSearchParams = useSearchParams();
  const { logger }: UseLogger = useLogger();

  useEffect(
    (): void => {
      const orderId: string | null = searchParams.get(BlueMediaSearchParam.OrderId);
      const hash: string | null = searchParams.get(BlueMediaSearchParam.Hash);

      if (orderId && hash) {
        updatePaymentStatus({ orderId, hash })
          .catch((error: FrontendApiError): void => logger('CartSuccessView', error));
      }
    },
    [searchParams.toString()]
  );

  return (
    <BaseSuccessView 
      title={t('onarte.website.cartSuccessView.message.title')}
      description={t('onarte.website.cartSuccessView.message.description')}
      icon={IconName.Collector}
      buttonLabel={t('onarte.website.cartSuccessView.message.button')}
      buttonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.UserMyShopping)?.url ?? '/')}
    />
  );
};
