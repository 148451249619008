import { LoginByPasswordModel } from '@on-arte/core-types';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useSignInValidation: () => Yup.SchemaOf<LoginByPasswordModel> = (): Yup.SchemaOf<LoginByPasswordModel> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    login: Yup.string()
      .required(t('onarte.common.requiredField')),
    password: Yup.string()
      .required(t('onarte.common.requiredField')),
  });
};
