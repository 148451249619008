import { RouteNameEnum } from '@onArte/shared/enums';

export const pathsWithFooterHidden: RouteNameEnum[] = [
  RouteNameEnum.CartBilling,
  RouteNameEnum.CartDelivery,
  RouteNameEnum.ChoosePassword,
  RouteNameEnum.SignIn,
  RouteNameEnum.Registration,
  RouteNameEnum.RegistrationConfirm,
  RouteNameEnum.RemindPassword,
];

export const pathsWithHeaderMenuHidden: RouteNameEnum[] = [
  RouteNameEnum.CartDelivery,
  RouteNameEnum.CartBilling,
  RouteNameEnum.CartDelivery,
  RouteNameEnum.ChoosePassword,
  RouteNameEnum.SignIn,
  RouteNameEnum.Registration,
  RouteNameEnum.RegistrationConfirm,
  RouteNameEnum.RemindPassword,
];

export const pathsWithHeaderMenuTransparentTheme: RouteNameEnum[] = [
  RouteNameEnum.Home,
  RouteNameEnum.EventDetails,
  RouteNameEnum.AboutUs,
  RouteNameEnum.Collection,
  RouteNameEnum.ArtistDetails,
  RouteNameEnum.CollectorDetails
];

export const pathsWithInfoBarHidden: RouteNameEnum[] = [
  RouteNameEnum.CartDelivery,
  RouteNameEnum.CartBilling,
  RouteNameEnum.CartDelivery,
  RouteNameEnum.ChoosePassword,
  RouteNameEnum.SignIn,
  RouteNameEnum.Registration,
  RouteNameEnum.RegistrationConfirm,
  RouteNameEnum.RemindPassword,
  RouteNameEnum.NotFound,
  RouteNameEnum.PriceProposalRejected,
];

export const pathsWithHeaderBorderBottom: RouteNameEnum[] = [
  RouteNameEnum.Artists,
  RouteNameEnum.ArtistsList,
  RouteNameEnum.ArtworksList,
  RouteNameEnum.EventsList,
  RouteNameEnum.CartMissingItem,
  RouteNameEnum.CartPaymentError,
  RouteNameEnum.CartSuccess,
  RouteNameEnum.CollectionsList,
  RouteNameEnum.CollectorsList,
  RouteNameEnum.Contact,
  RouteNameEnum.Faq,
  RouteNameEnum.HandbookBuyer,
  RouteNameEnum.HandbookSeller,
  RouteNameEnum.NotFound,
  RouteNameEnum.PriceProposalAccepted,
  RouteNameEnum.PriceProposalRejected,
  RouteNameEnum.UserAccountSettings,
  RouteNameEnum.UserArtworkAdd,
  RouteNameEnum.UserArtworkDetails,
  RouteNameEnum.UserArtworkSalesSettings,
  RouteNameEnum.UserBiddingsList,
  RouteNameEnum.UserDataForPayouts,
  RouteNameEnum.UserDeliverySettings,
  RouteNameEnum.UserFavoriteArtworks,
  RouteNameEnum.UserMyShopping,
  RouteNameEnum.UserPublicProfileSettings,
  RouteNameEnum.UserSalesList,
  RouteNameEnum.UserSoldArtworks,
  RouteNameEnum.Search
];

export const pathsWithDynamicRouting: RouteNameEnum[] = [
  RouteNameEnum.ArtistDetails,
  RouteNameEnum.ArtworkDetails,
  RouteNameEnum.Collection,
  RouteNameEnum.CollectorDetails,
  RouteNameEnum.EventDetails,
];

export const pathsWithTopBorderInFooter: RouteNameEnum[] = [
  RouteNameEnum.NotFound,
  RouteNameEnum.ArtistDetails,
  RouteNameEnum.CollectorDetails,
  RouteNameEnum.ArtworkDetails,
];
