import * as React from 'react';

import { Container, StyledBoxesSlider, StyledSectionTitle, StyledMessageBox } from './boxesSliderSection.styled';
import { BoxesSliderSectionProps } from './boxesSliderSection.types';

export const BoxesSliderSection: React.FC<BoxesSliderSectionProps> = (props: BoxesSliderSectionProps) => {
  const {
    header,
    buttonLabel,
    buttonAction,
    slides,
    slideSize,
    className,
    withSliderWithoutMargin = false,
    noSlidesMessage,
    id = 'boxes-slider-section',
  }: BoxesSliderSectionProps = props;

  return (
    <Container className={className} id={id}>
      <StyledSectionTitle
        header={header}
        buttonLabel={buttonLabel}
        buttonAction={buttonAction}
      />
      {(!slides.length && noSlidesMessage) ? (
        <StyledMessageBox message={noSlidesMessage} />
      ) : (
        <StyledBoxesSlider slides={slides} slideSize={slideSize} $withoutBottomMargin={withSliderWithoutMargin} />
      )}
    </Container>
  );
};
