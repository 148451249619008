import { ArtistTitle, ArtworkDescription, Breakpoint, Color, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { PublicProfilePhotoBackgroundContainerProps } from './baseViewWithBio.types';

export const Container: StyledComponent<'div'> = styled.div``;

export const DetailsWrapper: StyledComponent<'div'> = styled.div``;

export const DetailsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;

  @media ${Breakpoint.Tablet} {
    padding: 40px 32px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 40px 20px;
  }
`;

export const PhotoBackgroundContainer: StyledComponent<'div', PublicProfilePhotoBackgroundContainerProps> 
  = styled.div<PublicProfilePhotoBackgroundContainerProps>`
    position: relative;
    max-width: 100%;
    height: 420px;
    background-size: cover;
    background-position: center;
    ${({ $imgUrl }: PublicProfilePhotoBackgroundContainerProps): string => $imgUrl && `background-image: url(${$imgUrl})`};
`;

export const StyledArtworkDescription: StyledComponent<typeof ArtworkDescription> = styled(ArtworkDescription)`
  margin: 60px 0 120px;

  @media ${Breakpoint.Mobile} {
    margin-bottom: 80px;
  }
`;

export const StyledArtistTitle: StyledComponent<typeof ArtistTitle> = styled(ArtistTitle)`
  padding-bottom: 40px;
  border-bottom: 1px solid ${Color.Gray300};
`;

export const MosaicWrapper: StyledComponent<'div'> = styled.div`
  overflow: hidden;
  padding: 0 20px;

  @media ${Breakpoint.Tablet} {
    padding: 0 16px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 0 10px;
  }
`;

export const BioArtworkDescription: StyledComponent<typeof ArtworkDescription> = styled(ArtworkDescription)`
  margin-top: 120px;
  padding: 120px 40px;
  width: 100%;
  background-color: ${Color.Gray200};

  @media ${Breakpoint.SmallDesktop} {
    padding: 120px 32px;
  }

  @media ${Breakpoint.Mobile} {
    margin-top: 80px;
    padding: 80px 20px; 
  }
`;
