import { PublicProfileTypeEnum } from '@on-arte/core-types';
import { getPathWithParams } from '@on-arte/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { BaseViewWithAlphabet } from '@onArte/frontend/components';
import { usePublicProfilesListActions } from '@onArte/frontend/hooks';
import { UsePublicProfilesListActions } from '@onArte/frontend/interfaces';
import { RouteNameEnum } from '@onArte/shared/enums';
import { PublicProfileListItem } from '@onArte/shared/interfaces';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { ListContainer, ListItemButton, ListItem } from './collectorsList.styled';

export const CollectorsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    alphabet,
    activeLetter,
    setActiveLetter,
    isLoading,
    profilesList,
    initialActiveLetter
  }: UsePublicProfilesListActions = usePublicProfilesListActions(PublicProfileTypeEnum.Collector);
  
  return (
    <BaseViewWithAlphabet 
      pageTitle={t(
        'onarte.website.collectorsListView.pageTitle.title',
        { value: (activeLetter || initialActiveLetter) ? `- ${(activeLetter || initialActiveLetter).toUpperCase()}` : ''}
      )}
      alphabet={alphabet}
      activeLetter={activeLetter}
      setActiveLetter={setActiveLetter}
    >
      <ListContainer>
        {!isLoading && (
          profilesList.length ? (
            profilesList.map((profile: PublicProfileListItem): JSX.Element => (
              <ListItemButton 
                internalPath={profile.route?.prettyUrl 
                  ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.CollectorDetails)?.url ?? '/', { id: profile.id })}
                key={profile.id}
              >
                <ListItem>
                  {profile.name}
                </ListItem>
              </ListItemButton>
            ))
          ) : (
            <ListItem>{t('onarte.website.collectorsListView.listItem.emptyList')}</ListItem>
          )
        )}
      </ListContainer>
    </BaseViewWithAlphabet>
  );
};
