import { SellerCreateModel, SellerDTO } from '@on-arte/core-types';
import { Breakpoint, useFormikForm, UseFormikForm, UseLogger, useLogger, useNotifications, UseNotifications, UseState } from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import { getPayoutDetails, updatePayoutDetails } from '@onArte/frontend/api/requests';
import { BaseUserMySalesView } from '@onArte/frontend/components';
import { QueryKey, UserMySalesPositions } from '@onArte/frontend/enums';
import { FrontendApiError } from '@onArte/frontend/models';
import { FormikForm } from '@onArte/frontend/theme';

import { useUpdateDataForPayoutsFormValidation } from './userDataForPayouts.hook';
import { StyledInput, StyledButton } from './userDataForPayouts.styled';

export const UserDataForPayoutsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast }: UseNotifications = useNotifications();
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.SmallMobile });
  const updateDataForPayoutsFormValidationSchema: Yup.SchemaOf<SellerCreateModel> = useUpdateDataForPayoutsFormValidation();
  const [dataForPayouts, setDataForPayouts]: UseState<SellerDTO | null> = useState<SellerDTO | null>(null);
  const { logger }: UseLogger = useLogger();

  useQuery(
    [QueryKey.DataForPayouts],
    (): Promise<SellerDTO> => getPayoutDetails(),
    {
      onSuccess: (data: SellerDTO): void => setDataForPayouts(data),
      onError: (error: FrontendApiError): void => logger(QueryKey.DataForPayouts, error, 'error')
    }
  );

  const updateDataForPayouts: (data: SellerCreateModel) => void = (data: SellerCreateModel): void => {
    updatePayoutDetails(data)
      .then((response: SellerDTO): void => {
        setDataForPayouts(response);
        addToast({ content: t('onarte.website.userDataForPayoutsView.success') });
      })
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  return (
    <BaseUserMySalesView
      activePosition={UserMySalesPositions.UserDataForPayouts}
      title={t('onarte.website.meta.userDataForPayouts.title')}
      withoutBottomBorder
    >
      <Formik
        initialValues={{
          bankNumber: dataForPayouts?.bankNumber ?? '',
          name: dataForPayouts?.name ?? '',
          taxNumber: dataForPayouts?.taxNumber || undefined,
          address: dataForPayouts?.address ?? '',
          postalCode: dataForPayouts?.postalCode ?? '',
          city: dataForPayouts?.city ?? '',
          country: 'Polska'
        }}
        onSubmit={updateDataForPayouts}
        validationSchema={updateDataForPayoutsFormValidationSchema}
        validateOnChange={isFormSubmitted}
        validateOnBlur={isFormSubmitted}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, errors, values }: FormikProps<SellerCreateModel>) => (
          <FormikForm onSubmit={handleSubmit}>
            <StyledInput
              label={t('onarte.website.userDataForPayoutsView.bankNumber')}
              onChange={(value: string): void => setFieldValue('bankNumber', value)}
              validationMessage={errors.bankNumber ?? ''}
              value={values.bankNumber}
              description={t('onarte.website.userDataForPayoutsView.bankNumberDescription')}
            />
            <StyledInput
              label={t('onarte.website.userDataForPayoutsView.name')}
              onChange={(value: string): void => setFieldValue('name', value)}
              validationMessage={errors.name ?? ''}
              value={values.name}
            />
            <StyledInput
              label={t('onarte.website.userDataForPayoutsView.taxNumber')}
              onChange={(value: string): void => setFieldValue('taxNumber', value || undefined)}
              validationMessage={errors.taxNumber ?? ''}
              value={values.taxNumber}
              withOnlyNumbersAllowed
            />
            <StyledInput
              label={t('onarte.website.userDataForPayoutsView.address')}
              onChange={(value: string): void => setFieldValue('address', value)}
              validationMessage={errors.address ?? ''}
              value={values.address}
            />
            <StyledInput
              label={t('onarte.website.userDataForPayoutsView.postalCode')}
              onChange={(value: string): void => setFieldValue('postalCode', value)}
              validationMessage={errors.postalCode ?? ''}
              value={values.postalCode}
              format='##-###'
            />
            <StyledInput
              label={t('onarte.website.userDataForPayoutsView.city')}
              onChange={(value: string): void => setFieldValue('city', value)}
              validationMessage={errors.city ?? ''}
              value={values.city}
            />
            <StyledInput
              label={t('onarte.website.userDataForPayoutsView.country')}
              onChange={(value: string): void => setFieldValue('country', value)}
              validationMessage={errors.country ?? ''}
              value={values.country}
              disabled
            />
            <StyledButton
              label={t('onarte.common.saveChanges')}
              fullWidth={isSmallMobile}
              type='submit'
              onClick={setFormSubmitted}
            />
          </FormikForm>
        )}
      </Formik>
    </BaseUserMySalesView>
  );
};
