import { useRedirect, UseRedirect } from '@on-arte/ui';
import React, { useEffect, useMemo } from 'react';
import { Navigate, useLocation, Location, useMatch } from 'react-router-dom';

import { useAuth } from '@onArte/frontend/hooks';
import { UseAuth } from '@onArte/frontend/interfaces';
import { plRouting } from '@onArte/shared/constants';
import { RouteNameEnum } from '@onArte/shared/enums';
import { RouteInfo } from '@onArte/shared/interfaces';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { CheckAuthProps } from './checkAuth.types';

export const CheckAuth: React.FC<CheckAuthProps> = (props: CheckAuthProps): JSX.Element => {
  const { children }: CheckAuthProps = props;
  const { tokenExpiration, token }: UseAuth = useAuth();
  const location: Location = useLocation();
  const { redirect }: UseRedirect = useRedirect();
  const pathObject: RouteInfo[] = plRouting.filter(
    (route: RouteInfo): boolean => !!useMatch(route.url) && route.name !== RouteNameEnum.NotFound
  );
  const prohibitedViewsAfterSignIn: RouteNameEnum[] = useMemo(
    (): RouteNameEnum[] => [
      RouteNameEnum.SignIn,
      RouteNameEnum.ChoosePassword,
      RouteNameEnum.RemindPassword,
      RouteNameEnum.RegistrationConfirm,
      RouteNameEnum.Registration,
    ],
    []
  );

  useEffect(
    (): void => {
      if (tokenExpiration && Date.now() > tokenExpiration) {
        redirect(getRouteDetailsByName(RouteNameEnum.Logout)?.url ?? '/', { withReplaceState: true });
      }
    },
    [tokenExpiration]
  );

  if (!token && pathObject[0]?.secured) {
    return (
      <Navigate to={getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/'} state={{ from: location }} replace />
    );
  }

  if (!!token && prohibitedViewsAfterSignIn.includes(pathObject[0].name)) {
    return (
      <Navigate to={getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/'} replace />
    );
  }

  return (
    <>{children}</>
  );
};
