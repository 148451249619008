import { Breakpoint, Color, FontWeight, MenuPosition, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const ListContainer: StyledComponent<'div'> = styled.div`
  padding: 120px 40px;
  background-color: ${Color.White};
  flex: 1;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(10, 1fr);
  grid-template-columns: repeat(4, 1fr);
  column-gap: 40px;

  @media ${Breakpoint.Tablet} {
    padding: 120px 32px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${Breakpoint.Mobile} {
    padding: 80px 20px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
`;

export const ListItem: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Light};
  font-size: 18px;
  line-height: 26px;
`;

export const ListItemButton: StyledComponent<typeof MenuPosition> = styled(MenuPosition)``; 
