import { FontWeight, StyledComponent, Color, Breakpoint, MessageBox } from '@on-arte/ui';
import styled from 'styled-components';

export const Section: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 40px;

  @media ${Breakpoint.Tablet} {
    padding: 0 16px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 10px;
    margin-bottom: 0;
  }
`;

export const SectionTitle: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: ${Color.Black};
  padding-left: 20px;

  @media ${Breakpoint.Tablet} {
    padding-left: 16px;
  }

  @media ${Breakpoint.Mobile} {
    padding-left: 10px;
  }
`;

export const StyledMessageBox: StyledComponent<typeof MessageBox> = styled(MessageBox)`
  max-width: 740px;
  margin-left: 20px;
  margin-bottom: 80px;

  @media ${Breakpoint.Tablet} {
    margin-left: 16px;
  }

  @media ${Breakpoint.Mobile} {
    margin-left: 10px;
  }
`;
