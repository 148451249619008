import { BigPhotoBox, Breakpoint, Color, FontWeight, Image, NewsSection, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { PageTitle } from '@onArte/frontend/theme';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Color.Gray200};
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin: 40px;

  @media ${Breakpoint.SmallMobile} {
    margin: 0 20px 40px;
  }
`;

export const BackgroundImageContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 420px;
`;

export const BackgroundImage: StyledComponent<typeof Image> = styled(Image)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const Title: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 60px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${Color.Black};
  margin: 120px auto;
  padding: 0 20px;

  @media ${Breakpoint.Mobile} {
    font-size: 40px;
    line-height: 44px;
    margin: 80px auto;
  }

  @media ${Breakpoint.SmallMobile} { 
    font-size: 30px;
    line-height: 38px;
    margin: 60px auto;
  }
`;

export const ContentContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Color.White};
`;

export const NewsSectionsContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(1000px + 80px);
  width: 100%;
  padding: 0 40px;

  @media ${Breakpoint.Tablet} {
    padding: 0 32px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;

export const StyledNewsSection: StyledComponent<typeof NewsSection> = styled(NewsSection)`
  & + & {
    margin-top: 120px;
  }
`;

export const AccordionTitle: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${Color.Black};
  margin-bottom: 60px;
`;

export const AccordionGroupContainer: StyledComponent<'div'> = styled.div`
  max-width: 740px;
  width: 100%;
  margin-top: 160px;

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
    margin-top: 120px;
  }

  @media ${Breakpoint.SmallMobile} {
    margin-top: 80px;
  }
`;

export const StyledBigPhotoBox: StyledComponent<typeof BigPhotoBox> = styled(BigPhotoBox)`
  margin: 160px 0 120px;

  @media ${Breakpoint.Mobile} {
    margin: 120px 0 80px;
  }

  @media ${Breakpoint.SmallMobile} {
    margin: 80px 0;
  }
`;
