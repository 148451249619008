import { AddressFormData } from '@on-arte/ui';

export const initialBaseAddressValues: AddressFormData  = {
  street: '',
  houseNumber: '',
  apartmentNumber: '',
  postalCode: '',
  city: '',
  country:'Polska',
};
