import { Breakpoint, Color, DirectionEnum, Grid, NewsBox, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { StyledNewsBoxProps } from './newsBoxesSection.types';

export const Container: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.FullHd} + 80px);
  padding: 0 40px;

  @media ${Breakpoint.Tablet} {
    padding: 0 32px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;

export const NewsBoxes: StyledComponent<'div'> = styled.div`
  display: flex;
  margin-top: 32px;
  border-top: 1px solid ${Color.Gray300};
  border-bottom: 1px solid ${Color.Gray300};
  max-width: 100%;

  @media ${Breakpoint.SmallMobile} {
    flex-direction: column;
  }
`;

export const StyledNewsBox: StyledComponent<typeof NewsBox, StyledNewsBoxProps> = styled(NewsBox)<StyledNewsBoxProps>`
  padding: 40px;
  width: 50%;
  ${({ $position }: StyledNewsBoxProps): string => $position === DirectionEnum.Left ? 'padding-left: 0' : 'padding-right: 0'};
  
  & + & {
    border-left: 1px solid ${Color.Gray300};

    @media ${Breakpoint.SmallMobile} {
      border-left: 0;
      border-top: 1px solid ${Color.Gray300};
    }
  }

  @media ${Breakpoint.Tablet} {
    padding: 32px;
    ${({ $position }: StyledNewsBoxProps): string => $position === DirectionEnum.Left ? 'padding-left: 0' : 'padding-right: 0'};
  }

  @media ${Breakpoint.Mobile} {
    padding: 20px;
    ${({ $position }: StyledNewsBoxProps): string => $position === DirectionEnum.Left ? 'padding-left: 0' : 'padding-right: 0'};
  }

  @media ${Breakpoint.SmallMobile} {
    width: 100%;
    padding: 40px 0;
  }
`;
