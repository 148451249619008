import { Footer } from '@on-arte/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { appConfig } from '@onArte/frontend/app.config';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { FooterComponentProps } from './footerComponent.types';

export const FooterComponent: React.FC<FooterComponentProps> = (props: FooterComponentProps): JSX.Element => {
  const { withTopBorder }: FooterComponentProps = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <Footer
      menuPositions={[
        {
          label: t('onarte.website.menu.browseTheWorks'),
          internalPath: getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/',
        },
        {
          label: t('onarte.website.menu.collections'), 
          internalPath: getRouteDetailsByName(RouteNameEnum.CollectionsList)?.url ?? '/'
        },
        {
          label: t('onarte.website.menu.auctions'), 
          internalPath: getRouteDetailsByName(RouteNameEnum.EventsList)?.url ?? '/'
        },
        ...(!appConfig.hiddenModules.collectors ? [{
          label: t('onarte.website.menu.forTheCollector'),
          internalPath: getRouteDetailsByName(RouteNameEnum.CollectorsList)?.url ?? '/'
        }] : []),
        {
          label: t('onarte.website.menu.faq'), 
          internalPath: getRouteDetailsByName(RouteNameEnum.Faq)?.url ?? '/'
        },
        {
          label: t('onarte.common.regulations'), 
          internalPath: getRouteDetailsByName(RouteNameEnum.Regulations)?.url ?? '/'
        },
        {
          label: t('onarte.common.privacyPolicy'), 
          internalPath: getRouteDetailsByName(RouteNameEnum.PrivacyPolicy)?.url ?? '/'
        },
        {
          label: t('onarte.website.menu.aboutUs'), 
          internalPath: getRouteDetailsByName(RouteNameEnum.AboutUs)?.url ?? '/'
        },
        {
          label: t('onarte.website.menu.contact'), 
          internalPath: getRouteDetailsByName(RouteNameEnum.Contact)?.url ?? '/'
        },
        {
          label: t('onarte.website.menu.magazine'), 
          externalUrl: appConfig.magazineUrl, 
          shouldOpenInNewTab: true
        },
      ]}
      socials={appConfig.socials}
      withTopBorder={withTopBorder}
    />
  );
};
