import {
  getPathWithParams,
  HeaderMenuIconButtonPositionsGroup, 
  HeaderMenuTheme, 
  IconName, 
  useRedirect, 
  UseRedirect, 
  UseState 
} from '@on-arte/ui';
import React, { useContext, useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { appConfig } from '@onArte/frontend/app.config';
import { BasketSidePanelContext } from '@onArte/frontend/contexts/basketSidePanel.context';
import { useAuth } from '@onArte/frontend/hooks';
import { BasketSidePanelContextState, UseAuth } from '@onArte/frontend/interfaces';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { BasketSidePanelElement } from '../basketSidePanelElement/basketSidePanelElement.component';

import { Container, StyledHeaderMenu } from './headerMenu.styled';
import { HeaderMenuProps } from './headerMenu.types';

export const HeaderMenu: React.FC<HeaderMenuProps> = (props: HeaderMenuProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { headerMenuTheme, withBottomBorder, className }: HeaderMenuProps = props;
  const [menuTheme, setMenuTheme]: UseState<HeaderMenuTheme> = useState<HeaderMenuTheme>(headerMenuTheme);
  const [menuTopOffset, setMenuTopOffset]: UseState<number | null> = useState<number | null>(null);
  const [menuTransitionEnabled, setMenuTransitionEnabled]: UseState<boolean> = useState<boolean>(false);
  const { redirect }: UseRedirect = useRedirect();
  const scrollY: React.MutableRefObject<number> = React.useRef<number>(0);
  const { signOut, isUserLoggedIn }: UseAuth = useAuth();
  const basketSideContext: BasketSidePanelContextState = useContext(BasketSidePanelContext);

  const enableWhiteTheme: () => void = (): void => {
    setMenuTheme(HeaderMenuTheme.SolidWhite);
    setMenuTopOffset(-100);
    setTimeout((): void => setMenuTransitionEnabled(true), 0);
  };

  useEffect(
    (): () => void => {
      setMenuTheme(headerMenuTheme);
      if (window.scrollY > 150) {
        enableWhiteTheme();
      }
      window.addEventListener('scroll', changeMenuTheme);
      return (): void => window.removeEventListener('scroll', changeMenuTheme);
    },
    [headerMenuTheme]
  );

  const changeMenuTheme: () => void = (): void => {
    if (window.scrollY < 100) {
      setMenuTheme(headerMenuTheme);
      setMenuTopOffset(null);
      setMenuTransitionEnabled(false);
    } else if (window.scrollY < 150) {
      enableWhiteTheme();
    } else if (scrollY.current > window.scrollY) {
      enableWhiteTheme();
      setMenuTopOffset(0);
    } else {
      setMenuTopOffset(-100);
    }
    scrollY.current = window.scrollY;
  };

  return (
    <Container>
      <StyledHeaderMenu
        withBottomBorder={withBottomBorder ?? false}
        className={className}
        headerMenuTheme={menuTheme}
        $top={menuTopOffset}
        $transitionEnabled={menuTransitionEnabled}
        onSearchIconClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Search)?.url ?? '/')}
        onUserIconClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/', { withReplaceState: true })}
        onLogoClick={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/')}
        onCartIconClick={(): void => (
          isUserLoggedIn() 
            ? basketSideContext.setIsBasketSidePanelOpen(true) 
            : redirect(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/', { withReplaceState: true })
        )}
        menuPositions={[
          {
            label: t('onarte.website.menu.browseTheWorks'),
            internalPath: getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/',
            displayOnDesktop: true,
            positionOnMobile: 'top'
          },
          {
            label: t('onarte.website.menu.collections'),
            internalPath: getRouteDetailsByName(RouteNameEnum.CollectionsList)?.url ?? '/',
            displayOnDesktop: true,
            positionOnMobile: 'top'
          },
          {
            label: t('onarte.website.menu.auctions'),
            internalPath: getRouteDetailsByName(RouteNameEnum.EventsList)?.url ?? '/',
            displayOnDesktop: true,
            positionOnMobile: 'top'
          },
          {
            label: t('onarte.website.menu.artists'),
            internalPath: getRouteDetailsByName(RouteNameEnum.Artists)?.url ?? '/',
            displayOnDesktop: true,
            positionOnMobile: 'top'
          },
          {
            label: t('onarte.website.menu.sellIt'),
            internalPath: getRouteDetailsByName(RouteNameEnum.HandbookSeller)?.url ?? '/',
            displayOnDesktop: true,
            positionOnMobile: 'bottom'
          },
          {
            label: t('onarte.website.menu.aboutUs'),
            internalPath: getRouteDetailsByName(RouteNameEnum.AboutUs)?.url ?? '/',
            displayOnDesktop: true,
            positionOnMobile: 'bottom'
          },
          {
            label: t('onarte.website.menu.magazine'),
            externalUrl: appConfig.magazineUrl,
            displayOnDesktop: true,
            positionOnMobile: 'bottom',
            shouldOpenInNewTab: true
          },
          {
            label: t('onarte.website.menu.myAccount'),
            internalPath: getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/',
            displayOnDesktop: false,
            positionOnMobile: 'bottom',
            name: HeaderMenuIconButtonPositionsGroup.User,
            childrenPositions: !isUserLoggedIn()
              ? []
              : [
                {
                  icon: IconName.Settings,
                  label: t('onarte.website.menu.settings'),
                  internalPath: getRouteDetailsByName(RouteNameEnum.UserAccountSettings)?.url ?? '/',
                },
                {
                  icon: IconName.Image,
                  label: t('onarte.website.menu.myShopping'),
                  internalPath: getRouteDetailsByName(RouteNameEnum.UserMyShopping)?.url ?? '/',
                },
                {
                  icon: IconName.Money,
                  label: t('onarte.website.menu.biddingsList'),
                  internalPath: getRouteDetailsByName(RouteNameEnum.UserBiddingsList)?.url ?? '/',
                },
                {
                  icon: IconName.Delivery,
                  label: t('onarte.website.menu.delivery'),
                  internalPath: getRouteDetailsByName(RouteNameEnum.UserDeliverySettings)?.url ?? '/',
                },
                {
                  icon: IconName.Favourite,
                  label: t('onarte.website.menu.favorites'),
                  internalPath: getRouteDetailsByName(RouteNameEnum.UserFavoriteArtworks)?.url ?? '/',
                },
                {
                  icon: IconName.PublicProfile,
                  label: t('onarte.website.menu.publicProfiles'),
                  internalPath: getPathWithParams(
                    getRouteDetailsByName(RouteNameEnum.UserPublicProfileSettings)?.url ?? '/',
                    { contextId: '-', contextType: '-', contextName: '-' }
                  ),
                },
                {
                  label: t('onarte.website.menu.salesList'),
                  icon: IconName.Store,
                  internalPath: getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/',
                  childrenPositions: [
                    {
                      label: t('onarte.website.menu.myCollection'),
                      internalPath: getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/'
                    },
                    {
                      label: t('onarte.website.menu.artworkAdd'),
                      internalPath: getRouteDetailsByName(RouteNameEnum.UserArtworkAdd)?.url ?? '/'
                    },
                    {
                      label: t('onarte.website.menu.soldArtworks'),
                      internalPath: getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/'
                    },
                    {
                      label: t('onarte.website.menu.dataForPayouts'),
                      internalPath: getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/'
                    },
                  ]
                },
                { icon: IconName.Logout, label: t('onarte.website.menu.logout'), action: (): void => signOut() },
              ]
          },
        ]}
      />
      {isUserLoggedIn() && (
        <BasketSidePanelElement
          isOpen={basketSideContext.isBasketSidePanelOpen}
          setIsOpen={basketSideContext.setIsBasketSidePanelOpen}
        />
      )}
    </Container>
  );
};
