import { UseState } from '@on-arte/ui';
import { useContext, useState } from 'react';
import { Socket } from 'socket.io-client';

import { SocketContext } from '@onArte/frontend/contexts';

import { UseSocket } from '../interfaces';

export const useSocket: () => UseSocket = (): UseSocket => {
  const socket: Socket | undefined = useContext(SocketContext);
  const [connectedToSocket, setConnectedToSocket]: UseState<boolean> = useState<boolean>(false);

  const setIsConnectedToSocket: () => void = (): void => {
    setConnectedToSocket(true);
  };

  return {
    socket,
    connectedToSocket,
    setIsConnectedToSocket,
  };
};
