import { CategoryAttributeInterface, ItemAttributeTypeEnum } from '@on-arte/core-types';
import { TransProps, useTranslation } from 'react-i18next';

import { ItemsHelpers } from '@onArte/frontend/interfaces';

export const useItemsHelpers: () => ItemsHelpers = (): ItemsHelpers => {
  const { t }: TransProps<never> = useTranslation();

  const getAttributeValueByType = (value: string, attributeProperties: CategoryAttributeInterface): string => {
    switch (attributeProperties.type) {
      case ItemAttributeTypeEnum.Logic:
        return t(value === 'yes' ? 'onarte.common.yes' : 'onarte.common.no');
      case ItemAttributeTypeEnum.SingleChoice:
        return t(`onarte.common.itemsAttributes.${attributeProperties.name}.options.${value}.label`);
      case ItemAttributeTypeEnum.MultipleChoice:
        const values: string[] = value.replace(/\ /, '').split(',');
        return values
          .map((valueItem: string): string => {
            return t(`onarte.common.itemsAttributes.${attributeProperties.name}.options.${valueItem}.label`);
          })
          .join(', ');
      default:
        return value;
    }
  };

  return { getAttributeValueByType };
};
