import { OrangeStripeSectionInterface, SettingsInterface, WebsiteUrlInterface } from '@on-arte/core-types';
import { HeaderMenuTheme, InfoBar, Logger, UseLocalStorage, useLocalStorage, UseLogger, useLogger, UseState } from '@on-arte/ui';
import { Location } from 'history';
import { Match, match, MatchFunction } from 'path-to-regexp';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { getRoutingDetails, getSettings } from '@onArte/frontend/api/requests';
import { appConfig } from '@onArte/frontend/app.config';
import { AuthContext, RoutingContext } from '@onArte/frontend/contexts';
import { BasketSidePanelContext } from '@onArte/frontend/contexts/basketSidePanel.context';
import { LocalStorageKey, QueryKey } from '@onArte/frontend/enums';
import { AuthContextState, RoutingState } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { authReducer } from '@onArte/frontend/reducers';
import { AuthContextData } from '@onArte/frontend/types';
import { plRouting } from '@onArte/shared/constants';
import { RouteNameEnum, WebsiteUrlType } from '@onArte/shared/enums';
import { RouteInfo } from '@onArte/shared/interfaces';
import { getRouteDetailsByName, getRouteDetailsByUrl } from '@onArte/shared/utils';

import { CookiesPopup } from '../cookiesPopup/cookiesPopup.component';
import { FooterComponent } from '../footerComponent/footerComponent.component';
import { HeaderMenu } from '../headerMenu/headerMenu.component';

import {
  pathsWithDynamicRouting,
  pathsWithFooterHidden,
  pathsWithHeaderBorderBottom,
  pathsWithHeaderMenuHidden,
  pathsWithHeaderMenuTransparentTheme,
  pathsWithInfoBarHidden,
  pathsWithTopBorderInFooter
} from './appWrapper.configs';
import { ChildrenContainer, HeaderWrapper } from './appWrapper.styled';
import { AppWrapperProps } from './appWrapper.types';

export const AppWrapper: React.FC<AppWrapperProps> = (props: AppWrapperProps): JSX.Element => {
  const { children, token }: AppWrapperProps = props;
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const location: Location = useLocation();
  const pathObject: RouteInfo | undefined = getRouteDetailsByUrl(location.pathname) ?? getRouteDetailsByName(RouteNameEnum.NotFound);
  const [isBasketSidePanelOpen, setIsBasketSidePanelOpen]: UseState<boolean> = useState<boolean>(false);
  const [orangeStripeData, setOrangeStripeData]: UseState<OrangeStripeSectionInterface | null> 
    = useState<OrangeStripeSectionInterface | null>(null);
  const [routingState, setRoutingState]: UseState<RoutingState> = useState<RoutingState>({
    routes: plRouting, elementParamsFromRouting: {}, currentWebsiteUrl: undefined, currentRouteObject: undefined
  });
  const [authData]: UseLocalStorage<AuthContextState> = useLocalStorage<AuthContextState>(
    LocalStorageKey.AuthData, { userData: null, token: null, tokenExpiration: null }
  );
  const [authState, authDispatch]: AuthContextData = useReducer(
    authReducer,
    authData
      ? { userData: authData.userData, token: authData.token, tokenExpiration: authData.tokenExpiration }
      : { userData: null, token: null, tokenExpiration: null },
  );
  
  const valueForAuthContext: AuthContextData = useMemo((): AuthContextData => [
    { ...authState, ...(token ? { token } : {})},
    authDispatch
  ], [authState, token]);

  const setLocalUrlToState: (urlMatch: MatchFunction<Record<string, string>>, pathObjectValue: RouteInfo) => void = (
    urlMatch: MatchFunction<Record<string, string>>, pathObjectValue: RouteInfo
  ): void => {
    const localUrlMatch: Match<Record<string, string>> | false = urlMatch(location.pathname);
    if (localUrlMatch) {
      setRoutingState({
        ...routingState,
        currentWebsiteUrl: undefined,
        elementParamsFromRouting: localUrlMatch?.params,
        currentRouteObject: pathObjectValue
      });
    }
  };

  useQuery(
    [QueryKey.Settings],
    (): Promise<SettingsInterface> => getSettings(),
    {
      onSuccess: (data: SettingsInterface): void => setOrangeStripeData(data.orangeStripeSection),
      onError: (error: FrontendApiError): void => logger(QueryKey.Settings, error)
    }
  );
  
  useEffect(
    (): void => {
      setTimeout((): void => window.scrollTo(0, 0), 0);
      setRoutingState({ ...routingState, currentWebsiteUrl: undefined, elementParamsFromRouting: {}, currentRouteObject: undefined });
      
      if (pathObject) {
        document.title = `${t(pathObject.title)} | ${appConfig.applicationName}`;

        if (pathsWithDynamicRouting.includes(pathObject.name)) {
          const urlMatch: MatchFunction<Record<string, string>> = match(pathObject.url);
          getRoutingDetails(location.pathname, WebsiteUrlType.Both)
            .then((route: WebsiteUrlInterface | undefined): void => {
              if (route) {
                const realUrlMatch: Match<Record<string, string>> | false = urlMatch(route.realUrl);
                if (realUrlMatch) {
                  setRoutingState({
                    ...routingState,
                    currentWebsiteUrl: route,
                    elementParamsFromRouting: realUrlMatch?.params,
                    currentRouteObject: pathObject
                  });
                }
              } else {
                setLocalUrlToState(urlMatch, pathObject);
              }
            })
            .catch((): void => setLocalUrlToState(urlMatch, pathObject));
        }
      }
    },
    [location.pathname]
  );
  
  const isOrangeStripeUrlExternal: boolean | undefined = useMemo(
    (): boolean | undefined => {
      return orangeStripeData?.link.slice(0, 4) === 'http';
    },
    [orangeStripeData]
  );

  return (
    <RoutingContext.Provider value={routingState}>
      <Logger
        applicationId={appConfig.loggerSettings.applicationId}
        clientToken={appConfig.loggerSettings.clientToken}
        service={appConfig.loggerSettings.service}
        env={appConfig.loggerSettings.env}
        version={appConfig.loggerSettings.version}
        userId={authState.userData?.id}
      >
        <BasketSidePanelContext.Provider value={{isBasketSidePanelOpen, setIsBasketSidePanelOpen}}>
          <AuthContext.Provider value={valueForAuthContext}>
            {!!pathObject && !pathsWithInfoBarHidden.includes(pathObject.name) && !!orangeStripeData && (
              <InfoBar 
                content={orangeStripeData.text} 
                externalUrl={isOrangeStripeUrlExternal ? orangeStripeData.link : undefined}
                internalPath={!isOrangeStripeUrlExternal ? orangeStripeData.link : undefined}
              />
            )}
            {!!pathObject && !pathsWithHeaderMenuHidden.includes(pathObject.name) && (
              <HeaderWrapper>
                <HeaderMenu
                  headerMenuTheme={pathsWithHeaderMenuTransparentTheme.includes(pathObject.name)
                    ? HeaderMenuTheme.Transparent
                    : HeaderMenuTheme.SolidBeige
                  }
                  withBottomBorder={pathsWithHeaderBorderBottom.includes(pathObject.name)}
                />
              </HeaderWrapper>
            )}
            <ChildrenContainer
              $withoutMarginTop={!!pathObject && pathsWithHeaderMenuTransparentTheme.includes(pathObject.name)}
            >
              {children}
            </ChildrenContainer>
            {!!pathObject && !pathsWithFooterHidden.includes(pathObject.name) && (
              <FooterComponent withTopBorder={pathsWithTopBorderInFooter.includes(pathObject.name)} />
            )}
            <CookiesPopup />
          </AuthContext.Provider>
        </BasketSidePanelContext.Provider>
      </Logger>
    </RoutingContext.Provider>
  );
};
