import { UseState, UseNotifications, useNotifications, UseLogger, useLogger } from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getAgreements, subscribeToNewsletter } from '@onArte/frontend/api/requests';
import { QueryKey } from '@onArte/frontend/enums';
import { FrontendApiError } from '@onArte/frontend/models';
import { AgreementTypeEnum, RouteNameEnum } from '@onArte/shared/enums';
import { AgreementInterface } from '@onArte/shared/interfaces/common/users';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { StyledNewsletterSection } from './newsletterBoxSection.styled';
import { NewsletterBoxSectionProps } from './newsletterBoxSection.types';

export const NewsletterBoxSection: React.FC<NewsletterBoxSectionProps> = (props: NewsletterBoxSectionProps) => {
  const { t }: TransProps<never> = useTranslation();
  const { withTopBorder = false, className }: NewsletterBoxSectionProps = props;
  const { addToast }: UseNotifications = useNotifications();
  const [agreementContent, setAgreementContent]: UseState<string> = useState<string>('');
  const { logger }: UseLogger = useLogger();

  const registerToNewsletter: (email: string) => void = (email: string): void => {
    subscribeToNewsletter({ email })
      .then(() => addToast({ content: t('onarte.website.newsletterBoxSection.subscribeToNewsletter.success') }))
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  useQuery(
    [QueryKey.Agreements],
    (): Promise<AgreementInterface[]> => getAgreements(),
    {
      onSuccess: (data: AgreementInterface[]): void => {
        const searchedAgreement: AgreementInterface | undefined = data.find(
          (agreement: AgreementInterface): boolean => agreement.type === AgreementTypeEnum.MarketingUsage
        );
        setAgreementContent(injectUrl(searchedAgreement?.label ?? ''));
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.Agreements, error)
    }
  );

  const injectUrl: (content: string) => string = (content: string): string => {
    return t(content).replace('[privacyPolicyPath]', getRouteDetailsByName(RouteNameEnum.PrivacyPolicy)?.url ?? '/');
  };

  return (
    <StyledNewsletterSection
      checkboxLabel={agreementContent}
      onAction={registerToNewsletter}
      className={className}
      $withTopBorder={withTopBorder}
    />
  );
};
