import { AddressTypeEnum } from '@on-arte/core-types';
import { IsEnum, IsOptional } from 'class-validator';

export class UpdateAddressIdInOrderModel {
  @IsEnum(AddressTypeEnum)
  @IsOptional()
  public addressType?: AddressTypeEnum;

  @IsOptional()
  public addressId?: string;

  @IsOptional()
  public invoiceRequired?: boolean;

  constructor(
    addressType?: AddressTypeEnum,
    addressId?: string,
    invoiceRequired?: boolean,
  ) {
    this.addressType = addressType;
    this.addressId = addressId;
    this.invoiceRequired = invoiceRequired;
  }
}
