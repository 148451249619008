import { getPathWithParams, IconName, useRedirect, UseRedirect } from '@on-arte/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { BaseSuccessView } from '@onArte/frontend/components';
import { useBasket } from '@onArte/frontend/hooks';
import { UseBasket } from '@onArte/frontend/interfaces';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const CartMissingItemView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { cartData }: UseBasket = useBasket();
  const { redirect }: UseRedirect = useRedirect();

  return (
    <BaseSuccessView 
      title={t('onarte.website.cartMissingItemView.message.title')}
      description={t('onarte.website.cartMissingItemView.message.description')}
      icon={IconName.Collector}
      buttonLabel={t('onarte.website.cartMissingItemView.message.button')}
      buttonAction={
        (): void => cartData
          ? redirect(getPathWithParams(getRouteDetailsByName(RouteNameEnum.CartDelivery)?.url ?? '/', { id: cartData?.id ?? '' }))
          : redirect(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/', { withReplaceState: true })
      }
    />
  );
};
