import { StepPriceInterface } from '@on-arte/core-types';

export const findStepPriceElement: (stepPrices: StepPriceInterface[], currentPrice: number) => StepPriceInterface | undefined = (
  stepPrices: StepPriceInterface[], currentPrice: number
): StepPriceInterface | undefined => {

  return stepPrices.find(
    (price: StepPriceInterface): boolean => price.from <= currentPrice && (!price.to || price.to > currentPrice)
  );
};
