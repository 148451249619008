import { 
  AttachmentTypeEnum,
  AuctionStatusEnum, 
  AuctionTypeEnum,
  ReactionUsageContextTypeEnum,
  ExternalAttachmentInterface,
  ThumbnailAttachmentTypeEnum,
  UploadedAttachmentInterface,
} from '@on-arte/core-types';
import {
  getPathWithParams,
  IconName, 
  PhotosSlider, 
  QuoteCarousel, 
  UseRedirect,
  useRedirect,
} from '@on-arte/ui';
import React, { useMemo, useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { BlogBoxesSliderSection } from '@onArte/frontend/components';
import { ArtworkDescriptionEnum } from '@onArte/frontend/enums';
import { useBasket, useRouteInfo, useReactions } from '@onArte/frontend/hooks';
import { RoutingState, UseBasket, UseReactions } from '@onArte/frontend/interfaces';
import { Breadcrumbs } from '@onArte/frontend/theme';
import { getImageThumbnail } from '@onArte/frontend/utils';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { useArtworkDetails } from './artworkDetails.hook';
import { 
  AboutArtworkDescriptionWrapper,
  AboutContainer,
  AllDetailsContainer, 
  ArtworkDetailsContainer, 
  ArtworkIconsContainer, 
  Container, 
  DeliveryArtworkDescriptionWrapper, 
  PhotosSliderContainer, 
  OfferConditionsArtworkDescriptionWrapper, 
  QuoteCarouselContainer,
  StyledArtworkDescription,
  StyledArtworkIcon,
  StyledArtworkSummary,
  ArtworkDetailsWrapper,
  StyledBoxesSliderSection,
  StyledBoxesSlider,
  StyledSectionTitle,
} from './artworkDetails.styled';
import { UseArtworkDetails } from './artworkDetails.types';
import { FullScreenPopup } from './components';

export const ArtworkDetailsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const aboutWrapperRef: React.Ref<HTMLDivElement> = useRef(null);
  const deliveryWrapperRef: React.Ref<HTMLDivElement> = useRef(null);
  const offerConditionsWrapperRef: React.Ref<HTMLDivElement> = useRef(null);
  const { onAddToCartItem }: UseBasket = useBasket();
  const { elementParamsFromRouting, currentRouteObject }: RoutingState = useRouteInfo();
  const {
    artworkSummaryTheme,
    attributesWithValues,
    artworkDimensions,
    buyNowAction,
    openPopupAction,
    nextPrice,
    artworkDetailsData,
    artworkPhotos,
    authorMoreArtworks,
    artworkDescriptionAttributes,
    isPopupVisible,
    setIsPopupVisible,
    makeBiddingOfferAction,
    makePriceProposalOfferAction,
    onPopupInputChange,
    inputValue,
    popupValidationMessage,
    offerPrice,
    commissionPercent
  }: UseArtworkDetails = useArtworkDetails(elementParamsFromRouting.id, currentRouteObject);
  const todaysTime: number = new Date().getTime();
  const { redirect }: UseRedirect = useRedirect();
  const { 
    userFavouriteItemsIds, 
    userFavouriteManufacturersIds, 
    isFavourite, 
    changeLikeState 
  }: UseReactions = useReactions();

  const isItemFavourite: boolean = useMemo(
    (): boolean => isFavourite(artworkDetailsData?.itemDetails.id ?? '', ReactionUsageContextTypeEnum.Item),
    [artworkDetailsData?.itemDetails.id, userFavouriteItemsIds.length]
  );

  const isManufacturerFavourite: boolean = useMemo(
    (): boolean => isFavourite(artworkDetailsData?.itemDetails.manufacturer.id ?? '', ReactionUsageContextTypeEnum.Manufacturer),
    [artworkDetailsData?.itemDetails.manufacturer.id, userFavouriteManufacturersIds.length]
  );

  const handleManufacturerReaction: () => void = (): void => {
    if (!artworkDetailsData?.itemDetails.manufacturer.id) {
      return;
    }

    changeLikeState(artworkDetailsData.itemDetails.manufacturer.id, isManufacturerFavourite, ReactionUsageContextTypeEnum.Manufacturer);
  };

  return (
    <Container>
      {artworkDetailsData && (
        <ArtworkDetailsWrapper>
          <Breadcrumbs 
            breadcrumbs={[
              { 
                label: t('onarte.website.artworkDetailsView.breadcrumbs.title'), 
                path: getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/' 
              }, 
              { label: artworkDetailsData.itemDetails.name, path: '/' }
            ]} 
          />
          <ArtworkDetailsContainer id='artwork-container'>
            <PhotosSliderContainer>
              <PhotosSlider images={artworkPhotos} />
            </PhotosSliderContainer>
            <AllDetailsContainer>
              <StyledArtworkSummary
                isAuctionEnded={artworkDetailsData.status === AuctionStatusEnum.Finished}
                inputDefaultValue={nextPrice?.toString()}
                informationBoxDetails={{
                  name: artworkDetailsData.itemDetails.manufacturer.name, 
                  iconName: IconName.CollectorFilled,
                  onClick: handleManufacturerReaction,
                  buttonLabel: t(isManufacturerFavourite
                    ? 'onarte.website.artworkDetailsView.unfollowButtonLabel'
                    : 'onarte.website.artworkDetailsView.followButtonLabel'
                  ),
                  nameUrlSettings: {
                    internalPath: artworkDetailsData.publicProfile ? (
                      artworkDetailsData.publicProfile.route?.prettyUrl
                      ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtistDetails)?.url ?? '/', { 
                        id: artworkDetailsData.publicProfile.id ?? ''
                      })
                    ) : undefined,
                  },
                  avatar: artworkDetailsData.publicProfile?.attachments.length 
                    ? getImageThumbnail(
                      artworkDetailsData.publicProfile.attachments.find((
                        attachment: ExternalAttachmentInterface | UploadedAttachmentInterface
                      ): boolean => attachment.type === AttachmentTypeEnum.PublicProfileAvatar),
                      ThumbnailAttachmentTypeEnum.Size_100x100
                    )
                    : undefined,
                  // TODO: temporary hidden
                  // onClick: (): void => addToast({ content: t('onarte.common.functionalityCurrentlyNotAvailable') }),
                  // buttonLabel: t('onarte.website.artworkDetailsView.followButtonLabel')
                }} 
                title={artworkDetailsData.itemDetails.name} 
                artworkSummaryTheme={artworkSummaryTheme} 
                artworkAttributes={[
                  `${t(`onarte.common.itemsCategories.${artworkDetailsData.itemDetails.category.name}`)},
                   ${artworkDetailsData.itemDetails.year ?? ''}`,
                  artworkDimensions
                ]}
                price={artworkDetailsData.type !== AuctionTypeEnum.PriceProposal ? artworkDetailsData.price : undefined}
                onButtonClick={artworkDetailsData.type === AuctionTypeEnum.BuyNow
                  ? buyNowAction
                  : openPopupAction
                }
                onSecondaryButtonClick={(): void => onAddToCartItem(
                  artworkDetailsData.id,
                  artworkDetailsData.itemDetails.name,
                  artworkDetailsData.price ?? null
                )}
                artworkSummaryAuctionDetails={artworkDetailsData.estimation
                  ? {
                    bidders: artworkDetailsData.offers,
                    estimationMin: artworkDetailsData.estimation.from,
                    estimationMax: artworkDetailsData.estimation.to,
                    auctionDeadline: artworkDetailsData.validity?.to ?? new Date().getTime(),
                    auctionStart: artworkDetailsData.validity?.from ?? new Date().getTime(),
                    minimalPriceNotReached: !artworkDetailsData.reachedAcceptablePrice,
                  }
                  : undefined
                }
                disabled={artworkDetailsData.isItMine || ((artworkDetailsData.validity?.from ?? 0) > todaysTime)}
              />
              <FullScreenPopup 
                isPopupVisible={isPopupVisible} 
                setIsPopupVisible={setIsPopupVisible}
                artworkDetailsData={artworkDetailsData}
                popupAction={artworkDetailsData.type === AuctionTypeEnum.Bidding ? makeBiddingOfferAction : makePriceProposalOfferAction}
                artworkPhoto={artworkPhotos[0]}
                onPopupInputChange={onPopupInputChange}
                inputValue={inputValue}
                popupValidationMessage={popupValidationMessage}
                offerPrice={offerPrice}
                commissionPercent={commissionPercent}
              />
              <ArtworkIconsContainer>
                <StyledArtworkIcon 
                  label={t('onarte.website.artworkDetailsView.aboutArtwork.label')} 
                  icon={IconName.Image} 
                  onClick={(): void | null => aboutWrapperRef.current?.scrollIntoView({ behavior: 'smooth' })}
                />
                {artworkDetailsData.offerConditions && (
                  <StyledArtworkIcon 
                    label={t('onarte.website.artworkDetailsView.offerConditions.label')} 
                    icon={IconName.Money} 
                    onClick={(): void | null => offerConditionsWrapperRef.current?.scrollIntoView({ behavior: 'smooth' })}
                  />
                )}
                <StyledArtworkIcon 
                  label={t('onarte.website.artworkDetailsView.delivery.label')} 
                  icon={IconName.Delivery} 
                  onClick={(): void | null => deliveryWrapperRef.current?.scrollIntoView({ 
                    behavior: 'smooth'
                  })}
                />
                <StyledArtworkIcon
                  label={t(isItemFavourite
                    ? 'onarte.website.artworkDetailsView.removeFavorite.label'
                    : 'onarte.website.artworkDetailsView.favorite.label'
                  )}
                  icon={isItemFavourite ? IconName.FavouriteFilled : IconName.Favourite}
                  onClick={(): void => changeLikeState(
                    artworkDetailsData.itemDetails.id, isItemFavourite, ReactionUsageContextTypeEnum.Item
                  )}
                  id='artwork-details-favorite-button'
                />
              </ArtworkIconsContainer>
            </AllDetailsContainer>
          </ArtworkDetailsContainer>
          {artworkDetailsData.itemDetails.curatorialCommentary && (
            <QuoteCarouselContainer>
              <QuoteCarousel 
                quotes={[{
                  content: artworkDetailsData.itemDetails.curatorialCommentary, 
                  author: '',
                  authorSpecialty: '',
                }]}
              />
            </QuoteCarouselContainer>
          )}
          <AboutContainer>
            <StyledArtworkDescription
              title={t('onarte.website.artworkDetailsView.author.label')}
              description={artworkDetailsData.publicProfile?.description}
              informationBoxDetails={{
                name: artworkDetailsData.itemDetails.manufacturer.name, 
                iconName: IconName.CollectorFilled,
                onClick: handleManufacturerReaction,
                buttonLabel: t(isManufacturerFavourite
                  ? 'onarte.website.artworkDetailsView.unfollowButtonLabel'
                  : 'onarte.website.artworkDetailsView.followButtonLabel'
                ),
                nameUrlSettings: {
                  internalPath: artworkDetailsData.publicProfile ? (
                    artworkDetailsData.publicProfile.route?.prettyUrl
                    ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtistDetails)?.url ?? '/', { 
                      id: artworkDetailsData.publicProfile.id ?? '' 
                    })
                  ) : undefined,
                },
                avatar: artworkDetailsData.publicProfile?.attachments.length 
                  ? getImageThumbnail(
                    artworkDetailsData.publicProfile?.attachments.find((
                      attachment: ExternalAttachmentInterface | UploadedAttachmentInterface
                    ): boolean => attachment.type === AttachmentTypeEnum.PublicProfileAvatar),
                    ThumbnailAttachmentTypeEnum.Size_100x100
                  )
                  : undefined,
                // TODO: temporary hidden
                // onClick: (): void => addToast({ content: t('onarte.common.functionalityCurrentlyNotAvailable') }), 
                // buttonLabel: t('onarte.website.artworkDetailsView.followButtonLabel')
              }}
            />
            {!!artworkDetailsData.offerConditions && (
              <OfferConditionsArtworkDescriptionWrapper ref={offerConditionsWrapperRef}>
                <StyledArtworkDescription
                  title={t('onarte.website.artworkDetailsView.offerConditions.label')} 
                  attributes={[
                    artworkDescriptionAttributes[ArtworkDescriptionEnum.Commission],
                    artworkDescriptionAttributes[ArtworkDescriptionEnum.Contact],
                  ]} 
                  description={artworkDetailsData.offerConditions}
                />
              </OfferConditionsArtworkDescriptionWrapper>
            )}
            <AboutArtworkDescriptionWrapper ref={aboutWrapperRef}>
              <StyledArtworkDescription 
                title={t('onarte.website.artworkDetailsView.aboutArtwork.label')} 
                attributes={attributesWithValues} 
                description={artworkDetailsData.itemDetails.description ?? ''}
              />
            </AboutArtworkDescriptionWrapper>
            <DeliveryArtworkDescriptionWrapper ref={deliveryWrapperRef}>
              <StyledArtworkDescription 
                title={t('onarte.website.artworkDetailsView.delivery.label')} 
                attributes={[
                  artworkDescriptionAttributes[ArtworkDescriptionEnum.Location],
                  artworkDescriptionAttributes[ArtworkDescriptionEnum.Contact],
                  artworkDescriptionAttributes[ArtworkDescriptionEnum.Return],
                  // TODO: temporary hidden
                  // artworkDescriptionAttributes[ArtworkDescriptionEnum.DeliveryTime],
                  artworkDescriptionAttributes[ArtworkDescriptionEnum.Delivery],
                ]} 
              />
            </DeliveryArtworkDescriptionWrapper>
            {!!authorMoreArtworks.length && (
              <StyledBoxesSliderSection id='boxes-slider-more-artworks'>
                <StyledSectionTitle
                  header={t('onarte.website.artworkDetailsView.authorMoreArtworks.header')}
                  // TODO: temporary hidden
                  // buttonLabel={buttonLabel}
                  // buttonAction={buttonAction}
                />
                <StyledBoxesSlider slides={authorMoreArtworks} />
              </StyledBoxesSliderSection>
            )}
          </AboutContainer>
          {!!artworkDetailsData.itemDetails.blogPosts.length && (
            <BlogBoxesSliderSection posts={artworkDetailsData.itemDetails.blogPosts} />
          )}
        </ArtworkDetailsWrapper>
      )}
    </Container>
  );
};
