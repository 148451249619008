import {
  ListPaginationInterface,
  AuctionInfoWithItemIdInterface,
  AuctionOfferListElementDTO,
  AuctionOfferModel,
  AuctionOfferStatusEnum,
  AuctionsSearchCriteriaOnWebsiteModel
} from '@on-arte/core-types';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { AuctionPublicDetails, OffersListFilters } from '@onArte/shared/interfaces';

import { api } from '../api';

export const getAuctions: (
  params: AuctionsSearchCriteriaOnWebsiteModel
) => Promise<ListPaginationInterface<AuctionInfoWithItemIdInterface>> = (
  params: AuctionsSearchCriteriaOnWebsiteModel
): Promise<ListPaginationInterface<AuctionInfoWithItemIdInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<AuctionInfoWithItemIdInterface>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/auctions?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<AuctionInfoWithItemIdInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getAuctionDetails: (id: string) => Promise<AuctionPublicDetails> = (
  id: string
): Promise<AuctionPublicDetails> => new Promise(
  (resolve: ApiResolve<AuctionPublicDetails>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/auctions/${id}`).then(
      (response: AxiosResponse<AuctionPublicDetails>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getOffersList: (params: OffersListFilters) => Promise<ListPaginationInterface<AuctionOfferListElementDTO>> = (
  params: OffersListFilters
): Promise<ListPaginationInterface<AuctionOfferListElementDTO>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<AuctionOfferListElementDTO>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/auctions/offers?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<AuctionOfferListElementDTO>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const makeOffer: (auctionId: string, data: AuctionOfferModel) => Promise<void> = (
  auctionId: string, data: AuctionOfferModel
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/auctions/${auctionId}/make-offer`, data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const setOfferStatus: (offerToken: string, status: AuctionOfferStatusEnum) => Promise<void> = (
  offerToken: string, status: AuctionOfferStatusEnum
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/auctions/offers/${offerToken}/${status}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
