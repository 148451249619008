import { ArtworkDescription, Breakpoint, Color, FontWeight, hexToRgba, Image, StyledComponent, ZIndex } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  max-width: 100%;
  padding-bottom: 40px;
`;

export const Wrapper: StyledComponent<'div'> = styled.div``;

export const DetailsContainer: StyledComponent<'div'> = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 40px 0;

  @media ${Breakpoint.Tablet} {
    padding: 40px 32px 0;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 40px 20px 0;
  }
`;

export const StyledArtworkDescription: StyledComponent<typeof ArtworkDescription> = styled(ArtworkDescription)`
  margin: 60px 0 120px 0;

  @media ${Breakpoint.Mobile} {
    margin-bottom: 80px;
  }
`;

export const BackgroundContainer: StyledComponent<'div'> = styled.div`
  height: calc(100vh - 38px);
  width: 100%;
  position: relative;

  @media ${Breakpoint.SmallMobile} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const BackgroundImage: StyledComponent<typeof Image> = styled(Image)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  @media ${Breakpoint.SmallMobile} {
    position: static;
  }
`;

export const BackgroundOverlay: StyledComponent<'div'> = styled.div`
  background-color: ${hexToRgba(Color.Black, 0.2)};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${ZIndex.Zero};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BackgroundContent: StyledComponent<'div'> = styled.div`
  position: absolute;
  top: 88px;
  left: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 740px;
  z-index: ${ZIndex.Min};
  font-weight: ${FontWeight.Medium};
  color: ${Color.White};
  line-height: 44px;
  letter-spacing: -0.01em;

  @media ${Breakpoint.Tablet} {
    left: 32px;
  }

  @media ${Breakpoint.Mobile} {
    left: 20px;
  }
`;

export const BackgroundTitle: StyledComponent<'span'> = styled.span`
  font-size: 40px;
  margin: 0 0 20px;

  @media ${Breakpoint.SmallMobile} {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const BackgroundSubtitle: StyledComponent<'span'> = styled.span`
  font-size: 30px;
  margin: 0 0 20px;

  @media ${Breakpoint.SmallMobile} {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const NoArtworksMessage: StyledComponent<'div'> = styled.div`
  padding: 40px 40px 0;

  @media ${Breakpoint.Tablet} {
    padding: 40px 32px 0;
  }

  @media ${Breakpoint.Mobile} {
    text-align: center;
  }
`;

export const MosaicWrapper: StyledComponent<'div'> = styled.div`
  overflow: hidden;
  padding: 0 20px;

  @media ${Breakpoint.Tablet} {
    padding: 0 16px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 0 10px;
  }
`;
