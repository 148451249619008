import { AuctionTypeEnum } from '@on-arte/core-types';

import { ItemSaleEditStatus } from '@onArte/shared/enums';

export const getItemSaleEditStatusByAuctionType = (auctionType: AuctionTypeEnum): ItemSaleEditStatus => {
  switch (auctionType) {
    case AuctionTypeEnum.BuyNow:
      return ItemSaleEditStatus.BuyNow;
    case AuctionTypeEnum.PriceProposal:
      return ItemSaleEditStatus.PriceProposal;
    case AuctionTypeEnum.Bidding:
      return ItemSaleEditStatus.Bidding;
    default:
      return ItemSaleEditStatus.Disabled;
  }
};
