import { browserIdRegexp } from '@on-arte/core-types';
import { Type } from 'class-transformer';
import { IsDefined, IsEnum, IsString, Matches, MinLength, ValidateIf, ValidateNested } from 'class-validator';

import { ConversionType } from '@onArte/shared/enums';

import { ConversionEventCustomData } from './conversionEventCustomData.model';

export class ConversionEventData {
  @IsString()
  @MinLength(1)
  public eventId: string;

  @IsString()
  @MinLength(1)
  @Matches(browserIdRegexp)
  public browserId: string;

  @IsEnum(ConversionType)
  @IsDefined()
  public conversionType: ConversionType;

  @IsString()
  @IsDefined()
  public sourceUrl: string;

  @ValidateIf((object: unknown, value: ConversionEventCustomData | undefined): boolean => value !== undefined)
  @ValidateNested()
  @Type(() => ConversionEventCustomData)
  public customData?: ConversionEventCustomData;

  constructor(
    eventId: string,
    browserId: string,
    conversionType: ConversionType,
    sourceUrl: string,
    customData?: ConversionEventCustomData,
  ) {
    this.eventId = eventId;
    this.browserId = browserId;
    this.conversionType = conversionType;
    this.sourceUrl = sourceUrl;
    this.customData = customData;
  }
}
