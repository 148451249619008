import { 
  AttachmentTypeEnum, 
  AuctionTypeEnum, 
  ExternalAttachmentInterface, 
  ListPaginationInterface, 
  PublicProfileTypeEnum, 
  ReactionUsageContextTypeEnum, 
  ThumbnailAttachmentTypeEnum, 
  UploadedAttachmentInterface 
} from '@on-arte/core-types';
import { 
  IconName, 
  InfinitePaginationDetails, 
  ItemsMosaic, 
  ItemsMosaicTheme, 
  LoadingSpinner, 
  UseLogger, 
  UseNotifications, 
  UseRedirect, 
  UseState, 
  getPathWithParams, 
  useInfinitePagination, 
  useLogger, 
  useNotifications, 
  useRedirect,
} from '@on-arte/ui';
import React, { useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQuery } from 'react-query';

import { getPublicProfileDetails, getPublicProfileItems } from '@onArte/frontend/api/requests';
import { FrontendResponseCode, QueryKey } from '@onArte/frontend/enums';
import { useRouteInfo, useReactions } from '@onArte/frontend/hooks';
import { RoutingState, UseReactions, ArtworkMosaicItemDetailsWithItemId } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { emptyRequest, getImageThumbnail } from '@onArte/frontend/utils';
import { RouteNameEnum } from '@onArte/shared/enums';
import { ItemWithAuctionDetails, PublicProfileDetails } from '@onArte/shared/interfaces';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { BlogBoxesSliderSection } from '../blogBoxesSliderSection/blogBoxesSliderSection.component';

import { 
  BioArtworkDescription, 
  Container, 
  MosaicWrapper,
  DetailsContainer, 
  DetailsWrapper,
  PhotoBackgroundContainer, 
  StyledArtistTitle, 
  StyledArtworkDescription,
} from './baseViewWithBio.styled';
import { BaseViewWithBioProps } from './baseViewWithBio.types';

export const BaseViewWithBio: React.FC<BaseViewWithBioProps> = (props: BaseViewWithBioProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { type, route }: BaseViewWithBioProps = props;
  const { logger }: UseLogger = useLogger();
  const { redirect }: UseRedirect = useRedirect();
  const { addToast }: UseNotifications = useNotifications();
  const { elementParamsFromRouting, currentRouteObject }: RoutingState = useRouteInfo();
  const { 
    hasMoreItems, 
    fetchMoreData, 
    initialItemsCount, 
    paginationOffset, 
    setMaxItems 
  }: InfinitePaginationDetails = useInfinitePagination(20);
  const { 
    userFavouriteManufacturersIds, 
    isFavourite, 
    changeLikeState,
    favoritesReadyState,
    userFavouriteItemsIds
  }: UseReactions = useReactions();
  const [details, setDetails]: UseState<PublicProfileDetails | null> = useState<PublicProfileDetails | null>(null);
  const [mosaicItems, setMosaicItems]: UseState<ArtworkMosaicItemDetailsWithItemId[]> = useState<ArtworkMosaicItemDetailsWithItemId[]>([]);

  useQuery(
    [QueryKey.PublicProfileDetails, elementParamsFromRouting.id],
    (): Promise<PublicProfileDetails | void> => elementParamsFromRouting.id && currentRouteObject?.name === route
      ? getPublicProfileDetails(elementParamsFromRouting.id)
      : emptyRequest(),
    {
      onSuccess: (data: PublicProfileDetails): void => {
        if (data.type !== type) {
          redirect(getRouteDetailsByName(RouteNameEnum.NotFound)?.url ?? '/');
        }
        setDetails(data);
      },
      onError: (error: FrontendApiError): void => {
        logger(QueryKey.PublicProfileDetails, error);
        if (error.responseCode === FrontendResponseCode.NotFound) {
          redirect(getRouteDetailsByName(RouteNameEnum.NotFound)?.url ?? '/');
          return;
        } else {
          addToast({ content: t(error.message) });
        }
      }
    }
  );

  useQuery(
    [QueryKey.PublicProfileItems, paginationOffset, elementParamsFromRouting.id, favoritesReadyState],
    (): Promise<ListPaginationInterface<ItemWithAuctionDetails> | void> => (
      elementParamsFromRouting.id && currentRouteObject?.name === route && favoritesReadyState
    )
      ? getPublicProfileItems({ limit: initialItemsCount, offset: paginationOffset }, elementParamsFromRouting.id) 
      : emptyRequest()
    ,
    {
      onSuccess: (data: ListPaginationInterface<ItemWithAuctionDetails>): void => {        
        setMaxItems(data.amount);
        setMosaicItems([
          ...mosaicItems,
          ...data.list.map((item: ItemWithAuctionDetails): ArtworkMosaicItemDetailsWithItemId => {
            const isItemLiked: boolean = isFavourite(item.itemId, ReactionUsageContextTypeEnum.Item);

            return {
              itemId: item.itemId,
              image: getImageThumbnail(item.attachment, ThumbnailAttachmentTypeEnum.Size_340xAuto),
              action: !item.auctionId
                ? (): void => addToast({ content: t('onarte.common.mosaicItems.noAuction') })
                : undefined,
              internalPath: item.auctionId
                ? item.route?.prettyUrl
                  ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/', { id: item.auctionId })
                : undefined,
              boxTitleDetails: {
                itemName: item.name,
                author: item.manufacturer.name,
                price: item.auctionType === AuctionTypeEnum.PriceProposal ? undefined : item.price,
                bottomButtonLabel: item.auctionType === AuctionTypeEnum.PriceProposal ? t('onarte.common.priceProposal') : undefined,
                itemDescriptiveAttributes: [t(item.label)],
              },
              isLiked: isItemLiked,
              onLikeClick: (): void => changeLikeState(item.itemId, isItemLiked, ReactionUsageContextTypeEnum.Item)
            };
          })
        ]);
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.PublicProfileItems, error)
    }
  );

  useEffect(
    (): void => {
      setMosaicItems(mosaicItems.map((item: ArtworkMosaicItemDetailsWithItemId) => {
        const isItemLiked: boolean = !!userFavouriteItemsIds.find((value: string) => value === item.itemId);
        return { 
          ...item, 
          isLiked: isItemLiked,
          onLikeClick: (): void => changeLikeState(item.itemId, isItemLiked, ReactionUsageContextTypeEnum.Item)
        };
      }));
    },
    [userFavouriteItemsIds]
  );

  const getFilteredAttachments: (
    photoType: AttachmentTypeEnum
  ) => (ExternalAttachmentInterface | UploadedAttachmentInterface)[] = (
    photoType: AttachmentTypeEnum
  ): (ExternalAttachmentInterface | UploadedAttachmentInterface)[] => {
    if (!details) {
      return [];
    }

    return details.attachments.filter((
      img: ExternalAttachmentInterface | UploadedAttachmentInterface
    ): boolean => img.type === photoType);
  };

  const coverPhoto: string = useMemo(
    (): string => {
      const filteredAttachments: (ExternalAttachmentInterface | UploadedAttachmentInterface)[] = getFilteredAttachments(
        AttachmentTypeEnum.PublicProfileCoverPhoto
      );

      return filteredAttachments.length
        ? filteredAttachments[0].path
        : '';
    },
    [details]
  );

  const avatarPhoto: string = useMemo(
    (): string => {
      const filteredAttachments: (ExternalAttachmentInterface | UploadedAttachmentInterface)[] = getFilteredAttachments(
        AttachmentTypeEnum.PublicProfileAvatar
      );

      return filteredAttachments.length
        ? getImageThumbnail(filteredAttachments[0], ThumbnailAttachmentTypeEnum.Size_340xAuto)
        : '';
    },
    [details]
  );

  const galleryPhotos: string[] = useMemo(
    (): string[] => {
      const filteredAttachments: (ExternalAttachmentInterface | UploadedAttachmentInterface)[] = getFilteredAttachments(
        AttachmentTypeEnum.PublicProfileGalleryPhoto
      );

      return filteredAttachments.map((attachment: ExternalAttachmentInterface | UploadedAttachmentInterface): string => attachment.path);
    },
    [details]
  );

  const isProfileManufacturer: boolean = useMemo(
    (): boolean => details?.type === PublicProfileTypeEnum.Manufacturer,
    [details?.type]
  );

  const isManufacturerFavourite: boolean = useMemo(
    (): boolean => isFavourite(details?.relationId ?? '', ReactionUsageContextTypeEnum.Manufacturer),
    [details?.relationId, userFavouriteManufacturersIds.length]
  );

  const changeManufacturerLikeState: () => void = (): void => {
    if (!details?.relationId) {
      return;
    }

    changeLikeState(details.relationId, isManufacturerFavourite, ReactionUsageContextTypeEnum.Manufacturer);
  };

  return (
    <Container>
      {details && (
        <DetailsWrapper>
          {!!coverPhoto && <PhotoBackgroundContainer $imgUrl={coverPhoto} />}
          <DetailsContainer>
            <StyledArtistTitle 
              title={details.name} 
              subtitle={t(`onarte.website.baseViewWithBio.${details.type}.subtitle`)}
              image={avatarPhoto}
              buttonLabel={
                isProfileManufacturer && !!details.relationId
                  ? t(isManufacturerFavourite
                    ? 'onarte.website.baseViewWithBio.button.unfollow'
                    : 'onarte.website.baseViewWithBio.button.follow')
                  : undefined
              }
              buttonIcon={
                isProfileManufacturer && !!details.relationId
                  ? (isManufacturerFavourite
                    ? IconName.FavouriteFilled
                    : IconName.Favourite)
                  : undefined
              }
              buttonAction={
                isProfileManufacturer && !!details.relationId
                  ? changeManufacturerLikeState
                  : undefined
              }
            />
            <StyledArtworkDescription
              title={t('onarte.website.baseViewWithBio.about.title')}
              description={details.description}
              // TODO: temporary hidden - fix when BE will be ready
              // badges={[{
              //   label: 'Selekcja on*arte',
              //   icon: IconName.Collector
              // }]}
            />
          </DetailsContainer>
          {!!mosaicItems.length && (
            <InfiniteScroll
              dataLength={mosaicItems.length}
              next={fetchMoreData}
              hasMore={hasMoreItems}
              loader={
                <LoadingSpinner />
              }
              endMessage={false}
            >
              <MosaicWrapper>
                <ItemsMosaic 
                  items={mosaicItems}
                  theme={ItemsMosaicTheme.Mosaic}
                />
              </MosaicWrapper>
            </InfiniteScroll>
          )}
          {!!details.bio && (
            <BioArtworkDescription 
              title={t('onarte.website.baseViewWithBio.bio.title')}
              description={details.bio}
              images={galleryPhotos}
            />
          )}
          {!!details.blogPosts.length && <BlogBoxesSliderSection posts={details.blogPosts} withTopBar />}
        </DetailsWrapper>
      )}
    </Container>
  );
};
