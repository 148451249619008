import { AddressInterface, AddressTypeEnum } from '@on-arte/core-types';
import {
  BillingAddressForm,
  BillingAddressFormData,
  DialogTheme,
  ShippingAddressForm,
  ShippingAddressFormData,
  SmallDialog,
  useFormikForm,
  UseFormikForm,
  UseState,
} from '@on-arte/ui';
import { FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { deleteAddress, saveAddress } from '@onArte/frontend/api/requests';
import { initialBillingAddressValues, initialShippingAddressValues } from '@onArte/frontend/constants';
import { useAddresses } from '@onArte/frontend/hooks';
import { UseAddresses } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { AddressSaveWithLocationModel } from '@onArte/shared/models';

import { StyledShippingBox } from './addressesDialog.styled';
import { AddressesDialogProps } from './addressesDialog.types';

export const AddressesDialog: React.FC<AddressesDialogProps> = (props: AddressesDialogProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { formType, onActiveAddressChange, activeAddressId, isVisible, onClose }: AddressesDialogProps = props;
  const { isFormSubmitted, setFormSubmitted, setFormNotSubmitted }: UseFormikForm = useFormikForm();
  const formRef: React.RefObject<FormikProps<ShippingAddressFormData | BillingAddressFormData>> = useRef(null);
  const {
    deliveryAddresses,
    billingAddresses,
    addAddressToCache,
    removeAddressFromCache,
    updateAddressInCache,
    getPrettyAddressLines,
    getBillingAddressTitle,
    transformBillingAddressDataToAddressWithLocation,
    transformShippingAddressDataToAddressWithLocation,
    transformAddressInterfaceToShippingAddressFormData,
    transformAddressInterfaceToBillingAddressFormData,
  }: UseAddresses = useAddresses(true);
  const userAddresses: AddressInterface[] = formType === AddressTypeEnum.Delivery
    ? deliveryAddresses
    : billingAddresses;
  const [isPopupVisible, setIsPopupVisible]: UseState<boolean> = useState<boolean>(isVisible);
  const [addressIdInForm, setAddressIdInForm]: UseState<string | undefined> = useState<string | undefined>();
  const [isFormVisible, setIsFormVisible]: UseState<boolean> = useState<boolean>(false);
  const [addressFormValues, setAddressFormValues]: UseState<ShippingAddressFormData | BillingAddressFormData>
    = useState<ShippingAddressFormData | BillingAddressFormData>(formType
      ? initialShippingAddressValues
      : initialBillingAddressValues
    );
  const [currentAddress, setCurrentAddress]: UseState<AddressInterface | undefined> = useState<AddressInterface | undefined>();

  useEffect((): void => setIsPopupVisible(isVisible), [isVisible]);

  useEffect(
    (): void => {
      const searchedAddress: AddressInterface | undefined = userAddresses
        .find((address: AddressInterface): boolean => address.id === activeAddressId);
      if (searchedAddress) {
        setCurrentAddress(searchedAddress);
      }
      setIsFormVisible(!userAddresses.length);
    },
    [activeAddressId, userAddresses]
  );

  const closePopupAction: () => void = (): void => {
    setIsPopupVisible(false);
    setIsFormVisible(false);
    setAddressIdInForm(undefined);
    onClose();
  };

  const chooseAddress: (address: AddressInterface) => void = (address: AddressInterface): void => {
    onActiveAddressChange(address);
    closePopupAction();
  };

  const onCancelButtonPopupAction: () => void = (): void => {
    if (isFormVisible) {
      setIsFormVisible(false);
      setAddressIdInForm(undefined);
    } else {
      setIsFormVisible(true);
      setAddressFormValues(formType === AddressTypeEnum.Delivery
        ? initialShippingAddressValues
        : initialBillingAddressValues
      );
    }
  };

  const onEditAddressClick: (addressId: string) => void = (addressId: string) => {
    const editedAddress: AddressInterface | undefined = userAddresses
      .find((address: AddressInterface): boolean => address.id === addressId);

    if (editedAddress) {
      setAddressFormValues(formType === AddressTypeEnum.Delivery
        ? transformAddressInterfaceToShippingAddressFormData(editedAddress)
        : transformAddressInterfaceToBillingAddressFormData(editedAddress)
      );
      setAddressIdInForm(addressId);
      setIsFormVisible(true);
    }
  };

  const deleteAddressAction: (addressId: string) => void = (addressId: string): void => {
    deleteAddress(addressId)
      .then((): void => {
        const filteredAddresses: AddressInterface[] = userAddresses
          .filter((address: AddressInterface): boolean => address.id !== addressId);
        if (currentAddress?.id === addressId && filteredAddresses.length) {
          chooseAddress(filteredAddresses[0]);
        }
        removeAddressFromCache(formType, addressId);
      })
      .catch((): void => undefined);
  };

  const saveAddressMethod: () => Promise<string> = (): Promise<string> => {
    return new Promise<string>(async (resolve: ((value: string) => void), reject: ((error: string) => void)): Promise<void> => {
      if (!formRef?.current) {
        return;
      }

      setFormSubmitted();
      const formObject: FormikProps<ShippingAddressFormData | BillingAddressFormData> = formRef.current;
      const isValid: boolean = !Object.keys((await formObject.validateForm())).length;

      if (!isValid) {
        return;
      }

      const saveData: AddressSaveWithLocationModel = formType === AddressTypeEnum.Delivery
        ? transformShippingAddressDataToAddressWithLocation(formObject.values as ShippingAddressFormData, addressIdInForm)
        : transformBillingAddressDataToAddressWithLocation(formObject.values, addressIdInForm);

      if (saveData) {
        saveAddress(saveData)
          .then((data: AddressInterface): void => {
            if (!addressIdInForm || (addressIdInForm && addressIdInForm !== data.id)) {
              addAddressToCache(formType, data);
              chooseAddress(data);
              resolve(data.id);
            } else {
              updateAddressInCache(formType, data);
              if (currentAddress?.id === addressIdInForm) {
                setCurrentAddress(data);
              }
              setAddressIdInForm(undefined);
            }
            setFormNotSubmitted();
            setIsFormVisible(false);
            formRef?.current?.resetForm();
          })
          .catch((error: FrontendApiError): void => reject(error.message));
      }
    });
  };

  return (
    <SmallDialog
      settings={{
        isActive: isPopupVisible,
        header: !isFormVisible
          ? t('onarte.website.cartDelivery.savedAddresses.popupHeader')
          : addressFormValues === initialShippingAddressValues 
            ? t('onarte.website.cartDelivery.newAddress.popupHeader')
            : t('onarte.website.cartDelivery.editAddress.popupHeader'),
        acceptButton: {
          label: !isFormVisible
            ? t('onarte.website.cartDelivery.choose.buttonLabel')
            : t('onarte.website.cartDelivery.saveChanges.buttonLabel'),
          action: isFormVisible 
            ? saveAddressMethod
            : currentAddress
              ? (): void => chooseAddress(currentAddress)
              : undefined,
        },
        theme: DialogTheme.Medium,
        cancelButton: {
          action: onCancelButtonPopupAction,
          label: isFormVisible
            ? t('onarte.common.cancel')
            : t('onarte.website.cartDelivery.addNewAddress.buttonLabel'),
        },
        closeButtonAction: closePopupAction,
      }}
    >
      {!isFormVisible ?
        userAddresses.map((address: AddressInterface, addressIndex: number): JSX.Element => (
          <StyledShippingBox
            title={formType === AddressTypeEnum.Billing
              ? t(getBillingAddressTitle(address))
              : undefined
            }
            addressLines={getPrettyAddressLines(address, formType)}
            isActive={currentAddress?.id === address.id}
            onBoxChoose={(): void => setCurrentAddress(address)}
            onEditClick={(): void => onEditAddressClick(address.id)}
            onRemoveClick={(): void => deleteAddressAction(address.id)}
            key={`${address?.lastName}_${addressIndex}`}
          />
        )) : formType === AddressTypeEnum.Delivery ? (
          <ShippingAddressForm
            initialValues={addressFormValues as ShippingAddressFormData}
            isFormSubmitted={isFormSubmitted}
            ref={formRef as React.RefObject<FormikProps<ShippingAddressFormData>>}
          />
        ) : (
          <BillingAddressForm
            initialValues={addressFormValues as BillingAddressFormData}
            isFormSubmitted={isFormSubmitted}
            ref={formRef}
          />
        )}
    </SmallDialog>
  );
};
