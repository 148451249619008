import { 
  ExternalUserProfileWithContextTokenInterface, 
  InitResetPasswordModel, 
  LoginByPasswordModel,
  ResetPasswordModel 
} from '@on-arte/core-types';
import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { UserProfileWithToken } from '@onArte/shared/interfaces/api';

import { api } from '../api';

export const loginRequest: (data: LoginByPasswordModel) => Promise<UserProfileWithToken> = (
  data: LoginByPasswordModel
): Promise<UserProfileWithToken> => new Promise(
  (resolve: ApiResolve<UserProfileWithToken>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/authorization/login', data).then(
      (response: AxiosResponse<UserProfileWithToken>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const logoutRequest: () => Promise<void> = (): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<void>): Promise<void> => (
    api.post('/authorization/logout').then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<void>): void => reject(error))
  )
);

export const initResetPassword: (data: InitResetPasswordModel) => Promise<void> = (
  data: InitResetPasswordModel
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/authorization/init-reset-password', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const resetPassword: (data: ResetPasswordModel) => Promise<ExternalUserProfileWithContextTokenInterface> = (
  data: ResetPasswordModel
): Promise<ExternalUserProfileWithContextTokenInterface> => new Promise(
  (resolve: ApiResolve<ExternalUserProfileWithContextTokenInterface>, reject: ApiReject<void>): Promise<void> => (
    api.post('/authorization/reset-password', data).then(
      (response: AxiosResponse<ExternalUserProfileWithContextTokenInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<void>): void => reject(error))
  )
);

export const sendConfirmMail: (token: string) => Promise<void> = (token: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<void>): Promise<void> => (
    api.post(`/authorization/send-confirm-mail/${token}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<void>): void => reject(error))
  )
);
