import { AccordionGroup } from '@on-arte/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { BaseStaticView } from '@onArte/frontend/components';

import { faqContent } from './faq.config';

export const FaqView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();

  return (
    <BaseStaticView title={t('onarte.website.faqView.title')}>
      <AccordionGroup accordionItems={faqContent} />
    </BaseStaticView>
  );
};
