import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { BaseStaticView } from '@onArte/frontend/components';
import { privacyPolicy } from '@onArte/shared/constants';
import { Paragraph } from '@onArte/shared/interfaces';

import { Content, Header, MarkdownContainer } from './privacyPolicy.styled';

export const PrivacyPolicyView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();

  return (
    <BaseStaticView title={t('onarte.website.privacyPolicyView.title')}>
      {privacyPolicy.map((markdown: Paragraph): JSX.Element => (
        <MarkdownContainer key={markdown.header}>
          <Header text={markdown.header} />
          {!!markdown.content && markdown.content.map((content: string, index: number): JSX.Element => (
            <Content text={content} key={index} />
          ))}
        </MarkdownContainer>
      ))}
    </BaseStaticView>
  );
};
