import { ArtworkBoxManage, Pagination, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const StyledArtworkBoxManage: StyledComponent<typeof ArtworkBoxManage> = styled(ArtworkBoxManage)`
  & + & {
    margin-top: 20px;
  }
`;

export const StyledPagination: StyledComponent<typeof Pagination> = styled(Pagination)`
  margin-top: 20px;
`;
