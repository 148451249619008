import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';

import { appConfig } from '@onArte/frontend/app.config';
import { LocalStorageKey } from '@onArte/frontend/enums';
import { AuthContextState } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { CustomResponseHeader, RouteNameEnum } from '@onArte/shared/enums';
import { ApiError } from '@onArte/shared/models';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const api: AxiosInstance = axios.create({
  baseURL: appConfig.api,
  responseType: 'json',
  headers: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    ...((typeof window !== 'undefined' && JSON.parse(window.localStorage.getItem(LocalStorageKey.AuthData) ?? '{}')?.token)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      ? { token: JSON.parse(window.localStorage.getItem(LocalStorageKey.AuthData) ?? '{}').token }
      : {}
    ),
  }
});

export const setAuthToken: (token: string | null) => void = (token: string | null): void => {
  const id: number = api.interceptors.request.use((request: AxiosRequestConfig): AxiosRequestConfig => {
    const headers: AxiosRequestHeaders = request.headers ?? {};
    if (!token && headers.token) {
      delete headers.token;
    }
    if (token) {
      headers.token = token;
    }

    return request;
  });
  if (id > 0) {
    api.interceptors.request.eject(id - 1);
  }
};

api.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    if (response.headers[CustomResponseHeader.XAuthExpires]) {
      const authData: AuthContextState = JSON.parse(window.localStorage.getItem(LocalStorageKey.AuthData) ?? '{}') as AuthContextState;
      authData.tokenExpiration = parseInt(response.headers[CustomResponseHeader.XAuthExpires], 10);
      window.localStorage.setItem(LocalStorageKey.AuthData, JSON.stringify(authData));
    }

    return response;
  },
  (error: AxiosError<ApiError>): void | ApiError => {
    if (error.response) {
      const apiErrorObject: FrontendApiError = new FrontendApiError(
        error.response.data.message,
        error.response.status,
        error.response.data.messageParams,
        error.response.data.details,
      );

      if (error.response.status === 401) {
        if (
          window.location.pathname !== getRouteDetailsByName(RouteNameEnum.SignIn)?.url
          && window.location.pathname !== getRouteDetailsByName(RouteNameEnum.Logout)?.url
        ) {
          window.location.assign(getRouteDetailsByName(RouteNameEnum.Logout)?.url ?? '/');
        }
        throw apiErrorObject;
      } else if (error.response.status === 400 || error.response.status > 401) {
        throw apiErrorObject;
      }
    } else if (error.request && !error.code) {
      throw new FrontendApiError(
        'onarte.website.errors.custom.noInternetConnection',
        503,
        {},
      );
    } else {
      throw new FrontendApiError(
        'onarte.website.errors.custom.unknownApiError',
        503,
        {},
      );
    }
  },
);
