import { AuctionTypeEnum } from '@on-arte/core-types';
import { ArtistSummary, BasketSummary, BoxTitleDetails, FullscreenPopup, IconName } from '@on-arte/ui';
import React, { useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { PopupChildrenContainer, PopupHeader, StyledInput } from './fullScreenPopup.styled';
import { FullScreenPopupProps } from './fullScreenPopup.types';

export const FullScreenPopup: React.FC<FullScreenPopupProps> = (props: FullScreenPopupProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { 
    isPopupVisible, 
    setIsPopupVisible, 
    artworkDetailsData, 
    popupAction, 
    artworkPhoto,
    onPopupInputChange,
    inputValue,
    popupValidationMessage,
    commissionPercent,
    offerPrice
  }: FullScreenPopupProps = props;

  const boxTitleDetails: BoxTitleDetails = useMemo(
    (): BoxTitleDetails => {      
      return {
        author: artworkDetailsData.itemDetails.manufacturer.name,
        itemName: artworkDetailsData.itemDetails.name,
        itemDescriptiveAttributes: [artworkDetailsData.itemDetails.label],
        ...(artworkDetailsData.type === AuctionTypeEnum.Bidding 
          ? {
            minimalPriceNotReached: !artworkDetailsData.reachedAcceptablePrice,
            price: artworkDetailsData.price,
            biddersNumber: artworkDetailsData.offers
          } 
          : {}
        )
      };
    },
    [artworkDetailsData]
  );

  return artworkDetailsData ? (
    <FullscreenPopup
      isVisible={isPopupVisible}
      firstButtonLabel={t(`onarte.website.fullScreenPopup.firstButtonLabel.${artworkDetailsData.type}`)}
      firstButtonAction={popupAction}
      secondButtonAction={(): void => setIsPopupVisible(false)}
    >
      <PopupChildrenContainer>
        <PopupHeader>{t(`onarte.website.fullScreenPopup.popupHeader.${artworkDetailsData.type}`)}</PopupHeader>
        <ArtistSummary boxTitleDetails={boxTitleDetails} image={artworkPhoto} />
        <StyledInput
          label={t('onarte.website.fullScreenPopup.input.yourOffer')}
          value={inputValue}
          icon={IconName.Zl}
          onChange={(value: string): void => onPopupInputChange(value)}
          description={t(`onarte.website.fullScreenPopup.input.description.${artworkDetailsData.type}`)}
          validationMessage={popupValidationMessage}
          withOnlyNumbersAllowed
          withPriceParsing
        />
        {artworkDetailsData.type === AuctionTypeEnum.Bidding && (
          <BasketSummary
            items={[
              { label: t('onarte.website.fullScreenPopup.maxOffer'), price: offerPrice },
              {
                label: t('onarte.website.fullScreenPopup.commission'),
                price: (offerPrice * commissionPercent)
              },
              {
                label: t('onarte.website.fullScreenPopup.totalPrice'),
                price: offerPrice + (offerPrice * commissionPercent)
              },
            ]}
            description={t('onarte.website.fullScreenPopup.popupBasketSummaryDescription')}
          />
        )}
      </PopupChildrenContainer>
    </FullscreenPopup>
  ) : <></>;
};
