import { Breakpoint, Color, Grid, NewsletterSection, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { StyledNewsletterSectionProps } from './newsletterBoxSection.types';

export const StyledNewsletterSection: StyledComponent<typeof NewsletterSection, StyledNewsletterSectionProps> 
  = styled(NewsletterSection)<StyledNewsletterSectionProps>`
    max-width: calc(${Grid.FullHd} + 80px);
    padding: 0 40px 120px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media ${Breakpoint.Tablet} {
      padding: 0 32px 120px;
    }
  
    @media ${Breakpoint.Mobile} {
      padding: 0 20px 80px;
    }

    ${({ $withTopBorder }: StyledNewsletterSectionProps): string | false => $withTopBorder && `
      &:before {
        display: block;
        border-top: 1px solid ${Color.Gray300};
        content: '';
        width: 100%;
        order: -1;
        margin-bottom: 120px;

        @media ${Breakpoint.Mobile} {
          margin-bottom: 80px;
        }
      }
    `}
`;
