import { 
  ArtworkContainer, 
  BasketSummary, 
  Breakpoint, 
  ChooseBox, 
  Color, 
  FontWeight,
  StyledComponent,
  ValidationBar 
} from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  max-width: 480px;
  margin: 80px 40px;

  @media ${Breakpoint.Tablet} {
    margin: 80px 32px;
  }

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
    margin: 0 20px;
    padding-bottom: 24px;
  }
`;

export const CartTitle: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title: StyledComponent<'div'> = styled.div`
  font-size: 18px;
  font-weight: ${FontWeight.Medium};
  line-height: 26px;
  color: ${Color.Black};
`;

export const UnderlineButton: StyledComponent<'button'> = styled.button`
  font-size: 14px;
  font-weight: ${FontWeight.Medium};
  line-height: 18px;
  text-decoration-line: underline;
  color: ${Color.Gray500};
`;

export const ItemsContainer: StyledComponent<'div'> = styled.div`
  margin: 24px auto;
  border-top: 1px solid ${Color.Gray300};
  border-bottom: 1px solid ${Color.Gray300};

  @media ${Breakpoint.Mobile} {
    margin: 0 0 24px;
  }
`;

export const StyledArtworkContainer: StyledComponent<typeof ArtworkContainer> = styled(ArtworkContainer)`
  padding: 24px 0;

  & + & {
    border-top: 1px solid ${Color.Gray300};
  }
`;

export const DiscountContainer: StyledComponent<'div'> = styled.div`
  display: flex;
`;

export const DiscountCodesContainer: StyledComponent<'div'> = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

export const StyledChooseBox: StyledComponent<typeof ChooseBox> = styled(ChooseBox)`
  & + & {
    margin-top: 12px;
  }
`;

export const ApplyButtonContainer: StyledComponent<'div'> = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ApplyButton: StyledComponent<'button'> = styled.button`
  font-size: 14px;
  font-weight: ${FontWeight.Medium};
  line-height: 18px;
  text-decoration-line: underline;
  color: ${Color.Gray500};
  margin-left: 16px;
`;

export const SummaryContainer: StyledComponent<'div'> = styled.div`
  margin-top: 24px;
`;

export const StyledBasketSummary: StyledComponent<typeof BasketSummary> = styled(BasketSummary)`
  margin-bottom: 32px;
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-top: 24px;
`;
