import { AttributeNameEnum } from '@on-arte/core-types';
import { AddPhotoTheme, BooleanValue, FormFieldType, FormSectionData } from '@on-arte/ui';

export const artworkEditForm: FormSectionData[] = [
  {
    label: 'onarte.common.artworkBasicInfoForm.infoSection.label',
    children: [
      {
        type: FormFieldType.Autosuggest,
        label: 'onarte.common.itemsAttributes.author.label',
        description: {
          position: 'top',
          content: 'onarte.common.itemsAttributes.author.description',
        },
        name: 'manufacturer',
        alwaysAvailable: true,
        additionalFieldProps: {
          withAcceptNotSuggestedValue: true,
        },
        validationRules: [
          { type: 'required', value: true },
        ],
      },
      {
        type: FormFieldType.Input,
        label: 'onarte.common.itemsAttributes.name.label',
        name: 'name',
        alwaysAvailable: true,
        validationRules: [
          { type: 'required', value: true },
          { type: 'min', value: 3 },
          { type: 'max', value: 64 }
        ],
      },
    ]
  },
  {
    label: 'onarte.common.artworkBasicInfoForm.photosSection.label',
    children: [
      {
        type: FormFieldType.AddPhoto,
        name: 'artworkPhotos',
        alwaysAvailable: true,
        description: {
          position: 'top',
          content: 'onarte.common.itemsAttributes.artworkPhotos.description',
        },
        additionalFieldProps: {
          addPhotoTheme: AddPhotoTheme.WithBoxLoaderOnInit,
          requirements: [
            'onarte.common.itemsAttributes.artworkPhotos.requirements.1',
            'onarte.common.itemsAttributes.artworkPhotos.requirements.2',
            'onarte.common.itemsAttributes.artworkPhotos.requirements.3',
            'onarte.common.itemsAttributes.artworkPhotos.requirements.4',
          ],
          attachmentType: 'uploaded_itemPhoto',
          acceptedFilesTypes: 'image/png, image/jpeg',
          maxFileSizeInBytes: 5 * 1024 * 1024,
          maxNumberOfFiles: 8,
        },
        validationRules: [
          { type: 'required', value: true },
        ],
      },
    ]
  },
  {
    label: 'onarte.common.artworkDetailsForm.detailsSection.label',
    children: [
      {
        type: FormFieldType.Input,
        name: 'year',
        label: 'onarte.common.itemsAttributes.year.label',
        alwaysAvailable: true,
        validationRules: [
          { type: 'required', value: true },
          { type: 'min', value: 1 },
          { type: 'max', value: 32 }
        ],
      },
      {
        type: FormFieldType.FullscreenDropdown,
        label: 'onarte.common.itemsAttributes.category.label',
        name: 'category',
        additionalFieldProps: {
          options: [],
          multiple: false,
        },
        alwaysAvailable: true,
        disabled: true,
        validationRules: [
          { type: 'required', value: true },
        ],
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.SerialNumber,
        description: {
          position: 'bottom',
          content: 'onarte.common.itemsAttributes.serialNumber.description',
        },
        validationRules: [
          { type: 'max', value: 64 }
        ]
      },
      {
        type: FormFieldType.Dropdown,
        name: AttributeNameEnum.SignedByArtist,
        additionalFieldProps: {
          options: [
            { name: BooleanValue.Yes, label: 'onarte.common.yes' },
            { name: BooleanValue.No, label: 'onarte.common.no' },
          ],
        },
        validationRules: [
          { type: 'required', value: true },
        ],
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.SignatureDescription,
        validationRules: [
          { type: 'required', value: true },
          { type: 'max', value: 64 }
        ],
      },
      {
        type: FormFieldType.AddPhoto,
        name: 'signaturePhoto',
        conditionFromField: AttributeNameEnum.SignatureDescription,
        description: {
          position: 'top',
          content: 'onarte.common.itemsAttributes.signatureDescription.description',
        },
        additionalFieldProps: {
          addPhotoTheme: AddPhotoTheme.WithBarLoaderOnInit,
          attachmentType: 'uploaded_itemSignature',
          acceptedFilesTypes: 'image/png, image/jpeg',
          maxFileSizeInBytes: 5 * 1024 * 1024,
          maxNumberOfFiles: 1,
        },
        validationRules: [
          { type: 'required', value: true },
        ],
      },
    ]
  },
  {
    label: 'onarte.common.artworkDetailsForm.dimensionsSection.label',
    children: [
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.Height,
        validationRules: [
          { type: 'required', value: true },
          { type: 'regexp', value: /^[\d]+$/ },
          { type: 'max', value: 16 }
        ],
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.Width,
        validationRules: [
          { type: 'required', value: true },
          { type: 'regexp', value: /^[\d]+$/ },
          { type: 'max', value: 16 }
        ],
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.Depth,
        validationRules: [
          { type: 'regexp', value: /^[\d]*$/ },
          { type: 'max', value: 16 }
        ],
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.Weight,
        validationRules: [
          { type: 'required', value: true },
          { type: 'regexp', value: /^[\d]+$/ },
          { type: 'max', value: 16 }
        ],
      },
      {
        type: FormFieldType.Dropdown,
        name: AttributeNameEnum.IsFramed,
        additionalFieldProps: {
          options: [
            { name: BooleanValue.Yes, label: 'onarte.common.yes' },
            { name: BooleanValue.No, label: 'onarte.common.no' },
          ],
        },
        validationRules: [
          { type: 'required', value: true },
        ],
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.HeightWithFrame,
        validationRules: [
          { type: 'required', value: true },
          { type: 'regexp', value: /^[\d]+$/ },
          { type: 'max', value: 16 }
        ],
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.WidthWithFrame,
        validationRules: [
          { type: 'required', value: true },
          { type: 'regexp', value: /^[\d]+$/ },
          { type: 'max', value: 16 }
        ],
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.DepthWithFrame,
        validationRules: [
          { type: 'regexp', value: /^[\d]*$/ },
          { type: 'max', value: 16 }
        ],
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.FrameMaterial,
        validationRules: [
          { type: 'required', value: true },
          { type: 'max', value: 64 }
        ],
        description: {
          position: 'bottom',
          content: 'onarte.common.itemsAttributes.frameMaterial.description',
        }
      },
    ]
  },
  {
    label: 'onarte.common.artworkDetailsForm.parametersSection.label',
    children: [
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.Medium,
        validationRules: [
          { type: 'required', value: true },
          { type: 'max', value: 64 }
        ],
      },
      {
        type: FormFieldType.FullscreenDropdown,
        name: AttributeNameEnum.Technique,
        validationRules: [
          { type: 'required', value: true },
        ],
        additionalFieldProps: {
          options: [
            { name: BooleanValue.Yes, label: 'onarte.common.yes' },
            { name: BooleanValue.No, label: 'onarte.common.no' },
          ],
          multiple: true,
        }
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.TechniqueOther,
        validationRules: [
          { type: 'required', value: true },
          { type: 'max', value: 64 }
        ],
      },
      {
        type: FormFieldType.FullscreenDropdown,
        name: AttributeNameEnum.Material,
        validationRules: [
          { type: 'required', value: true },
        ],
        additionalFieldProps: {
          options: [
            { name: BooleanValue.Yes, label: 'onarte.common.yes' },
            { name: BooleanValue.No, label: 'onarte.common.no' },
          ],
          multiple: true,
        }
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.MaterialOther,
        validationRules: [
          { type: 'required', value: true },
          { type: 'max', value: 64 }
        ],
      },
      {
        type: FormFieldType.FullscreenDropdown,
        name: AttributeNameEnum.Style,
        validationRules: [
          { type: 'required', value: true },
        ],
        additionalFieldProps: {
          options: [],
          multiple: true,
        }
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.StyleOther,
        validationRules: [
          { type: 'required', value: true },
          { type: 'max', value: 64 }
        ],
      },
      {
        type: FormFieldType.FullscreenDropdown,
        name: AttributeNameEnum.Subject,
        validationRules: [
          { type: 'required', value: true },
        ],
        additionalFieldProps: {
          options: [],
          multiple: true,
        },
      },
      {
        type: FormFieldType.Input,
        name: AttributeNameEnum.SubjectOther,
        validationRules: [
          { type: 'required', value: true },
          { type: 'max', value: 64 }
        ],
      },
      {
        type: FormFieldType.FullscreenDropdown,
        name: AttributeNameEnum.Color,
        validationRules: [
          { type: 'required', value: true },
        ],
        additionalFieldProps: {
          options: [],
          multiple: true,
        },
      },
      {
        type: FormFieldType.Textarea,
        label: 'onarte.common.itemsAttributes.description.label',
        name: 'description',
        conditionFromField: AttributeNameEnum.AuthenticityCertificate,
        validationRules: [
          { type: 'required', value: true },
          { type: 'max', value: 2000 }
        ],
        additionalFieldProps: {
          maxCharsCount: 2000
        }
      },
    ]
  },
  {
    label: 'onarte.common.artworkDetailsForm.certificateSection.label',
    children: [
      {
        type: FormFieldType.Dropdown,
        name: AttributeNameEnum.AuthenticityCertificate,
        additionalFieldProps: {
          options: [
            { name: BooleanValue.Yes, label: 'onarte.common.yes' },
            { name: BooleanValue.No, label: 'onarte.common.no' },
          ],
        },
        validationRules: [
          { type: 'required', value: true },
        ],
      },
      {
        type: FormFieldType.AddPhoto,
        name: 'authenticityCertificatePhoto',
        description: {
          position: 'top',
          content: 'onarte.common.itemsAttributes.authenticityCertificatePhoto.description',
        },
        additionalFieldProps: {
          addPhotoTheme: AddPhotoTheme.WithBarLoaderOnInit,
          attachmentType: 'uploaded_itemCertificate',
          acceptedFilesTypes: 'image/png, image/jpeg',
          maxFileSizeInBytes: 1 * 1024 * 1024,
          maxNumberOfFiles: 2,
        },
        fieldRenderCondition: {
          type: 'visibleIfAnswerIsTruly',
          relatedFieldName: AttributeNameEnum.AuthenticityCertificate,
        },
        validationRules: [
          { type: 'required', value: true },
        ],
      },
    ]
  },
];
