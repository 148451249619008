import { Breakpoint, Color, Image, SearchInput, StyledComponent, hexToRgba } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: calc(100vh - 100px);
  background-color: ${Color.Gray200};
  padding: 80px 40px 100px;
  position: relative;

  @media ${Breakpoint.Tablet} {
    padding: 80px 32px 100px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 80px 20px;
  }
`;

export const SearchInputContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSearchInput: StyledComponent<typeof SearchInput> = styled(SearchInput)`
  max-width: 1200px;
  width: 100%;
`;

export const CloseButton: StyledComponent<'button'> = styled.button`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: ${Color.White};
  box-shadow: 0px 0px 4px ${hexToRgba(Color.Black, 0.2)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 40px;

  @media ${Breakpoint.Mobile} {
    position: absolute;
    top: 24px;
    right: 20px;
  }
`;

export const ItemsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @media ${Breakpoint.SmallMobile} {
    margin-top: 40px;
  }
`;

export const SearchedItem: StyledComponent<'button'> = styled.button`
  display: flex;
  align-items: center;
  text-align: left;

  & + & {
    margin-top: 10px;
  }

  @media ${Breakpoint.SmallMobile} {
    flex-direction: column;
    align-items: start;

    & + & {
      margin-top: 25px;
    }
  }
`;

export const ImageBox: StyledComponent<'div'> = styled.div`
  width: 330px;
  height: 200px;
  margin-right: 40px;
  flex-shrink: 0;

  @media ${Breakpoint.Mobile} {
    margin-right: 20px;
    width: 280px;
    height: 170px;
  }

  @media ${Breakpoint.SmallMobile} {
    width: 100%;
    height: 190px;
    margin-right: 0;
    margin-bottom: 16px;
  }
`;

export const ImageElement: StyledComponent<typeof Image> = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
