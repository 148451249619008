import { InitResetPasswordModel } from '@on-arte/core-types';
import {
  Button,
  ButtonTheme,
  HeadingType,
  useFormikForm,
  UseFormikForm,
  UseState,
  UseNotifications,
  useNotifications,
  UseRedirect,
  useRedirect
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { initResetPassword } from '@onArte/frontend/api/requests';
import { LoginFormWrapper } from '@onArte/frontend/components/loginFormWrapper/loginFormWrapper.component';
import { FrontendApiError } from '@onArte/frontend/models';
import { FormikForm } from '@onArte/frontend/theme';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { useRemindPasswordValidation } from './remindPassword.hooks';
import {
  Container,
  DescriptionBelowHeader,
  StyledInput,
  StyledHeading,
  StyledSendAgainButton,
  StyledSubmitButton
} from './remindPassword.styled';

export const RemindPasswordView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [stage, setStage]: UseState<'form' | 'message'> = useState<'form' | 'message'>('form');
  const RemindPasswordFormValidationSchema: Yup.SchemaOf<InitResetPasswordModel> = useRemindPasswordValidation();
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const { addToast }: UseNotifications = useNotifications();
  const [cachedData, setCachedData]: UseState<InitResetPasswordModel> = useState<InitResetPasswordModel>({ email: '' });
  const { redirect }: UseRedirect = useRedirect();
  const goBack: () => void = (): void => redirect(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/');
  const goBackToForm: () => void = (): void => setStage('form');

  const remindPasswordAction: (data: InitResetPasswordModel) => void = (data: InitResetPasswordModel): void => {
    setCachedData(data);
    initResetPassword(data)
      .then((): void => setStage('message'))
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  const sendEmailAgain: () => void = (): void => {
    initResetPassword(cachedData)
      .then((): void => addToast({ content: t('onarte.website.remindPasswordView.sendEmailAgainSuccess') }))
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
  };

  return (
    <Container>
      <LoginFormWrapper>
        {stage === 'form' && (
          <>
            <StyledHeading type={HeadingType.H4} text={t('onarte.website.remindPasswordView.header')} />
            <DescriptionBelowHeader>{t('onarte.website.remindPasswordView.subheader')}</DescriptionBelowHeader>
            <Formik
              initialValues={{ email: '' }}
              onSubmit={remindPasswordAction}
              validationSchema={RemindPasswordFormValidationSchema}
              validateOnChange={isFormSubmitted}
              validateOnBlur={isFormSubmitted}
            >
              {({ handleSubmit, setFieldValue, errors }: FormikProps<InitResetPasswordModel>) => (
                <FormikForm onSubmit={handleSubmit}>
                  <StyledInput
                    label={t('onarte.common.email')}
                    onChange={(value: string): void => setFieldValue('email', value)}
                    onBlur={(value: string): void => setFieldValue('email', value)}
                    validationMessage={errors.email || ''}
                  />
                  <StyledSubmitButton
                    label={t('onarte.website.remindPasswordView.resetYourPassword')}
                    type='submit'
                    onClick={setFormSubmitted}
                    fullWidth
                  />
                </FormikForm>
              )}
            </Formik>
            <Button
              label={t('onarte.common.goBack')}
              onClick={goBack}
              buttonTheme={ButtonTheme.GrayTransparent}
              fullWidth
            />
          </>
        )}
        {stage === 'message' && (
          <>
            <StyledHeading type={HeadingType.H4} text={t('onarte.website.remindPasswordView.successHeader')} />
            <DescriptionBelowHeader>
              {t('onarte.website.remindPasswordView.successMessage')}
            </DescriptionBelowHeader>
            <StyledSendAgainButton label={t('onarte.common.messageResend')} onClick={sendEmailAgain} fullWidth />
            <Button
              label={t('onarte.common.goBack')}
              onClick={goBackToForm}
              buttonTheme={ButtonTheme.GrayTransparent}
              fullWidth
            />
          </>
        )}
      </LoginFormWrapper>
    </Container>
  );
};
