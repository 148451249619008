import {
  ArtworkDescription,
  ArtworkIcon,
  ArtworkSummary,
  BoxesSlider,
  Breakpoint,
  Color,
  Grid,
  SectionTitle,
  StyledComponent,
  ZIndex
} from '@on-arte/ui';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export const artworkDescriptionWrapperStyle: FlattenSimpleInterpolation = css`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 120px;

  @media ${Breakpoint.Mobile} {
    margin-top: 80px;
  }
`;

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Color.Gray200};
`;

export const ArtworkDetailsWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
`;

export const ArtworkDetailsContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  padding-bottom: 120px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    padding: 0 20px;
  }
`;

export const PhotosSliderContainer: StyledComponent<'div'> = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const AllDetailsContainer: StyledComponent<'div'> = styled.div`
  width: 50%;    
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  margin-left: 40px;

  @media ${Breakpoint.BigDesktop} {
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0;
    width: 100%;
    max-width: 100%;
    margin-top: 40px;
  }
`;

export const QuoteCarouselContainer: StyledComponent<'div'> = styled.div`
  padding: 120px 40px;
  width: 100%;
  display: flex;
  justify-content: center; 
  z-index: ${ZIndex.Min};
`;

export const AboutContainer: StyledComponent<'div'> = styled.div`
  padding: 120px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${Color.White};

  @media ${Breakpoint.SmallDesktop} {
    padding: 120px 32px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 80px 20px; 
  }
`;

export const StyledArtworkDescription: StyledComponent<typeof ArtworkDescription> = styled(ArtworkDescription)`
  max-width: calc(${Grid.FullHd} + 80px);
  width: 100%;
`;

export const StyledArtworkSummary: StyledComponent<typeof ArtworkSummary> = styled(ArtworkSummary)`
  width: 100%;
  max-width: 480px;   

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
  }
`;

export const StyledArtworkIcon: StyledComponent<typeof ArtworkIcon> = styled(ArtworkIcon)``;

export const AboutArtworkDescriptionWrapper: StyledComponent<'div'> = styled.div`
  ${artworkDescriptionWrapperStyle};
`;

export const DeliveryArtworkDescriptionWrapper: StyledComponent<'div'> = styled.div`
  ${artworkDescriptionWrapperStyle};
`;

export const OfferConditionsArtworkDescriptionWrapper: StyledComponent<'div'> = styled.div`
  ${artworkDescriptionWrapperStyle}
`;

export const ArtworkIconsContainer: StyledComponent<'div'> = styled.div`
  max-width: 480px;
  width: 100%;
  margin: 40px 0;
  display: flex;
  flex-direction: column;

  ${StyledArtworkIcon} + ${StyledArtworkIcon} {
    margin-top: 10px;
  }

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
  }
`;

export const StyledBoxesSliderSection: StyledComponent<'div'> = styled.div`
  width: 100%;
  margin-top: 120px;
  position: relative;
`;

export const StyledSectionTitle: StyledComponent<typeof SectionTitle> = styled(SectionTitle)`
  width: 100%;
  margin: 0 auto 32px;
`;

export const StyledBoxesSlider: StyledComponent<typeof BoxesSlider> = styled(BoxesSlider)`
  margin-left: -40px;
  width: calc(100% + 40px);

  @media ${Breakpoint.Tablet} {
    margin-left: -32px;
    width: calc(100% + 32px);
  }

  @media ${Breakpoint.Mobile} {
    margin-left: -20px;
    width: calc(100% + 20px);
  }
`;
