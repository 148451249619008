import { Button, ChooseBox, Color, FontWeight, ShippingBox, StyledComponent, ValidationBar } from '@on-arte/ui';
import styled from 'styled-components';

import { GatewayItemProps } from './cartBilling.types';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
`;

export const DeliveryAddress: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${Color.Gray500};
  padding: 24px 0;
  border-bottom: 1px solid ${Color.Gray300};
  display: flex;
  justify-content: space-between;
`;

export const Subtitle: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 18px;
  line-height: 26px;
  color: ${Color.Black};
  margin: 40px 0 24px;
`;

export const StyledChooseBox: StyledComponent<typeof ChooseBox> = styled(ChooseBox)`
  & + & {
    margin-top: 12px;
  }
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-top: 12px;
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const UnderlineButton: StyledComponent<'button'> = styled.button`
  font-weight: ${FontWeight.Medium};
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: ${Color.Gray500};
`;

export const StyledShippingBox: StyledComponent<typeof ShippingBox> = styled(ShippingBox)`
  margin-top: 12px;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  & + ${UnderlineButton} {
    margin-top: 24px;
  }
`;

export const GatewaysContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
`;

export const GatewayItem: StyledComponent<'div', GatewayItemProps> = styled.div<GatewayItemProps>`
  display: flex;
  flex-direction: column; 
  align-items: center;
  width: 100%;
  border: 1px solid ${({ $isActive }: GatewayItemProps): string => $isActive ? `${Color.Error500}` : `${Color.Gray300}`};
  padding: 2px;
  transition: .3s;
  cursor: pointer;
`;

export const GatewayIcon: StyledComponent<'img'> = styled.img`
  width: 100%;
  height: auto;
`;
