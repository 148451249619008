import { ReactionTypeEnum, ReactionUsageContextTypeEnum } from '@on-arte/core-types';
import { useLogger, UseLogger, useNotifications, UseNotifications, useRedirect, UseRedirect, UseState } from '@on-arte/ui';
import { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';

import { addToFavourites, getFavouriteItems, getFavouriteManufacturers, removeFromFavourites } from '@onArte/frontend/api/requests';
import { FavouriteActionType, QueryKey } from '@onArte/frontend/enums';
import { UseAuth, UseReactions, UpdateFavouriteInterface } from '@onArte/frontend/interfaces';
import { RouteNameEnum } from '@onArte/shared/enums';
import { ExtendedManufacturerItem, ItemWithAuctionDetails } from '@onArte/shared/interfaces';
import { ApiError } from '@onArte/shared/models';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { useAuth } from './useAuth.hook';

export const useReactions: () => UseReactions = (): UseReactions => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { addToast }: UseNotifications = useNotifications();
  const { logger }: UseLogger = useLogger();
  const { isUserLoggedIn }: UseAuth = useAuth();
  const [userFavouriteItemsIds, setUserFavouriteItemsIds]: UseState<string[]> = useState<string[]>([]);
  const [userFavouriteManufacturersIds, setUserFavouriteManufacturersIds]: UseState<string[]> = useState<string[]>([]);
  const [favoritesReadyState, setFavoritesReadyState]: UseState<boolean> = useState<boolean>(false);

  const favoriteItemsQuery: QueryObserverResult = useQuery(
    [QueryKey.FavouriteItemsList],
    (): Promise<ItemWithAuctionDetails[]> => getFavouriteItems(ReactionTypeEnum.Like),
    {
      onSuccess: (data: ItemWithAuctionDetails[]): void => setUserFavouriteItemsIds(
        data.map((item: ItemWithAuctionDetails): string => item.itemId)
      ),
      onError: (error: ApiError): void => logger(QueryKey.FavouriteItemsList, error),
      enabled: isUserLoggedIn()
    }
  );

  const favoriteManufacturersQuery: QueryObserverResult =  useQuery(
    [QueryKey.FavouriteManufacturersList],
    (): Promise<ExtendedManufacturerItem[]> => getFavouriteManufacturers(ReactionTypeEnum.Like),
    {
      onSuccess: (data: ExtendedManufacturerItem[]): void => setUserFavouriteManufacturersIds(
        data.map((manufacturer: ExtendedManufacturerItem): string => manufacturer.id)
      ),
      onError: (error: ApiError): void => logger(QueryKey.FavouriteManufacturersList, error),
      enabled: isUserLoggedIn()
    }
  );

  const updateFavourites: (
    contextId: string, contextType: ReactionUsageContextTypeEnum, actionType: FavouriteActionType
  ) => void = (
    contextId: string, contextType: ReactionUsageContextTypeEnum, actionType: FavouriteActionType
  ): void => {
    if (contextType === ReactionUsageContextTypeEnum.Item) {
      setUserFavouriteItemsIds(
        actionType === FavouriteActionType.Add
          ? [...userFavouriteItemsIds, contextId]
          : userFavouriteItemsIds.filter((itemId: string): boolean => itemId !== contextId)
      );
    } else if (contextType === ReactionUsageContextTypeEnum.Manufacturer) {
      setUserFavouriteManufacturersIds(
        actionType === FavouriteActionType.Add
          ? [...userFavouriteManufacturersIds, contextId]
          : userFavouriteManufacturersIds.filter((manufacturerId: string): boolean => manufacturerId !== contextId)
      );
    }
  };

  const isFavourite: (id: string, type: ReactionUsageContextTypeEnum) => boolean = (
    id: string, type: ReactionUsageContextTypeEnum
  ): boolean => {
    switch (type) {
      case ReactionUsageContextTypeEnum.Item:
        return userFavouriteItemsIds.includes(id);
      case ReactionUsageContextTypeEnum.Manufacturer:
        return userFavouriteManufacturersIds.includes(id);
      default:
        return false;
    }
  };

  const handleFavourite: (params: UpdateFavouriteInterface) => void = (params: UpdateFavouriteInterface): void => {
    if (isUserLoggedIn()) {
      const { contextId, contextType, reactionType, actionType }: UpdateFavouriteInterface = params;
      if (actionType === FavouriteActionType.Add) {
        addToFavourites({ contextType, contextId, reactionType })
          .then((): void => updateFavourites(contextId, contextType, actionType))
          .catch((error: ApiError): void => addToast({ content: t(error.message) }));
      } else if (actionType === FavouriteActionType.Remove) {
        removeFromFavourites({ contextType, contextId, reactionType })
          .then((): void => updateFavourites(contextId, contextType, actionType))
          .catch((error: ApiError): void => addToast({ content: t(error.message) }));
      }
    } else {
      redirect(getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/', { withReplaceState: true });
    }
  };

  const changeLikeState: (contextId: string, shouldLike: boolean, type: ReactionUsageContextTypeEnum) => void = (
    contextId: string, shouldLike: boolean, type: ReactionUsageContextTypeEnum
  ): void => {
    handleFavourite({
      contextId,
      contextType: type,
      reactionType: ReactionTypeEnum.Like,
      actionType: shouldLike
        ? FavouriteActionType.Remove 
        : FavouriteActionType.Add
    });
  };

  useEffect(
    (): void => {
      if (!isUserLoggedIn() || !!(!favoriteItemsQuery.isLoading && !favoriteManufacturersQuery.isLoading)) {
        setFavoritesReadyState(true);
      }
    },
    [isUserLoggedIn(), favoriteItemsQuery.isLoading, favoriteManufacturersQuery.isLoading]
  );

  return { userFavouriteItemsIds, userFavouriteManufacturersIds, isFavourite, handleFavourite, changeLikeState, favoritesReadyState };
};
