import { 
  AttachmentTypeEnum,
  AuctionInfoInterface,
  AuctionTypeEnum,
  ExternalAttachmentInterface,
  ListPaginationInterface, 
  PublicProfileTypeEnum, 
  SearchableTypeEnum, 
  ThumbnailAttachmentTypeEnum, 
  UploadedAttachmentInterface
} from '@on-arte/core-types';
import { 
  BoxTitle, 
  Color, 
  Icon, 
  IconName, 
  InfinitePaginationDetails, 
  LoadingSpinner, 
  LocationState, 
  UseLogger, 
  UseRedirect, 
  UseState, 
  getPathWithParams, 
  useInfinitePagination, 
  useLogger, 
  useRedirect 
} from '@on-arte/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQuery } from 'react-query';
import { useLocation, Location, useSearchParams } from 'react-router-dom';

import { search } from '@onArte/frontend/api/requests';
import { appConfig } from '@onArte/frontend/app.config';
import { QueryKey } from '@onArte/frontend/enums';
import { PublicProfileModelWithRoute } from '@onArte/frontend/interfaces/api';
import { FrontendApiError } from '@onArte/frontend/models';
import { UrlSearchParams } from '@onArte/frontend/types';
import { getImageThumbnail } from '@onArte/frontend/utils';
import { RouteNameEnum } from '@onArte/shared/enums';
import { SearchItemDTO } from '@onArte/shared/interfaces';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { 
  CloseButton, 
  Container, 
  ImageElement, 
  ImageBox, 
  ItemsContainer, 
  SearchInputContainer, 
  SearchedItem, 
  StyledSearchInput 
} from './search.styled';

export const SearchView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const { redirect }: UseRedirect = useRedirect();
  const { 
    hasMoreItems, 
    fetchMoreData, 
    paginationOffset, 
    setMaxItems,
    initialItemsCount,
    clearPagination
  }: InfinitePaginationDetails = useInfinitePagination(10);
  const [inputValue, setInputValue]: UseState<string> = useState<string>('');
  const [searchedItems, setSearchedItems]: UseState<SearchItemDTO[]> = useState<SearchItemDTO[]>([]);
  const [searchedPublicProfiles, setSearchedPublicProfiles]: UseState<PublicProfileModelWithRoute[]>
  = useState<PublicProfileModelWithRoute[]>([]);
  const [searchedAuctions, setSearchedAuctions]: UseState<AuctionInfoInterface[]> = useState<AuctionInfoInterface[]>([]);
  const [shouldClearSearchedItems, setShouldClearSearchedItems]: UseState<boolean> = useState<boolean>(false);
  const [searchInputLabel, setSearchInputLabel]: UseState<string> = useState<string>('');
  const [prevLocation, setPrevLocation]: UseState<string> = useState<string>('');
  const [searchParams, setSearchParams]: UrlSearchParams = useSearchParams();
  const location: Location = useLocation();

  useEffect(
    (): void => {
      if (!(location.state as LocationState)?.from?.pathname && !prevLocation) {
        setPrevLocation(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
      } 
      
      if ((location.state as LocationState)?.from?.pathname 
      && (location.state as LocationState)?.from?.pathname !== getRouteDetailsByName(RouteNameEnum.Search)?.url) {
        setPrevLocation((location.state as LocationState)?.from?.pathname);
      }
    },
    [location]
  );

  useEffect(
    (): void => {
      const searchValue: string | null = searchParams.get('search');

      if (searchValue) {
        setInputValue(searchValue);
      }
    },
    []
  );

  useQuery(
    [QueryKey.Search, inputValue, paginationOffset],
    (): Promise<ListPaginationInterface<SearchItemDTO> | void> => search(
      inputValue, { limit: initialItemsCount, offset: paginationOffset }
    ),
    {
      enabled: inputValue.length > 1,
      onSuccess: (data: ListPaginationInterface<SearchItemDTO>): void => {
        setMaxItems(data.amount);
        setSearchInputLabel(!data.list.length
          ? t('onarte.website.searchView.searchInputLabel.notFound')
          : t('onarte.website.searchView.searchInputLabel.searchedItems', { value: data.amount }));
        setSearchedItems([
          ...(!shouldClearSearchedItems ? searchedItems : []),
          ...data.list
        ]);
        let publicProfileItems: PublicProfileModelWithRoute[] = [];
        let auctionItems: AuctionInfoInterface[] = [];
        data.list.forEach((item: SearchItemDTO): void => {
          if (item.type === SearchableTypeEnum.PublicProfile) {
            if (appConfig.hiddenModules.collectors) {
              if (item.dto.type !== PublicProfileTypeEnum.Collector) {
                publicProfileItems = [ ...publicProfileItems, { ...item.dto, route: item.route } ];
              }
            } else {
              publicProfileItems = [ ...publicProfileItems, { ...item.dto, route: item.route } ];
            }
          }
          if (item.type === SearchableTypeEnum.Auction) {
            auctionItems = [ ...auctionItems, { ...item.dto, route: item.route } ];
          }
        });
        setSearchedPublicProfiles([
          ...(!shouldClearSearchedItems ? searchedPublicProfiles : []),
          ...publicProfileItems
        ]);
        setSearchedAuctions([
          ...(!shouldClearSearchedItems ? searchedAuctions : []),
          ...auctionItems
        ]);
        if (shouldClearSearchedItems) {
          setShouldClearSearchedItems(false);
        }
      },
      onError: (error: FrontendApiError) => logger(QueryKey.Search, error)
    }
  );

  useEffect(
    (): void => {
      setSearchParams({ search: inputValue });

      if (inputValue.length < 2) {
        setSearchedItems([]);
        setSearchedPublicProfiles([]);
        setSearchedAuctions([]);
        setSearchInputLabel('');
        setMaxItems(0);
      } else if (inputValue.length) {
        setShouldClearSearchedItems(true);
        clearPagination();
      }
    },
    [inputValue]
  );

  const getPhotoFromAttachment: (
    attachments: (ExternalAttachmentInterface | UploadedAttachmentInterface)[], type: AttachmentTypeEnum
  ) => string = (attachments: (ExternalAttachmentInterface | UploadedAttachmentInterface)[], type: AttachmentTypeEnum): string => {
    if (!attachments) {
      return '';
    }
    
    return getImageThumbnail(
      attachments.find((
        attachment: ExternalAttachmentInterface | UploadedAttachmentInterface
      ): boolean => attachment.type === type), 
      ThumbnailAttachmentTypeEnum.Size_340xAuto
    );
  };

  return (
    <Container>
      <SearchInputContainer>
        <StyledSearchInput 
          placeholder={t('onarte.website.searchView.searchInput.placeholder')}
          value={inputValue}
          onChange={setInputValue}
          inputLabel={searchInputLabel}
        />
        <CloseButton
          type='button'
          onClick={(): void => redirect(prevLocation)}
        >
          <Icon name={IconName.Close} size={20} color={Color.Gray500} />
        </CloseButton>
      </SearchInputContainer>
      <InfiniteScroll
        dataLength={searchedItems.length}
        next={fetchMoreData}
        hasMore={hasMoreItems}
        loader={
          <LoadingSpinner />
        }
        endMessage={false}
      >
        <ItemsContainer>
          {searchedAuctions.map((item: AuctionInfoInterface): JSX.Element => (
            <SearchedItem
              key={`${item.id}-${item.name}-${item.type}`}
              onClick={(): void => redirect(
                item.route?.prettyUrl 
                ?? getPathWithParams(
                  getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/', { id: item.id }
                ))}
            >
              <ImageBox>
                <ImageElement src={getImageThumbnail(item.coverPhoto, ThumbnailAttachmentTypeEnum.Size_340xAuto)} />
              </ImageBox>
              <BoxTitle 
                boxTitleDetails={{
                  tagLabel: t(`onarte.common.search.type.${SearchableTypeEnum.Auction}`),
                  itemName: item.name,
                  author: item.manufacturer.name,
                  itemDescriptiveAttributes: [item.label],
                  price: item.type === AuctionTypeEnum.PriceProposal ? undefined : item.price,
                  bottomButtonLabel: item.type === AuctionTypeEnum.PriceProposal ? t('onarte.common.priceProposal') : undefined,
                  biddersNumber: item.type === AuctionTypeEnum.Bidding
                    ? item.offers 
                    : undefined,
                }}
              />
            </SearchedItem>
          ))}
          {searchedPublicProfiles.map((item: PublicProfileModelWithRoute): JSX.Element => (
            <SearchedItem 
              key={`${item.id}-${item.name}-${item.type}`} 
              onClick={
                (): void => redirect(
                  item.route?.prettyUrl 
                  ?? getPathWithParams(
                    getRouteDetailsByName(
                      item.type === PublicProfileTypeEnum.Collector
                        ? RouteNameEnum.CollectorDetails
                        : RouteNameEnum.ArtistDetails
                    )?.url ?? '/',
                    { id: item.id }
                  )
                )}
            >
              <ImageBox>
                <ImageElement src={getPhotoFromAttachment(item.attachments, AttachmentTypeEnum.PublicProfileCoverPhoto)} />
              </ImageBox>
              <BoxTitle 
                boxTitleDetails={{
                  tagLabel: t(`onarte.common.search.type.${item.type}`),
                  author: item.name
                }}
              />
            </SearchedItem>
          ))}
        </ItemsContainer>       
      </InfiniteScroll>
    </Container>
  );
};
