import { IconName, useRedirect, UseRedirect } from '@on-arte/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { BaseSuccessView } from '@onArte/frontend/components';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const CartPaymentErrorView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();

  return (
    <BaseSuccessView 
      title={t('onarte.website.cartPaymentErrorView.message.title')}
      description={t('onarte.website.cartPaymentErrorView.message.description')}
      icon={IconName.Danger}
      buttonLabel={t('onarte.website.cartPaymentErrorView.message.button')}
      // TODO: update button action when BlueMedia link will be ready
      buttonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/')}
    />
  );
};
