import { AuctionTypeEnum, ThumbnailAttachmentTypeEnum } from '@on-arte/core-types';
import { 
  ArtworkMosaicItemDetails,
  getPathWithParams, 
  InfinitePaginationDetails, 
  ItemsMosaic, 
  ItemsMosaicTheme, 
  LoadingSpinner,
  useInfinitePagination,
  useLogger,
  UseLogger,
  useNotifications,
  UseNotifications,
  useRedirect,
  UseRedirect,
  UseState 
} from '@on-arte/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQuery } from 'react-query';

import { getCollection } from '@onArte/frontend/api/requests';
import { FrontendResponseCode, QueryKey } from '@onArte/frontend/enums';
import { useRouteInfo } from '@onArte/frontend/hooks';
import { RoutingState } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { emptyRequest, getImageThumbnail } from '@onArte/frontend/utils';
import { RouteNameEnum } from '@onArte/shared/enums';
import { Collection, ItemWithAuctionDetails } from '@onArte/shared/interfaces/api';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { 
  Container, 
  DetailsContainer,
  StyledArtworkDescription, 
  BackgroundContainer,
  BackgroundTitle,
  BackgroundImage,
  BackgroundContent,
  BackgroundOverlay,
  BackgroundSubtitle,
  NoArtworksMessage,
  MosaicWrapper,
  Wrapper
} from './collection.styled';

export const CollectionView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { 
    setMaxItems, 
    hasMoreItems, 
    fetchMoreData, 
    paginationOffset, 
    initialItemsCount,
  }: InfinitePaginationDetails = useInfinitePagination(20);
  const [collectionDetails, setCollectionDetails]: UseState<Collection | null> = useState<Collection | null>(null);
  const [slicedMosaicItems, setSlicedMosaicItems]: UseState<ArtworkMosaicItemDetails[]> = useState<ArtworkMosaicItemDetails[]>([]);
  const [allMosaicItems, setAllMosaicItems]: UseState<ArtworkMosaicItemDetails[]> = useState<ArtworkMosaicItemDetails[]>([]);
  const { addToast }: UseNotifications = useNotifications();
  const { logger }: UseLogger = useLogger();
  const { elementParamsFromRouting, currentRouteObject }: RoutingState = useRouteInfo();
  const { redirect }: UseRedirect = useRedirect();

  useQuery(
    [QueryKey.Collection, elementParamsFromRouting.id],
    (): Promise<Collection | void> => elementParamsFromRouting.id && currentRouteObject?.name === RouteNameEnum.Collection
      ? getCollection(elementParamsFromRouting.id)
      : emptyRequest(),
    {
      onSuccess: (data: Collection | undefined): void => {
        if (data) {
          setCollectionDetails(data);
          setMaxItems(data.items.length);
          const allMosaicItemsArray: ArtworkMosaicItemDetails[] = data.items.map(
            (item: ItemWithAuctionDetails): ArtworkMosaicItemDetails => ({
              image: getImageThumbnail(item.attachment, ThumbnailAttachmentTypeEnum.Size_340xAuto),
              boxTitleDetails: {
                itemName: item.name,
                author: item.manufacturer.name,
                price: item.auctionType === AuctionTypeEnum.PriceProposal ? undefined : item.price,
                biddersNumber: item.auctionType === AuctionTypeEnum.Bidding ? item.offers : undefined,
                bottomButtonLabel: item.auctionType === AuctionTypeEnum.PriceProposal ? t('onarte.common.priceProposal') : undefined,
                itemDescriptiveAttributes: item.label
                  ? [t(item.label)]
                  : [],
              },
              action: !item.auctionId
                ? (): void => addToast({ content: t('onarte.common.mosaicItems.noAuction') })
                : undefined,
              internalPath: item.auctionId
                ? item.route?.prettyUrl
                  ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.ArtworkDetails)?.url ?? '/', { id: item.auctionId })
                : undefined
            })
          );
          setAllMosaicItems(allMosaicItemsArray);
          setSlicedMosaicItems(allMosaicItemsArray.slice(0, initialItemsCount));
        }
      },
      onError: (error: FrontendApiError): void => {
        logger(QueryKey.Collection, error);
        if (error.responseCode === FrontendResponseCode.NotFound) {
          redirect(getRouteDetailsByName(RouteNameEnum.NotFound)?.url ?? '/');
          return;
        } else {
          addToast({ content: t(error.message) });
        }
      }
    }
  );
  
  useEffect(
    (): void => {
      setMaxItems(allMosaicItems.length);
      setSlicedMosaicItems(allMosaicItems.slice(0, (initialItemsCount + paginationOffset)));
    },
    [paginationOffset]
  );

  return (
    <Container>
      {!!collectionDetails && (
        <Wrapper>
          <BackgroundContainer>
            <BackgroundContent>
              <BackgroundTitle>{collectionDetails.name}</BackgroundTitle>
              <BackgroundSubtitle>{collectionDetails.title}</BackgroundSubtitle>
            </BackgroundContent>
            <BackgroundImage src={collectionDetails.coverPhoto} alt={collectionDetails.name} />
            <BackgroundOverlay />
          </BackgroundContainer>
          <DetailsContainer>
            <StyledArtworkDescription
              title={t('onarte.website.collectionView.about.title')}
              description={collectionDetails.description ?? undefined}
              withReadMore 
            />
          </DetailsContainer>
          {slicedMosaicItems.length ? (
            <InfiniteScroll
              dataLength={slicedMosaicItems.length}
              next={fetchMoreData}
              hasMore={hasMoreItems}
              loader={
                <LoadingSpinner />
              }
              endMessage={false}
            >
              <MosaicWrapper id='collection-list'>
                <ItemsMosaic 
                  items={slicedMosaicItems}
                  theme={ItemsMosaicTheme.Mosaic}
                />
              </MosaicWrapper>
            </InfiniteScroll>
          ) : (
            <NoArtworksMessage>{t('onarte.website.collectionView.noArtworksMessage')}</NoArtworksMessage>
          )}
        </Wrapper>
      )}
    </Container>
  );
};
