import { Breakpoint, Color, Grid, PageTitle, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { ContainerProps } from './baseViewWithBreadcrumbs.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  background-color: ${({ $background }: ContainerProps): Color => $background};
  padding-bottom: 120px;
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin-top: 50px;
  margin-bottom: 60px;
`;

export const BreadcrumbsContainer: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Gray200};
`;

export const Content: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.FullHd} + 80px);
  margin: 0 auto;
  padding: 0 40px;

  @media ${Breakpoint.Tablet} {
    padding: 0 32px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 0 20px;
  }
`;
