import { FormField, FormSection, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div``;

export const StyledFormField: StyledComponent<typeof FormField> = styled(FormField)`
  & + & {
    margin-top: 24px;
  }
`;

export const StyledFormSection: StyledComponent<typeof FormSection> = styled(FormSection)`
  & + & {
    margin-top: 60px;
  }
`;
