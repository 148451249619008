import { PublicProfileTypeEnum } from '@on-arte/core-types';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { PublicProfileForm } from '@onArte/frontend/interfaces';

export const useUserPublicProfiledValidation: () => Yup.SchemaOf<PublicProfileForm> = (): Yup.SchemaOf<PublicProfileForm> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    name: Yup.string()
      .required(t('onarte.common.requiredField')),
    type: Yup.mixed().when('isSecondButtonVisible', {
      is: true,
      then: Yup.mixed<PublicProfileTypeEnum>()
        .oneOf(Object.values(PublicProfileTypeEnum))
        .required(t('onarte.common.requiredField')),
    }),
    description: Yup.string()
      .required(t('onarte.common.requiredField'))
      .min(2, t('onarte.common.requiredField'))
      .max(350, t('onarte.common.validations.maxLength')),
    bio: Yup.string()
      .required(t('onarte.common.requiredField'))
      .min(2, t('onarte.common.requiredField'))
      .max(2048, t('onarte.common.validations.maxLength')),
    avatar: Yup.array()
      .of(
        // TODO: move AddPhotoFile validation to ui lib
        Yup.object().shape({
          id: Yup.string().required(t('onarte.common.requiredField')),
          type: Yup.string().required(t('onarte.common.requiredField')),
          path: Yup.string().required(t('onarte.common.requiredField')),
          name: Yup.string().required(t('onarte.common.requiredField')),
          objectName: Yup.string().defined().nullable(),
        })
      )
      .min(1, t('onarte.common.requiredField'))
      .required(t('onarte.common.requiredField')),
    cover: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(t('onarte.common.requiredField')),
          type: Yup.string().required(t('onarte.common.requiredField')),
          path: Yup.string().required(t('onarte.common.requiredField')),
          name: Yup.string().required(t('onarte.common.requiredField')),
          objectName: Yup.string().defined().nullable(),
        })
      )
      .min(1, t('onarte.common.requiredField'))
      .required(t('onarte.common.requiredField')),
    galleryPhoto: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(t('onarte.common.requiredField')),
          type: Yup.string().required(t('onarte.common.requiredField')),
          path: Yup.string().required(t('onarte.common.requiredField')),
          name: Yup.string().required(t('onarte.common.requiredField')),
          objectName: Yup.string().defined().nullable(),
        })
      )
      .min(1, t('onarte.common.requiredField'))
      .required(t('onarte.common.requiredField'))
  });
};
