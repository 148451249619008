import { InfoBoxTheme } from '@on-arte/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { Container, StyledInfoBox } from './infoBoxes.styled';

export const InfoBoxes: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();

  return (
    <Container>
      <StyledInfoBox
        header={t('onarte.website.infoBoxes.first.header')}
        subheader='01'
        infoBoxTheme={InfoBoxTheme.Orange}
        description={t('onarte.website.infoBoxes.first.description')}
      />
      <StyledInfoBox
        header={t('onarte.website.infoBoxes.second.header')}
        subheader='02'
        infoBoxTheme={InfoBoxTheme.Black}
        description={t('onarte.website.infoBoxes.second.description')}
      />
      <StyledInfoBox
        header={t('onarte.website.infoBoxes.third.header')}
        subheader='03'
        infoBoxTheme={InfoBoxTheme.Gray}
        description={t('onarte.website.infoBoxes.third.description')}
      />
    </Container>
  );
};
