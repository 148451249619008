import { FontWeight, Input, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const PopupHeader: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.01em;
  margin-bottom: 20px;
`;

export const PopupChildrenContainer: StyledComponent<'div'> = styled.div`
  max-width: 480px;
  width: 100%;
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  margin: 36px 0 32px;
`;
