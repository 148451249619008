import { StyledComponent, Breadcrumbs as BaseBreadcrumbs, Color, Breakpoint } from '@on-arte/ui';
import styled from 'styled-components';

export const Breadcrumbs: StyledComponent<typeof BaseBreadcrumbs> = styled(BaseBreadcrumbs)`
  padding: 24px 0;
  border-top: 1px solid ${Color.Gray300};
  margin: 0 40px;
  width: calc(100% - 80px);

  @media ${Breakpoint.Tablet} {
    margin: 0 32px;
    width: calc(100% - 64px);
  }

  @media ${Breakpoint.Mobile} {
    margin: 0 20px;
    width: calc(100% - 40px);
  }
`;
