import { AddressTypeEnum } from '@on-arte/core-types';

import { initialBillingAddressValues, initialShippingAddressValues } from '@onArte/frontend/constants';

import { UserDeliverySettingsState } from './userDeliverySettings.interface';

export const userDeliverySettingsInitialState: UserDeliverySettingsState = {
  addresses: [],
  isPopupVisible: false,
  currentAddressTypeInPopup: AddressTypeEnum.Delivery,
  initialShippingValues: initialShippingAddressValues,
  initialBillingValues: initialBillingAddressValues,
  addressId: '',
  smallDialogHeaderTranslation: '',
};
