import React from 'react';

import { arrowMenuPositions } from './baseStaticView.config';
import { ChildrenContainer, Container, ContentContainer, StyledArrowMenu, Title } from './baseStaticView.styled';
import { BaseStaticViewProps } from './baseStaticView.types';

export const BaseStaticView: React.FC<BaseStaticViewProps> = (props: BaseStaticViewProps): JSX.Element => {
  const { title, children }: BaseStaticViewProps = props;

  return (
    <Container>
      <Title>{title}</Title>
      <ContentContainer>
        <StyledArrowMenu positions={arrowMenuPositions} />
        <ChildrenContainer>
          {children}
        </ChildrenContainer>
      </ContentContainer>
    </Container>
  );
};
