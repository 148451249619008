import { Breakpoint, Button, Checkbox, Color, FontWeight, Input, ShippingBox, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media ${Breakpoint.Mobile} {
    justify-content: flex-start;
  }
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 18px;
  line-height: 26px;
  margin-top: 40px;
  margin-bottom: 24px;
`;

export const StyledCheckbox: StyledComponent<typeof Checkbox> = styled(Checkbox)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 40px;
`;

export const UnderlineButton: StyledComponent<'button'> = styled.button`
  margin-top: 24px;
  font-size: 14px;
  font-weight: ${FontWeight.Medium};
  line-height: 18px;
  color: ${Color.Gray500};
  text-decoration: underline;
`;

export const StyledShippingBox: StyledComponent<typeof ShippingBox> = styled(ShippingBox)`
  & + & {
    margin-top: 25px;
  }
`;

export const ItemShippingSettingsContainer: StyledComponent<'div'> = styled.div`
  & + & {
    margin-top: 40px;
  }
`;

export const CommentToOrderInput: StyledComponent<typeof Input> = styled(Input)`
  margin-top: 24px;
`;
