import {
  CategoryWithAttributesInterface,
  CreateItemModel,
  ItemSearchInterface,
  ItemInterface,
  ItemSearchCriteriaInterface,
  ItemWithAuctionsInterface,
  ListPaginationInterface,
  UpdateItemModel,
  ManufacturerInterface,
  CategoryAttributeInterface
} from '@on-arte/core-types';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { ListParams } from '@onArte/shared/interfaces';
import { UpdateAuctionRequestModel } from '@onArte/shared/models';

import { api } from '../api';

export const createItem: (data: CreateItemModel) => Promise<ItemInterface> = (data: CreateItemModel): Promise<ItemInterface> => new Promise(
  (resolve: ApiResolve<ItemInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/items', data).then(
      (response: AxiosResponse<ItemInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getItemDetails: (itemId: string) => Promise<ItemWithAuctionsInterface> = (
  itemId: string
): Promise<ItemWithAuctionsInterface> => new Promise(
  (resolve: ApiResolve<ItemWithAuctionsInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/items/${itemId}`).then(
      (response: AxiosResponse<ItemWithAuctionsInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updateItemDetails: (
  itemId: string, data: UpdateItemModel
) => Promise<void> = (itemId: string, data: UpdateItemModel): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.patch(`/items/${itemId}`, data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getItemsCategories: () => Promise<CategoryWithAttributesInterface[]>
= (): Promise<CategoryWithAttributesInterface[]> => new Promise(
  (resolve: ApiResolve<CategoryWithAttributesInterface[]>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/items/categories').then(
      (response: AxiosResponse<CategoryWithAttributesInterface[]>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getManufacturers: (name: string) => Promise<ListPaginationInterface<ManufacturerInterface>> = (
  name: string
): Promise<ListPaginationInterface<ManufacturerInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<ManufacturerInterface>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/items/manufacturers?${stringify({ name }, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<ManufacturerInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getManufacturersForLoggedUser: (name?: string) => Promise<ListPaginationInterface<ManufacturerInterface>> = (
  name?: string
): Promise<ListPaginationInterface<ManufacturerInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<ManufacturerInterface>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/items/logged-user-manufacturers?${stringify({ name }, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<ManufacturerInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getAttributes: () => Promise<Record<string, CategoryAttributeInterface>>
= (): Promise<Record<string, CategoryAttributeInterface>> => new Promise(
  (resolve: ApiResolve<Record<string, CategoryAttributeInterface>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/items/attributes').then(
      (response: AxiosResponse<Record<string, CategoryAttributeInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const searchItems: (
  params: Partial<ListParams & ItemSearchCriteriaInterface>
) => Promise<ListPaginationInterface<ItemSearchInterface>> = (
  params: Partial<ListParams & ItemSearchCriteriaInterface>
): Promise<ListPaginationInterface<ItemSearchInterface>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<ItemSearchInterface>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/items/search?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<ItemSearchInterface>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const changeSalesSettings: (itemId: string, data: UpdateAuctionRequestModel) => Promise<void> = (
  itemId: string, data: UpdateAuctionRequestModel
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.patch(`/items/${itemId}/sales-settings`, data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
