import { AddressInterface, ListPaginationInterface } from '@on-arte/core-types';
import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { AddressSaveWithLocationModel } from '@onArte/shared/models';

import { api } from '../api';

export const getAddresses: () => Promise<ListPaginationInterface<AddressInterface>>
  = (): Promise<ListPaginationInterface<AddressInterface>> => new Promise(
    (resolve: ApiResolve<ListPaginationInterface<AddressInterface>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
      api.get('/addresses').then(
        (response: AxiosResponse<ListPaginationInterface<AddressInterface>>): void => { resolve(response?.data); }
      ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
    )
  );

export const saveAddress: (data: AddressSaveWithLocationModel) => Promise<AddressInterface> = (
  data: AddressSaveWithLocationModel
): Promise<AddressInterface> => new Promise(
  (resolve: ApiResolve<AddressInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/addresses', data).then(
      (response: AxiosResponse<AddressInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const deleteAddress: (id: string) => Promise<void> = (id: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.delete(`/addresses/${id}`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
