import { 
  CartCostTypeEnum, 
  ListPaginationInterface, 
  OrderAuctionDetailsDTO, 
  OrderStatusEnum, 
  PaymentStatusEnum, 
  SalesListElementDTO, 
  ThumbnailAttachmentTypeEnum
} from '@on-arte/core-types';
import { 
  DropdownOption, 
  PaginationDetails, 
  PurchasedArtworkSummary, 
  Status, 
  usePagination, 
  UseState 
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getOrdersSales } from '@onArte/frontend/api/requests';
import { BaseUserMySalesView } from '@onArte/frontend/components';
import { orderStatuses } from '@onArte/frontend/constants';
import { QueryKey, UserMySalesPositions } from '@onArte/frontend/enums';
import { useAddresses } from '@onArte/frontend/hooks';
import { UseAddresses } from '@onArte/frontend/interfaces';
import { getImageThumbnail } from '@onArte/frontend/utils';
import { getCostFinalPriceByType } from '@onArte/shared/utils';

import { StyledArtworkBoxSoldSummary, StyledDropdown, StyledPagination } from './userSoldArtworks.styled';

export const UserSoldArtworksView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { setMaxItems, setPage, itemsPerPage, maxItems, offset }: PaginationDetails = usePagination();
  const [ordersStatus, setOrdersStatus]: UseState<OrderStatusEnum | undefined> = useState<OrderStatusEnum | undefined>(undefined);
  const [orders, setOrders]: UseState<SalesListElementDTO[]> = useState<SalesListElementDTO[]>([]);
  const {
    transformAddressInterfaceToShippingAddressFormData,
    transformAddressInterfaceToBillingAddressFormData,
    transformAddressInterfaceToDeliveryCompanyOrderFormData,
  }: UseAddresses = useAddresses();

  useQuery(
    [QueryKey.SalesList, ordersStatus, offset],
    (): Promise<ListPaginationInterface<SalesListElementDTO>> => getOrdersSales({ limit: itemsPerPage, offset, status: ordersStatus }),
    {
      onSuccess: (data: ListPaginationInterface<SalesListElementDTO>): void => {
        setMaxItems(data.amount);
        setOrders(data.list);
      }
    }
  );

  return (
    <BaseUserMySalesView activePosition={UserMySalesPositions.UserSoldArtworks} title={t('onarte.website.meta.userSoldArtworks.title')}>
      <StyledDropdown 
        label={t('onarte.website.userSoldArtworksView.dropdown.all')}
        options={Object.entries(orderStatuses).map(([key, value]: [string, Status]): DropdownOption => ({
          name: key,
          label: t(value.label)
        }))}
        onChooseAnswer={(option: DropdownOption): void => setOrdersStatus(option.name as OrderStatusEnum)}
      />
      {orders
        // TODO: temporary hidden
        .filter((order: SalesListElementDTO): boolean => !!order.auctions[0].deliveryAddress)
        .map((order: SalesListElementDTO): JSX.Element => (
          <StyledArtworkBoxSoldSummary 
            createdDate={order.orderedAt}
            badges={[orderStatuses[order.status]].map((status: Status): Status => ({ ...status, label: t(status.label) }))}
            items={order.auctions.map((auction: OrderAuctionDetailsDTO): PurchasedArtworkSummary => ({
              artworkDetails: {
                author: auction.auction.manufacturer.name,
                itemName: auction.auction.name,
                itemDescriptiveAttributes: [t(auction.auction.label)],
              },
              image: getImageThumbnail(auction.auction.coverPhoto, ThumbnailAttachmentTypeEnum.Size_100x100),
              costsDetails: {
                price: getCostFinalPriceByType(auction.costs, CartCostTypeEnum.Item),
                commission: getCostFinalPriceByType(auction.costs, CartCostTypeEnum.Commission),
                deliveryCost: getCostFinalPriceByType(auction.costs, CartCostTypeEnum.Delivery),
                deliveryTypeLabel: t(`onarte.common.deliveryType.${auction.deliveryType}.name`),
              },
              commentToSeller: auction.commentToSeller ?? '',
              trackingId: auction.trackingCode ?? undefined,
              trackingUrl: auction.trackingUrl ?? undefined,
              isDeliveryCompleted: auction.delivered ?? undefined
            }))}
            ordersDetails={{
              id: order.no,
              hasInvoice: !!order.auctions.find((auction: OrderAuctionDetailsDTO): boolean => !!auction.invoiceRequired)?.invoiceRequired,
              paymentStatusLabel: t(`onarte.common.paymentStatus.${order.payment.paymentStatus ?? PaymentStatusEnum.Failed}`),
              paymentTypeLabel: t(`onarte.common.paymentType.${order.payment.paymentType}`),
              commentToOrder: order.commentToOrder ?? '',
              shippingAddress: transformAddressInterfaceToShippingAddressFormData(order.auctions[0].deliveryAddress!),
              billingAddress: transformAddressInterfaceToBillingAddressFormData(order.auctions[0].billingAddress!),
              sourceParcelAddress: order.auctions[0].pickupAddress
                ? transformAddressInterfaceToDeliveryCompanyOrderFormData(order.auctions[0].pickupAddress)
                : undefined,
            }}
            key={order.id}
          />
        ))
      }
      {maxItems > itemsPerPage && (
        <StyledPagination
          onPageChange={setPage}
          maxItems={maxItems}
        />
      )}
    </BaseUserMySalesView>
  );
};
