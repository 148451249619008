import { UserArtworkSalesSettingsAction } from './userArtworkSalesSettingsAction.enum';
import { UserArtworkSalesSettingsActions } from './userArtworkSalesSettingsActions.type';
import { UserArtworkSalesSettingsState } from './userArtworkSalesSettingsState.interface';

export const userArtworkSalesSettingsReducer = (
  state: UserArtworkSalesSettingsState, action: UserArtworkSalesSettingsActions
): UserArtworkSalesSettingsState => {
  switch (action.type) {
    case UserArtworkSalesSettingsAction.SetItemSalesType:
      return { ...state, itemSalesType: action.payload };
    case UserArtworkSalesSettingsAction.SetAcceptablePrice:
      return { ...state, acceptablePrice: action.payload };
    case UserArtworkSalesSettingsAction.SetCanParticipateEvent:
      return { ...state, canParticipateEvent: action.payload };
    case UserArtworkSalesSettingsAction.SetAll:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
