import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { ConversionEventData } from '@onArte/shared/models';

import { api } from '../api';

export const sendConversionEvent: (data: ConversionEventData) => Promise<void> = (
  data: ConversionEventData
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/conversions/send', data).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
