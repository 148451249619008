import { Color } from '@on-arte/ui';
import React from 'react';

import { Breadcrumbs } from '@onArte/frontend/theme';

import { BreadcrumbsContainer, Container, Content, StyledPageTitle } from './baseViewWithBreadcrumbs.styled';
import { BaseViewWithBreadcrumbsProps } from './baseViewWithBreadcrumbs.types';

export const BaseViewWithBreadcrumbs: React.FC<BaseViewWithBreadcrumbsProps> = (props: BaseViewWithBreadcrumbsProps): JSX.Element => {
  const { breadcrumbs, pageTitleSettings, withGrayBackground = false, children }: BaseViewWithBreadcrumbsProps = props;

  return (
    <Container $background={withGrayBackground ? Color.Gray200 : Color.White}>
      <BreadcrumbsContainer>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </BreadcrumbsContainer>
      <Content>
        {pageTitleSettings.title && <StyledPageTitle pageTitleSettings={{ ...pageTitleSettings, withUnderline: true }} />}
        {children}
      </Content>
    </Container>
  );
};
