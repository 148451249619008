import { AuctionOfferStatusEnum } from '@on-arte/core-types';
import { IconName, UseLogger, UseNotifications, UseRedirect, useLogger, useNotifications, useRedirect } from '@on-arte/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { Params, useParams } from 'react-router-dom';

import { setOfferStatus } from '@onArte/frontend/api/requests';
import { BaseSuccessView } from '@onArte/frontend/components';
import { FrontendApiError } from '@onArte/frontend/models';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

export const PriceProposalAcceptedView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { id }: Readonly<Params<string>> = useParams();
  const { logger }: UseLogger = useLogger();
  const { addToast }: UseNotifications = useNotifications();

  useEffect(
    (): void => {
      if (!id) {
        return;
      }

      setOfferStatus(id, AuctionOfferStatusEnum.Accepted)
        .then((): void => addToast({ content: t('onarte.website.priceProposalAcceptedView.setOfferStatus.success') }))
        .catch((error: FrontendApiError): void => {
          addToast({ content: error.message });
          logger('setOfferStatus', error);
          redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
        });
    },
    [id]
  );

  return (
    <BaseSuccessView 
      title={t('onarte.website.priceProposalAcceptedView.title')} 
      description={t('onarte.website.priceProposalAcceptedView.description')} 
      icon={IconName.Image} 
      buttonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.UserSalesList)?.url ?? '/')}
      buttonLabel={t('onarte.website.priceProposalAcceptedView.buttonLabel')}
    />
  );
};
