import { UploadAttachmentTypeEnum, AttachmentInterface } from '@on-arte/core-types';
import { AddPhotoFile } from '@on-arte/ui';

import { uploadAttachment } from '@onArte/frontend/api/requests';
import { UseFiles } from '@onArte/frontend/interfaces';

export const useFiles: () => UseFiles = (): UseFiles => {
  const addFile: (
    file: File, onProgress: (progressEvent: ProgressEvent<FileReader>) => void, attachmentType: string
  ) => Promise<AddPhotoFile> = (
    file: File, onProgress: (progressEvent: ProgressEvent<FileReader>) => void, attachmentType: string
  ): Promise<AddPhotoFile> => {
    return new Promise<AddPhotoFile>((resolve: (createdFile: AddPhotoFile) => void, reject: () => void): void => {
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      uploadAttachment(formData, onProgress, attachmentType as UploadAttachmentTypeEnum)
        .then((response: AttachmentInterface): void => resolve({
          ...response,
          objectName: null
        }))
        .catch((): void => reject());
    });
  };

  return { addFile };
};
