import {
  ListPaginationInterface,
  PurchasesListElementDTO,
  SalesListElementDTO,
  SalesListQuery,
  SellerCreateModel,
  SellerDTO
} from '@on-arte/core-types';
import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';
import { ListParams } from '@onArte/shared/interfaces';

import { api } from '../api';

export const getPurchases: (params: Partial<ListParams>) => Promise<ListPaginationInterface<PurchasesListElementDTO>> = (
  params: Partial<ListParams>
): Promise<ListPaginationInterface<PurchasesListElementDTO>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<PurchasesListElementDTO>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/orders/purchases?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<PurchasesListElementDTO>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getOrdersSales: (params: SalesListQuery) => Promise<ListPaginationInterface<SalesListElementDTO>> = (
  params: SalesListQuery
): Promise<ListPaginationInterface<SalesListElementDTO>> => new Promise(
  (resolve: ApiResolve<ListPaginationInterface<SalesListElementDTO>>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/orders/sales?${stringify(params, { skipEmptyString: true })}`).then(
      (response: AxiosResponse<ListPaginationInterface<SalesListElementDTO>>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const setParcelInAuctionAsDelivered: (orderId: string, auctionId: string) => Promise<void> = (
  orderId: string, auctionId: string
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/orders/purchases/${orderId}/auctions/${auctionId}/delivered`).then(
      (response: AxiosResponse<void>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getPayoutDetails: () => Promise<SellerDTO> = (): Promise<SellerDTO> => new Promise(
  (resolve: ApiResolve<SellerDTO>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get('/orders/payout-details').then(
      (response: AxiosResponse<SellerDTO>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const updatePayoutDetails: (data: SellerCreateModel) => Promise<SellerDTO> = (
  data: SellerCreateModel
): Promise<SellerDTO> => new Promise(
  (resolve: ApiResolve<SellerDTO>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/orders/payout-details', data).then(
      (response: AxiosResponse<SellerDTO>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
