import { useRedirect, UseRedirect } from '@on-arte/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { BaseHandbookView } from '@onArte/frontend/components';
import { FileFromViews } from '@onArte/frontend/enums';
import { UseHandbook } from '@onArte/frontend/interfaces';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { useHandbookBuyer } from './handbookBuyer.hook';

export const HandbookBuyerView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { newsSections, faqContent }: UseHandbook = useHandbookBuyer();
  const { redirect }: UseRedirect = useRedirect();

  return (
    <BaseHandbookView 
      pageTitleSettings={{
        title: t('onarte.website.handbookBuyerView.pageTitleSettings.title'),
        linkLabel: t('onarte.website.handbookBuyerView.pageTitleSettings.linkLabel'),
        linkAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.HandbookSeller)?.url ?? '/'),
        withUnderline: false
      }}
      backgroundImage={FileFromViews.BuyerBackground}
      title={t('onarte.website.handbookBuyerView.title')}
      newsBoxes={newsSections}
      // TODO: temporary hidden
      // accordionTitle={t('onarte.website.handbookBuyerView.accordionTitle')}
      // accordionContent={faqContent}
      bigPhotoBoxData={{
        title: t('onarte.website.handbookBuyerView.bigPhotoBoxTitle'),
        image: FileFromViews.SellerBackground,
        label: t('onarte.website.handbookBuyerView.bigPhotoBoxButtonLabel'),
        action: (): void => redirect(getRouteDetailsByName(RouteNameEnum.HandbookSeller)?.url ?? '/')
      }}
    />
  );
};
