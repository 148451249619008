import { Breakpoint, Color, FontWeight, Logo, MenuPosition, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const UserDetailsContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(100% - 690px);
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media ${Breakpoint.SmallDesktop} {
    max-width: calc(100% - 520px);
  }

  @media ${Breakpoint.Tablet} {
    max-width: calc(100% - 460px);
  }

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
  }
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 80px;
  display: flex;
  flex-direction: column;

  @media ${Breakpoint.Tablet} {
    margin: 80px 32px;
    max-width: 100%;
  }

  @media ${Breakpoint.Mobile} {
    margin: 0 0 40px;
    max-width: 100%;
  }
`;

export const BasketColumnContainer: StyledComponent<'div'> = styled.div`
  max-width: 880px;
  width: 100%;
  background-color: ${Color.Gray200};

  @media ${Breakpoint.BigDesktop} {
    max-width: 720px;
  }

  @media ${Breakpoint.SmallDesktop} {
    max-width: 520px;
  }

  @media ${Breakpoint.Tablet} {
    max-width: 460px;
  }

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
  }
`;

export const MobileCartTitle: StyledComponent<'div'> = styled.div`
  padding: 24px 20px;
  width: 100%;
  background-color: ${Color.Gray200};
  border-top: 1px solid ${Color.Gray300};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.Black};
`;

export const UnderlineButton: StyledComponent<'button'> = styled.button`
  font-size: 14px;
  line-height: 18px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.Gray500};
  text-decoration-line: underline;
`;

export const LogoButton: StyledComponent<typeof MenuPosition> = styled(MenuPosition)``;

export const StyledLogo: StyledComponent<typeof Logo> = styled(Logo)`
  width: 128px;
`;

export const LogoContainer: StyledComponent<'div'> = styled.div`
  width: 100%;

  @media ${Breakpoint.Mobile} {
    background-color: ${Color.Gray200};
    padding: 20px;
  }
`;

export const ChildrenContainer: StyledComponent<'div'> = styled.div`
  @media ${Breakpoint.Mobile} { 
    padding: 0 20px;
  }
`;

export const BreadcrumbsContainer: StyledComponent<'div'> = styled.div`
  padding: 32px 0 20px;
  border-bottom: 1px solid ${Color.Gray300};

  @media ${Breakpoint.Mobile} {
    padding: 24px 20px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 20px;
  }
`;

export const MobileCartContainer: StyledComponent<'div'> = styled.div``;
