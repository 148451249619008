import { Quote } from '@on-arte/ui';

export const quotes: Quote[] = [
  {
    content: 'Cały proces sprzedażowy oraz komunikacja z klientem - wzorowa. Bardzo polecam on*arte!',
    author: 'Beata',
    authorSpecialty: 'Kolekcjonerka',
  },
  {
    content: 'Doceniam profesjonalizm on*arte oraz przejrzyste reguły współpracy.',
    author: 'Tomasz Wysocki',
    authorSpecialty: 'Artysta',
  },
  {
    content: 'Profesjonalne doradztwo w doborze dzieł sztuki, zgodne z określonymi przeze mnie preferencjami.',
    author: 'Piotr',
    authorSpecialty: 'Kolekcjoner',
  },
];
