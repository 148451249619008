import { TextBoxTheme, UseLocalStorage, useLocalStorage, UseState } from '@on-arte/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { LocalStorageKey } from '@onArte/frontend/enums';

import { StyledTextBox } from './cookiesPopup.styled';

export const CookiesPopup: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [cookiesPopupVisibleValue, storeCookiesPopupVisibleValue]: UseLocalStorage<boolean | null>
    = useLocalStorage<boolean | null>(LocalStorageKey.CookiesPopupVisible, null);
  const [cookiesPopupVisible, setCookiesPopupVisible]: UseState<boolean | null> = useState<boolean | null>(null);

  useEffect(
    (): void => {
      setCookiesPopupVisible(cookiesPopupVisibleValue ?? true);
    },
    [cookiesPopupVisibleValue]
  );

  const hidePopup: () => void = (): void => {
    setCookiesPopupVisible(false);
    storeCookiesPopupVisibleValue(false);
  };

  return (
    <StyledTextBox
      textBoxTheme={TextBoxTheme.White}
      content={t('onarte.website.cookiesPopup.content')}
      buttonLabel={t('onarte.website.cookiesPopup.buttonLabel')}
      buttonAction={hidePopup}
      $visible={!!cookiesPopupVisible}
    />
  );
};
