import { Breakpoint, Color, FontWeight, MenuPosition, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { BoxesSliderSection } from '@onArte/frontend/components';
import { PageTitle } from '@onArte/frontend/theme';

import { FirstAuctionBoxWrapperProps } from './eventsList.props';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${Color.Gray200};
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin-bottom: 40px;
`;

export const EventsListContainer: StyledComponent<'div'> = styled.div`
  background-color: ${Color.White};
  padding: 40px 0;

  @media ${Breakpoint.Mobile} {
    padding-top: 0;
  }
`;

export const FirstAuctionBoxWrapper: StyledComponent<typeof MenuPosition, FirstAuctionBoxWrapperProps> 
  = styled(MenuPosition)<FirstAuctionBoxWrapperProps>`
    max-width: ${({ $fullWidth }: FirstAuctionBoxWrapperProps): string => $fullWidth ? '100%' : '60%'};
    width: 100%;
    font-weight: ${FontWeight.Regular};

    @media ${Breakpoint.Mobile} {
      max-width: 100%;
      height: 332px;
    }
`;

export const SecondAuctionBoxWrapper: StyledComponent<typeof FirstAuctionBoxWrapper> = styled(FirstAuctionBoxWrapper)`
  max-width: 40%;

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
  }
`;

export const AuctionBoxesContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  height: 660px;
  padding: 0 40px;

  ${FirstAuctionBoxWrapper} + ${SecondAuctionBoxWrapper} {
    margin-left: 10px;
  }

  @media ${Breakpoint.Tablet} {
    height: 416px;
    padding: 0 32px;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    height: auto;
    padding: 0;

    ${FirstAuctionBoxWrapper} + ${SecondAuctionBoxWrapper} {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;

export const StyledBoxesSliderSection: StyledComponent<typeof BoxesSliderSection> = styled(BoxesSliderSection)`
  margin-top: 120px;

  @media ${Breakpoint.SmallMobile} {
    margin-top: 80px;
  }
`;
