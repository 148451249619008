import { AuctionSoldInterface, CartAuctionInterface, PromotionCodeInterface } from '@on-arte/core-types';
import {
  BasketSidePanel,
  DiscountCode,
  getPathWithParams,
  useNotifications,
  UseNotifications,
  useRedirect,
  UseRedirect,
} from '@on-arte/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from 'react-query';

import { QueryKey } from '@onArte/frontend/enums';
import { useAuth, useBasket, useSocket } from '@onArte/frontend/hooks/';
import { UseAuth, UseBasket, UseSocket } from '@onArte/frontend/interfaces/';
import { RouteNameEnum, WebSocketCommand } from '@onArte/shared/enums';
import { WebSocketSubscriptionDetails } from '@onArte/shared/models';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { BasketSidePanelElementProps } from './basketSidePanelElement.types';

export const BasketSidePanelElement: React.FC<BasketSidePanelElementProps> = (props: BasketSidePanelElementProps): JSX.Element => {
  const { isOpen, setIsOpen, className }: BasketSidePanelElementProps = props;
  const { t }: TransProps<never> = useTranslation();
  const { 
    cartData, 
    onSubmitDiscountCode, 
    onDeleteCartItem, 
    onDeleteDiscountCode, 
    cartPrices, 
    basketItems,
    validationMessage
  }: UseBasket = useBasket();
  const { redirect }: UseRedirect = useRedirect();
  const { userData }: UseAuth = useAuth();
  const { socket, connectedToSocket, setIsConnectedToSocket }: UseSocket = useSocket();
  const { addToast }: UseNotifications = useNotifications();
  const queryClient: QueryClient = useQueryClient();

  const onSubmit: () => void = (): void => {
    redirect(getPathWithParams(getRouteDetailsByName(RouteNameEnum.CartDelivery)?.url ?? '/', { id: cartData?.id ?? '' }));
    setIsOpen(false);
  };

  useEffect(
    (): void => {
      if (!socket || connectedToSocket || !cartData?.auctions) {
        return;
      }

      setIsConnectedToSocket();
      cartData.auctions.forEach((cartAuction: CartAuctionInterface): void => {
        socket.emit(WebSocketCommand.SubscribeAuction, { id: cartAuction.auction.auctionId } as WebSocketSubscriptionDetails);
      });
      socket.on(WebSocketCommand.AuctionSold, (data: AuctionSoldInterface): void => {
        if (data.buyer.id !== userData?.id) {
          addToast({ content: t('onarte.website.common.auctionInYourCartSold', { name: data.auction.name })});
          void queryClient.invalidateQueries(QueryKey.Cart);
        }
      });
    },
    [socket, cartData?.auctions]
  );

  useEffect(
    (): (() => void) => {
      return (): void => {
        if (socket) {
          socket.off(WebSocketCommand.AuctionSold);
        }
      };
    },
    [socket]
  );

  return (
    <BasketSidePanel 
      items={basketItems}
      basePrice={cartPrices.auctionsBasePrice}
      finalPrice={cartData?.finalPrice ?? 0}
      deliveryStartPrice={cartPrices.deliveryPrice}
      discountsCodes={
        cartData?.promotionsCodes.map((code: PromotionCodeInterface): DiscountCode => ({
          value: code.code,
          effect: code.name
        })) ?? []
      }
      discount={cartData?.promotionsCodes.length ? (cartData?.basePrice - cartData?.finalPrice) : undefined}
      onClose={(): void => setIsOpen(false)}
      onDiscountCodeEnter={onSubmitDiscountCode}
      onRemoveDiscountCode={onDeleteDiscountCode}
      onRemoveItem={onDeleteCartItem}
      onSubmitButtonClick={onSubmit}
      visible={isOpen}
      validationMessage={validationMessage}
      className={className} 
    />
  );
};
