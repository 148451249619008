import { Color, FontWeight, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { WrapperProps } from './baseSuccessView.types';

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div<WrapperProps>`
  width: 100%;
  min-height: ${({ $fullHeight }: WrapperProps): string => $fullHeight ? '100vh' : 'calc(100vh - 100px)'};
  background-color: ${Color.Gray200};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container: StyledComponent<'div'> = styled.div`
  max-width: 480px;
  margin: 0 20px;
  color: ${Color.Black};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconContainer: StyledComponent<'div'> = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid ${Color.Gray300};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  flex-shrink: 0;
`;

export const MessageTitle: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.01em;
`;

export const MessageDescription: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Light};
  font-size: 18px;
  line-height: 26px;
  margin: 16px 0 32px;
`;
