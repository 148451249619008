import { 
  Breakpoint, 
  Button, 
  ButtonTheme, 
  Filters, 
  IconName, 
  ItemsMosaic, 
  ItemsMosaicTheme, 
  useRedirect,
  UseRedirect,
  UseState 
} from '@on-arte/ui';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { useAutosuggestActions } from '@onArte/frontend/hooks';
import { AutosuggestActions } from '@onArte/frontend/interfaces';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { useArtworksList } from './artworksList.hook';
import { 
  Container,
  ButtonContainer, 
  ItemsMosaicContainer,
  MosaicWrapper,
  NoAuctionMessage,
  StyledPageTitle, 
  ProgressBarContainer,
  ProgressBar,
  ArtworksAmountText
} from './artworksList.styled';
import { UseArtworksList } from './artworksList.types';

export const ArtworksListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { searchManufacturersAction }: AutosuggestActions = useAutosuggestActions();
  const { 
    onFilterHandler, 
    scrollToPreviousAction, 
    mosaicItems, 
    filters, 
    defaultFilters, 
    maxItems,
    changePageAction,
    page,
    itemsPerPage,
    initialPageNumber,
    lowestLoadedPage,
    highestLoadedPage
  }: UseArtworksList = useArtworksList();
  const [filtersOpen, setFiltersOpen]: UseState<boolean> = useState<boolean>(false);
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.SmallMobile });

  const items: number = useMemo(
    (): number => {
      if (itemsPerPage * page > maxItems) {
        return maxItems;
      } else if (initialPageNumber && initialPageNumber > page) {
        return itemsPerPage * initialPageNumber;
      } else {
        return itemsPerPage * page;
      }
    },
    [itemsPerPage, page, maxItems, initialPageNumber]
  );

  return (
    <Container>
      <StyledPageTitle 
        pageTitleSettings={{
          title: t('onarte.website.artworksListView.pageTitle.title'),
          linkLabel: t('onarte.website.artworksListView.pageTitle.link'),
          linkAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.CollectionsList)?.url ?? '/'),
          withUnderline: false,
          description: t('onarte.website.artworksListView.pageTitle.description')
        }}
      />
      <ItemsMosaicContainer>
        <ButtonContainer>
          <Button
            label={t('onarte.website.artworksListView.filterButton.label')}
            icon={IconName.Filter}
            buttonTheme={isSmallMobile ? ButtonTheme.PrimarySmall : ButtonTheme.PrimaryBig}
            onClick={(): void => setFiltersOpen(true)}
          />
        </ButtonContainer>
        {!!lowestLoadedPage && lowestLoadedPage > 1 && (
          <ButtonContainer>
            <Button
              label={t('onarte.website.artworksListView.previousButton.label')}
              buttonTheme={isSmallMobile ? ButtonTheme.SecondarySolidSmall : ButtonTheme.SecondarySolidBig}
              onClick={(): void => changePageAction(lowestLoadedPage - 1)}
            />
          </ButtonContainer>
        )}
        {!!mosaicItems?.length && (
          <MosaicWrapper id='artworks-list'>
            <ItemsMosaic 
              items={mosaicItems} 
              theme={ItemsMosaicTheme.Mosaic} 
              onLayoutComplete={scrollToPreviousAction} 
            />
          </MosaicWrapper>
        )}
        {mosaicItems?.length === 0 && (
          <NoAuctionMessage>{t('onarte.website.artworksListView.noAuctionsMessage')}</NoAuctionMessage>
        )}
        {!!mosaicItems?.length && (
          <ButtonContainer>
            <ArtworksAmountText>
              {t('onarte.website.artworksListView.artworksAmountText', { items, maxItems })}
            </ArtworksAmountText>
            <ProgressBarContainer>
              <ProgressBar $width={items / maxItems * 100}/>
            </ProgressBarContainer>
            {!!highestLoadedPage && itemsPerPage * page < maxItems && (
              <Button
                label={t('onarte.website.artworksListView.nextButton.label')}
                buttonTheme={isSmallMobile ? ButtonTheme.SecondarySolidSmall : ButtonTheme.SecondarySolidBig}
                onClick={(): void => changePageAction(highestLoadedPage + 1)}
              />
            )}
          </ButtonContainer>
        )}
      </ItemsMosaicContainer>
      <Filters 
        filters={filters} 
        onFilter={(onFilters: Record<string, string[]>): void => onFilterHandler(onFilters)} 
        onClose={(): void => setFiltersOpen(false)}
        visible={filtersOpen} 
        defaultFilters={defaultFilters}
        filtersActions={{
          manufacturerIds: { autosuggestOnValueChange: searchManufacturersAction }
        }}
      />
    </Container>
  );
};
