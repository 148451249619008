import { Breakpoint, ButtonTheme } from '@on-arte/ui';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { FileFromViews } from '@onArte/frontend/enums';

import { ErrorContainer, BackgroundShape, ErrorHeading, StyledButton, Container } from './baseErrorView.styled';
import { BaseErrorViewProps } from './baseErrorView.types';

export const BaseErrorView: React.FC<BaseErrorViewProps> = (props: BaseErrorViewProps): JSX.Element => {
  const { title, buttonLabel, buttonAction }: BaseErrorViewProps = props;
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.SmallMobile });

  return (
    <Container>
      <ErrorContainer>
        <BackgroundShape src={FileFromViews.OrangePattern} />
        <ErrorHeading>{title}</ErrorHeading>
        <StyledButton 
          label={buttonLabel} 
          buttonTheme={isSmallMobile ? ButtonTheme.PrimarySmall : ButtonTheme.PrimaryBig} 
          onClick={buttonAction} 
        />
      </ErrorContainer>
    </Container>
  );
};
