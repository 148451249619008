import { useContext } from 'react';

import { RoutingContext } from '@onArte/frontend/contexts';
import { RoutingState } from '@onArte/frontend/interfaces';

export const useRouteInfo: () => RoutingState = (): RoutingState => {
  const routesArray: RoutingState = useContext(RoutingContext);

  if (!routesArray) {
    throw new Error('Routes info context error');
  }

  return routesArray;
};
