import { CartAuctionInterface, CartCostInterface, CartCostTypeEnum } from '@on-arte/core-types';

export const findAllCommissionCost: (auctions: CartAuctionInterface[]) => number = (auctions: CartAuctionInterface[]): number => {
  return auctions.reduce(
    (acc: number, item: CartAuctionInterface): number => {
      const commissionCost: number = item.costs
        .find((cost: CartCostInterface): boolean => cost.type === CartCostTypeEnum.Commission)?.finalPrice ?? 0;
      
      return acc + commissionCost;
    },
    0
  );
};
