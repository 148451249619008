import { AddressInterface, AddressTypeEnum, ListPaginationInterface, LocationTypeEnum } from '@on-arte/core-types';
import {
  AddressFormData,
  BillingAddressForm,
  BillingAddressFormData,
  Button,
  DialogTheme,
  ShippingAddressForm,
  ShippingAddressFormData,
  SmallDialog,
  useFormatPhoneNumber,
  UseFormatPhoneNumber,
  UseFormikForm,
  useFormikForm,
  useLogger,
  UseLogger,
} from '@on-arte/ui';
import { FormikProps } from 'formik';
import React, { Dispatch, RefObject, useEffect, useReducer, useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { deleteAddress, getAddresses, saveAddress } from '@onArte/frontend/api/requests';
import { BaseViewWithTabs } from '@onArte/frontend/components';
import { initialBillingAddressValues, initialShippingAddressValues } from '@onArte/frontend/constants';
import { QueryKey } from '@onArte/frontend/enums';
import { FrontendApiError } from '@onArte/frontend/models';
import { AddressSaveWithLocationModel } from '@onArte/shared/models';

import {
  UserDeliverySettingsAction,
  UserDeliverySettingsActions,
  userDeliverySettingsInitialState,
  userDeliverySettingsReducer,
  UserDeliverySettingsState
} from './reducer';
import {
  AddressBox,
  ButtonContainer,
  Container,
  Header,
  InnerContainer, 
  StyledMessageBox,
  StyledShippingBox
} from './userDeliverySettings.styled';

export const UserDeliverySettingsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const shippingFormRef: React.Ref<FormikProps<ShippingAddressFormData>> = useRef(null);
  const billingFormRef: React.Ref<FormikProps<BillingAddressFormData>> = useRef(null);
  const { setFormSubmitted, isFormSubmitted, setFormNotSubmitted }: UseFormikForm = useFormikForm();
  const { logger }: UseLogger = useLogger();
  const [userDeliverySettingsState, userDeliverySettingsDispatch]: [
    UserDeliverySettingsState, Dispatch<UserDeliverySettingsActions>
  ] = useReducer(userDeliverySettingsReducer, userDeliverySettingsInitialState);
  const billingAddresses: AddressInterface[] = userDeliverySettingsState.addresses
    .filter((address: AddressInterface): boolean => address.type === AddressTypeEnum.Billing);
  const deliveryAddresses: AddressInterface[] = userDeliverySettingsState.addresses
    .filter((address: AddressInterface): boolean => address.type === AddressTypeEnum.Delivery);
  const { formatPhone }: UseFormatPhoneNumber = useFormatPhoneNumber();

  useQuery(
    [QueryKey.AddressesList],
    (): Promise<ListPaginationInterface<AddressInterface>> => getAddresses(),
    {
      onSuccess: (data: ListPaginationInterface<AddressInterface>): void => {
        userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetAddresses, payload: data.list });
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.AddressesList, error)
    }
  );

  useEffect(
    (): void => {
      if (!userDeliverySettingsState.isPopupVisible) {
        userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetAddressId, payload: '' });
      }
    },
    [userDeliverySettingsState.isPopupVisible]
  );

  const addAddress: (type: AddressTypeEnum) => void = (type: AddressTypeEnum): void => {
    if (type === AddressTypeEnum.Delivery) {
      userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetInitialShippingValues, payload: initialShippingAddressValues });
    } else {
      userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetInitialBillingValues, payload: initialBillingAddressValues });
    }
    userDeliverySettingsDispatch({
      type: UserDeliverySettingsAction.SetSmallDialogHeaderTranslation,
      payload: 'onarte.website.userDeliverySettings.newAddress.title'
    });
    userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetCurrentAddressTypeInPopup, payload: type });
    userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetIsPopupVisible, payload: true });
  };

  const editAddress: (type: AddressTypeEnum, address: AddressInterface) => void = (
    type: AddressTypeEnum, address: AddressInterface
  ): void => {
    userDeliverySettingsDispatch({
      type: UserDeliverySettingsAction.SetSmallDialogHeaderTranslation,
      payload: 'onarte.website.userDeliverySettings.editAddress.title'
    });
    userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetCurrentAddressTypeInPopup, payload: type });
    
    const baseInitialValues: AddressFormData = {
      street: address.location.address.street ?? '',
      houseNumber: address.location.address.houseNumber ?? '',
      apartmentNumber: address.location.address.apartmentNumber,
      postalCode: address.location.address.postalCode ?? '',
      city: address.location.address.city,
      country: address.location.address.country,
    };
    userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetAddressId, payload: address.id });
    if (type === AddressTypeEnum.Delivery) {
      const newInitialValues: ShippingAddressFormData = {
        ...baseInitialValues,
        firstName: address.firstName,
        lastName: address.lastName,
        phone: address.phone ?? '',
      };
      userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetInitialShippingValues, payload: newInitialValues });
    } else {
      const newInitialValues: BillingAddressFormData = {
        ...baseInitialValues,
        firstName: address.firstName,
        lastName: address.lastName,
        taxId: address.taxId ?? '',
        companyName: address.companyName ?? '',
      };
      userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetInitialBillingValues, payload: newInitialValues });
    }
    userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetIsPopupVisible, payload: true });
  };

  const saveAddressMethod: (
    saveData: AddressSaveWithLocationModel,
    formRef: RefObject<FormikProps<ShippingAddressFormData>> | RefObject<FormikProps<BillingAddressFormData>>
  ) => void = (
    saveData: AddressSaveWithLocationModel,
    formRef: RefObject<FormikProps<ShippingAddressFormData>> | RefObject<FormikProps<BillingAddressFormData>>
  ): void => {
    saveAddress(saveData)
      .then((data: AddressInterface): void => {
        const editedAddress: AddressInterface | undefined = userDeliverySettingsState.addresses
          .find((address: AddressInterface): boolean => address.id === userDeliverySettingsState.addressId);
        if (editedAddress) {
          userDeliverySettingsState.addresses.splice(userDeliverySettingsState.addresses.indexOf(editedAddress), 1, {
            ...editedAddress,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            companyName: data.companyName,
            taxId: data.taxId,
            location: {
              ...editedAddress.location,
              address: {
                street: data.location.address.street,
                houseNumber: data.location.address.houseNumber,
                apartmentNumber: data.location.address.apartmentNumber,
                postalCode: data.location.address.postalCode,
                city: data.location.address.city,
                country: data.location.address.country,
              }
            }
          });
        } else {
          userDeliverySettingsDispatch({
            type: UserDeliverySettingsAction.SetAddresses,
            payload: [...userDeliverySettingsState.addresses, data]
          });
        }
        userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetIsPopupVisible, payload: false });
        setFormNotSubmitted();
        formRef.current?.resetForm();
      })
      .catch((): void => undefined);
  };

  const saveShippingAddress: () => Promise<void> = async (): Promise<void> => {
    if (!shippingFormRef?.current) {
      return;
    }

    const formObject: FormikProps<ShippingAddressFormData> = shippingFormRef.current;
    setFormSubmitted();
    const isValid: boolean = !Object.keys((await shippingFormRef.current.validateForm())).length;
    if (isValid) {
      const shippingSaveData: AddressSaveWithLocationModel = {
        id: userDeliverySettingsState.addressId,
        type: AddressTypeEnum.Delivery,
        firstName: formObject.values.firstName,
        lastName: formObject.values.lastName,
        phone: formObject.values.phone ?? '',
        location: {
          type: LocationTypeEnum.Approximate,
          latitude: 0,
          longitude: 0,
          country: formObject.values.country,
          city: formObject.values.city,
          postalCode: formObject.values.postalCode,
          street: formObject.values.street,
          houseNumber: formObject.values.houseNumber,
          apartmentNumber: formObject.values.apartmentNumber || null,
        }
      };
      saveAddressMethod(shippingSaveData, shippingFormRef);
    }
  };

  const saveBillingAddress: () => Promise<void> = async (): Promise<void> => {
    if (!billingFormRef?.current) {
      return;
    }

    const formObject: FormikProps<BillingAddressFormData> = billingFormRef.current;
    setFormSubmitted();
    const isValid: boolean = !Object.keys((await billingFormRef.current.validateForm())).length;

    if (isValid) {
      const billingBaseSaveData: AddressSaveWithLocationModel = {
        id: userDeliverySettingsState.addressId,
        type: AddressTypeEnum.Billing,
        firstName: formObject.values.firstName,
        lastName: formObject.values.lastName,
        location: {
          type: LocationTypeEnum.Approximate,
          latitude: 0,
          longitude: 0,
          country: formObject.values.country,
          city: formObject.values.city,
          postalCode: formObject.values.postalCode,
          street: formObject.values.street,
          houseNumber: formObject.values.houseNumber,
          apartmentNumber: formObject.values.apartmentNumber || null,
        },
        ...formObject.values.taxId ? { taxId: formObject.values.taxId } : {},
        ...formObject.values.companyName ? { companyName: formObject.values.companyName } : {},
      };
      saveAddressMethod(billingBaseSaveData, billingFormRef);
    }
  };

  const deleteAddressAction: (id: string) => void = (id: string): void => {
    deleteAddress(id)
      .then((): void => {
        userDeliverySettingsDispatch({
          type: UserDeliverySettingsAction.SetAddresses,
          payload: userDeliverySettingsState.addresses.filter((address: AddressInterface): boolean => address.id !== id)
        });
      })
      .catch((): void => undefined);
  };

  return (
    <Container>
      <BaseViewWithTabs>
        <InnerContainer>
          <AddressBox>
            <Header>{t('onarte.website.userDeliverySettings.shippingAddress.header')}</Header>
            {!deliveryAddresses.length ? (
              <StyledMessageBox message={t('onarte.website.userDeliverySettings.messages.noAddress')} />
            ) : (
              deliveryAddresses.map((address: AddressInterface, index: number): JSX.Element => (
                <StyledShippingBox
                  addressLines={[
                    `${address.firstName} ${address.lastName}`,
                    `${address.location.address.street ?? ''}
                    ${address.location.address.houseNumber ?? ''}
                    ${address.location.address.apartmentNumber ? `/ ${address.location.address.apartmentNumber}` : ''}`,
                    `${address.location.address.postalCode ?? ''} ${address.location.address.city}`,
                    `${formatPhone(address.phone ?? '')}`,
                  ]}
                  onEditClick={(): void => editAddress(AddressTypeEnum.Delivery, address)}
                  onRemoveClick={(): void => deleteAddressAction(address.id)}
                  key={`${address.firstName}_${index}`}
                />
              ))
            )}
            <ButtonContainer>
              <Button
                label={t('onarte.website.userDeliverySettings.addAddress.buttonLabel')}
                onClick={(): void => addAddress(AddressTypeEnum.Delivery)}
              />
            </ButtonContainer>  
          </AddressBox>
          <AddressBox>
            <Header>{t('onarte.website.userDeliverySettings.billingAddress.header')}</Header>
            {!billingAddresses.length ? (
              <StyledMessageBox message={t('onarte.website.userDeliverySettings.messages.noAddress')} />
            ) : (
              billingAddresses.map((address: AddressInterface, index: number): JSX.Element => (
                <StyledShippingBox
                  addressLines={[
                    `${address.firstName} ${address.lastName}`,
                    `${address.companyName ?? ''}`,
                    `${address.location.address.street ?? ''}
                    ${address.location.address.houseNumber ?? ''}
                    ${address.location.address.apartmentNumber ? `/ ${address.location.address.apartmentNumber}` : ''}`,
                    `${address.location.address.postalCode ?? ''} ${address.location.address.city}`,
                    `${address.taxId ? `${t('onarte.website.userDeliverySettings.taxId.prefix')} ${address.taxId}` : ''}`,
                  ]}
                  onEditClick={(): void => editAddress(AddressTypeEnum.Billing, address)}
                  onRemoveClick={(): void => deleteAddressAction(address.id)}
                  key={`${address.lastName}_${index}`}
                />
              ))
            )}
            <ButtonContainer>
              <Button
                label={t('onarte.website.userDeliverySettings.addAddress.buttonLabel')}
                onClick={(): void => addAddress(AddressTypeEnum.Billing)}
              />
            </ButtonContainer>  
          </AddressBox>
        </InnerContainer>
      </BaseViewWithTabs>
      <SmallDialog
        settings={{
          isActive: userDeliverySettingsState.isPopupVisible,
          header: t(userDeliverySettingsState.smallDialogHeaderTranslation),
          acceptButton: {
            label: t('onarte.common.save'),
            action: userDeliverySettingsState.currentAddressTypeInPopup === AddressTypeEnum.Billing
              ? saveBillingAddress
              : saveShippingAddress
          },
          theme: DialogTheme.Medium,
          cancelButton: {
            action: (): void => {
              userDeliverySettingsDispatch({ type: UserDeliverySettingsAction.SetIsPopupVisible, payload: false });
            },
            label: t('onarte.common.cancel'),
          }
        }}
      >
        {userDeliverySettingsState.currentAddressTypeInPopup === AddressTypeEnum.Delivery ? (
          <ShippingAddressForm
            initialValues={userDeliverySettingsState.initialShippingValues}
            isFormSubmitted={isFormSubmitted}
            ref={shippingFormRef}
          />
        ) : (
          <BillingAddressForm
            initialValues={userDeliverySettingsState.initialBillingValues}
            isFormSubmitted={isFormSubmitted}
            ref={billingFormRef}
          />
        )}
      </SmallDialog>
    </Container>
  );
};
