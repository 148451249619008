import { 
  AddressInterface, 
  AddressTypeEnum, 
  CartAuctionInterface, 
  CartCheckoutInterface, 
  CartInterface, 
  CartStatusEnum, 
  LocationAddressInterface, 
  PaymentGatewayInterface, 
  PaymentTypeEnum, 
  TranslationsEnum 
} from '@on-arte/core-types';
import {
  UseCounter, 
  UseLogger, 
  UseNotifications, 
  UseRedirect, 
  UseState, 
  getPathWithParams, 
  useCounter, 
  useLogger, 
  useNotifications, 
  useRedirect 
} from '@on-arte/ui';
import { Dispatch, useEffect, useMemo, useReducer, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { finishCart, getAvailableGateways, getCart, updateAddressIdInOrder, updatePaymentSettings } from '@onArte/frontend/api/requests';
import { allowedCartsStatuses } from '@onArte/frontend/constants';
import { QueryKey } from '@onArte/frontend/enums';
import { useConversions } from '@onArte/frontend/hooks';
import { UseConversions } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { UseCartBilling } from './cartBilling.types';
import { CartBillingAction, CartBillingActions, CartBillingState, cartBillingInitialState } from './reducer';
import { cartBillingReducer } from './reducer/cartBilling.reducer';

export const useCartBilling: (id: string) => UseCartBilling = (id: string): UseCartBilling => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const { addToast, showSmallDialog, updateAcceptButtonLabel }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const { startCounter, counter }: UseCounter = useCounter();
  const [paymentGateways, setPaymentGateways]: UseState<PaymentGatewayInterface[]> = useState<PaymentGatewayInterface[]>([]);
  const [isFinishButtonLoading, setIsFinishButtonLoading]: UseState<boolean> = useState<boolean>(false);
  const [paymentRedirectUrl, setPaymentRedirectUrl]: UseState<string> = useState<string>('');
  const [cartBillingState, cartBillingDispatch]: [CartBillingState, Dispatch<CartBillingActions>]
    = useReducer(cartBillingReducer, cartBillingInitialState);
  const { sendPurchaseEvent }: UseConversions = useConversions();

  useQuery(
    [QueryKey.BlueMediaGateways],
    (): Promise<PaymentGatewayInterface[]> => getAvailableGateways(),
    {
      onSuccess: (data: PaymentGatewayInterface[]): void => setPaymentGateways(data),
      onError: (error: FrontendApiError): void => logger(QueryKey.BlueMediaGateways, error)
    }
  );

  useQuery(
    [QueryKey.Cart],
    (): Promise<CartInterface> => getCart(id),
    {
      onSuccess: (dataCart: CartInterface): void => {
        checkIfViewShouldBeAvailable(dataCart);
        cartBillingDispatch({ type: CartBillingAction.SetCartData, payload: dataCart });
        if (dataCart.paymentType) {
          cartBillingDispatch({ type: CartBillingAction.SetPaymentMethod, payload: dataCart.paymentType });
        }
        if (dataCart.paymentGatewayId) {
          cartBillingDispatch({ type: CartBillingAction.SetGatewayId, payload: dataCart.paymentGatewayId });
        }
        if (dataCart.auctions.length) {
          if (dataCart.auctions[0].billingAddress) {
            cartBillingDispatch({ type: CartBillingAction.SetCurrentAddress, payload: dataCart.auctions[0].billingAddress });
          }
          if (dataCart.auctions[0].invoiceRequired) {
            cartBillingDispatch({ type: CartBillingAction.SetIsInvoiceRequired, payload: dataCart.auctions[0].invoiceRequired });
          }
        }
      },
      onError: (error: FrontendApiError): void => {
        if (error.message === TranslationsEnum.CartNotEditable) {
          redirect(getRouteDetailsByName(RouteNameEnum.CartSuccess)?.url ?? '/');
        } else if (error.message === TranslationsEnum.AccessDenied) {
          redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
        } else {
          logger(QueryKey.Cart, error);
        }
      }
    }
  );

  const blikGateway: PaymentGatewayInterface | undefined = useMemo(
    (): PaymentGatewayInterface | undefined => {
      return paymentGateways.find((gateway: PaymentGatewayInterface): boolean => gateway.paymentType === PaymentTypeEnum.Blik);
    },
    [paymentGateways]
  );

  const cardGateway: PaymentGatewayInterface | undefined = useMemo(
    (): PaymentGatewayInterface | undefined => {
      return paymentGateways.find((gateway: PaymentGatewayInterface): boolean => gateway.paymentType === PaymentTypeEnum.CardPayment);
    },
    [paymentGateways]
  );

  const onlineGateways: PaymentGatewayInterface[] = useMemo(
    (): PaymentGatewayInterface[] => {
      return paymentGateways
        .filter((gateway: PaymentGatewayInterface): boolean => ![PaymentTypeEnum.Blik, PaymentTypeEnum.CardPayment]
          .includes(gateway.paymentType)
        );
    },
    [paymentGateways]
  );

  const onInvoiceRequiredChange: (invoiceRequired: boolean) => void = (invoiceRequired: boolean): void => {
    if (id) {
      updateAddressIdInOrder(id, { invoiceRequired })
        .then((): void => {
          cartBillingDispatch({ type: CartBillingAction.SetIsInvoiceRequired, payload: invoiceRequired });
        })
        .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
    }
  };

  const goToDelivery: () => void = (): void => {
    redirect(
      getPathWithParams(getRouteDetailsByName(RouteNameEnum.CartDelivery)?.url ?? '/', { id: cartBillingState.cartData?.id ?? '' })
    );
  };

  const checkIfViewShouldBeAvailable: (dataCart: CartInterface) => void = (dataCart: CartInterface): void => {
    if (!allowedCartsStatuses.includes(dataCart.status) || !dataCart.auctions.length) {
      redirect(getRouteDetailsByName(RouteNameEnum.Home)?.url ?? '/');
    } else if (dataCart.auctions.some((cartAuction: CartAuctionInterface): boolean => !cartAuction.deliveryType)) {
      redirect(getPathWithParams(getRouteDetailsByName(RouteNameEnum.CartDelivery)?.url ?? '/', { id: dataCart.id ?? '' }));
    } else if ([CartStatusEnum.Closed].includes(dataCart.status)) {
      redirect(getRouteDetailsByName(RouteNameEnum.CartSuccess)?.url ?? '/');
    }
  };

  const onPaymentMethodChangeAction: (payment: PaymentTypeEnum, gatewayId?: string) => void = (
    payment: PaymentTypeEnum, gatewayId?: string
  ): void => {
    if (!cartBillingState.cartData) {
      return;
    }

    updatePaymentSettings(cartBillingState.cartData.id, { paymentType: payment, paymentGatewayId: gatewayId })
      .then((): void => {
        cartBillingDispatch({ type: CartBillingAction.SetPaymentMethod, payload: payment });
        cartBillingDispatch({ type: CartBillingAction.SetGatewayId, payload: gatewayId ?? '' });
      })
      .catch((error: FrontendApiError): void => logger(QueryKey.Cart, error));
  };

  const onFinishCartAction: () => void = (): void => {
    if (!cartBillingState.cartData) {
      return;
    }

    if (!cartBillingState.paymentMethod) {
      addToast({ content: t('onarte.website.useCartBilling.noPaymentType.failure') });
      return;
    }

    sendPurchaseEvent(cartBillingState.cartData.id, cartBillingState.cartData.finalPrice);
    setIsFinishButtonLoading(true);

    finishCart(cartBillingState.cartData.id)
      .then((response: CartCheckoutInterface): void => {
        if (!response.redirectUrl) {
          addToast({ content: t('onarte.website.useCartBilling.paymentDialog.failure') });
        } else {
          setPaymentRedirectUrl(response.redirectUrl);
          showSmallDialog({
            withCloseButton: false,
            header: t('onarte.website.useCartBilling.paymentDialog.header'),
            content: t('onarte.website.useCartBilling.paymentDialog.content'),
            acceptButton: {
              label: t('onarte.website.useCartBilling.paymentDialog.acceptButton', { seconds: 5 }),
              action: (): void => {
                if (response.redirectUrl) {
                  redirect(response.redirectUrl, { isExternal: true });
                }
              }
            },
          });
          startCounter(5);
        }
      })
      .catch((error: FrontendApiError): void => {
        setIsFinishButtonLoading(false);
        addToast({ content: t(error.message) });
        logger(QueryKey.Cart, error);
      });
  };

  useEffect(
    (): void => {
      if (counter > 0) {
        updateAcceptButtonLabel(t('onarte.website.useCartBilling.paymentDialog.acceptButton', { seconds: counter }));
      } else if (paymentRedirectUrl) {
        redirect(paymentRedirectUrl, { isExternal: true });
      }
    },
    [counter]
  );

  const deliveryAddress: string = useMemo((): string => {
    if (cartBillingState.cartData?.auctions?.length && cartBillingState.cartData?.auctions[0].deliveryAddress?.location.address) {
      const data: LocationAddressInterface = cartBillingState.cartData.auctions[0].deliveryAddress.location.address;
      const apartmentNumber: string = data.apartmentNumber
        ? `\/${data.apartmentNumber}`
        : '';
      const street: string = t('onarte.common.streetPrefix', { value: data.street });

      return `${street} ${data.houseNumber ?? ''}${apartmentNumber}, ${data.postalCode ?? ''} ${data.city}`;
    }

    return '';
  }, [cartBillingState.cartData]);

  const onAddressChoose: (address: AddressInterface) => void = (address: AddressInterface): void => {
    if (id) {
      updateAddressIdInOrder(id, { addressType: AddressTypeEnum.Billing, addressId: address.id })
        .then((): void => {
          cartBillingDispatch({ type: CartBillingAction.SetCurrentAddress, payload: address });
        })
        .catch((error: FrontendApiError): void => addToast({ content: t(error.message) }));
    }
  };

  return {
    blikGateway,
    cardGateway,
    onInvoiceRequiredChange,
    goToDelivery,
    isFinishButtonLoading,
    onPaymentMethodChangeAction,
    deliveryAddress,
    onFinishCartAction,
    onAddressChoose,
    cartBillingState,
    cartBillingDispatch,
    onlineGateways
  };
};
