import { Breakpoint, CollectionSection, Grid, SectionTitle, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div``;

export const StyledSectionTitle: StyledComponent<typeof SectionTitle> = styled(SectionTitle)`
  max-width: calc(${Grid.FullHd} + 80px);
  padding: 0 40px;
  margin: 0 auto 32px;

  @media ${Breakpoint.Tablet} {
    padding: 0 32px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;

export const StyledCollectionSection: StyledComponent<typeof CollectionSection> = styled(CollectionSection)`
  max-width: calc(${Grid.FullHd} + 80px);
  padding: 0 40px;
  margin: 0 auto;

  @media ${Breakpoint.Tablet} {
    padding: 0 32px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0;
  }
`;
