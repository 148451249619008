import { BoxesSlider, Breakpoint, Color, SectionTitle, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { StyledSectionTitleProps } from './blogBoxesSliderSection.types';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  position: relative;
  padding: 0 40px 120px;
  background-color: ${Color.Gray200};

  @media ${Breakpoint.SmallDesktop} {
    padding: 0 32px 120px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 20px 80px; 
  }
`;

export const StyledSectionTitle: StyledComponent<typeof SectionTitle, StyledSectionTitleProps>
  = styled(SectionTitle)<StyledSectionTitleProps>`
    width: 100%;
    margin: 0 auto 32px;
    padding-top: 120px;
    border-top: ${({ $withTopBar }: StyledSectionTitleProps): string => $withTopBar ? `1px solid ${Color.Gray300}` : 'none'};

    @media ${Breakpoint.Mobile} {
      padding-top: 80px;
    }
  `;

export const StyledBoxesSlider: StyledComponent<typeof BoxesSlider> = styled(BoxesSlider)`
  margin-left: -40px;
  width: calc(100% + 40px);

  @media ${Breakpoint.Tablet} {
    margin-left: -32px;
    width: calc(100% + 32px);
  }

  @media ${Breakpoint.Mobile} {
    margin-left: -20px;
    width: calc(100% + 20px);
  }
`;
