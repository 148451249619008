import { 
  ListPaginationInterface,
  PublicProfileTypeEnum,
  ReactionUsageContextTypeEnum, 
  SliderWithItemDataDTO, 
  SlidersArrayInterface, 
  ThumbnailAttachmentTypeEnum, 
  WebsiteSliderButtonPathTypeEnum, 
  WebsiteSliderTypeEnum 
} from '@on-arte/core-types';
import {
  BoxSlide, 
  BoxesSliderSlideSize, 
  HighlightSection, 
  UseLogger,
  UseRedirect, 
  UseState, 
  getPathWithParams, 
  useLogger,
  useRedirect
} from '@on-arte/ui';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getPublicProfilesPaginationList, getSliders } from '@onArte/frontend/api/requests';
import { BaseViewWithAlphabet, NewsletterBoxSection } from '@onArte/frontend/components';
import { QueryKey } from '@onArte/frontend/enums';
import { usePublicProfilesListActions, useReactions } from '@onArte/frontend/hooks';
import { HighlightSectionSettingsWithItemId, UsePublicProfilesListActions, UseReactions } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { emptyRequest, getImageThumbnail } from '@onArte/frontend/utils';
import { RouteNameEnum } from '@onArte/shared/enums';
import { PublicProfileExtendedListItem } from '@onArte/shared/interfaces';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { Container, StyledBoxesSliderSection, StyledNewsBoxesSection } from './artists.styled';

export const ArtistsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { logger }: UseLogger = useLogger();
  const { redirect }: UseRedirect = useRedirect();
  const {
    isFavourite,
    changeLikeState,
    favoritesReadyState,
    userFavouriteItemsIds,
    userFavouriteManufacturersIds
  }: UseReactions = useReactions();
  const { alphabet }: UsePublicProfilesListActions = usePublicProfilesListActions(PublicProfileTypeEnum.Manufacturer);
  const [authorsSlides, setAuthorsSlides]: UseState<BoxSlide[]> = useState<BoxSlide[]>([]);
  const [highlightSectionData, setHighlightSectionData]: UseState<HighlightSectionSettingsWithItemId | null>
    = useState<HighlightSectionSettingsWithItemId | null>(null);

  useQuery(
    [QueryKey.PublicProfilesPaginationList, favoritesReadyState],
    (): Promise<ListPaginationInterface<PublicProfileExtendedListItem> | void> => favoritesReadyState
      ? getPublicProfilesPaginationList(
        { type: PublicProfileTypeEnum.Manufacturer, sortByHighlighted: true, highlighted: true, limit: 100 }
      )
      : emptyRequest(),
    {
      onSuccess: (data: ListPaginationInterface<PublicProfileExtendedListItem> | void): void => {
        if (!data) {
          return;
        }

        setAuthorsSlides(data.list
          .map((artist: PublicProfileExtendedListItem): BoxSlide => {
            const isArtistLiked: boolean = isFavourite(artist.relationId, ReactionUsageContextTypeEnum.Manufacturer);

            return ({
              relationId: artist.relationId,
              image: getImageThumbnail(artist.avatar, ThumbnailAttachmentTypeEnum.Size_340xAuto),
              boxTitleDetails: {
                author: artist.name,
                bottomButtonLabel: t(`onarte.common.favourites.${isArtistLiked ? 'unfollow' : 'follow'}`),
                bottomButtonOnClick: (): void => changeLikeState(
                  artist.relationId, isArtistLiked, ReactionUsageContextTypeEnum.Manufacturer
                )
              },
              internalPath: artist.route?.prettyUrl ?? getPathWithParams(
                getRouteDetailsByName(RouteNameEnum.ArtistDetails)?.url ?? '', { id: artist.id }
              )
            });
          })
        );
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.PublicProfilesList, error)
    }
  );

  useQuery(
    [QueryKey.Sliders, favoritesReadyState],
    (): Promise<SlidersArrayInterface[] | void> => favoritesReadyState ? getSliders() : emptyRequest(),
    {
      onSuccess: (sliders: SlidersArrayInterface[]): void => {
        const slider: SlidersArrayInterface | undefined = sliders.find(
          (item: SlidersArrayInterface): boolean => item.type === WebsiteSliderTypeEnum.RecommendationsArtists
        );
        if (!slider?.slides?.[0]?.config) {
          return;
        }
        const slideConfig: SliderWithItemDataDTO = slider.slides[0].config;
        const isSlideLinkedItemFavourite: boolean = isFavourite(slideConfig.linkedItem?.id ?? '', ReactionUsageContextTypeEnum.Item);

        setHighlightSectionData({
          itemId: slideConfig.linkedItem?.id,
          header: slideConfig.header,
          favouriteBadgeDetails: slideConfig.linkedItem 
            ? {
              author: slideConfig.linkedItem.manufacturerName,
              description: slideConfig.linkedItem.name ?? '',
              isSelected: isSlideLinkedItemFavourite,
              onClick: (): void => changeLikeState(
                slideConfig.linkedItem?.id ?? '', isSlideLinkedItemFavourite, ReactionUsageContextTypeEnum.Item
              )
            }
            : undefined,
          backgroundImage: slideConfig.image.path,
          buttonSettings: { 
            internalPath: slideConfig.buttonSettings?.path?.type === WebsiteSliderButtonPathTypeEnum.Internal
              ? slideConfig.buttonSettings.path.value
              : undefined,
            externalUrl: slideConfig.buttonSettings?.path?.type === WebsiteSliderButtonPathTypeEnum.External
              ? slideConfig.buttonSettings.path.value
              : undefined,
            label: slideConfig.buttonSettings?.label,
          },
          description: slideConfig.description ?? '',
        });
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.Sliders, error)
    }
  );

  useEffect(
    (): void => {
      if (!highlightSectionData) {
        return;
      }

      setHighlightSectionData({
        ...highlightSectionData,
        favouriteBadgeDetails: {
          author: highlightSectionData.favouriteBadgeDetails?.author ?? '',
          description: highlightSectionData.favouriteBadgeDetails?.description ?? '',
          isSelected: !!userFavouriteItemsIds.includes(highlightSectionData.itemId ?? ''),
          onClick: (): void => changeLikeState(
            highlightSectionData.itemId ?? '', 
            !!userFavouriteItemsIds.includes(highlightSectionData.itemId ?? ''), 
            ReactionUsageContextTypeEnum.Item
          )
        }
      });
    },
    [userFavouriteItemsIds]
  );

  useEffect(
    (): void => {
      setAuthorsSlides(authorsSlides.map((slide: BoxSlide): BoxSlide => {
        const isManufacturerLiked: boolean = !!userFavouriteManufacturersIds
          .find((value: string): boolean => value === slide.relationId);

        return ({
          ...slide,
          boxTitleDetails: {
            author: slide.boxTitleDetails.author,
            bottomButtonLabel: t(`onarte.common.favourites.${isManufacturerLiked ? 'unfollow' : 'follow'}`),
            bottomButtonOnClick: (): void => changeLikeState(
              slide.relationId ?? '', isManufacturerLiked, ReactionUsageContextTypeEnum.Manufacturer
            )
          },
        });
      }));
    },
    [userFavouriteManufacturersIds]
  );

  return (
    <BaseViewWithAlphabet
      pageTitle={t('onarte.website.artistsView.pageTitle.title')}
      pageDescription={t('onarte.website.artistsView.pageTitle.description')}
      letterAction={(letter: string): void => redirect(`${getRouteDetailsByName(RouteNameEnum.ArtistsList)?.url ?? '/'}?query=${letter}`)}
      alphabet={alphabet}
    >
      <Container>
        {!!authorsSlides.length && (
          <StyledBoxesSliderSection
            header={t('onarte.website.artistsView.highlightedManufactures')}
            slides={authorsSlides}
            slideSize={BoxesSliderSlideSize.Big}
            withSliderWithoutMargin
          />
        )}
        {!!highlightSectionData && <HighlightSection {...highlightSectionData} />}
        <StyledNewsBoxesSection />
        <NewsletterBoxSection />
      </Container>
    </BaseViewWithAlphabet>
  );
};
