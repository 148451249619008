import { ColorValuesEnum } from '@on-arte/core-types';

export const colorAttributeValues: Record<string, string> = {
  [ColorValuesEnum.White]: '#fff',
  [ColorValuesEnum.SkyBlue]: '#99E0FF',
  [ColorValuesEnum.Brown]: '#997C32',
  [ColorValuesEnum.Black]: '#000',
  [ColorValuesEnum.Red]: '#CD4141',
  [ColorValuesEnum.Violet]: '#8C4BCD',
  [ColorValuesEnum.Copper]: '#C78B79',
  [ColorValuesEnum.Blue]: '#3F5FB3',
  [ColorValuesEnum.Orange]: '#FF7A00',
  [ColorValuesEnum.Pink]: '#FF84EB',
  [ColorValuesEnum.Silver]: '#E0E0E0',
  [ColorValuesEnum.Gray]: '#808380',
  [ColorValuesEnum.Green]: '#2CA940',
  [ColorValuesEnum.Gold]: '#D5BD65',
  [ColorValuesEnum.Yellow]: '#F5DF69',
  [ColorValuesEnum.Achromatic]: 'conic-gradient(from 180deg at 50% 50%, #C4C4C4 0deg, rgba(196, 196, 196, 0) 360deg)',
  [ColorValuesEnum.Multicolor]: 'conic-gradient(from 180deg at 50% 50%, #FF0000 0deg, #FAFF00 127.5deg, #00FDED 245.62deg, #2400FF 360deg)',
  [ColorValuesEnum.Metallic]: '#aaa9ad',
};
