import {
  CollectionBoxes,
  CollectionBoxItem,
  getPathWithParams,
  useLogger,
  UseLogger,
  useRedirect,
  UseRedirect,
  UseState
} from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getCollections } from '@onArte/frontend/api/requests';
import { QueryKey } from '@onArte/frontend/enums';
import { FrontendApiError } from '@onArte/frontend/models';
import { RouteNameEnum } from '@onArte/shared/enums';
import { Collection } from '@onArte/shared/interfaces';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { CollectionsContainer, Container, StyledPageTitle } from './collectionsList.styled';

export const CollectionsListView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const [collections, setCollections]: UseState<CollectionBoxItem[]> = useState<CollectionBoxItem[]>([]);
  const { logger }: UseLogger = useLogger();

  useQuery(
    [QueryKey.Collections],
    (): Promise<Collection[]> => getCollections(),
    {
      onSuccess: (data: Collection[]): void => {
        setCollections(data.map((item: Collection): CollectionBoxItem => ({
          label: item.name,
          image: item.coverPhoto,
          linkData: {
            internalPath: item.route?.prettyUrl
              ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.Collection)?.url ?? '/', { id: item.id })
          }
        })));
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.Collections, error)
    }
  );

  return (
    <Container>
      <StyledPageTitle 
        pageTitleSettings={{
          title: t('onarte.website.collectionsListView.pageTitle.title'),
          linkLabel: t('onarte.website.collectionsListView.pageTitle.link'),
          linkAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.ArtworksList)?.url ?? '/'),
          withUnderline: false,
          description: t('onarte.website.collectionsListView.pageTitle.description')
        }}
      />
      <CollectionsContainer>
        <CollectionBoxes items={collections} />
      </CollectionsContainer>
    </Container>
  );
};
