/* eslint-disable max-len */
export const faqContent: { title: string; content: string }[] = [
  {
    title: 'Jaką formę dostawy wybrać?',
    content: 'W zależności od obiektu i decyzji sprzedającego masz do wyboru transport on*arte lub odbiór osobisty. W ramach **transportu on&#42;arte** oferujemy specjalistyczną dostawę dedykowaną dziełom sztuki. Termin oraz inne szczegóły ustalimy z Tobą indywidualnie. Koszt transportu on*arte pokrywa zawsze kupujący i wynosi on 450 zł. \n\n **Odbiór osobisty** dzieła daje Tobie możliwość wyboru daty odbioru pracy. Szczegóły ustalisz bezpośrednio ze sprzedającym. Za odbiór osobisty on*arte nie pobiera opłat.'
  },
  {
    title: 'Czy jest możliwy zwrot zakupionego lub wylicytowanego dzieła?',
    content: 'Dokonując zakupu online na platformie onarte.pl zarówno w ramach oferty **Kup teraz** jak i **Licytacji** masz możliwość zwrotu pracy w ciągu 14 dni od daty odbioru pracy. Koszt transportu dzieła do sprzedającego pokrywa kupujący.'
  },
  {
    title: 'Jakie prowizje pobiera on*arte?',
    content: 'W przypadku szybkiej transakcji Kup teraz nie obciążamy kupującego prowizją. Standardowo pobieramy od sprzedającego 22% ceny sprzedaży dzieła. Dodatkowo zgodnie z europejskim prawem autorskim przy odsprzedaży pracy na rynku sztuki pobieramy maksymalnie 5% droit de suite dla autora lub autorki dzieła.'
  },
  {
    title: 'Czy on*arte dolicza opłatę aukcyjną?',
    content: 'Tak. Do końcowej ceny wylicytowanego obiektu doliczymy 10% wartości pracy z tytułu przeprowadzenia aukcji.'
  },
  {
    title: 'Ile mam czasu na opłacenie zakupu wylicytowanego dzieła?',
    content: 'W ciągu 7 dni od daty zakończenia aukcji opłać należność w panelu użytkownika w zakładce moje zakupy. Terminem płatności jest data zaksięgowania środków na koncie on*arte.'
  },
]; 
