import { IsDefined, IsString } from 'class-validator';

export class WebSocketSubscriptionDetails {
  @IsDefined()
  @IsString()
  public id: string;

  constructor(
    id: string,
  ) {
    this.id = id;
  }
}
