import { AttachmentInterface, ExternalAttachmentModel, UploadAttachmentTypeEnum } from '@on-arte/core-types';
import { AxiosResponse } from 'axios';

import { FrontendApiError } from '@onArte/frontend/models';
import { ApiReject, ApiResolve } from '@onArte/frontend/types';

import { api } from '../api';

export const uploadAttachment: (
  fileForm: FormData, onProgress: (progressEvent: ProgressEvent<FileReader>) => void, attachmentType: UploadAttachmentTypeEnum
) => Promise<AttachmentInterface> = (
  fileForm: FormData, onProgress: (progressEvent: ProgressEvent<FileReader>) => void, attachmentType: UploadAttachmentTypeEnum
): Promise<AttachmentInterface> => new Promise(
  (resolve: ApiResolve<AttachmentInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post(`/attachments/upload?type=${attachmentType}`, fileForm, { onUploadProgress: onProgress }).then(
      (response: AxiosResponse<AttachmentInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const saveExternalAttachment: (data: ExternalAttachmentModel) => Promise<AttachmentInterface> = (
  data: ExternalAttachmentModel
): Promise<AttachmentInterface> => new Promise(
  (resolve: ApiResolve<AttachmentInterface>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/attachments/external', data).then(
      (response: AxiosResponse<AttachmentInterface>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);

export const getAttachment: (objectName: string) => Promise<Buffer> = (objectName: string): Promise<Buffer> => new Promise(
  (resolve: ApiResolve<Buffer>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.get(`/attachments/${objectName}`).then(
      (response: AxiosResponse<Buffer>): void => { resolve(response?.data); }
    ).catch((error: AxiosResponse<FrontendApiError>): void => reject(error))
  )
);
