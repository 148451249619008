import { CollectionSectionItem, getPathWithParams, useLogger, UseLogger, useRedirect, UseRedirect, UseState } from '@on-arte/ui';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getCollections } from '@onArte/frontend/api/requests';
import { QueryKey } from '@onArte/frontend/enums';
import { FrontendApiError } from '@onArte/frontend/models';
import { RouteNameEnum } from '@onArte/shared/enums';
import { Collection } from '@onArte/shared/interfaces';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { Container, StyledCollectionSection, StyledSectionTitle } from './collectionSectionWithTitle.styled';
import { CollectionSectionWithTitleProps } from './collectionSectionWithTitle.types';

export const CollectionSectionWithTitle: React.FC<CollectionSectionWithTitleProps> = (
  props: CollectionSectionWithTitleProps
): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { className }: CollectionSectionWithTitleProps = props;
  const { redirect }: UseRedirect = useRedirect();
  const [collections, setCollections]: UseState<CollectionSectionItem[]> = useState<CollectionSectionItem[]>([]);
  const { logger }: UseLogger = useLogger();

  useQuery(
    [QueryKey.Collections],
    (): Promise<Collection[]> => getCollections(),
    {
      onSuccess: (data: Collection[]): void => {
        setCollections(data.map((item: Collection): CollectionSectionItem => ({
          label: item.name,
          backgroundImage: item.coverPhoto,
          onClick: (): void => redirect(
            item.route?.prettyUrl
              ?? getPathWithParams(getRouteDetailsByName(RouteNameEnum.Collection)?.url ?? '/', { id: item.id })),
        })));
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.Collections, error)
    }
  );

  return (
    <Container className={className}>
      <StyledSectionTitle
        header={t('onarte.website.collectionSectionWithTitle.header')}
        buttonLabel={t('onarte.common.showMore')}
        buttonAction={(): void => redirect(getRouteDetailsByName(RouteNameEnum.CollectionsList)?.url ?? '/')}
      />
      <StyledCollectionSection items={collections} />
    </Container>
  );
};
