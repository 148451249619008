import { AccountHeadline, Breakpoint, Color, Grid, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { ContentProps } from './baseViewWithTabs.types';

export const Container: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Gray200};
`;

export const StyledAccountHeadline: StyledComponent<typeof AccountHeadline> = styled(AccountHeadline)`
  max-width: calc(${Grid.FullHd} + 80px);
  margin: 0 auto;
  padding: 40px 40px 0;

  @media ${Breakpoint.Tablet} {
    padding: 0 32px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 0 20px;
  }
`;

export const ContentContainer: StyledComponent<'div'> = styled.div`
  background-color: ${Color.White};
  padding-top: 60px;
  padding-bottom: 120px;
`;

export const Content: StyledComponent<'div', ContentProps> = styled.div`
  max-width: calc(${Grid.FullHd} + 80px);
  margin: 0 auto;
  padding: ${({ $withoutPadding }: ContentProps): string => $withoutPadding ? '0' : '0 40px'};

  @media ${Breakpoint.Tablet} {
    padding: ${({ $withoutPadding }: ContentProps): string => $withoutPadding ? '0' : '0 32px'};
  }

  @media ${Breakpoint.SmallMobile} {
    padding: ${({ $withoutPadding }: ContentProps): string => $withoutPadding ? '0' : '0 20px'};
  }
`;
