import { Breakpoint, Color, FontWeight, Image, Markdown, StyledComponent, ZIndex } from '@on-arte/ui';
import styled from 'styled-components';

import { NewsletterBoxSection } from '@onArte/frontend/components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BackgroundContainer: StyledComponent<'div'> = styled.div`
  height: calc(100vh - 38px);
  width: 100%;
  position: relative;

  @media ${Breakpoint.SmallMobile} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const BackgroundImage: StyledComponent<typeof Image> = styled(Image)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  @media ${Breakpoint.SmallMobile} {
    position: static;
  }
`;

export const BackgroundContentWrapper: StyledComponent<'div'> = styled.div`
  position: absolute;
  top: 88px;
  left: 40px;
  z-index: ${ZIndex.Min};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 740px;

  @media ${Breakpoint.Tablet} {
    left: 32px;
  }

  @media ${Breakpoint.Mobile} {
    left: 20px;
  }

  @media ${Breakpoint.SmallMobile} {
    position: static;
    background-color: ${Color.Gray200};
    padding: 40px 20px;
  }
`;

export const BackgroundTitle: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.Medium};
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.01em;
  margin: 0 20px 32px 0;
  color: ${Color.White};

  @media ${Breakpoint.SmallMobile} {
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 32px 0;
    color: ${Color.Black};
  }
`;

export const QuoteContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 120px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${Breakpoint.Mobile} {
    padding: 80px 0;
  }
`;

export const CollectionBoxesContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: auto;
  padding: 40px 40px 120px;

  @media ${Breakpoint.Tablet} {
    padding: 40px 32px 120px;
  }
  
  @media ${Breakpoint.Mobile} {
    padding: 40px 0 80px;
  }
`;

export const TitleContainer: StyledComponent<'div'> = styled.div`
  padding: 120px 40px;
  max-width: 870px;

  @media ${Breakpoint.Tablet} {
    padding: 120px 32px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 80px 20px;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 40px 20px;
  }
`;

export const Title: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.Medium};
  font-size: 60px;
  line-height: 64px;
  letter-spacing: -0.01em;
  border-bottom: 1px solid ${Color.Gray200};
  padding: 0 0 120px;

  @media ${Breakpoint.Mobile} {
    font-size: 40px;
    line-height: 44px;
    padding: 0 0 80px;
  }

  @media ${Breakpoint.SmallMobile} {
    font-size: 30px;
    line-height: 38px;
    padding: 0 0 40px;
  }
`;

export const VideoTitle: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.Medium};
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.01em;
  width: 50%;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }

  @media ${Breakpoint.SmallMobile} {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const VideoContentWrapper: StyledComponent<'div'> = styled.div`
  width: 50%;
  margin-left: 40px;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
  }
`;

export const VideoContainer: StyledComponent<'div'> = styled.div`
  padding: 0 0 200px;
  margin: 0 auto;
  display: flex;
  width: calc(100% - 80px);

  @media ${Breakpoint.Tablet} { 
    width: calc(100% - 64px);
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 0 160px;
    width: calc(100% - 40px);
    flex-direction: column;
  }

  @media ${Breakpoint.SmallMobile} {
    padding: 0 0 120px;
  }
`;

export const VideoDescription: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.Light};
  font-size: 18px;
  line-height: 26px;

  p + p {
    margin-top: 32px;
  }

  @media ${Breakpoint.Mobile} {
    margin-top: 40px;
  }

  @media ${Breakpoint.SmallMobile} { 
    margin: 40px auto 0;
  }
`;

export const VideoPlayerBox: StyledComponent<'div'> = styled.div`
  margin-top: 40px;
  aspect-ratio: 16 / 9;

  @media ${Breakpoint.SmallMobile} {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
`;

export const VideoPlayer: StyledComponent<'iframe'> = styled.iframe``;

export const StyledNewsletterBoxSection: StyledComponent<typeof NewsletterBoxSection> = styled(NewsletterBoxSection)`
  margin-top: 120px;
`;
