import { creditCardExpirationRegexp, cvvRegexp, PaymentTypeEnum, PaymentVendorEnum } from '@on-arte/core-types';
import { IsCreditCard, IsDefined,  IsEnum, IsOptional, Matches } from 'class-validator';

export class CartUpdatePaymentSettingsModel {
  @IsDefined()
  @IsEnum(PaymentTypeEnum)
  public paymentType: PaymentTypeEnum;

  @IsOptional()
  public ownerName?: string;

  @IsCreditCard()
  @IsOptional()
  public cardNumber?: string;

  @Matches(creditCardExpirationRegexp)
  @IsOptional()
  public expiration?: string;
  
  @Matches(cvvRegexp)
  @IsOptional()
  public cvv?: string;

  @IsOptional()
  @IsEnum(PaymentVendorEnum)
  public paymentVendor?: PaymentVendorEnum;

  @IsOptional()
  public paymentGatewayId?: string | null;

  constructor(
    paymentType: PaymentTypeEnum,
    ownerName?: string,
    cardNumber?: string,
    expiration?: string,
    cvv?: string,
    paymentVendor?: PaymentVendorEnum,
    paymentGatewayId?: string | null
  ) {
    this.paymentType = paymentType;
    this.ownerName = ownerName;
    this.cardNumber = cardNumber;
    this.expiration = expiration;
    this.cvv = cvv;
    this.paymentVendor = paymentVendor;
    this.paymentGatewayId = paymentGatewayId;
  }
}
