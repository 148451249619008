import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { regexps } from '@onArte/shared/constants';

import { SetPasswordForm } from './setPassword.types';

export const useSetPasswordValidation: () => Yup.SchemaOf<SetPasswordForm> = (): Yup.SchemaOf<SetPasswordForm> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    password: Yup.string()
      .required(t('onarte.common.requiredField'))
      .matches(regexps.Password, t('onarte.common.validations.password'))
      .oneOf([Yup.ref('repeatedPassword'), null], t('onarte.common.validations.passwordsMustMatch')),
    repeatedPassword: Yup.string()
      .required(t('onarte.common.requiredField'))
      .matches(regexps.Password, t('onarte.common.validations.password'))
      .oneOf([Yup.ref('password'), null], t('onarte.common.validations.passwordsMustMatch')),
  });
};
