import { StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

import { ChildrenContainerProps } from './appWrapper.types';

export const ChildrenContainer: StyledComponent<'div', ChildrenContainerProps> = styled.div<ChildrenContainerProps>`
  margin-top: ${({ $withoutMarginTop }: ChildrenContainerProps): string => $withoutMarginTop ?  '-64px' : '0'};
`;

export const HeaderWrapper: StyledComponent<'div'> = styled.div`
  height: 64px;
`;
