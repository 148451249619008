import {
  AttachmentInterface,
  AttachmentTypeEnum,
  ExternalAttachmentInterface,
  ListPaginationInterface,
  ManufacturerInterface,
  PublicProfileListElementInterface,
  PublicProfileStatusEnum,
  PublicProfileTypeEnum,
  PublicProfileVersionModel,
  UploadedAttachmentInterface,
  VersionStatusEnum
} from '@on-arte/core-types';
import {
  AccountSubmenuPosition,
  AddPhotoFile,
  DropdownOption,
  FormFieldData,
  FormSectionData,
  IconName,
  UseFormikForm,
  useFormikForm,
  UseLogger,
  useLogger,
  useNotifications,
  UseNotifications,
  UseRedirect,
  useRedirect,
  UseState
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';
import { Params, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { getAllowedPublicProfiles, getManufacturersForLoggedUser, getPublicProfileDetails } from '@onArte/frontend/api/requests';
import { appConfig } from '@onArte/frontend/app.config';
import { BaseViewWithTabs } from '@onArte/frontend/components';
import { publicProfilesForm } from '@onArte/frontend/constants';
import { QueryKey } from '@onArte/frontend/enums';
import { useFiles, useObjectsTransformations } from '@onArte/frontend/hooks';
import { PublicProfileForm, UseFiles, UseObjectsTranformations } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { FormikForm } from '@onArte/frontend/theme';
import { emptyRequest } from '@onArte/frontend/utils';
import { PublicProfileDetails } from '@onArte/shared/interfaces';

import { usePublicProfileActions } from './hooks';
import { PublicProfileContextType } from './userPublicProfileSettings.enums';
import { useUserPublicProfiledValidation } from './userPublicProfileSettings.hooks';
import {
  InnerContainer,
  StyledAccountSubmenu,
  ContentContainer,
  StyledFormField,
  StyledFormSection,
  StyledButton,
  StyledMessageBox,
  BottomInformationBox,
  TopInformationBox
} from './userPublicProfileSettings.styled';
import { UsePublicProfileActions } from './userPublicProfileSettings.types';

export const UserPublicProfileSettingsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { contextId, contextType, contextName }: Readonly<Params<string>> = useParams();
  const [formFiles, setFormFiles]: UseState<Record<string, AddPhotoFile[]>> = useState<Record<string, AddPhotoFile[]>>({});
  const [menuPositions, setMenuPositions]: UseState<AccountSubmenuPosition[] | null> = useState<AccountSubmenuPosition[] | null>(null);
  const [loggedUserManufacturers, setLoggedUserManufacturers]: UseState<ManufacturerInterface[] | null>
    = useState<ManufacturerInterface[] | null>(null);
  const [isLoadingFinished, setIsLoadingFinished]: UseState<boolean> = useState<boolean>(false);
  const { transformAttachmentInterfaceToAddPhotoFile }: UseObjectsTranformations = useObjectsTransformations();
  const { setFormSubmitted, isFormSubmitted, getFormikFieldError }: UseFormikForm = useFormikForm();
  const { logger }: UseLogger = useLogger();
  const { addToast }: UseNotifications = useNotifications();
  const UserPublicProfiledValidation: Yup.SchemaOf<PublicProfileForm>  = useUserPublicProfiledValidation();
  const { addFile }: UseFiles = useFiles();
  const { redirect }: UseRedirect = useRedirect();
  const {
    submitAction,
    changeProfileActiveStatus,
    getMenuPositions,
    activeProfile,
    setActiveProfile,
    getAttachmentsFromProfile,
    allowedPublicProfiles,
    setAllowedPublicProfiles
  }: UsePublicProfileActions = usePublicProfileActions();
  const form: FormSectionData[] = useMemo(
    (): FormSectionData[] => {
      return publicProfilesForm.map((section: FormSectionData): FormSectionData => ({
        ...section,
        children: section.children.map((field: FormFieldData): FormFieldData => ({
          ...field,
          additionalFieldProps: {
            ...field.additionalFieldProps,
            ...(formFiles[field.name] ? { files: formFiles[field.name] } : { files: [] }),
            options: field.additionalFieldProps?.options
              ? field.additionalFieldProps?.options.map((option: DropdownOption): DropdownOption => ({
                ...option,
                label: t(option.label),
              }))
              : undefined,
          },
          description: field.description
            ? {
              ...field.description,
              content: t(field.description?.content ?? ''),
            }
            : undefined
        }))
      }));
    },
    [formFiles]
  );

  const allowedPublicProfilesQuery: QueryObserverResult = useQuery(
    [QueryKey.AllowedPublicProfiles],
    (): Promise<PublicProfileListElementInterface[]> => getAllowedPublicProfiles(),
    {
      onSuccess: (data: PublicProfileListElementInterface[]): void => setAllowedPublicProfiles(
        data.filter((profile: PublicProfileListElementInterface): boolean => {
          if (appConfig.hiddenModules.collectors) {
            return profile.type !== PublicProfileTypeEnum.Collector;
          }
          
          return true;
        })
      ),
      onError: (error: FrontendApiError): void => logger(QueryKey.AllowedPublicProfiles, error)
    }
  );

  const manufacturersForLoggedUserQuery: QueryObserverResult = useQuery(
    [QueryKey.ManufacturersForLoggedUser],
    (): Promise<ListPaginationInterface<ManufacturerInterface>> => getManufacturersForLoggedUser(),
    {
      onSuccess: (data: ListPaginationInterface<ManufacturerInterface>): void => setLoggedUserManufacturers(data.list),
      onError: (error: FrontendApiError): void => logger(QueryKey.ManufacturersForLoggedUser, error)
    }
  );

  useEffect(
    (): void => {
      if (loggedUserManufacturers) {
        setMenuPositions(getMenuPositions(loggedUserManufacturers));
      }
    },
    [loggedUserManufacturers, allowedPublicProfiles, activeProfile]
  );

  useEffect(
    (): void => {
      if (allowedPublicProfiles && activeProfile.name) {
        const profileIndex: number = allowedPublicProfiles
          .findIndex((item: PublicProfileListElementInterface): boolean => activeProfile.id === item.id);
        if (profileIndex !== -1) {
          const allowedPublicProfilesCopy: PublicProfileListElementInterface[] = [...allowedPublicProfiles];
          const attachmentsArray: AttachmentInterface[] = getAttachmentsFromProfile(activeProfile);

          allowedPublicProfilesCopy[profileIndex] = {
            ...allowedPublicProfilesCopy[profileIndex],
            avatar: attachmentsArray
              .find((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.PublicProfileAvatar) ?? null,
            ...(activeProfile.name ? { name: activeProfile.name } : {}),
            ...(activeProfile.status ? { status: activeProfile.status } : {}),
            ...(activeProfile.hasChangesToVerified ? { hasChangesToVerified: activeProfile.hasChangesToVerified } : {}),
          };
          setAllowedPublicProfiles(allowedPublicProfilesCopy);
        }
      }
    },
    [activeProfile]
  );

  const publicProfileDetailsQuery: QueryObserverResult = useQuery(
    [QueryKey.PublicProfileDetails, contextId],
    (): Promise<PublicProfileDetails | void> => contextId && contextType === PublicProfileContextType.Profile
      ? getPublicProfileDetails(contextId)
      : emptyRequest(),
    {
      onSuccess: (data: PublicProfileDetails | undefined): void => {
        if (data) {
          setActiveProfile(data);
          setFormFiles({});
          let formFilesValue: Record<string, AddPhotoFile[]> = {};
          const attachmentsArray: AttachmentInterface[] = getAttachmentsFromProfile(data);

          attachmentsArray.forEach((attachment: ExternalAttachmentInterface | UploadedAttachmentInterface): void => {
            if (!attachment.hasOwnProperty('objectName')) {
              return;
            }
            const attachmentObject: UploadedAttachmentInterface = attachment as UploadedAttachmentInterface;
            switch (attachmentObject.type) {
              case AttachmentTypeEnum.PublicProfileAvatar:
                formFilesValue = { ...formFilesValue, avatar: [...(formFilesValue.avatar ?? []), attachmentObject] };
                break;
              case AttachmentTypeEnum.PublicProfileCoverPhoto:
                formFilesValue = { ...formFilesValue, cover: [...(formFilesValue.cover ?? []), attachmentObject] };
                break;
              case AttachmentTypeEnum.PublicProfileGalleryPhoto:
                formFilesValue = { ...formFilesValue, galleryPhoto: [...(formFilesValue.galleryPhoto ?? []), attachmentObject] };
                break;
            }
          });
          setFormFiles(formFilesValue);
        }
      },
      onError: (error: FrontendApiError): void => {
        logger(QueryKey.PublicProfileDetails, error);
        addToast({ content: t(error.message) });
      }
    }
  );

  useEffect(
    (): void => {
      if (
        contextId !== '-'
        && contextType
        && [PublicProfileContextType.Manufacturer, PublicProfileContextType.Collector].includes(contextType as PublicProfileContextType)
      ) {
        setActiveProfile({
          name: contextName ? decodeURIComponent(contextName) : undefined,
          ...(Object.values(PublicProfileTypeEnum).includes(contextType as PublicProfileTypeEnum)
            ? { type: contextType as PublicProfileTypeEnum }
            : {}
          )
        });
        setFormFiles({});
      }
    },
    [contextId, contextType]
  );

  useEffect(
    (): void => {
      if (contextType === '-' && contextId === '-' && menuPositions?.length && menuPositions[0].internalPath) {
        redirect(menuPositions[0].internalPath);
      }
    },
    [contextId, contextType, menuPositions]
  );

  const submitFormAction: (data: PublicProfileForm) => void = (data: PublicProfileForm): void => {
    if (!contextId) {
      return;
    }

    submitAction(contextId, contextType as PublicProfileContextType, data);
  };

  const formValues: PublicProfileForm = useMemo(
    (): PublicProfileForm => {
      const versionWaitingForApproval: PublicProfileVersionModel | undefined = activeProfile
        ?.versions
        ?.find((version: PublicProfileVersionModel): boolean => version.status === VersionStatusEnum.WaitingForApproval);
      const attachments: AttachmentInterface[] = getAttachmentsFromProfile(activeProfile);

      return {
        type: activeProfile?.type ?? PublicProfileTypeEnum.Manufacturer,
        name: versionWaitingForApproval
          ? versionWaitingForApproval?.data?.name ?? ''
          : activeProfile?.name ?? '',
        description: versionWaitingForApproval
          ? versionWaitingForApproval?.data?.description ?? ''
          : activeProfile?.description ?? '',
        bio: versionWaitingForApproval
          ? versionWaitingForApproval?.data?.bio ?? ''
          : activeProfile?.bio ?? '',
        avatar: attachments
          .filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.PublicProfileAvatar)
          .map(transformAttachmentInterfaceToAddPhotoFile),
        cover: attachments
          .filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.PublicProfileCoverPhoto)
          .map(transformAttachmentInterfaceToAddPhotoFile),
        galleryPhoto: attachments
          .filter((attachment: AttachmentInterface): boolean => attachment.type === AttachmentTypeEnum.PublicProfileGalleryPhoto)
          .map(transformAttachmentInterfaceToAddPhotoFile),
      };
    },
    [activeProfile]
  );

  useEffect(
    (): void => {
      if (publicProfileDetailsQuery.isFetched && allowedPublicProfilesQuery.isFetched 
          && manufacturersForLoggedUserQuery.isFetched && menuPositions !== null) {
        setIsLoadingFinished(true);
      }
    },
    [allowedPublicProfilesQuery.isFetched, manufacturersForLoggedUserQuery.isFetched, publicProfileDetailsQuery.isFetched, menuPositions]
  );

  return (
    <BaseViewWithTabs>
      {isLoadingFinished && (
        menuPositions?.length ? (
          <InnerContainer>
            <StyledAccountSubmenu
              menuPositions={menuPositions}
              activePosition={activeProfile.id}
            />
            <ContentContainer>
              {activeProfile?.hasChangesToVerified && (
                <StyledMessageBox message={t('onarte.website.userPublicProfileSettingsView.underVerification')} />
              )}
              {!!Object.keys(activeProfile).length && !activeProfile?.id && (
                <StyledMessageBox message={t('onarte.website.userPublicProfileSettingsView.infoMessage')} />
              )}
              {activeProfile?.status === PublicProfileStatusEnum.InActive && (
                <TopInformationBox
                  informationDetails={{
                    name: t('onarte.website.userPublicProfileSettingsView.profileHiddenInfo.name'),
                    subName: t('onarte.website.userPublicProfileSettingsView.profileHiddenInfo.subName'),
                    onClick: (): void => changeProfileActiveStatus(true, contextId, activeProfile?.name),
                    buttonLabel:  t('onarte.website.userPublicProfileSettingsView.profileHiddenInfo.buttonLabel'),
                    iconName: IconName.Visibility
                  }}
                />
              )}
              <Formik
                initialValues={formValues}
                onSubmit={submitFormAction}
                validationSchema={UserPublicProfiledValidation}
                validateOnChange={isFormSubmitted}
                validateOnBlur={isFormSubmitted}
                enableReinitialize
              >
                {({ handleSubmit, setFieldValue, errors, values }: FormikProps<PublicProfileForm>) => (
                  <FormikForm onSubmit={handleSubmit}>
                    {form.map((section: FormSectionData): JSX.Element => (
                      <StyledFormSection title={t(section.label)} key={section.label} desktopRowPosition='vertical'>
                        {section.children.map((field: FormFieldData) => (
                          <StyledFormField
                            key={field.name}
                            fieldType={field.type}
                            fieldName={field.name}
                            setFieldValue={setFieldValue}
                            value={values[field.name as keyof PublicProfileForm]}
                            label={t(field.label ?? '')}
                            description={field.description}
                            disabled={field.disabled}
                            additionalFieldProps={field.additionalFieldProps}
                            validationMessage={isFormSubmitted
                              ? getFormikFieldError(errors[field.name as keyof PublicProfileForm])
                              : undefined
                            }
                            actions={{ addFile }}
                          />
                        ))}
                      </StyledFormSection>
                    ))}
                    <StyledButton
                      label={t('onarte.website.userPublicProfileSettingsView.submitButtonLabel')}
                      type='submit'
                      onClick={setFormSubmitted}
                    />
                    {activeProfile?.status === PublicProfileStatusEnum.Active && (
                      <BottomInformationBox
                        informationDetails={{
                          name:  t('onarte.website.userPublicProfileSettingsView.hideProfileInfo.name'),
                          subName:  t('onarte.website.userPublicProfileSettingsView.hideProfileInfo.subName'),
                          onClick: (): void => changeProfileActiveStatus(false, contextId, activeProfile?.name),
                          buttonLabel: t('onarte.website.userPublicProfileSettingsView.hideProfileInfo.buttonLabel'),
                          iconName: IconName.Visibility
                        }}
                      />
                    )}
                  </FormikForm>
                )}
              </Formik>
            </ContentContainer>
          </InnerContainer>
        ) : (
          <StyledMessageBox message={t('onarte.website.userPublicProfileSettingsView.emptyList')} />
        )
      )}
    </BaseViewWithTabs>
  );
};
