
import { initialShippingAddressValues } from '@onArte/frontend/constants';

import { CartDeliveryState } from './cartDelivery.interface';

export const cartDeliveryInitialState: CartDeliveryState = {
  cartAuctions: [],
  auctionAvailableDeliveries: {},
  isPopupVisible: false,
  currentAddress: undefined,
  activeAddressIdOnPopup: '',
  addressFormValues: initialShippingAddressValues,
  chosenAuctionDeliverySettings: {},
  commentToOrder: '',
};
