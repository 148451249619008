import { 
  ReactionUsageContextTypeEnum, 
  SliderElementInterface, 
  SlidersArrayInterface, 
  WebsiteSliderButtonPathTypeEnum, 
  WebsiteSliderTypeEnum 
} from '@on-arte/core-types';
import { 
  FullscreenSlide, 
  FullscreenSlider,
  useLogger, 
  UseLogger, 
  UseState 
} from '@on-arte/ui';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { getSliders } from '@onArte/frontend/api/requests';
import { QueryKey } from '@onArte/frontend/enums';
import { useReactions } from '@onArte/frontend/hooks';
import { UseReactions } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { emptyRequest } from '@onArte/frontend/utils';

import { FullscreenSliderSectionProps } from './fullscreenSliderSection.types';

export const FullscreenSliderSection: React.FC<FullscreenSliderSectionProps> = (props: FullscreenSliderSectionProps) => {
  const { className }: FullscreenSliderSectionProps = props;
  const { logger }: UseLogger = useLogger();
  const { isFavourite, changeLikeState, favoritesReadyState, userFavouriteItemsIds }: UseReactions = useReactions();
  const [slides, setSlides]: UseState<FullscreenSlide[]> = useState<FullscreenSlide[]>([]);

  useQuery(
    [QueryKey.Sliders, favoritesReadyState, userFavouriteItemsIds],
    (): Promise<SlidersArrayInterface[] | void> => favoritesReadyState ? getSliders() : emptyRequest(),
    {
      onSuccess: (sliders: SlidersArrayInterface[] | void): void => {
        if (!sliders) {
          return;
        }

        const slider: SlidersArrayInterface | undefined = sliders.find(
          (item: SlidersArrayInterface): boolean => item.type === WebsiteSliderTypeEnum.Homepage
        );
        if (slider) {
          const slidesArray: FullscreenSlide[] = slider.slides.map((item: SliderElementInterface): FullscreenSlide => {
            const isSlideLinkedItemFavourite: boolean = isFavourite(item.config.linkedItem?.id ?? '', ReactionUsageContextTypeEnum.Item);
            return {
              image: item.config.image.path,
              favouriteBadgeDetails: item.config.linkedItem 
                ? {
                  author: item.config.linkedItem?.manufacturerName ?? '',
                  description: item.config.linkedItem.name ?? '',
                  isSelected: isSlideLinkedItemFavourite,
                  onClick: (): void => changeLikeState(
                    item.config.linkedItem?.id ?? '', isSlideLinkedItemFavourite, ReactionUsageContextTypeEnum.Item
                  )
                } 
                : undefined,
              header: item.config.header,
              ...(item.config.buttonSettings?.label
                ? {
                  buttonSettings: {
                    label: item.config.buttonSettings?.label,
                    externalUrl: item.config.buttonSettings.path.type === WebsiteSliderButtonPathTypeEnum.External
                      ? item.config.buttonSettings.path.value
                      : undefined,
                    internalPath: item.config.buttonSettings.path.type === WebsiteSliderButtonPathTypeEnum.Internal
                      ? item.config.buttonSettings.path.value
                      : undefined,
                  }
                }
                : {}
              ),
              ...(item.config.secondButtonSettings?.label
                ? {
                  secondButtonSettings: {
                    label: item.config.secondButtonSettings.label,
                    externalUrl: item.config.secondButtonSettings.path.type === WebsiteSliderButtonPathTypeEnum.External
                      ? item.config.secondButtonSettings.path.value
                      : undefined,
                    internalPath: item.config.secondButtonSettings.path.type === WebsiteSliderButtonPathTypeEnum.Internal
                      ? item.config.secondButtonSettings.path.value
                      : undefined,
                  }
                }
                : {}
              ),
            };
            
          });
          setSlides(slidesArray);
        }
      },
      onError: (error: FrontendApiError): void => logger(QueryKey.Sliders, error)
    }
  );

  return slides.length ? (
    <FullscreenSlider
      slides={slides}
      slideHeight='calc(100vh - 36px)'
      className={className}
    />
  ) : null;
};
