import { FontWeight, MessageBox, ShippingBox, StyledComponent } from '@on-arte/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div``;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  max-width: 740px;
`;

export const AddressBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 60px;
  }
`;

export const Header: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.01em;
  margin-bottom: 32px;
`;

export const StyledMessageBox: StyledComponent<typeof MessageBox> = styled(MessageBox)`
  margin-bottom: 24px;
  width: 100%;
`;

export const ButtonContainer: StyledComponent<'div'> = styled.div`
  margin-top: 24px;
`;

export const StyledShippingBox: StyledComponent<typeof ShippingBox> = styled(ShippingBox)`
  & + & {
    margin-top: 12px;
  }
`;
