import { useRedirect, UseRedirect } from '@on-arte/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { BaseHandbookView } from '@onArte/frontend/components';
import { FileFromViews } from '@onArte/frontend/enums';
import { useConversions } from '@onArte/frontend/hooks';
import { UseConversions, UseHandbook } from '@onArte/frontend/interfaces';
import { RouteNameEnum } from '@onArte/shared/enums';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { useHandbookSeller } from './handbookSeller.hook';

export const HandbookSellerView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { newsSections, faqContent }: UseHandbook = useHandbookSeller();
  const { redirect }: UseRedirect = useRedirect();
  const { isFbqAvailable, sendViewContentEvent }: UseConversions = useConversions();

  useEffect(
    (): void => {
      if (isFbqAvailable) {
        sendViewContentEvent();
      }
    },
    [isFbqAvailable]
  );

  return (
    <BaseHandbookView 
      pageTitleSettings={{
        title: t('onarte.website.handbookSellerView.pageTitleSettings.title'),
        linkLabel: t('onarte.website.handbookSellerView.pageTitleSettings.linkLabel'),
        linkAction: (): void => redirect(getRouteDetailsByName(RouteNameEnum.HandbookBuyer)?.url ?? '/'),
        withUnderline: false
      }}
      backgroundImage={FileFromViews.SellerBackground}
      title={t('onarte.website.handbookSellerView.title')}
      newsBoxes={newsSections}
      // TODO: temporary hidden
      // accordionTitle={t('onarte.website.handbookSellerView.accordionTitle')}
      // accordionContent={faqContent}
      bigPhotoBoxData={{
        title: t('onarte.website.handbookSellerView.bigPhotoBoxTitle'),
        image: FileFromViews.BuyerBackground,
        label: t('onarte.website.handbookSellerView.bigPhotoBoxButtonLabel'),
        action: (): void => redirect(getRouteDetailsByName(RouteNameEnum.HandbookBuyer)?.url ?? '/')
      }}
    />
  );
};
