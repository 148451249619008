import { LoginByPasswordModel, TranslationsEnum } from '@on-arte/core-types';
import {
  Button,
  ButtonTheme,
  HeadingType,
  UseFormikForm,
  UseState,
  useFormikForm,
  UseRedirect,
  useRedirect,
  UseNotifications,
  useNotifications
} from '@on-arte/ui';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { loginRequest, sendConfirmMail } from '@onArte/frontend/api/requests';
import { LoginFormWrapper } from '@onArte/frontend/components';
import { useAuth } from '@onArte/frontend/hooks';
import { UseAuth } from '@onArte/frontend/interfaces';
import { FrontendApiError } from '@onArte/frontend/models';
import { FormikForm } from '@onArte/frontend/theme';
import { RouteNameEnum } from '@onArte/shared/enums';
import { UserProfileWithToken } from '@onArte/shared/interfaces/api';
import { getRouteDetailsByName } from '@onArte/shared/utils';

import { useSignInValidation } from './signIn.hooks';
import { Container, StyledButton, StyledHeading, StyledInput, StyledSocialMediaLoginBar, StyledValidationBar } from './signIn.styled';

export const SignInView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [loginError, setLoginError]: UseState<string> = useState<string>('');
  const { redirect }: UseRedirect = useRedirect();
  const { signIn }: UseAuth = useAuth();
  const SignInFormValidationSchema: Yup.SchemaOf<LoginByPasswordModel> = useSignInValidation();
  const { setFormSubmitted, isFormSubmitted }: UseFormikForm = useFormikForm();
  const [sendConfirmMailToken, setSendConfirmMailToken]: UseState<string> = useState<string>('');
  const { addToast }: UseNotifications = useNotifications();

  const loginAction: (data: LoginByPasswordModel) => void = (data: LoginByPasswordModel): void => {
    loginRequest(data)
      .then((response: UserProfileWithToken): void => {
        setLoginError('');
        signIn(response.user, response.contextToken, response.expiration);
      })
      .catch((error: FrontendApiError): void => {
        setLoginError(t(error.message));

        if (error.message === TranslationsEnum.AccountIsNotActivated) {
          setSendConfirmMailToken(error.messageParams.token);
        }
      });
  };

  const remindPassword: () => void = (): void => redirect(getRouteDetailsByName(RouteNameEnum.RemindPassword)?.url ?? '/');

  const sendConfirmMailAgain: () => void = (): void => {
    sendConfirmMail(sendConfirmMailToken)
      .then((): void => {
        addToast({ content: t('onarte.website.signInView.sendConfirmMailAgainSuccess') });
        setLoginError('');
      })
      .catch((): void => addToast({ content: t('onarte.website.signInView.sendConfirmMailAgainFailed') }));
  };

  return (
    <Container>
      <LoginFormWrapper
        activeTab='signIn'
        tabs={[
          {
            name: 'registration',
            label: t('onarte.website.common.signInTabs.signUp'),
            path: getRouteDetailsByName(RouteNameEnum.Registration)?.url ?? '/'
          },
          {
            name: 'signIn',
            label: t('onarte.website.common.signInTabs.signIn'),
            path: getRouteDetailsByName(RouteNameEnum.SignIn)?.url ?? '/'
          },
        ]}
      >
        <StyledHeading type={HeadingType.H4} text={t('onarte.website.signInView.header')} />
        <Formik
          initialValues={{ login: '', password: '' }}
          onSubmit={loginAction}
          validationSchema={SignInFormValidationSchema}
          validateOnChange={isFormSubmitted}
          validateOnBlur={isFormSubmitted}
        >
          {({ handleSubmit, setFieldValue, errors }: FormikProps<LoginByPasswordModel>) => (
            <FormikForm onSubmit={handleSubmit}>
              <StyledInput
                label={t('onarte.common.email')}
                onChange={(value: string): void => setFieldValue('login', value)}
                onBlur={(value: string): void => setFieldValue('login', value)}
                validationMessage={errors.login || ''}
              />
              <StyledInput
                label={t('onarte.common.password')}
                type='password'
                onChange={(value: string): void => setFieldValue('password', value)}
                onBlur={(value: string): void => setFieldValue('password', value)}
                validationMessage={errors.password || ''}
              />
              <StyledValidationBar 
                message={loginError}
                buttonLabel={sendConfirmMailToken ? t('onarte.website.signInView.validationBar.loginError.buttonLabel') : undefined} 
                buttonData={{ action: sendConfirmMailAgain }}
                renderButtonInline
              />
              {/* TODO: temporary hidden */}
              {/* <StyledSocialMediaLoginBar barLabel={t('onarte.website.signInView.socialMediaLoginBarLabel')} /> */}
              <StyledButton
                label={t('onarte.common.signIn')}
                type='submit'
                onClick={setFormSubmitted}
                fullWidth
              />
            </FormikForm>
          )}
        </Formik>
        <Button
          label={t('onarte.common.remindPassword')}
          onClick={remindPassword}
          buttonTheme={ButtonTheme.GrayTransparent}
          fullWidth
        />
      </LoginFormWrapper>
    </Container>
  );
};
